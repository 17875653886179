<template>
    <ValidationProvider :rules="validationRules" :name="label" v-slot="{ errors }" slim mode="eager">

        <OaoSearchSelect v-model="innerValue" :label="label" :searchKey="searchKey" :populatesWith="populatesWith"
            :options="options" :placeholder="placeholder" :disabled="disabled" :error="errors[0]"
            :dropdownOpenByDefault="dropdownOpenByDefault" :useInternalFiltering="useInternalFiltering" :allowFreeForm="allowFreeForm"
            @query="queryChanged" @blur="$emit('blur')" />

    </ValidationProvider>
</template>




<script>
import { ValidationProvider } from 'vee-validate';
import { OaoSearchSelect } from '@/components/form';

export default {

    name: 'FormSearchSelect',
    components: {

        ValidationProvider,
        OaoSearchSelect

    },
    props: {

        value: {
            required: true
        },
        label: {
            type: String,
            required: true
        },
        placeholder: {
            type: String,
            required: false,
            default: 'Please Select an Option'
        },
        options: {
            type: Array,
            required: true
        },
        disabled: {
            type: Boolean,
            required: false
        },
        searchKey: {
            type: String,
            required: true
        },
        isOptional: {
            type: Boolean,
            requied: false,
            default: false
        },
        validationRules: {
            type: [String, Object],
            required: false
        },
        dropdownOpenByDefault: {
            type: Boolean,
            require: false,
            default: false
        },
        useInternalFiltering: {
            type: Boolean,
            require: false,
            default: true
        },
        populatesWith: {
            type: String,
            required: true
        },
        allowFreeForm: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    computed: {

        innerValue: {

            get() {

                return this.value;

            },
            set(val) {

                this.$emit('input', val);

            }

        }

    },
    methods: {
        queryChanged(newQuery) {

            this.$emit('query', newQuery);

        }
    }

}

</script>