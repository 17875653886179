<template functional>

    <g>
        <path d="M16.9,1.5C14.3,3,11.8,2.1,9.1,1.2c-2.7-1-5.5-1.9-8.4-0.3L0.6,1v0.2V15v3h0.7v-3.4c2.5-1.4,5-0.6,7.6,0.3
            c1.6,0.5,3.3,1.1,4.9,1.1c1.1,0,2.3-0.3,3.4-1l0.2-0.1V1.2L16.9,1.5z M16.7,14.5c-2.5,1.4-5,0.6-7.6-0.3c-1.6-0.5-3.3-1.1-4.9-1.1
            c-1,0-1.9,0.2-2.9,0.7V1.4c2.5-1.4,5-0.5,7.6,0.4c2.6,0.9,5.2,1.8,7.8,0.6V14.5z"/>
        <path d="M1.9,2.1v3.2C2.9,5,4,4.8,5.1,5V1.8C4,1.7,2.9,1.8,1.9,2.1z"/>
        <path d="M9,6.1C10,6.4,11,6.7,12.1,7V3.9C11,3.6,10,3.3,9,2.9V6.1z"/>
        <path d="M1.9,9.3v3.2c1-0.4,2.1-0.5,3.1-0.4V8.9C4,8.8,2.9,8.9,1.9,9.3z"/>
        <path d="M9,13.2c1,0.4,2.1,0.6,3.1,0.9V11C11,10.7,10,10.4,9,10V13.2z"/>
        <path d="M5.5,8.6c1.1,0.2,2.1,0.5,3.1,0.9V6.3c-1-0.4-2.1-0.6-3.1-0.9V8.6z"/>
        <path d="M12.5,7.5v3.1c1,0.2,2.1,0.1,3.1-0.1V7.3C14.7,7.6,13.6,7.7,12.5,7.5z"/>
    </g>

</template>





<script>

    export default {

        name: 'CheckeredFlagIcon'

    }

</script>