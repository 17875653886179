import axios from '@/utils/axios';
import { Module } from 'vuex';
import { IRootState } from '..';
import setData from '../shared/setData';
import { disclosuresAPIv2 } from '@/api';

interface IDisclosureAcceptance {
	id: number;
	accepted: boolean;
}

interface IEStatementOption {
	id: number;
	submitValue: string;
}

export interface IDisclosuresState {
	disclosureAcceptances: IDisclosureAcceptance[];
	overridePolling: boolean;
	autoDecline: boolean;
	creditBuilderQualified: boolean;
	eStatementOption: IEStatementOption[];
}

export interface IPullElectronicDisclosureAcceptanceRequest {
	applicantToken: string;
	Retries: number;
	RetryCadenceMilliseconds: number;
}

const disclosures: Module<IDisclosuresState, IRootState> = {
	namespaced: true,
	state: {
		disclosureAcceptances: [],
		overridePolling: false,
		autoDecline: false,
		creditBuilderQualified: false,
		eStatementOption: []
	},
	mutations: {
		setData,
		resetState(state) {
			state.disclosureAcceptances = [];
			state.overridePolling = false;
			state.autoDecline = false;
			state.creditBuilderQualified = false;
			state.eStatementOption = [];
		}
	},
	actions: {
		async pullElectronicDisclosureAcceptance(
			{ state },
			request: IPullElectronicDisclosureAcceptanceRequest
		): Promise<Boolean> {
			try {
				for (let i = 0; i < request.Retries; i++) {
					if (state.overridePolling) return false;

					await new Promise(resolve => setTimeout(resolve, request.RetryCadenceMilliseconds));
					let response = await disclosuresAPIv2.getElectronicDisclosuresAcceptanceAsync(
						request.applicantToken
					);
					if (response.created) {
						return response.created;
					}
				}

				return false;
			} catch (error) {
			
				if (typeof error === 'string') {
					throw new Error(error);
				}

				throw error;
			}
		},
		async getDisclosureAcceptance(
			{ commit },
			request: any
		): Promise<disclosuresAPIv2.IDisclosureAcceptance | null> {
			const response = await disclosuresAPIv2.getDisclosureAcceptanceAsync(request);
			commit('setData', { objName: 'disclosureAcceptanceData', data: response });
			return null;
		},
		async createDisclosureAcceptanceAsync(
			{ commit },
			request: any
		): Promise<disclosuresAPIv2.IDisclosureAcceptanceCreateResponse> {
			const response = await disclosuresAPIv2.createDisclosureAcceptanceAsync(request);

			if (response.created) {
				commit('setData', { objName: 'autoDecline', data: response.autoDecline });
				commit('setData', { objName: 'creditBuilderQualified', data: response.creditBuilderQualified });
			}

			return response;
		}
	}
};

export default disclosures;
