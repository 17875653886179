<template function>
	<!--!Font Awesome Pro 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.-->
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
		<!--!Font Awesome Pro 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.-->
		<path
			d="M263.9 2.1C259-.7 253-.7 248.1 2.1L15.8 133.7C6 139.3 0 149.6 0 160.8C0 178 14 192 31.2 192H256 480.8c17.2 0 31.2-14 31.2-31.2c0-11.2-6-21.6-15.8-27.1L263.9 2.1zM306.6 160c3.5-7.3 5.4-15.4 5.4-24c0-30.9-25.1-56-56-56s-56 25.1-56 56c0 8.6 1.9 16.7 5.4 24H34.3L256 34.4 477.7 160H306.6zM256 112a24 24 0 1 1 0 48 24 24 0 1 1 0-48zM64 352c-8.8 0-16 7.2-16 16s7.2 16 16 16H448c8.8 0 16-7.2 16-16s-7.2-16-16-16V224H416V352H336V224H304V352H208V224H176V352H96V224H64V352zM40 416c-8.8 0-16 7.2-16 16s7.2 16 16 16H472c8.8 0 16-7.2 16-16s-7.2-16-16-16H40zM16 480c-8.8 0-16 7.2-16 16s7.2 16 16 16H496c8.8 0 16-7.2 16-16s-7.2-16-16-16H16z"
		/>
	</svg>
</template>

<script>
	export default {
		name: 'BankIcon'
	};
</script>
