import { applicantAPI, jointOwnerAPI } from '@/api';
import { Module } from 'vuex';
import { IRootState } from '..';
import setData from '../shared/setData';
import { CaptchaAction, IAuthenticateSSORequest } from '@/api/applicant';
import { MemberType, AuthenticationTypes } from '@/constants';
import localStorage from '@/store/localStorage';

export interface IAccount {
	accountId: number;
	accountTitle: string;
	maskedAccountNumber: string;
}

export interface IUserState {
	firstName: string;
	lastName: string;
	email: string;
	eligibilityEmail: string;
	applicationId: number;
	encodedApplicationId: string;
	memberType: number;
	applicationType: 'none' | 'joint' | 'single';
	accounts: IAccount[];
	username: string;
	isAffiliate: boolean;
	authenticationMethod: number;
	applicationToken: string;
	primaryApplicantToken: string;
	manuallyConfirmedEligibility: boolean;
}

const user: Module<IUserState, IRootState> = {
	namespaced: true,
	state: {
		firstName: '',
		lastName: '',
		email: '',
		eligibilityEmail: '',
		applicationId: 0,
		encodedApplicationId: '',
		memberType: 0,
		applicationType: 'none',
		accounts: [],
		username: '',
		isAffiliate: false,
		authenticationMethod: 0,
		applicationToken: '',
		primaryApplicantToken: '',
		manuallyConfirmedEligibility: false
	},
	mutations: {
		setData,
		resetState(
			state,
			{
				firstName = '',
				lastName = '',
				email = '',
				eligibilityEmail = '',
				applicationId = 0,
				memberType = 0,
				applicationType = 'none',
				accounts = [],
				username = '',
				isAffiliate = false,
				applicationToken = '',
				primaryApplicantToken = '',
				manuallyConfirmedEligibility = false
			}
		) {
			state.firstName = firstName;
			state.lastName = lastName;
			state.email = email;
			state.eligibilityEmail = eligibilityEmail;
			state.applicationId = applicationId;
			state.memberType = memberType;
			state.applicationType = applicationType;
			state.accounts = accounts;
			state.username = username;
			state.isAffiliate = isAffiliate;
			state.applicationToken = applicationToken;
			state.primaryApplicantToken = primaryApplicantToken;
			state.manuallyConfirmedEligibility = manuallyConfirmedEligibility;
		}
	},
	actions: {
		async getUser({ getters, state }): Promise<IUserState> {
			if (!getters.hasUser) {
				throw new Error('No user could be found');
			}

			return state;
		},

		async authenticateSSO(
			{ dispatch, commit },
			{ sso, clientIp }
		): Promise<{ isEligibleToUseOAO: boolean; captchaAction: CaptchaAction; key: number }> {
			try {
				const request: IAuthenticateSSORequest = {
					sso: sso,
					clientIp: clientIp,
					loadUrl: window.location.href
				};

				const {
					firstName,
					lastName,
					email,
					applicationId,
					isEligibleToUseOAO,
					captchaAction,
					memberType,
					key,
					applicationToken,
					primaryApplicantToken
				} = await applicantAPI.authenticateSSO(request);

				commit('setData', { objName: 'firstName', data: firstName });
				commit('setData', { objName: 'lastName', data: lastName });
				commit('setData', { objName: 'email', data: email });
				commit('setData', { objName: 'applicationId', data: applicationId });
				commit('setData', { objName: 'memberType', data: memberType });
				commit('setData', { objName: 'encodedApplicationId', data: key });
				commit('setData', { objName: 'applicationToken', data: applicationToken });
				commit('setData', { objName: 'primaryApplicantToken', data: primaryApplicantToken });
				commit('setData', { objName: 'authenticationMethod', data: AuthenticationTypes.SSO });

				await dispatch('setApplicationType', 'single');

				return { isEligibleToUseOAO, captchaAction, key };
			} catch (error) {
				throw error;
			}
		},
		async createApplicant({ commit, dispatch }, request) {
			try {
				const {
					email,
					applicationId,
					isApplicantMembershipEligible,
					captchaAction,
					key,
					applicationToken,
					primaryApplicantToken
				} = await applicantAPI.newApplicant(request);

				commit('setData', { objName: 'firstName', data: request.firstName });
				commit('setData', { objName: 'lastName', data: request.lastName });
				commit('setData', { objName: 'email', data: email });
				commit('setData', { objName: 'applicationId', data: applicationId });
				commit('setData', { objName: 'encodedApplicationId', data: key });
				commit('setData', { objName: 'memberType', data: MemberType.NEW });
				commit('setData', { objName: 'applicationToken', data: applicationToken });
				commit('setData', { objName: 'primaryApplicantToken', data: primaryApplicantToken });
				commit('setData', { objName: 'authenticationMethod', data: AuthenticationTypes.APPLICANT });

				//enable deviceID sdk for fraud monitoring
				await dispatch('identityVerification/initDeviceId', {}, { root: true });

				return { isApplicantMembershipEligible, captchaAction, key };
			} catch (error) {
				throw error;
			}
		},
		async authenticate({ commit }, request) {
			try {
				const {
					firstName,
					lastName,
					email,
					applicationId,
					isEligibleToUseOAO,
					captchaAction,
					memberType,
					key,
					authenticationResult,
					applicationToken,
					primaryApplicantToken
				} = await applicantAPI.authenticate(request);

				commit('setData', { objName: 'firstName', data: firstName });
				commit('setData', { objName: 'lastName', data: lastName });
				commit('setData', { objName: 'email', data: email });
				commit('setData', { objName: 'applicationId', data: applicationId });
				commit('setData', { objName: 'encodedApplicationId', data: key });
				commit('setData', { objName: 'memberType', data: memberType });
				commit('setData', { objName: 'applicationToken', data: applicationToken });
				commit('setData', { objName: 'primaryApplicantToken', data: primaryApplicantToken });
				commit('setData', { objName: 'authenticationMethod', data: AuthenticationTypes.AUTHENTICATE });

				return { authenticationResult, isEligibleToUseOAO, captchaAction, key };
			} catch (error) {
				throw error;
			}
		},
		async initializeApplication(
			{ dispatch, commit },
			{ selectedModel, userInput, captchaValidationToken, memberAdvocateId, captchaResults }
		) {
			try {
				const clientIp = (await applicantAPI.getApplicantIp()) || '127.0.0.1';
				let result;

				if (selectedModel === 'sso') {
					result = await dispatch('authenticateSSO', { sso: userInput, clientIp: clientIp });
				}

				const startApplicationRequest = {
					...userInput,
					validationToken: captchaValidationToken,
					clientIp: clientIp,
					memberAdvocateId: memberAdvocateId,
					captchaResults: captchaResults,
					loadUrl: window.location.href
				};

				if (selectedModel === 'authenticate') {
					result = await dispatch('authenticate', startApplicationRequest);
				}

				if (selectedModel === 'applicant') {
					result = await dispatch('createApplicant', startApplicationRequest);
				}

				localStorage.setOrOverwrite('encodedApplicationId', result.key);
				return result;
			} catch (e) {
				throw e;
			}
		},

		async setApplicationType({ commit, state }, applicationType: 'none' | 'joint' | 'single'): Promise<void> {
			try {
				const jointOwnerOption = applicationType === 'joint';

				await jointOwnerAPI.setJointOption(jointOwnerOption);

				commit('setData', { objName: 'applicationType', data: applicationType });
			} catch (error) {
				throw error;
			}
		},
		setV1UserNecessities({ commit, state }, request) {
			commit('setData', { objName: 'firstName', data: request.data.firstName });
			commit('setData', { objName: 'lastName', data: request.data.lastName });
			commit('setData', { objName: 'email', data: request.data.email });
			commit('setData', { objName: 'applicationId', data: request.data.applicationId });
			commit('setData', { objName: 'encodedApplicationId', data: request.data.key });
			commit('setData', { objName: 'memberType', data: request.data.memberType });
			commit('setData', { objName: 'applicationToken', data: request.data.applicationToken });
			commit('setData', { objName: 'primaryApplicantToken', data: request.data.primaryApplicantToken });
		},
		setEncodedApplicationId({ commit }, encodedId: string) {
			commit('setData', { objName: 'encodedApplicationId', data: encodedId });
		}
	},
	getters: {
		hasUser: state =>
			state.firstName && state.lastName && state.memberType && state.applicationType ? true : false,
		memberTypeId: state => state.memberType,
		applicationType: state => state.applicationType,
		email: state => state.email.toLowerCase(),
		applicationId: state => state.applicationId,
		eligibilityEmail: state => state.eligibilityEmail.toLowerCase(),
		fullName: state => (state.firstName && state.lastName ? `${state.firstName} ${state.lastName}` : null),
		isNewMember: state => state.memberType === MemberType.NEW,
		isExistingMember: state => state.memberType === MemberType.EXISTING,
		isAffiliate: state => state.isAffiliate,
		encodedApplicationId: state => state.encodedApplicationId || localStorage.get('encodedApplicationId'),
		manuallyConfirmedEligibility: state => state.manuallyConfirmedEligibility
	}
};

export default user;
