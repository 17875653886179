import { Module } from 'vuex';
import { IRootState } from '..';
import { cardSelectionAPI } from '@/api';
import { ICardSelection } from '@/api/_v2/cardSelection';

export interface ICardSelectionState {
	cardSelections: Array<ICardSelection>
}

const CardSelection: Module<ICardSelectionState, IRootState> = {
	namespaced: true,
	state: {
		cardSelections: new Array()
	},
	mutations: {
		addApplicantCardSelections(
			state: ICardSelectionState,
			{ cardSelections }: { cardSelections: ICardSelection[]; }
		) {
			if (!state.cardSelections || !state.cardSelections.length) {
				state.cardSelections = cardSelections;
			}
			else {
				cardSelections.forEach(card => {
					let applicantProductCard = state.cardSelections.find((existing) =>
						existing.applicantToken == card.applicantToken &&
						existing.applicationProductId == card.applicationProductId);
					if (!applicantProductCard) {
						state.cardSelections.push(card);
					} else if (applicantProductCard.cardConfigurationId !== card.cardConfigurationId) {
						// remove old selection
						let deleteIndex = state.cardSelections.indexOf(applicantProductCard);
						state.cardSelections.splice(deleteIndex, 1);
						// add new selection
						state.cardSelections.push(card);
					}
				});
			}
		},
		resetState(state: ICardSelectionState) {		
			state.cardSelections = [];	
		}
	},
	actions: {
		async getCardSelectionsByApplicantToken({ commit, state, rootGetters }: any, applicantToken: string): Promise<ICardSelection[]> {
			try {
				let response;
				if (!state.cardSelections || !state.cardSelections.length) {
					const request = { applicationToken: rootGetters['application/applicationToken'] };
					const { cardSelections: cards } = await cardSelectionAPI.getApplicationCardSelections(request);

					if (cards) {
						commit('addApplicantCardSelections', { cardSelections: cards });
					}
				}

				if (state.cardSelections && state.cardSelections.length > 0) {
					response = state.cardSelections.filter((card: ICardSelection) => card.applicantToken === applicantToken);
				}

				return response;
			} catch (error) {
			
				if (typeof error === 'string') {
					throw new Error(error);
				}
				throw error;
			}
		},
		async getCardSelectionsByApplicationProduct({ commit, state, rootGetters }: any, applicationProductId: number): Promise<ICardSelection[]> {
			try {
				let response;
				if (!state.cardSelections || !state.cardSelections.length) {
					const request = { applicationToken: rootGetters['application/applicationToken'] };
					const { cardSelections: cards } = await cardSelectionAPI.getApplicationCardSelections(request);
					if (cards && cards.length > 0) {
						commit('addApplicantCardSelections', { cardSelections: cards });
					}
				}

				if (state.cardSelections && state.cardSelections.length > 0) {
					response = state.cardSelections.filter((card: ICardSelection) => card.applicationProductId === applicationProductId);
				}

				return response;
			} catch (error) {
			
				if (typeof error === 'string') {
					throw new Error(error);
				}
				throw error;
			}
		},
		async setCardSelections(
			{ commit, rootGetters },
			{ cardSelections, applicantToken }: { cardSelections: ICardSelection[]; applicantToken: string }
		): Promise<boolean> {
			try {
				cardSelections.forEach(async cardSelection => {
					let request = {
						applicantToken: applicantToken,
						cardOrder: cardSelection,
						applicationToken: rootGetters['application/applicationToken']
					};
					if (!cardSelection.cardOrderId) {
						let apiResponse = await cardSelectionAPI.createCardSelection(request);
						cardSelection.cardOrderId = apiResponse.cardOrderId;
					} else {
						await cardSelectionAPI.updateCardSelection(request);
					}
				});
				commit('addApplicantCardSelections', {
					cardSelections: cardSelections
				});
				return true;
			} catch (error) {
				throw error;
			}
		},
		addReselectCards({ commit, state }, cardOrders: ICardSelection[]): void {
			if (cardOrders != null) {
				cardOrders.forEach(cardOrder => state.cardSelections.push(cardOrder));
			}
		}
	},
	getters: {
		getAllCardSelections: state => {
			return state.cardSelections;
		}
	}
};
export default CardSelection;
