<template>
	<div class="form-address-input">
		<OaoFormSearchSelect
			v-model="value.street"
			placeholder=""
			:options="suggestions"
			:disabled="disabled"
			searchKey="formattedSuggestion"
			populatesWith="streetLine"
			:label="addressTitle"
			:validationRules="streetValidationRules"
			:dropdownOpenByDefault="true"
			:useInternalFiltering="false"
			@query="handleQuery"
			@input="handleSearchSelectInput"
			allowFreeForm
		/>

		<ValidationProvider rules="alphaWithNumbers" name="APT, STE, ETC." vid="secondaryLine" v-slot="{ errors }" slim>
			<OaoInput
				v-model="innerValue.secondaryLine"
				label="APT, STE, ETC."
				@input="handleAddressInfoChange"
				isOptional
				:disableAutocomplete="disableBrowserAutoFill"
				:error="errors[0]"
			/>
		</ValidationProvider>
		<OaoFormInput
			v-model="innerValue.city"
			label="City"
			@input="handleAddressInfoChange"
			:disableAutocomplete="disableBrowserAutoFill"
			:validationRules="{ required: true }"
		/>

		<div class="form-row">
			<ValidationProvider :rules="validationRules" name="state" vid="state" v-slot="{ errors }" slim>
				<OaoSelect
					v-model="innerValue.state"
					label="State"
					:options="statesOptions"
					@input="handleAddressInfoChange"
					:disableAutocomplete="disableBrowserAutoFill"
				/>
			</ValidationProvider>

			<OaoFormInput
				v-model="innerValue.postalCode"
				label="Zip code"
				type="tel"
				:validationRules="{ required: true, regex: /^[0-9]{5}([- /]?[0-9]{4})?$/ }"
				:mask="['#####', '#####-####']"
				@input="handleAddressInfoChange"
				:disableAutocomplete="disableBrowserAutoFill"
			/>
		</div>
	</div>
</template>

<script>
	import _debounce from 'lodash.debounce';
	import { ValidationProvider } from 'vee-validate';
	import { logError } from '@/utils';
	import {
		queryAutocompleteForSuggestions,
		selectSuggestion,
		validateAddress,
		updateStateFromValidatedAddress,
		formatSuggestion,
		setSmartyAuthKey
	} from '@/utils/smartyUtil.js';
	import { mapGetters } from 'vuex';


	export default {
		name: 'OaoAddressAutoComplete',
		components: {
			ValidationProvider,
			OaoInput: () => import('@/components/form/OaoInput.vue'),
			OaoFormInput: () => import('@/components/OaoFormInput.vue'),
			OaoSelect: () => import('@/components/form/OaoSelect.vue'),
			OaoCheckbox: () => import('@/components/form/OaoCheckbox.vue'),
			OaoFormSearchSelect: () => import('@/components/OaoFormSearchSelect.vue')
		},
		props: {
			value: {
				required: true
			},
			addressTitle: {
				type: String,
				required: true
			},
			statesOptions: {
				required: true
			},
			type: {
				type: String,
				required: false,
				default: 'text'
			},
			placeholder: {
				type: String,
				required: false
			},
			disabled: {
				type: Boolean,
				required: false
			},
			validationRules: {
				type: [String, Object],
				required: false
			},
			streetValidationRules: {
				type: String,
				required: true
			},
			dateConfig: {
				type: Object,
				required: false
			},
			mask: {
				type: [String, Array],
				required: false
			},
			maskOutput: {
				type: Boolean,
				required: false
			},
			isOptional: {
				type: Boolean,
				required: false
			},
			disableAutocomplete: {
				type: Boolean,
				required: false
			},
			validationId: {
				type: String,
				required: false
			},
			isSmall: {
				type: Boolean,
				required: false,
				default: false
			},
			disableBrowserAutoFill: {
				type: Boolean,
				require: false,
				default: false
			}
		},
		data() {
			return {
				model: null,
				useAutoComplete: true,
				shouldValidate: true,
				address1: '',
				address2: '',
				city: '',
				state: '',
				zipCode: '',
				country: 'US',
				suggestions: [],
				error: ''
			};
		},
		async created() {
			try {
				this.address1 = this.value.street;
				this.state = this.value.state;
				this.city = this.value.city;
				this.zipCode = this.value.postalCode;

			//TODO: Consolidate to store/API layer.
			let responseBody = await this.$axios.get(`/api/VendorToken`);
            let vendorToken = responseBody.data.token;

			if (vendorToken == "disabled") {
				this.useAutoComplete = false;
			} else {
				this.setSmartyAuthKey(vendorToken);
			}
		} catch (error) {
			console.error(error);
		}
	},
	methods: {
		lookupAddress: _debounce(function (query) {
			//only search on query if query is not null, query is not the same as the last query, and the user is not selected subentries of an address
			if (query && query !== this.address1) {
				this.queryAutocompleteForSuggestions(query);
			}
		}, 600),
		queryAutocompleteForSuggestions,
		selectSuggestion,
		validateAddress,
		setSmartyAuthKey,
		updateStateFromValidatedAddress,
		formatSuggestion,
		handleQuery(query) {
			this.value.street = query;
			if (this.useAutoComplete) {
				this.lookupAddress(query)
			}
		},
		async handleSearchSelectInput(selectedSuggestion) {
			try {
				await this.selectSuggestion(selectedSuggestion)
			} catch (error) {
				console.error(error);
				logError(error, error?.requestId, this.applicantToken, this.applicationToken);
			}
		},
		handleAddressInfoChange() {
			this.value.state = this.innerValue.state;
			this.value.city = this.innerValue.city;
			this.value.postalCode = this.innerValue.postalCode;
		}
	},
	computed: {
		...mapGetters('applicant', ['applicantToken']),
		...mapGetters('application', ['applicationToken']),
		innerValue: {
			get() {
				this.value.state = this.state;
				this.value.city = this.city;
				this.value.postalCode = this.zipCode;
				return this.value;
			},
			set(val) {
				this.$emit('input', val);
			}
		}
	},
	watch: {
		value: {
			handler(newValue) {
				if (newValue.street === "" && newValue.city === "" && newValue.postalCode === "" && newValue.state === "") {
					this.address1 = "";
					this.state = "";
					this.city = "";
					this.zipCode = "";
				}
			},
			deep: true
		}
	}
};
</script>

<style lang="scss" scoped>
	.form-address-input {
		&:not(:last-child) {
			margin-bottom: 1rem;
		}

		.autocomplete-list-item {
			margin: 2px 0;
			text-align: left;
			font-size: 1.25rem;
		}

		.autocomplete-suggestion:hover {
			cursor: pointer;
			margin: 2px 0;
			background-color: rgb(0, 58, 121, 0.6);
			box-shadow: 4px var(--primary);
			text-align: left;
		}

		.autocomplete-modal {
			position: relative;
			display: inline-block;
			width: 100%;
		}

		.autocomplete-modal-gone {
			position: relative;
			display: inline-block;
			width: 100%;
		}

		.autocomplete-list-container:not(:empty) {
			position: absolute;
			padding: 0px;
			box-shadow: 2px 2px 2px 2px #111;
			background-color: rgb(239, 240, 242, 0.9);
			z-index: 99;
			top: 100%;
			left: 0;
			right: 0;
		}

		.form-row {
			display: flex;
			flex-direction: row;
			gap: 1rem;
		}
	}
</style>
