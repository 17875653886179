import { configurationsAPIv2 } from '@/api';
import { IPrintConfigGetResponse } from '@/api/_v2/configurations';

export interface IPrintConfigurationState {
	showOLBRegistration: boolean;
	showOLBRedirect: boolean;
	onlineMobileBankingDisclosure: string;
	onlineBankingLoginURL: string;
	olbRegisterLaterSelection: boolean;
}

const state: IPrintConfigurationState = {
	showOLBRegistration: false,
	showOLBRedirect: false,
	onlineMobileBankingDisclosure: '',
	onlineBankingLoginURL: '',
	olbRegisterLaterSelection: false
};

function resetState(state) {
	state.showOLBRegistration = false;
	state.showOLBRedirect = false;
	state.onlineMobileBankingDisclosure = '';
	state.onlineBankingLoginURL = '';
	state.olbRegisterLaterSelection = false;
}

const mutations = {
	//TODO: Add Mutations (if any)
};

const actions = {
	async getPrintPageConfigurations({ commit, rootState, state }: any): Promise<any> {
		try {
			if (!rootState.applicant.applicantToken) {
				//Fixes issue where using FAO 'start new app button' on print page causes created() method on initiating page to be inexplicably called again.
				return;
			}

			const request = {
				applicationToken: rootState.application.applicationToken,
				applicantToken: rootState.applicant.applicantToken
			};

			const response = await configurationsAPIv2.getPrintPageConfigurations(request);
			
			commit('setData', { objName: 'showOLBRegistration', data: response.showOLBRegistration });
			commit('setData', { objName: 'showOLBLogin', data: response.showOLBLogin });
			commit('setData', { objName: 'showOLBRedirect', data: response.showOLBRedirect });
			commit('setData', {
				objName: 'onlineMobileBankingDisclosure',
				data: response.onlineMobileBankingDisclosure
			});
			commit('setData', { objName: 'onlineBankingLoginURL', data: response.onlineBankingLoginURL });

			return response;
		} catch (error) {
		
			if (typeof error === 'string') {
				throw new Error(error);
			}

			throw error;
		}
	},
	setRegisterLaterSelection({ commit, state }: any, registerlater: boolean) {
		commit('setData', { objName: 'olbRegisterLaterSelection', data: registerlater });
	}
};

const getters = {
	showOLBLogin: state => state.showOLBLogin,
	olbRegisterLaterSelection: state => state.olbRegisterLaterSelection
};

export { state, resetState, mutations, actions, getters };

export default {
	state,
	resetState,
	mutations,
	actions,
	getters
};
