<template functional>

    <path d="M14.8,10.3C14.9,10.1,15,10,15,9.8V8.3c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.1H8.4l2.2-2.4
	c0.1-0.1,0.2-0.3,0.2-0.5s-0.1-0.4-0.2-0.5L9.5,3.2C9.4,3,9.2,3,9,3S8.6,3,8.5,3.2L4.2,7.4L3.2,8.5C3,8.6,2.9,8.8,2.9,9
	S3,9.4,3.2,9.5l1.1,1.1l4.2,4.2C8.6,15,8.8,15,9,15s0.4-0.1,0.5-0.2l1.1-1.1c0.1-0.1,0.2-0.3,0.2-0.5s-0.1-0.4-0.2-0.5l-2.2-2.4h5.9
	C14.5,10.5,14.6,10.4,14.8,10.3z M16.8,4.5C17.6,5.9,18,7.4,18,9s-0.4,3.1-1.2,4.5s-1.9,2.5-3.3,3.3S10.6,18,9,18s-3.1-0.4-4.5-1.2
	S2,14.9,1.2,13.5S0,10.6,0,9s0.4-3.1,1.2-4.5S3.1,2,4.5,1.2S7.4,0,9,0s3.1,0.4,4.5,1.2S16,3.1,16.8,4.5z"/>

</template>





<script>

    export default {

        name: 'ActionArrowIcon'

    }

</script>