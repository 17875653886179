interface localStorageItem {
	data: any;
	expiration?: number;
}

//Set a new value, or overwrite an expired value, in local storage
//If an expiration is not provided, then value will be stored until overwritten
const set = (key: string, data: any, expiration?: number): void => {
	try {
		const preexistingValue = JSON.parse(localStorage.getItem(key) || '') as localStorageItem;
		if (preexistingValue.expiration) {
			//if values expiration is in the future, do not overwrite
			if (new Date().getTime() < preexistingValue.expiration) return;
		}
	} catch (e) {
		if (!(e instanceof SyntaxError)) throw e;
	}

	const newValue: localStorageItem = {
		data: data
	};

	if (expiration) {
		newValue.expiration = new Date().getTime() + expiration;
	} else {
		//set default expiration of 4 hours
		newValue.expiration = new Date().getTime() + 1000 * 60 * 60 * 4;
	}

	return localStorage.setItem(key, JSON.stringify(newValue));
};

//Will set a value for a key even if it already exist and is not expired
const setOrOverwrite = (key: string, data: any, expiration?: number) => {
	const newValue: localStorageItem = {
		data: data
	};

	if (expiration) newValue.expiration = new Date().getTime() + expiration;

	return localStorage.setItem(key, JSON.stringify(newValue));
};

//gets value if unexpired, if key does not exist returns null
const get = (key: string): any => {
	const stringifedValue = localStorage.getItem(key);

	if (!stringifedValue) return stringifedValue;

	try {
		const value = JSON.parse(stringifedValue) as localStorageItem;

		if (!value.expiration) return value.data;

		//if the values expiration is in the future, then return it because it is still valid
		if (new Date().getTime() < value.expiration) {
			return value.data;
		} else {
			console.log('Session token expired. . . removing');
			localStorage.removeItem(key);
			return null;
		}
	} catch (e) {
		if (e instanceof SyntaxError) return null;
		throw e;
	}
};

const remove = (key: string): void => {
	try {
		localStorage.removeItem(key);
	} catch (e) {
		throw e;
	}
};

const hasKey = (key: string): boolean => {
	const stringifedValue = localStorage.getItem(key);

	if (!stringifedValue) return false;

	try {
		const value = JSON.parse(stringifedValue) as localStorageItem;
		if (!value.expiration) return false;

		//if the values expiration is in the future return true because record is still valid
		return new Date().getTime() < value.expiration;
	} catch (e) {
		if (e instanceof SyntaxError) return false;
		throw e;
	}
};

export default {
	set,
	setOrOverwrite,
	get,
	remove,
	hasKey
};
