export default [
	{
		path: 'funding',
		name: 'Funding',
		component: () => import(/* webpackChunkName: "Funding" */ '@/views/_v2/Funding'),
		meta: {
			hideBack: true,
			requiresUser: true,
			showNCUALogo: false
		}
	},
	{
		path: 'funding/transfer',
		name: 'FundingTransfer',
		component: () => import(/* webpackChunkName: "FundingTransfer" */ '@/views/_v2/Funding/Transfer'),
		meta: {
			requiresUser: true,
			hideHelp: true,
			backButton: {
				route: 'Funding',
				title: 'Return to the Funding page'
			}
		}
	},
	{
		path: 'funding/card',
		name: 'FundingCard',
		component: () => import(/* webpackChunkName: "FundingCard" */ '@/views/_v2/Funding/Card'),
		meta: {
			requiresUser: true,
			hideHelp: true,
			backButton: {
				route: 'Funding',
				title: 'Return to the Funding page'
			}
		}
	},
	{
		path: 'funding/externalTransfer',
		name: 'FundingExternal',
		component: () => import(/* webpackChunkName: "FundingExternal" */ '@/views/_v2/Funding/ExternalTransfer'),
		meta: {
			requiresUser: true,
			hideHelp: true,
			backButton: {
				route: 'Funding',
				title: 'Return to the Funding page'
			}
		}
	},
	{
		path: 'funding/authorizationReturn',
		name: 'AuthorizationReturn',
		component: () => import(/* webpackChunkName: "FundingExternal" */ '@/views/_v2/Funding/AuthorizationReturn'),
		meta: {
			hideBack: true,
			resetState: true,
			bypassEnforcer: true,
			showFooter: true
		}
	}
];
