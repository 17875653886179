import axios from '@/utils/axios';
import { Module } from 'vuex';
import { IRootState } from '..';
import setData from '../shared/setData';

interface IResumeApplicationConfiguration {
	id: number;
	name: string;
	description: string;
	value: string;
}

export interface IResumeApplicationConfigurationState {
	resumeApplicationEnabled: boolean;
}

const resumeApplicationConfiguration: Module<IResumeApplicationConfigurationState, IRootState> = {
	namespaced: true,
	state: {
		resumeApplicationEnabled: false
	},
	mutations: {
		setData,
		resetState(state) {
			state.resumeApplicationEnabled = false;
		}
	},
	actions: {
		async getResumeApplicationConfig({ commit, state }): Promise<IResumeApplicationConfigurationState> {
			//TODO: Figure out Caching
			//The caching bit commented out below is causing a decent number of issues and I'm not really sure how best to fix it as-is.
			//The code functions fine without it, but will make the call to the back-end each time instead of persisting the value.
			//Can explain the issue in further detail over teams if needed.

			//if (resumeApplicationConfiguration) {
			//    return { resumeApplicationEnabled: state.resumeApplicationEnabled }
			//}

			try {
				const {
					data: { resumeApplicationEnabled = false }
				} = await axios.get<{ resumeApplicationEnabled: boolean }>(`/api/Application/DisplayResumeApplication`);

				commit('setData', {
					objName: 'resumeApplicationEnabled',
					data: resumeApplicationEnabled
				});

				return { resumeApplicationEnabled };
			} catch (error) {
				
				if (typeof error === 'string') {
					throw new Error(error);
				}
				throw error;
			}
		}
	},
	getters: {
		resumeApplicationEnabled: state => state.resumeApplicationEnabled
	}
};

export default resumeApplicationConfiguration;
