import { render, staticRenderFns } from "./OaoNotification.vue?vue&type=template&id=68ef9958&scoped=true&functional=true"
import script from "./OaoNotification.vue?vue&type=script&lang=js"
export * from "./OaoNotification.vue?vue&type=script&lang=js"
import style0 from "./OaoNotification.vue?vue&type=style&index=0&id=68ef9958&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "68ef9958",
  null
  
)

export default component.exports