<template>
	<div id="app">
		<transition name="fade">
			<OaoOverlay v-if="isLoading">
				<BaseIcon height="6rem" width="6rem" name="Loader" viewBox="0 0 50 50">
					<ClockIcon />
				</BaseIcon>
			</OaoOverlay>
		</transition>

		<div v-if="!isError ? defaultsLoaded : true">
			<OaoBrowserMessage v-if="badBrowser" />
			<OaoSessionExpired v-if="sessionExpired" />

			<transition name="fade">
				<OaoModal
					v-if="modal.isOpen"
					:title="modal.title"
					:centeredTitle="modal.centeredTitle"
					:smallTitle="modal.smallTitle"
					:isWide="modal.isWide"
					:preventClose="modal.preventClose"
					@close="closeModal"
				>
					<template>
						<OaoIconCallout v-if="showContact">
							<template slot="icon">
								<BaseIcon name="Phone" height="35" width="35" class="contact-icon">
									<PhoneIcon />
								</BaseIcon>
							</template>
							<a
								:href="'tel:' + clientPhoneNumber"
								title="Have a question? Call us!"
								class="contact-text"
								>{{ clientPhoneNumber }}</a
							>
						</OaoIconCallout>
						<LeavingModal
							v-if="modal.isLeavingModal"
							@submit="handleLeavingModalSubmit"
							:leaveReasons="leaveReasonConfigs"
						/>
						<PortalTarget v-if="hasPortal.content" name="modal-content" />
					</template>

					<template slot="footer">
						<PortalTarget v-if="hasPortal.footer" name="modal-footer" />
					</template>
				</OaoModal>
			</transition>

			<OaoNav
				v-if="showNav"
				:backRoute="backButton.route"
				:backTitle="backButton.title"
				:hideHelp="$route.meta.hideHelp"
				:logo="logo"
				:logoTitle="`${clientName} Logo`"
				@contact="handleContact"
				@leavingModal="handleShowLeavingModal"
			/>

			<div class="wrapper" v-if="!badBrowser">
				<router-view />
			</div>
		</div>
	</div>
</template>

<script>
	import { mapActions, mapGetters, mapState } from 'vuex';

	import { BaseIcon, ClockIcon, PhoneIcon } from '@/components/icons';
	import { OaoIconCallout, OaoModal, OaoNav, OaoOverlay, OaoSessionExpired } from '@/components';
	import MemberType from '@/constants/MemberType';

	export default {
		name: 'App',
		components: {
			BaseIcon,
			ClockIcon,
			OaoBrowserMessage: () => import('@/components/OaoBrowserMessage'),
			PhoneIcon,
			OaoIconCallout,
			OaoModal,
			OaoNav,
			OaoOverlay,
			OaoSessionExpired,
			LeavingModal: () => import('@/views/_v2/Partials').then(({ LeavingModal }) => LeavingModal),
			RemoveProductModal: () =>
				import('@/views/_v2/Partials').then(({ RemoveProductModal }) => RemoveProductModal)
		},
		data() {
			return {
				showContact: false,
				displayLeavingModal: false,
				leaveReasons: [],
				resumeApplicationEnabled: false,
				memberType: MemberType.EXISTING,
				applicationProductInfo: null
			};
		},
		computed: {
			...mapState({
				modal: state => state.modal,
				badBrowser: state => state.unsupportedBrowser,
				sessionExpired: state => state.sessionTimer.sessionExpired
			}),
			...mapGetters('modal', ['hasPortal']),
			...mapGetters('routeEnforcer', ['previous']),
			...mapGetters('config', [
				'clientPhoneNumber',
				'dynamicHyperlinks',
				'clientWebsite',
				'displayLeavingModalToggle',
				'resumeApplicationConfigToggle',
				'isLoading',
				'defaultsLoaded'
			]),
			...mapGetters({
				leaveReasonConfigs: 'config/leaveReasonConfigs'
			}),
			...mapGetters('branding', ['clientName', 'logo']),
			...mapGetters('applicant', ['applicantMemberType']),
			isError() {
				return this.$route.meta.isError;
			},
			backButton() {
				const explicitRoute = this.$route.meta.backButton;
				const computedRoute = {
					route: this.previous,
					title: `Return to the ${this.previous ? this.previous.name : ''} page`
				};

				//backroute backbutton has client website option, we want the back button
				//to route to the clients external home website
				if (explicitRoute && explicitRoute.clientWebsite) {
					return {
						route: this.clientWebsite,
						title: `Return to ${this.clientName} Home`
					};
				}

				return explicitRoute || computedRoute;
			},
			showNav(){
				//show nav if we are on a route that hides nav
				//show nav if client name does exists stopping nav from showing defore load with no data
				return !this.$route.meta.hideNav && this.clientName
			}
		},
		async created() {
			this.mountListeners();
			this.mapHomeConfigurations();
		},
		methods: {
			...mapActions('modal', ['triggerModal', 'openLeavingModal', 'closeLeavingModal']),
			handleContact() {
				this.showContact = true;

				this.triggerModal({ isOpen: true, title: 'Have a question?' });
			},
			mountListeners() {
				document.addEventListener('visibilitychange', () => {
					if (document.visibilityState === 'hidden') {
						this.handleShowLeavingModal();
					}
				});
				document.addEventListener('mouseleave', (ev) => {
					if (ev.clientX < 0 || ev.clientY < 0 || ev.clientX > window.innerWidth || ev.clientY > window.innerHeight) {
						this.handleShowLeavingModal();
					}
				});
				document.addEventListener('blur', () => this.handleShowLeavingModal());
			},
			async handleShowLeavingModal() {
				if (this.sessionExpired) {
					return this.sessionExpired;
				} else {
					if (this.$route.meta.leavingModal) {
						let canDisplay = await this.canDisplayForMembers();
						if (canDisplay) {
							this.populateLeaveReasonsIfEmpty();

							this.openLeavingModal({
								title: 'Sorry to see you leave!',
								smallTitle: false,
								preventClose: this.preventClose
							});
						}
					}
				}
			},
			closeModal() {
				this.showContact = false;
				this.triggerModal({ isOpen: false });
			},
			handleLeavingModalSubmit(selected) {
				this.closeLeavingModal();
				this.populateLeaveReasonsIfEmpty();
				const reasonObject = this.leaveReasons.find(x => x.id == selected);
				return window.location.assign(reasonObject.externalLink);
			},
			async getMemberType() {
				this.memberType = this.applicantMemberType;
			},
			async canDisplayForMembers() {
				await this.getMemberType();
				if (this.memberType != MemberType.EXISTING) {
					return true;
				} else if (this.memberType == MemberType.EXISTING && this.displayLeavingModalToggle == true) {
					return true;
				} else {
					return false;
				}
			},
			populateLeaveReasonsIfEmpty() {
                if (!this.leaveReasons || !this.leaveReasons.length) {
                    this.leaveReasons = this.leaveReasonConfigs;
                }
			},
			mapHomeConfigurations() {
				this.resumeApplicationEnabled = this.resumeApplicationConfigToggle;
			}
		}
	};
</script>

<style lang="scss">
	@import './assets/globalStyles.css';
	@import './assets/animations.css';
</style>

<style lang="scss" scoped>
	#app {
		display: flex;
		flex-direction: column;
		min-height: 100vh;

		.contact-icon {
			color: var(--primary);
		}
		.contact-text {
			color: var(--secondary);
			text-decoration: none;
			font-size: 2.25rem;
			line-height: 2.25rem;
			font-weight: 600;
			transition: color 0.3s;

			&:hover {
				color: var(--primary);
			}
		}

		.wrapper {
			flex: 1;

			&.no-nav {
				padding-top: 0;
			}
		}
	}
</style>
