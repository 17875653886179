import axios from '@/utils/axios';

export interface IVerifyEligibilityResponse {
	isEligible: boolean;
}

const verifyEligibilityAsync = async (applicationToken: string): Promise<IVerifyEligibilityResponse> => {
	try {
		const { data } = await axios.get<IVerifyEligibilityResponse>(`api/v2/Eligibility/${applicationToken}`);

		return data;
	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};

export default {
	verifyEligibilityAsync
};

export { verifyEligibilityAsync };
