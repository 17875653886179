import coApplicantRoutes from './coApplicantRoutes';
import fundingRoutes from './fundingRoutes';

export default [
	{
		path: 'resume/:appId?',
		name: 'Resume',
		component: () => import(/* webpackChunkName: "Resume" */ '@/views/_v2/Resume'),
		meta: {
			backButton: {
				clientWebsite: true
			},
			resetState: true,
			forceNavigation: true,
			bypassEnforcer: true,
			bypassAudit: true
		}
	},
	{
		path: 'memberAdvocateLogin',
		name: 'memberAdvocateLogin',
		component: () => import(/* webpackChunkName: "Home" */ '@/views/_v2/MemberAdvocateLogin'),
		meta: {
			hideBack: true,
			resetState: true,
			bypassEnforcer: true,
			showFooter: true,
			ignoreIncomingStateReset: true
		}
	},
	{
		path: '/',
		name: 'Home',
		component: () => import(/* webpackChunkName: "Home" */ '@/views/_v2/Home'),
		meta: {
			hideBack: true,
			overflow: true,
			resetState: true,
			forceNavigation: true,
			bypassEnforcer: true,
			bypassAudit: true,
			showFooter: true,
			abandonApplication: true
		}
	},
	{
		path: 'eligibility',
		name: 'Eligibility',
		component: () => import(/* webpackChunkName: "Eligibility" */ '@/views/_v2/Eligibility')
	},
	{
		path: 'select',
		name: 'Select',
		component: () => import(/* webpackChunkName: "Select" */ '@/views/_v2/Select'),
		meta: {
			requiresUser: true,
			leavingModal: true //allows 'why are you leaving modal' to show on page
		}
	},
	{
		path: 'cards/:index',
		name: 'Cards',
		component: () => import(/* webpackChunkName: "Print" */ '@/views/_v2/Cards'),
		//component: () => import(/* webpackChunkName: "Cards" */ '@/views/Cards'),
		meta: {
			requiresUser: true
		}
	},
	{
		path: 'contact',
		name: 'Contact',
		component: () => import(/* webpackChunkName: "Contact" */ '@/views/_v2/Contact'),
		meta: {
			requiresUser: true,
			isCoApplicant: false,
			module: 'applicant'
		}
	},
	{
		path: 'employment',
		name: 'Employment',
		component: () => import(/* webpackChunkName: "Employment" */ '@/views/_v2/Employment'),
		meta: {
			requiresUser: true,
			isCoApplicant: false,
			module: 'applicant'
		}
	},
	{
		path: 'identity',
		name: 'Identity',
		component: () => import(/* webpackChunkName: "Identity" */ '@/views/_v2/Identity'),
		meta: {
			requiresUser: true,
			isCoApplicant: false,
			module: 'applicant'
		}
	},
	{
		path: 'disclosures',
		name: 'Disclosures',
		component: () => import(/* webpackChunkName: "Disclosures" */ '@/views/_v2/Disclosures'),
		meta: {
			requiresUser: true,
			isCoApplicant: false,
			module: 'applicant'
		}
	},
	{
		path: 'reselect',
		name: 'Reselect',
		component: () => import(/* webpackChunkName: "Reselect" */ '@/views/_v2/Reselect'),
		meta: {
			requiresUser: true,
			forceNavigation: true
		}
	},
	{
		path: 'overdraft',
		name: 'Overdraft',
		component: () => import(/* webpackChunkName: "Verify" */ '@/views/_v2/Overdraft'),
		meta: {
			requiresUser: true,
			hideBack: true,
			forceNavigation: true //ToDo revisit/revise this with MT work
		}
	},
	{
		path: 'coApplicantSelect',
		name: 'CoApplicantSelect',
		component: () => import(/* webpackChunkName: "CoApplicantSelect" */ '@/views/_v2/CoApplicantSelect'),
		meta: {
			hideBack: true,
			requiresUser: true,
			module: 'coApplicant'
		}
	},
	...coApplicantRoutes,
	...fundingRoutes,
	{
		path: 'sign',
		name: 'Sign',
		component: () => import(/* webpackChunkName: "Sign" */ '@/views/_v2/Sign'),
		meta: {
			hideNav: true,
			requiresUser: true
		}
	},
	{
		path: 'register',
		name: 'Register',
		component: () => import(/* webpackChunkName: "Print" */ '@/views/_v2/Register'),
		meta: {
			requiresUser: true,
			hideNav: true,
			bypassAudit: false,
			forceNavigation: true,
			bypassEnforcer: true
		}
	},
	{
		path: 'print',
		name: 'Print',
		component: () => import(/* webpackChunkName: "Print" */ '@/views/_v2/Print'),
		meta: {
			requiresUser: true,
			/*hideNav: true,*/
			bypassAudit: true,
			forceNavigation: true,
			bypassEnforcer: true
		}
	}
];
