/* INHERITS STATE, MUTATIONS, ACTIONS and INTERFACE from ../shared/applicant.ts */
import { applicantAPIv2 } from '@/api';
import { Module } from 'vuex';
import { IRootState } from '..';
import { applicantActions, applicantMutations, applicantState, applicantGetters } from '../shared';
import { IApplicantState } from '../shared/applicant';
import { INewApplicantResponse } from '@/api/_v2/applicant';

const applicant: Module<IApplicantState, IRootState> = {
	namespaced: true,
	state: {
		...applicantState
	},
	mutations: {
		...applicantMutations
	},
	actions: {
		...applicantActions,
		async resetState({ commit }) {
			commit('resetApplicantState');
		},
		async createApplicant({ commit, dispatch }, request: any): Promise<INewApplicantResponse> {
			try {
				const response = await applicantAPIv2.createApplicant(request);

				return response;
			} catch (error) {
				throw error;
			}
		}
	},
	getters: {
		...applicantGetters
	}
};

export default applicant;
