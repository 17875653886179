<template>
	
	<div class="funding-row">
		<BaseIcon :name="title" :height="iconHeight" :width="iconWidth" :viewBox="iconViewbox" :minWidth="iconWidth">
			<component :is="iconName" />
		</BaseIcon>

		<span>{{ description }}</span>
	</div>
	
</template>

<script>
	import { BaseIcon, BankIcon, SmartphoneIcon } from '@/components/icons';

	export default {
		name: 'NoFundCard',
		components: {
			BaseIcon,
			BankIcon,
			SmartphoneIcon
		},
		props: {
			title: {
				type: String,
				required: true
			},
			iconHeight: {
				type: String,
				required: true
			},
			iconWidth: {
				type: String,
				required: true
			},
			iconViewbox: {
				type: String,
				required: true
			},
			iconName: {
				type: String,
				required: true
			},
			description: {
				type: String,
				required: true
			}
		}
	};
</script>

<style lang="scss" scoped>
    .funding-row {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        align-items: center;
    }
</style>
