import _cloneDeep from 'lodash.clonedeep';
import { IAffinityConfiguration, IAffiliateTriggerConfiguration } from '@/api/_v2/affiliationConfiguration';
import affiliationTriggerTypes from '@/constants/AffiliationTriggerTypes';
import { affiliationConfigurationAPIv2 } from '@/api';
export interface IAffiliateConfigurationsState {
	affinityConfigurations: IAffinityConfiguration[];
	cardTriggers: IAffiliateTriggerConfiguration[];
	domainTriggers: IAffiliateTriggerConfiguration[];
}

const state: IAffiliateConfigurationsState = {
	affinityConfigurations: [],
	cardTriggers: [],
	domainTriggers: []
};

function resetState(state: any) {
	state.affinityConfigurations = [];
	state.cardTriggers = [];
	state.domainTriggers = [];
}
const actions = {
	async getAffinityConfigurations({ state, commit }): Promise<any> {
		try {
			if (!state.cardTriggers.length) {
				const { affiliationTriggers = [], affinityConfigurations = [] } =
					await affiliationConfigurationAPIv2.getAffiliationTriggerConfigurations();
				commit('setData', { objName: 'affinityConfigurations', data: affinityConfigurations });

				commit('setData', {
					objName: 'cardTriggers',
					data: affiliationTriggers.filter(x => x.triggerTypeId == affiliationTriggerTypes.CARD)
				});
				commit('setData', {
					objName: 'domainTriggers',
					data: affiliationTriggers.filter(x => x.triggerTypeId == affiliationTriggerTypes.EMAIL)
				});
				commit('setData', { objName: 'isLoaded', data: true });
			}
			return {
				cardTriggers: state.cardTriggers,
				domainTriggers: state.domainTriggers,
				affinityConfigurations: state.affinityConfigurations
			};
		} catch (error) {
			if (typeof error === 'string') {
				throw new Error(error);
			}
			throw error;
		}
	}
};
const getters = {
	affinityConfigurations: state => state.affinityConfigurations,
	affiliateCardTriggers: state => state.cardTriggers,
	affiliateDomainTriggers: state => state.domainTriggers
};

export { state, resetState, actions, getters };
