export default [
	{
		path: 'co-applicant/contact',
		name: 'CoApplicantContact',
		component: () => import(/* webpackChunkName: "CoApplicantContact" */ '@/views/_v2/Contact'),
		meta: {
			hideBack: true,
			requiresUser: true,
			isCoApplicant: true,
			module: 'coApplicant'
		}
	},
	{
		path: 'co-applicant/employment',
		name: 'CoApplicantEmployment',
		component: () => import(/* webpackChunkName: "CoApplicantEmployment" */ '@/views/_v2/Employment'),
		meta: {
			requiresUser: true,
			isCoApplicant: true,
			module: 'coApplicant'
		}
	},
	{
		path: 'co-applicant/identity',
		name: 'CoApplicantIdentity',
		component: () => import(/* webpackChunkName: "CoApplicantIdentity" */ '@/views/_v2/Identity'),
		meta: {
			requiresUser: true,
			isCoApplicant: true,
			module: 'coApplicant'
		}
	},
	{
		path: 'co-applicant/disclosures',
		name: 'CoApplicantDisclosures',
		component: () => import(/* webpackChunkName: "CoApplicantDisclosures" */ '@/views/_v2/Disclosures'),
		meta: {
			requiresUser: true,
			isCoApplicant: true,
			module: 'coApplicant'
		}
	}
];
