import { OaoSessionWarning } from '@/components';

import axios from '@/utils/axios';
import { Module } from 'vuex';
import { IRootState } from '..';
import setData from '../shared/setData';

// TODO: Remove TS Ignores by instantiating Toasts at file level instead of injecting it directly into Vue

interface IConfig {
	warningInterval: number | null;
	timeoutWarningMessage?: string;
	expiredInterval: number | null;
}
export interface ISessionTimerState {
	config: IConfig;
	warningTimer: ReturnType<typeof setTimeout> | undefined;
	showWarning: boolean;
	expiredTimer: ReturnType<typeof setTimeout> | undefined;
	sessionExpired: boolean;
	warningToastId: number | null;
}

const sessionTimer: Module<ISessionTimerState, IRootState> = {
	namespaced: true,
	state: {
		config: {
			warningInterval: null,
			timeoutWarningMessage: '',
			expiredInterval: null
		},
		warningTimer: undefined,
		showWarning: false,
		expiredTimer: undefined,
		sessionExpired: false,
		warningToastId: null
	},
	mutations: {
		setData,
		resetTimers(state) {
			clearTimeout(state.warningTimer);
			state.warningTimer = undefined;
			state.showWarning = false;

			clearTimeout(state.expiredTimer);
			state.expiredTimer = undefined;
			state.sessionExpired = false;

			// @ts-ignore
			this._vm.$toast.dismiss(state.warningToastId);
			state.warningToastId = null;
		}
	},
	actions: {
		async getConfig({ commit, getters, state }): Promise<IConfig> {
			if (getters.hasConfig) {
				return state.config;
			}

			try {
				const {
					data: { timeoutWarningDisplayTime, timeoutWarningMessage, timerIntervalInMinutes }
				} = await axios.get<{
					timeoutWarningDisplayTime: number;
					timeoutWarningMessage: string;
					timerIntervalInMinutes: number;
				}>(`/api/Configuration/Timer`);

				commit('setData', {
					objName: 'config',
					data: {
						warningInterval: timeoutWarningDisplayTime * 60000,
						timeoutWarningMessage,
						expiredInterval: (timerIntervalInMinutes - timeoutWarningDisplayTime) * 60000
					}
				});

				return state.config;
			} catch (error) {
			
				if (typeof error === 'string') {
					throw new Error(error);
				}
				throw error;
			}
		},
		startExpiredTimer({ commit, dispatch, rootGetters }, intervalTime = 5000): void {
			commit('setData', {
				objName: 'expiredTimer',
				data: setTimeout(() => {
					commit('setData', { objName: 'sessionExpired', data: true });
					commit('setData', { objName: 'showWarning', data: false });
				}, intervalTime)
			});
		},
		async reset({ commit, dispatch }): Promise<IConfig> {
			try {
				const { warningInterval, expiredInterval, timeoutWarningMessage } = await dispatch('getConfig');

				await axios.get(`/api/Renew`);

				commit('resetTimers');
				commit('setData', {
					objName: 'warningTimer',
					data: setTimeout(() => {
						commit('setData', { objName: 'showWarning', data: true });
						commit('setData', {
							objName: 'warningToastId',
							// @ts-ignore
							data: this._vm.$toast.info(
								{
									component: OaoSessionWarning,
									props: {
										message: timeoutWarningMessage,
										renewAction: () => dispatch('reset')
									}
								},
								{
									timeout: expiredInterval,
									position: 'bottom-right',
									closeOnClick: false,
									pauseOnFocusLoss: false,
									pauseOnHover: false,
									closeButton: false,
									icon: true
								}
							)
						});

						dispatch('startExpiredTimer', expiredInterval);
					}, warningInterval)
				});

				return {
					warningInterval,
					expiredInterval
				};
			} catch (error) {
			
				if (typeof error === 'string') {
					throw new Error(error);
				}
				throw error;
			}
		}
	},
	getters: {
		hasConfig: state =>
			!!state.config.warningInterval && !!state.config.timeoutWarningMessage && !!state.config.expiredInterval
	}
};

export default sessionTimer;
