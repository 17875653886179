<template functional>

    <path d="M321.9 256l-17 17L113 465l-17 17L62.1 448l17-17 175-175L79 81l-17-17L96 30.1l17 17L305 239l17 17z" />

</template>





<script>

    export default {

        name: 'RightChevronIcon'

    }

</script>