import axios from '@/utils/axios';
import { Module } from 'vuex';
import { IRootState } from '..';
import setData from '../shared/setData';

interface IOption {
	value: number;
	label: string;
	hasCounties: boolean;
	hasSelectEmployeeGroups: boolean;
	postProcessingTypeId: number;
	fieldLabel: string;
	fieldValidationRules: string;
	fieldInputType: string;
}

interface ICounty {
	value: number;
	label: string;
	isCounty: boolean;
	isSelectEmployeeGroup: boolean;
}

export interface IEligibilityState {
	counties: ICounty[];
	options: IOption[];
	eligibilityTitle: string;
}

const eligibility: Module<IEligibilityState, IRootState> = {
	namespaced: true,
	state: {
		counties: [],
		options: [],
		eligibilityTitle: ''
	},
	mutations: {
		setData,
		resetState(state) {
			state.counties = [];
			state.options = [];
			state.eligibilityTitle = '';
		}
	},
	actions: {
		async getEligibilityData({ commit, state }): Promise<IEligibilityState> {
			const hasExistingState = Object.keys(state.options).length !== 0;

			if (hasExistingState) {
				return { counties: state.counties, options: state.options, eligibilityTitle: state.eligibilityTitle };
			}

			try {
				const {
					data: { counties = [], options = [], eligibilityTitle = '' }
				} = await axios.get<IEligibilityState>(`/api/Eligibility`);

				commit('setData', {
					objName: 'counties',
					data: counties
				});

				commit('setData', {
					objName: 'options',
					data: options
				});

				commit('setData', {
					objName: 'eligibilityTitle',
					data: eligibilityTitle
				});

				return {
					counties: counties,
					options: options,
					eligibilityTitle: eligibilityTitle
				};
			} catch (error) {
				
				if (typeof error === 'string') {
					throw new Error(error);
				}
				throw error;
			}
		}
	},
	getters: {
		eligibilityOptions: state => state.options,
		countyOptions: state => state.counties.filter(county => county.isCounty),
		selectEmployeeGroupOptions: state => state.counties.filter(county => county.isSelectEmployeeGroup),
		affiliationOptions: state => {
			const options = state.counties.filter(county => !county.isCounty);

			//Filter out duplicates based on label/value
			return options.filter(
				(option, index, optionsList) =>
					index === optionsList.findIndex(opt => opt.label === option.label && opt.value === option.value)
			);
		},
		eligibilityTitle: state => state.eligibilityTitle
	}
};

export default eligibility;
