/* INHERITS STATE, MUTATIONS, ACTIONS and INTERFACE from ../shared/applicant.ts */

import { Module } from 'vuex';
import { IRootState } from '..';
import { applicantActions, applicantMutations, applicantState, applicantGetters } from '../shared';
import { IApplicantState } from '../shared/applicant';
import { applicantAPIv2, coApplicantSelectAPI } from '@/api';

const coApplicant: Module<IApplicantState, IRootState> = {
	namespaced: true,
	state: {
		...applicantState
	},
	mutations: {
		...applicantMutations
	},
	actions: {
		async resetState({ commit }) {
			commit('resetApplicantState');
		},
		...applicantActions,
		async createCoApplicant({ commit }, request: any): Promise<any> {
			try {
				const response = await applicantAPIv2.createCoApplicant(request);

				commit('setData', { objName: 'applicantToken', data: response.coApplicantToken });

				return response;
			} catch (error) {
				throw error;
			}
		},
		async getCoApplicantSelectionOptions({ commit }, request: any): Promise<any> {
			try {
				const { coApplicantSelectionOptions } = await coApplicantSelectAPI.getCoApplicantSelectionsAsync(
					request
				);

				commit('setData', { objName: 'coApplicantSelectionOptions', data: coApplicantSelectionOptions });

				return coApplicantSelectionOptions;
			} catch (error) {
				throw error;
			}
		},
		async createCoApplicantSelection({ commit }, request: any): Promise<any> {
			try {
				const response = await coApplicantSelectAPI.createCoApplicantSelection(request);
				commit('setData', { objName: 'selectedCoApplicant', data: response.updated });
				return response.updated;
			} catch (error) {
				throw error;
			}
		},
		setCoApplicantSelectionData({ commit }, request: any): any {
			commit('setData', { objName: 'firstName', data: request.firstName });
			commit('setData', { objName: 'lastName', data: request.lastName });
			commit('setData', { objName: 'middleName', data: request.middleName });
			commit('setData', { objName: 'suffix', data: request.suffix });
		}
	},
	getters: {
		...applicantGetters
	}
};

export default coApplicant;
