<template>

    <div>
        <ValidationProvider    
        >
            <OaoSelectionTile
                v-for="tile in availableTiles"
                :key="tile.optionId"
                v-model="fundingId"
                :optionValue="tile.fundingSelectionType"
                :title="tile.title"
                :description="tile.description || ''"
                :iconComponentName="tile.iconComponentName"
                :height = "tile.iconHeight"
                :width = "tile.iconWidth"
                :viewbox = "tile.iconViewbox"
                v-if ="tile.enabled"
            />
        </ValidationProvider>


    </div>

</template>





<script>

    import { OaoSelectionTile } from '@/components/form';
    import { ValidationProvider } from 'vee-validate';
    export default {

        name: 'OaoTileSelection',
        components: {
            ValidationProvider,
            OaoSelectionTile
        },
        props: {            
            value: {
                required: true
            },
            availableTiles: {
                type: Array,
                required: false,
                default: () => ([])
            },

        },
        computed: {

            fundingId: {
                get() {
                    return this.value;
                },
                set(val) {
                    
                    this.$emit('input', val);

                }

            }

        }

    }

</script>





<style lang="scss" scoped>
    hr {
        border: 1px dashed var(--primary);
        border-style: none none dashed;
        color: transparent;
        background-color: transparent;
    }

</style>