var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{class:[
				'tile',
				{
					'is-required': _vm.required,
					'is-selected': !_vm.required & (_vm.optionValue == _vm.value)
				}
			],attrs:{"for":_vm.id}},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.innerValue),expression:"innerValue",modifiers:{"trim":true}}],attrs:{"id":_vm.id,"name":_vm.title,"type":"radio","data-test-input":""},domProps:{"value":_vm.optionValue,"checked":_vm._q(_vm.innerValue,_vm.optionValue)},on:{"change":function($event){_vm.innerValue=_vm.optionValue}}}),(_vm.required)?_c('p',{staticClass:"required"},[_vm._v("REQUIRED")]):_vm._e(),_c('section',[_c('BaseIcon',{attrs:{"name":_vm.title,"height":_vm.height,"width":_vm.width,"viewBox":_vm.viewbox,"color":_vm.fillColor}},[(_vm.optionValue == _vm.value)?_c(_vm.tileIconSelected,{tag:"component"}):_vm._e(),(_vm.optionValue != _vm.value)?_c(_vm.tileIcon,{tag:"component"}):_vm._e()],1),_c('div',[_c('h1',[_vm._v(_vm._s(_vm.title))]),_c('p',[_vm._v(_vm._s(_vm.description))])])],1),_c('BaseIcon',{staticClass:"add-button",attrs:{"name":_vm.optionValue == _vm.value ? 'tile Selected' : 'tile Unselected'}},[(_vm.optionValue == _vm.value)?_c('SelectedIcon'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }