import axios from '@/utils/axios';

export interface IUpdateClientAuditRequest {
	deviceSessionId: string;
	applicationToken: string;
}

export interface IUpdateClientAuditResponse {
	updated: boolean;
}

const updateClientAuditAsync = async (request: IUpdateClientAuditRequest): Promise<IUpdateClientAuditResponse> => {
	try {
		const { data } = await axios.post<IUpdateClientAuditResponse>('/api/Audit/Client', request);

		return data;
	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};

export { updateClientAuditAsync };