 <template functional>

    <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.77482 14.2233C4.46431 14.2233 4.25065 14.4659 4.25065 14.705V29.325C4.25065 29.5641 4.46431 29.8067 4.77482 29.8067H26.379C26.6895 29.8067 26.9032 29.5641 26.9032 29.325V14.705C26.9032 14.4659 26.6895 14.2233 26.379 14.2233H4.77482ZM2.83398 14.705C2.83398 13.6408 3.72533 12.8067 4.77482 12.8067H26.379C27.4285 12.8067 28.3198 13.6408 28.3198 14.705V29.325C28.3198 30.3892 27.4285 31.2233 26.379 31.2233H4.77482C3.72533 31.2233 2.83398 30.3892 2.83398 29.325V14.705Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M27.6107 19.89H3.52734V18.4733H27.6107V19.89Z" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.59318 4.25C7.27707 4.25 7.06901 4.48379 7.06901 4.73167V13.4583C7.06901 13.8495 6.75188 14.1667 6.36068 14.1667C5.96948 14.1667 5.65234 13.8495 5.65234 13.4583V4.73167C5.65234 3.64787 6.54928 2.83333 7.59318 2.83333H29.1973C30.2468 2.83333 31.1382 3.66743 31.1382 4.73167V19.4508C31.1382 20.5346 30.2412 21.3492 29.1973 21.3492H28.0498C27.6586 21.3492 27.3415 21.032 27.3415 20.6408C27.3415 20.2496 27.6586 19.9325 28.0498 19.9325H29.1973C29.5134 19.9325 29.7215 19.6987 29.7215 19.4508V4.73167C29.7215 4.49257 29.5079 4.25 29.1973 4.25H7.59318Z" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M30.4447 9.97333H6.36133V8.55667H30.4447V9.97333Z" />
    </svg>

</template>

<script>

    export default {

        name: 'CardsIcon'

    }

</script>