<template functional>

    <g>
        <circle cx="9" cy="9" r="9" fill="#FFF" />
        <path d="M9,0.7c-1.5,0-2.9,0.4-4.2,1.1c-1.3,0.7-2.3,1.8-3,3C1.1,6.1,0.7,7.5,0.7,9s0.4,2.9,1.1,4.2
            c0.7,1.3,1.8,2.3,3,3c1.3,0.7,2.7,1.1,4.2,1.1s2.9-0.4,4.2-1.1c1.3-0.7,2.3-1.8,3-3c0.7-1.3,1.1-2.7,1.1-4.2s-0.4-2.9-1.1-4.2v0
            c-0.7-1.3-1.8-2.3-3-3C11.9,1.1,10.5,0.7,9,0.7z M7.9,14.9c-0.4,0-0.8-0.1-1-0.4l-4.2-4.2c-0.3-0.3-0.4-0.6-0.4-1
            c0-0.4,0.1-0.8,0.4-1l1.1-1.1c0.6-0.6,1.5-0.6,2.1,0l2.1,2.1L12.2,5c0.6-0.6,1.5-0.6,2.1,0l1.1,1.1c0.3,0.3,0.4,0.6,0.4,1
            c0,0.4-0.1,0.8-0.4,1L9,14.5C8.7,14.8,8.3,14.9,7.9,14.9z"/>
    </g>

</template>




<script>

    export default {

        name: 'SelectedIcon'

    }

</script>