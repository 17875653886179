<template>

	<article v-if="isActive">

        <slot/>

    </article>

</template>





<script>

    export default {

        name: 'Tab',
        props: {

            name: {
                type: String,
                required: true
            },
            selected: {
                type: Boolean,
                required: false
            },
            id: {
                type: [ String, Number ],
                required: true
            }

        },
        data() {
            return {

                isActive: false

            }
        },
        mounted() {
            
            this.isActive = this.selected;
            
        },
        watch: {
            selected: function (newVal) {
                this.isActive = newVal;
            }
        }

    };

</script>