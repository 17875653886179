<template>
	<ValidationProvider
		:rules="validationRules"
		:name="title"
		v-slot="{ errors }"
		tag="section"
		class="funding-card box-shadow"
	>
		<main :class="{ 'has-error': errors.length > 0 }">
			<div v-if="showDelete" @click="removeProduct">
				<BaseIcon class="icon-close" name="Close" viewBox="-7 -6 30 30">
					<CloseIcon />
				</BaseIcon>
			</div>
			<div class="card-row">
				<section class="card-row-section">
					<div class="icon-circle">
						<BaseIcon :name="productCategoryName">
							<component :is="productIcon" />
						</BaseIcon>
					</div>

					<div class="title-block">
						<label :for="inputId">{{ title }}</label>
					</div>
				</section>

				<section class="card-row-section">
					<v-popover trigger="hover click focus" placement="left-start" offset="5">
						<p>?</p>

						<template #popover>
							<section class="tooltip">
								<h3>{{ title }}</h3>
								<p v-html="description"></p>
							</section>
						</template>
					</v-popover>

					<div class="currency-input-container">
						<CurrencyInput
							type="tel"
							:id="inputId"
							:name="title"
							currency="USD"
							placeholder="0.00"
							:distraction-free="false"
							:auto-decimal-mode="true"
							v-model="innerValue"
						/>
					</div>
				</section>
			</div>

			<div class="card-row-section" v-if="fundingMessage">
				<div v-html="fundingMessage" class="fundingMessage" />
			</div>
		</main>

		<transition name="fade">
			<p v-if="errors.length > 0" class="error-block">
				{{ errors[0] }}
			</p>
		</transition>
	</ValidationProvider>
</template>

<script>
	import { ValidationProvider } from 'vee-validate';
	import { CurrencyInput } from 'vue-currency-input';

	import { ArrowIcon, BaseIcon, CloseIcon } from '@/components/icons';

	export default {
		name: 'FundingCard',
		components: {
			ValidationProvider,
			CurrencyInput,
			ArrowIcon,
			BaseIcon,
			CloseIcon
		},
		props: {
			value: {
				required: true
			},
			title: {
				type: String,
				required: true
			},
			description: {
				type: String,
				required: false
			},
			categoryId: {
				type: Number,
				required: true
			},
			validationRules: {
				type: [String, Object],
				required: false
			},
			fundingMessage: {
				type: String,
				required: false
			},
			productConfigId: {
				type: Number,
				required: true
			},
			productCount: {
				type: Number,
				required: true
			},
			isRequired: {
				type: Boolean,
				required: true
			}
		},
		async created() {
			console.log('Validation rules for : ' + this.title + ' ' + JSON.stringify(this.validationRules));
		},
		computed: {
			innerValue: {
				get() {
					return this.value;
				},
				set(val) {
					this.$emit('input', val);
				}
			},
			showDelete() {
				//Membership Savings
				return this.isRequired || this.productCount == 1 ? false : true;
			},
			productCategoryName() {
				const nameMap = {
					0: 'None',
					1: 'Membership',
					2: 'Savings',
					3: 'Checking',
					4: 'Money Market',
					5: 'Checking',
					6: 'Savings Certificate',
					7: 'Jumbo Money Market'
				};

				return nameMap[this.categoryId];
			},
			productIcon() {
				switch (this.categoryId) {
					case 1:
					case 2:
						return () => import('@/components/icons').then(({ SavingsIcon }) => SavingsIcon);
					case 3:
					case 5:
						return () => import('@/components/icons').then(({ CheckingIcon }) => CheckingIcon);
					case 4:
						return () => import('@/components/icons').then(({ MoneyIcon }) => MoneyIcon);
					case 6:
						return () =>
							import('@/components/icons').then(({ SavingsCertificateIcon }) => SavingsCertificateIcon);
					case 7:
						return () => import('@/components/icons').then(({ MoneyIcon }) => MoneyIcon);
					default:
						return null;
				}
			},
			inputId() {
				return this.title.replace(/\s/g, '');
			}
		},
		methods: {
			removeProduct() {
				this.$emit('removeProduct', this.productConfigId);
			}
		}
	};
</script>

<style lang="scss" scoped>
	.funding-card {
		color: var(--text-body);
		background-color: var(--form-background);
		margin-bottom: 1rem;

		main {
			position: relative;
			padding: 1rem;

			.icon-close {
				color: var(--white);
				background-color: var(--icon-primary);
				border-radius: 100%;
				height: 1rem;
				width: 1rem;
				padding: 0.15rem;
				position: absolute;
				right: -0.6rem;
				top: -0.6rem;
				cursor: pointer;
				display: flex;
				justify-content: center;
				align-items: center;

				p {
					font-size: 0.9rem;
					text-align: center;
					font-weight: 500;
				}
			}

			.card-row {
				display: flex;
				justify-content: space-between;

				.card-row-section {
					display: flex;
					justify-content: center;
					align-items: center;

					& > *:not(:last-child) {
						margin-right: 0.75rem;
					}

					.icon-circle {
						box-sizing: border-box;
						width: 2.8125rem;
						height: 2.8125rem;
						border: 5px solid var(--icon-tertiary);
						color: var(--icon-tertiary);
						padding: 0.4rem;
						border-radius: 100%;
						display: flex;

						svg {
							width: 100%;
							height: auto;
						}
					}

					.title-block {
						flex: 1;
						display: flex;
						flex-direction: column;

						.subtitle {
							font-size: 0.65rem;
							color: var(--text-primary);
							position: relative;
							left: 1.85rem;

							em {
								margin-left: 0.25rem;
							}
						}
					}

					input {
						box-sizing: border-box;
						border: 1px solid var(--text-body);
						border-radius: 4px;
						font-size: 1.25rem;
						color: var(--text-primary);
						padding: 0.5rem;
						background: transparent;
						text-align: right;
						max-width: 9.1rem;

						&:active,
						&:focus {
							border: 1px solid var(--secondary);
							outline: none;
						}
					}

					&.has-error {
						&:after {
							background: var(--danger);
						}

						label {
							color: var(--danger);
						}

						.tooltip-button {
							border: 1px solid var(--danger);
							background-color: var(--danger);

							&:hover {
								background-color: transparent;
								color: var(--danger);
							}
						}

						input {
							color: var(--danger);
							border: 1px solid var(--danger);
						}
					}
				}
			}
		}

		.error-block {
			color: var(--danger);
			text-align: center;
			font-size: 0.8rem;
			margin-top: 0.5rem;
		}
	}

	::v-deep .trigger {
		text-align: center;
		font-size: 0.8125rem;
		font-weight: 400;
		height: 1.25rem;
		width: 1.25rem;
		border: 1px solid var(--icon-primary);
		border-radius: 100%;
		background-color: var(--icon-primary);
		color: var(--white);
		transition: background-color 0.5s, color 0.5s;
		cursor: pointer;

		//p { margin-top: -0.0625rem; }

		&:hover {
			background-color: transparent;
			color: var(--primary);
		}
	}

	.tooltip {
		text-align: center;
		padding: 0.75rem 0.25rem;
		border: 2px solid var(--tertiary);
		border-radius: 7px;
		background-color: var(--white);
		max-width: 11.25rem;
		box-sizing: border-box;

		h3 {
			color: var(--text-primary);
			font-size: 1rem;
			line-height: 1.125rem;
			margin-bottom: 1rem;
		}

		p {
			color: var(--text-body);
			font-size: 1rem;
			line-height: 1.4375rem;
			font-weight: 300;
		}
	}

	@media (max-width: 375px) {
		main {
			flex-wrap: wrap;
		}

		.funding-card main input {
			margin-top: 1rem;
			width: 100%;
			max-width: none;
		}

		.currency-input-container {
			width: 100%;
			text-align: center;
			flex-grow: 1;
		}
	}

	@media (max-width: 280px) {
		label {
			font-size: 0.875rem;
		}
	}
</style>
