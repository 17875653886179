var render = function render(_c,_vm){return _c('main',{class:[
        _vm.data.class,
        _vm.data.staticClass,
        'container',
        {
            'pad-vertical': _vm.props.padVertical
        }
    ],style:([
        _vm.data.style,
        _vm.data.staticStyle
    ])},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }