import axios from '@/utils/axios';

export interface IUpdateApplicantEmploymentRequest {
	applicationToken: string;
	applicantToken: string;
	employmentId: number;
}
export interface IUpdateApplicantEmploymentResponse {
	updated: boolean;
}
export interface IEmploymentGetResponse {
	employment: IEmployment;
}
export interface IEmploymentCreateResponse {
	employmentId: number;
}
export interface IEmploymentUpdateResponse {
	updated: boolean;
}

export interface IEmploymentGetRequest {
	applicantToken: string;
	applicationToken: string;
}
export interface IEmploymentCreateRequest {
	applicantToken: string;
	employment: IEmployment;
	applicationToken: string;
	isCoApplicant: boolean;
}
export interface IEmploymentUpdateRequest {
	applicantToken: string;
	employment: IEmployment;
	applicationToken: string;
	isCoApplicant: boolean;
}
export interface IEmployment {
	employmentId: number | null;
	coreEmploymentSerial: number | null;
	occupationSerial: number;
	occupationName: string;
	employmentStatus: string;
	employmentStatusShortName: string;
}

const getEmploymentAsync = async (request: IEmploymentGetRequest): Promise<IEmploymentGetResponse> => {
	try {
		const { data } = await axios.post<IEmploymentGetResponse>(`/api/v2/Employment/Applicant`, request);
		return data;
	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}

		throw error;
	}
};
const createEmploymentAsync = async (request: IEmploymentCreateRequest): Promise<IEmploymentCreateResponse> => {
	try {
		const { data } = await axios.post<IEmploymentCreateResponse>('/api/v2/Employment', request);

		return data;
	} catch (error) {

		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};
const updateEmploymentAsync = async (request: IEmploymentUpdateRequest): Promise<IEmploymentUpdateResponse> => {
	try {
		const { data } = await axios.put<IEmploymentUpdateResponse>('/api/v2/Employment', request);

		return data;
	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};

export { getEmploymentAsync, createEmploymentAsync, updateEmploymentAsync };
