<template>
    <nav class="navbar">
        <div class="container-fluid">
            <div class="center-left">
                <div v-if="backRoute && !$route.meta.hideBack">
                    <a v-if="linkType === 'external'" :href="backRoute" :title="backTitle">
                        <BaseIcon class="nav-icon" :name="backTitle" height="40" width="40">
                            <ActionArrowIcon />
                        </BaseIcon>
                    </a>

                    <a v-else
                       :to="linkType === 'internal' ? { name: backRoute } : backRoute"
                       :title="backTitle"
                       @click="showLeavingModal">
                        <BaseIcon class="nav-icon" :name="backTitle" height="40" width="40">
                            <ActionArrowIcon />
                        </BaseIcon>
                    </a>
                </div>
            </div>
            <div class="logo-container">
                <img :src="logo" :alt="logoTitle" />
            </div>
            <div class="center-right">
                <div class="button-wraptext"
                     v-if="hasMemberAdvocate"
                     title="Change MA Support"
                     @click="clearMemberAdvocate">
                    <h3 class="button-text">{{ employeeFirstName }}</h3>
                    <BaseIcon name="Change MA Support" height="40" width="40">
                        <LogoutIcon />
                    </BaseIcon>
                </div>
                <div v-else-if="!hideHelp">
                    <OaoButtonWrapper title="Contact us" @click="$emit('contact')">
                        <BaseIcon name="Contact" height="40" width="40">
                            <Agent />
                        </BaseIcon>
                    </OaoButtonWrapper>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
    import { mapGetters, mapMutations, mapState } from 'vuex';
    import { OaoButtonWrapper } from '@/components';
    import { ActionArrowIcon, BaseIcon, Agent, LogoutIcon } from '@/components/icons';

    export default {
        name: 'Nav',
        components: {
            OaoButtonWrapper,
            ActionArrowIcon,
            BaseIcon,
            LogoutIcon,
            Agent
        },

        props: {
            backRoute: {
                type: [Object, String],
                required: false
            },
            backTitle: {
                type: String,
                required: false
            },
            hideHelp: {
                type: Boolean,
                required: false
            },
            logo: {
                type: String,
                required: false
            },
            logoTitle: {
                type: String | null,
                required: true
            }
        },
        watch: {

        },
        computed: {
            ...mapGetters('modal', ['leavingModalShown']),
            ...mapGetters('memberAdvocate', ['hasMemberAdvocate', 'employeeFirstName']),
            ...mapGetters('user', ['isNewMember']),
            linkType() {
                if (typeof this.backRoute === 'object') {
                    return 'object';
                }
                if (this.backRoute.includes('http')) {
                    return 'external';
                }
                return 'internal';
            }
        },
        data() {
            return {
                showModal: false,
                advocateName: '',
                showAdvocate: false
            };
        },
        methods: {
            ...mapMutations({
                resetMemberAdvocate: 'memberAdvocate/resetMemberAdvocate'
            }),
            clearMemberAdvocate() {
                this.resetMemberAdvocate();
                this.advocateName = '';
                this.showAdvocate = false;
                this.$router.push(this.$constructUrl('memberAdvocateLogin'));
            },
            showLeavingModal() {
                let route = this.linkType === 'internal' ? { name: this.backRoute } : this.backRoute;

                if (!this.leavingModalShown && this.$route.meta.leavingModal && this.isNewMember) {
                    this.$emit('leavingModal', true);
                } else {
                    this.$router.push(route);
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    .navbar {
        position: fixed;
        z-index: 5;
        width: 100%;
        background-color: var(--navbar-background);

        .container-fluid {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        a,
        ::v-deep button {
            color: var(--primary);
        }

        a {
            cursor: pointer;
        }

        .center-left {
            padding: 0.55rem 0 0 0.25rem;
            min-width: 2rem;
        }

        .center-right {
            padding: 0.55rem 0.25rem 0 0;
            min-width: 2rem;
        }
    }

    .button-wraptext {
        cursor: pointer;
        color: var(--primary);
        display: flex;
        flex-direction: row;

        .button-text {
            color: var(--text-primary);
            font-size: 1rem;
            line-height: 40px;
            margin-right: 0.5rem;
        }
    }

    .logo-container {
        padding: 0.2rem 0 0 0;
    }
</style>
