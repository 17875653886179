import { CaptchaImplementation } from '@/constants'
import { mapCaptchaConfiguration, fetchCaptchaConfiguration } from '@/utils'

export default (configs) => {

    let result = {};

    if (configs) {
        
        result.v2 = mapCaptchaConfiguration(fetchCaptchaConfiguration(CaptchaImplementation.RECAPTCHAV2, configs));
        result.v2Hidden = mapCaptchaConfiguration(fetchCaptchaConfiguration(CaptchaImplementation.RECAPTCHAV2HIDDEN, configs));
        result.v3 = mapCaptchaConfiguration(fetchCaptchaConfiguration(CaptchaImplementation.RECAPTCHAV3, configs));

    }

    return result;

};