import constructUrl from './constructUrl';
import handleAudit from './handleAudit';
import router from '@/router';

const handleGetError = async error => {
	console.error(error);

	handleAudit({ success: false });

	const { response = null, message = 'There was an error processing your request.' } = error;

	return router.push(
		constructUrl('Error', {
			statusType: response && response.status ? response.status : '500',
			statusText: response && response.statusText ? response.statusText : 'Internal Server Error',
			message
		})
	);
};

export default handleGetError;
