<template functional>
<g fill="none">
	<path d="M19.6694 23.5002C25.5264 23.5002 30.2744 18.9297 30.2744 13.2918C30.2744 7.65392 25.5264 3.0835 19.6694 3.0835C13.8125 3.0835 9.06445 7.65392 9.06445 13.2918C9.06445 18.9297 13.8125 23.5002 19.6694 23.5002Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
	<path d="M37.8884 43.9167C37.8884 36.0154 29.7226 29.625 19.6691 29.625C9.61554 29.625 1.44971 36.0154 1.44971 43.9167" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
	<path d="M54.5503 41.8332C54.5503 33.9319 46.3844 27.5415 36.3309 27.5415C32.1546 27.5415 29.3428 28.6443 26.2681 30.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
	<path d="M28.9088 18.5C30.8216 20.3042 33.4412 21.4167 36.3308 21.4167C42.1878 21.4167 46.9358 16.8462 46.9358 11.2083C46.9358 5.57043 42.1878 1 36.3308 1C33.2295 1 30.4392 2.28142 28.5 4.32421C28.0127 4.83749 27.5792 5.39884 27.208 6" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>

</template>

<script>
	export default {
		name: 'MultipleUserIcon'
	};
</script>
