<template>
	<h1 :class="className">
		<OaoDynamicHyperlink :htmlContent="htmlContent" :className="className" />
	</h1>
</template>

<script>
	import { mapGetters } from 'vuex';
	import { OaoDynamicHyperlink } from '@/components';

	export default {
		name: 'OaoLandingHyperlinkContainer',
		components: {
			OaoDynamicHyperlink
		},
		props: {
			htmlContent: {
				type: String,
				required: true
			},
			className: {
				type: String,
				required: false
			}
		}
	};
</script>

<style lang="scss" scoped>
	h1 {
		    color: var(--text-primary);
			font-size: 1.75rem;
			font-weight: 600;
			line-height: 2.25rem;
			text-align: center;
			margin: 2rem 1rem;
	}

	@media only screen and (max-width: 849px) {
		h1 {
			font-size: 1.25rem;
			line-height: 1.75rem;
			font-weight: 700;
			margin: 1.5rem 0;
		}
	}

	@media only screen and (min-width: 850px) {
		 h1 { 
			font-size: 1.75rem;
			line-height: 2rem;
			font-weight: 700;
			color: var(--headerCopy);
			text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.75);			
    	}
	}


</style>
