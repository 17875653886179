import axios from '@/utils/axios';
import vendorAxios from 'axios';

const createApplication = async () => {
	//Currently handled as a part of CreateApplicant
};

const updateApplication = async () => {
};

const getApplication = async () => {
};

const abandonApplication = async (applicationToken: string) => {
	
	const response = axios.get(`/api/v2/Application/Abandon/${applicationToken}`);

	return response;
};

const clearApplicationSession = async () => {
	
	const response = axios.get(`/api/v2/Application/ClearSession/`);

	return response;
};

export default {
	createApplication,
	updateApplication,
	getApplication,
	abandonApplication,
	clearApplicationSession
};

export { createApplication, updateApplication, getApplication, abandonApplication, clearApplicationSession};