<template>
	<div class="input-container">
		<header :class="['input-header', { 'is-small': isSmall, 'sr-hide': hideLabel }]">
			<label :class="{ 'has-error': error }" :for="id" data-test-label>
				{{ label }}<span v-if="isOptional & !disabled" data-test-optional> (OPTIONAL)</span>
			</label>
		</header>

		<textarea
			:class="{ 'is-small': isSmall, 'has-error': error }"
			:id="id"
			:name="label"
			:placeholder="placeholder"
			v-model.trim="innerValue"
			:disabled="disabled"
			data-test-input
			:autocomplete="disableAutocomplete ? 'off' : 'on'"
			@change="$emit('change', $event)"
		/>

		<transition name="fade">
			<p class="has-error" v-if="error" data-test-error>
				{{ error }}
			</p>
		</transition>
	</div>
</template>

<script>
	export default {
		name: 'TextArea',
		props: {
			value: {
				required: true
			},
			label: {
				type: String,
				required: true
			},
			hideLabel: {
				type: Boolean,
				required: false
			},
			placeholder: {
				type: String,
				required: false
			},
			disabled: {
				type: Boolean,
				required: false
			},
			error: {
				type: String,
				required: false
			},
			isOptional: {
				type: Boolean,
				required: false
			},
			disableAutocomplete: {
				type: Boolean,
				required: false
			},
			isSmall: {
				type: Boolean,
				required: false,
				default: false
			}
		},
		computed: {
			id() {
				return `${this.label.replace(/\s/g, '')}-${this._uid}`;
			},
			innerValue: {
				get() {
					return this.value;
				},
				set(val) {
					this.$emit('input', val);
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	.input-container {
		&:not(:last-child) {
			margin-bottom: 1rem;
		}

		.has-error {
			color: var(--danger);
		}

		.input-header {
			align-items: baseline;

			label {
				display: block;
				color: var(--primary);
				font-size: 0.8rem;
				margin-bottom: 0.5rem;
				font-weight: 400;
				text-transform: uppercase;
			}
		}

		textarea {
			color: var(--input-text);
			box-sizing: border-box;
			width: 100%;
			border: 1.5px solid rgb(215, 215, 215);
			background-color: transparent;
			font-size: 1.25rem;
			padding: 0.25rem;

			&::placeholder {
				color: var(--lightGrey);
				font-style: oblique;
			}

			&.has-error {
				border: 1.5px solid var(--danger);
			}

			&:disabled {
				color: var(--lightGrey);
				cursor: not-allowed;
				-webkit-text-fill-color: var(--lightGrey);
				opacity: 1;
			}
		}

		p {
			font-size: 0.85rem;
			margin-top: 0.25rem;
		}

		.is-small {
			width: auto;
			min-width: 12rem;
		}
	}
</style>
