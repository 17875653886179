<template>
	<section v-if="!hasError">
		<OaoButtonWrapper
			id="externalButton"
			title="Select to fund from an external account"
			class="icon-button"
			@click="handleClick"
		>
			<BaseIcon
				id="externalSVG"
				name="External Account Funding"
				width="6.3125rem"
				height="6.3125rem"
				viewBox="0 0 50 50"
			>
				<ExternalAccountFundingSelected v-if="selected" />
				<ExternalAccountFunding v-else />
			</BaseIcon>

			<h2>External Transfer</h2>
		</OaoButtonWrapper>
	</section>
</template>

<script>
	import { BaseIcon, ExternalAccountFunding, ExternalAccountFundingSelected } from '@/components/icons';
	import OaoButtonWrapper from '@/components/OaoButtonWrapper';

	import showSpinner from '@/mixins/showSpinner';
	import { logError } from '@/utils';
	import { mapGetters } from 'vuex';


	export default {
		name: 'PlaidHandler',
		mixins: [showSpinner],
		components: {
			BaseIcon,
			ExternalAccountFunding,
			ExternalAccountFundingSelected,
			OaoButtonWrapper
		},
		props: {
			selected: {
				type: Boolean,
				required: false
			}
		},
		data() {
			return {
				instance: null,
				hasError: false
			};
		},
		async mounted() {
			try {
				await this.writePlaidScript();

				return (this.instance = Plaid.create({
					token: await this.getSessionToken(),
					onSuccess: (public_token, metadata) => this.$emit('success', { public_token, metadata }),
					onLoad: () => this.$emit('load'),
					onExit: (error, metadata) => this.$emit('exit', { error, metadata }),
					onEvent: (eventName, metadata) => this.$emit('event', { eventName, metadata })
				}));
			} catch (error) {
				return this.$toast.error(`Plaid | ${error.message || error}`);
			}
		},
		computed: {
			...mapGetters('applicant', ['applicantToken']),
			...mapGetters('application', ['applicationToken']),
		},
		methods: {
			async writePlaidScript() {
				return new Promise((resolve, reject) => {
					const script = document.createElement('script');
					script.async = true;
					script.src = 'https://cdn.plaid.com/link/v2/stable/link-initialize.js';

					script.addEventListener('load', resolve);
					script.addEventListener('error', e => {
						return reject(e);
					});
					script.addEventListener('abort', e => {
						return reject(e);
					});

					document.head.appendChild(script);
				});
			},
			async getSessionToken() {
				try {
					const {
						data: { token = '' }
					} = await this.$axios.get(`/api/Funding/ACHTransfer/`);

					return token;
				} catch (error) {
					this.hasError = true;
					logError(error, error?.requestId, this.applicantToken, this.applicationToken);
					throw new Error(error);
				}
			},
			handleClick() {
				try {
					this.$emit('click');
					return this.instance.open();
				} catch (error) {
					this.$toast.error('ERROR: There was a problem connecting to Plaid. Please refresh and try again.');
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	::v-deep .icon-button {
		padding: 0.5rem;
		border: 2px solid var(--disabled);
		border-radius: 10px;
	}
	h2 {
		margin-top: 1rem;
		font-size: 1.125rem;
		color: var(--primary);
		font-weight: 400;
		transition: color 0.5s;
	}
</style>
