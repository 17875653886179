import { configurationsAPIv2, applicantAPIv2 } from '@/api';
import { IFundingConfigurationsResponse } from '@/api/_v2/configurations';

export interface IFundingConfigurationState {
	enableReselectField: boolean;
	enableACHPayments: boolean;
	fundingEnabled: boolean;
	headerText: string;
}

const state: IFundingConfigurationState = {
	enableReselectField: false,
	enableACHPayments: false,
	fundingEnabled: false,
	headerText: ''
};

function resetState(state) {
	state.enableReselectField = false;
	state.enableACHPayments = false;
	state.fundingEnabled = false;
	state.headerText = ''
}

const mutations = {
	//TODO: Add Mutations (if any)
};

const actions = {
	async getFundingPageConfigurations({ commit, state }: any): Promise<IFundingConfigurationsResponse> {
		try {
			const response = await configurationsAPIv2.getFundingPageConfigurations();

			commit('setData', { objName: 'enableReselectField', data: response.enableReselectField });
			commit('setData', { objName: 'enableACHPayments', data: response.enableACHPayments });
			commit('setData', { objName: 'fundingEnabled', data: response.fundingEnabled });
			commit('setData', { objName: 'headerText', data: response.headerText });

			return response;
		} catch (error) {
		
			if (typeof error === 'string') {
				throw new Error(error);
			}

			throw error;
		}
	}
};

const getters = {
	enableACHPayments: state => state.enableACHPayments,
	enableReselectField: state => state.enableReselectField,
	fundingEnabled: state => state.fundingEnabled,
	headerText: state => state.headerText
};

export { state, resetState, mutations, actions, getters };

export default {
	state,
	resetState,
	mutations,
	actions,
	getters
};
