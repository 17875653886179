import { Module } from 'vuex';
import { IRootState } from '..';
import setData from '../shared/setData';
import { documentAPI } from '@/api';
import { ApplicantTypes } from '@/constants';
import { IDocumentUploadConfigurationResponse, IDocumentUploadConfiguration, IUploadDocumentRequest, IUploadDocumentResponse, IDocumentApplicationCreateRequest, IDocumentUploadConfigurationRequest } from '@/api/_v2/document';

enum DocumentUploadType {
	None = 0,
	ApplicantIdentification = 1
}

export interface IDocumentState {
	primaryDocumentUploadConfigs: IDocumentUploadConfiguration[];
	jointDocumentUploadConfigs: IDocumentUploadConfiguration[];
	primaryUploadCompleted: boolean,
	jointUploadCompleted: boolean,
	coreApplicationCreated: boolean
}

const document: Module<IDocumentState, IRootState> = {
	namespaced: true,
	state: {
		primaryDocumentUploadConfigs: [],
		jointDocumentUploadConfigs: [],
		primaryUploadCompleted: false,
		jointUploadCompleted: false,
		coreApplicationCreated: false
	},
	mutations: {
		setData,
		resetState(state) {
			state.primaryDocumentUploadConfigs = [];
			state.jointDocumentUploadConfigs = [];
			state.primaryUploadCompleted = false;
			state.jointUploadCompleted = false;
			state.coreApplicationCreated = false;
		},
	},
	actions: {
		async getPrimaryDocumentUploadConfig({ commit, state }, request: IDocumentUploadConfigurationRequest): Promise<any | null> {

			if (!state.primaryDocumentUploadConfigs.length) {
				const response = await documentAPI.getDocumentUploadConfiguration(request);

				commit('setData', { objName: 'primaryDocumentUploadConfigs', data: response.documentUploadConfigurations });
			}
            //let docUploadConfig = state.documentUploadConfigs.find(x => x.documentUploadType == request);

			return state.primaryDocumentUploadConfigs;
		},
		async getJointDocumentUploadConfig({ commit, state }, request: IDocumentUploadConfigurationRequest): Promise<any | null> {

			if (!state.jointDocumentUploadConfigs.length) {
				const response = await documentAPI.getDocumentUploadConfiguration(request);

				commit('setData', { objName: 'jointDocumentUploadConfigs', data: response.documentUploadConfigurations });
			}
			//let docUploadConfig = state.documentUploadConfigs.find(x => x.documentUploadType == request);

			return state.jointDocumentUploadConfigs;
		},
		async createDocumentUpload({ commit, state }, request: IUploadDocumentRequest): Promise<IUploadDocumentResponse> {
			const response = await documentAPI.uploadDocuments(request);

			if (request.applicantType == ApplicantTypes.PRIMARYAPPLICANT) {
				commit('setData', { objName: 'primaryUploadCompleted', data: response.posted });
			}
			else {
				commit('setData', { objName: 'jointUploadCompleted', data: response.posted });
			}

			return response;
		},
		async createDocumentApplication({ commit, state }, request: IDocumentApplicationCreateRequest): Promise<any> {

			if (!state.coreApplicationCreated) {
				const response = await documentAPI.createDocumentApplication(request);

				commit('setData', { objName: 'coreApplicationCreated', data: response.applicationSerial > 0 });
			}
			
			return state.coreApplicationCreated;
		}
	},
	getters: {
		primaryUploadCompleted: state => state.primaryUploadCompleted,
		jointUploadCompleted: state => state.jointUploadCompleted
	}
};

export default document;
