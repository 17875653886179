<template>

    <ValidationProvider
        :rules="validationRules"
        :name="label"
        :vid="validationId"
        v-slot="{ errors }"
        slim
    >

        <OaoInput
            v-model="innerValue"
            :label="label"
            :type="type"
            :placeholder="placeholder"
            :disabled="disabled"
            :error="errors[0]"
            :dateConfig="dateConfig"
            :mask="mask"
            :maskOutput="maskOutput"
            :isOptional="isOptional"
            :disableAutocomplete="disableAutocomplete"
            :isSmall="isSmall"
            @change="$emit('change', $event)"
        />

    </ValidationProvider>

</template>




<script>

    import { ValidationProvider } from 'vee-validate';
    
    import { OaoInput } from '@/components/form';

    export default {

        name: 'FormInput',
        components: {
            ValidationProvider,
            OaoInput
        },
        props: {
            value: {
                required: true
            },
            label: {
                type: String,
                required: true
            },
            type: {
                type: String,
                required: false,
                default: 'text'
            },
            placeholder: {
                type: String,
                required: false
            },
            disabled: {
                type: Boolean,
                required: false
            },
            validationRules: {
                type: [ String, Object],
                required: false
            },
            dateConfig: {
                type: Object,
                required: false
            },
            mask: {
                type: [String, Array],
                required: false
            },
            maskOutput: {
                type: Boolean,
                required: false
            },
            isOptional: {
                type: Boolean,
                required: false
            },
            disableAutocomplete: {
                type: Boolean,
                required: false
            },
            validationId: {
                type: String,
                required: false
            },
            isSmall: {
                type: Boolean,
                required: false,
                default: false
            }

        },
        computed: {
            
            innerValue: {

                get() {

                    return this.value;

                },
                set(val) {

                    this.$emit('input', val);

                }

            }

        }

    }

</script>