<template functional>
	<g>
  <path class="mafs-2" d="M11.72,3.07c10.79-5.33,20.3.17,23.05,10.2l.47,1.71M37.96,9.71l-2.72,5.34-5.34-2.72M27.15,31.98c-10.79,5.33-20.3-.17-23.05-10.2l-.47-1.71M.89,24.33l2.72-5.34,5.34,2.72"/>
  <path class="mafs-1" d="M27.88,12.65l-8.44-3.83-8.43,3.83M27.88,14.19H11.01M13.69,21.47v-5.75h-1.92v7.29M12.92,22.62h7.67M11.39,24.16h9.2M15.23,21.47v-4.99c.01-.2.1-.38.24-.52.14-.14.33-.21.53-.21s.39.08.53.21c.14.14.23.32.24.52v4.99M18.68,21.47v-4.99c.01-.2.1-.38.24-.52.14-.14.33-.21.53-.21s.39.08.53.21c.14.14.23.32.24.52v4.99M22.54,20.44v.57c.01.3.14.58.35.79s.5.33.79.34h1.12c.3-.01.58-.13.79-.34s.33-.49.34-.79v-.16c0-.22-.08-.44-.22-.61s-.33-.3-.54-.36l-1.85-.55c-.22-.06-.41-.18-.55-.36-.14-.17-.22-.39-.23-.61v-.17c.01-.3.13-.58.34-.79s.49-.33.79-.34h1.13c.3.01.58.13.79.34s.33.49.34.79v.57M24.24,17.04v-1.7M24.24,22.14v1.7M20.59,11.89c0,.23-.07.45-.19.64s-.31.34-.52.43c-.21.09-.44.11-.67.07-.22-.04-.43-.15-.59-.32-.16-.16-.27-.37-.32-.59-.04-.22-.02-.46.07-.67s.24-.39.43-.52.41-.19.64-.19c.3,0,.6.12.81.34.22.22.34.51.34.81Z"/>
	</g>
</template>

<script>
	export default {
		name: 'MemberAccountFundingSelected'
	};
</script>


<style lang="scss">
	.mafs-1, .mafs-2 {
	fill: none;
	stroke: var(--form-background);
	stroke-miterlimit: 10;
	}

	.mafs-2 {
	stroke-width: 2px;
	}
</style>
