import store from '@/store/_v2';
import router from '@/router';

const constructUrlNewParams = (name, newParams = {}) => {
	const { path: fromRoute } = router.currentRoute;
	return {
		name,
		params: {
			...newParams,
			fromRoute
		},
		query: {
			memberType: store.state.user.memberType,
			applicationType: store.state.user.applicationType
		}
	};
};

export default constructUrlNewParams;
