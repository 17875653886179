<template>
	<div class="button-container">
		<router-link
			v-if="hasMemberAdvocate"
			:to="{ name: 'Home' }"
			:class="[
				'button-link',
				{
					'is-fullwidth': fullwidth,
					'is-small': small,
					'is-uppercase': !lowercase
				}
			]"
			>Start a new Application</router-link
		>
		<a
			v-if="hasClientWebsiteLink"
			:href="clientWebsiteLink"
			class="button-link"
			:title="anchorTitle"
		>
			Return to {{ clientName }}
		</a>
	</div>
	<!-- <div class="container"> -->
</template>

<script>
	export default {
		name: 'StartNewApplication',
		props: {
			hasMemberAdvocate: {
				type: Boolean,
				required: false
			},
			fullwidth: {
				type: Boolean,
				required: false
			},
			small: {
				type: Boolean,
				required: false
			},
			lowercase: {
				type: Boolean,
				required: false
			},
			clientWebsiteLink: {
				type: String,
				required: false,
			},
			clientName: {
				type: String,
				required: false
			}
		},
		data() {
			return {};
		},
		computed: {
			hasClientWebsiteLink() {
				return this.clientWebsiteLink && this.clientName
			},
			anchorTitle() {
				return `Go back to the ${this.clientName} homepage`
			}
		} 
	};
</script>

<style lang="scss" scoped>
	a,
	.is-fullwidth {
		padding: 15px 0;
		width: 100%;
	}
	.button-container {
		max-width: 75%;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
	}
	.button-link {
		display: inline-block;
		margin-bottom: 0.5rem;
		margin-top: 0.5rem;
		width: auto;
		padding: 15px 0;
		text-align: center;
		vertical-align: middle;
		font-size: 1rem;
		font-weight: 600;
		transition: all 0.3s linear;
		text-decoration: none;
		background-color: var(--white);
		border: 2px solid var(--primary);
		color: var(--primary);

		&:hover {
			background-color: var(--primary);
			border: 2px solid var(--primary);
			color: var(--white);
		}
	}
</style>
