<template>
	<div class="options-container">
		<OaoRadioDep
			v-for="(option, index) in coApplicantOptions"
			:key="option.label"
			v-model="innerValue"
			greyRadioIcon
			:value="option.value"
			:optionValue="option.value"
			:label="option.label"
			:name="name"
			:class="['option', { 'is-selected': isSelected(option.value) }]"
		/>

		<div class="divider"></div>

		<OaoRadioDep
			v-model="innerValue"
			greyRadioIcon
			:value="newCoApplicantSelection.value"
			:optionValue="newCoApplicantSelection.value"
			:label="newCoApplicantSelection.label"
			:name="name"
			:class="['option', 'top-border', { 'is-selected': isSelected(0) }]"
		/>
	</div>
</template>

<script>
	import { OaoFormRadioGroupDep as OaoFormRadioGroup } from '@/components';
	import { OaoRadioDep } from '@/components/form';
	import { ValidationProvider } from 'vee-validate';

	export default {
		name: 'CoApplicantSelection',
		components: {
			ValidationProvider,
			OaoFormRadioGroup,
			OaoRadioDep
		},
		data() {
			return {
				selectedOption: null,
				newCoApplicantSelection: { label: 'Add New Joint', value: 0 }
			};
		},
		props: {
			options: {
				required: true,
				type: Array
			}
		},
		computed: {
			coApplicantOptions() {
				const formattedOptions = this.options.map(obj => ({
					label: `${obj.firstName.charAt(0).toUpperCase() + obj.firstName.slice(1)}${
						obj.middleName != null
							? ' ' + obj.middleName.charAt(0).toUpperCase() + obj.middleName.slice(1)
							: ''
					} ${obj.lastName.charAt(0).toUpperCase() + obj.lastName.slice(1)} ${
						obj.suffix != null ? obj.suffix : ''
					}`,
					value: obj.personSerial
				}));

				return formattedOptions;
			},
			name() {
				return 'coApplicantOption';
			},
			innerValue: {
				get() {
					return this.value;
				},
				set(val) {
					this.selectedOption = val;
					this.$emit('select', val);
				}
			}
		},
		methods: {
			isSelected(option) {
				return option === this.selectedOption;
			}
		}
	};
</script>

<style lang="scss" scoped>
	.options-container {
		margin-bottom: 1rem;

		.divider {
			border: 1.5px solid var(--lightGrey-50);
			margin-bottom: 1.25rem;
		}

		.option {
			margin-bottom: 1.25rem;
			border: 2px solid var(--lightGrey-50);
			border-radius: 5px;
			padding: 0.5rem;
			color: var(--text-primary);
			font-size: 1.1rem;
			font-family: Open Sans;
			font-weight: 600;
			line-height: 16px;
			text-align: left;

			transition: all 0.25s linear;
		}

		.is-selected {
			border: 2px solid var(--primary);
		}
	}
</style>
