import { applicantAPI, memberAdvocateAPI } from '@/api';
import { Module } from 'vuex';
import { IRootState } from '..';
import setData from '../shared/setData';
export interface IMemberAdvocateState {
	firstName: string;
	lastName: string;
	memberAdvocateId: number;
	employeeId: string;
	sessionStartTime: Date | null;
	config: memberAdvocateAPI.IMemberAdvocateConfiguration | null;
}

const memberAdvocate: Module<IMemberAdvocateState, IRootState> = {
	namespaced: true,
	state: {
		firstName: '',
		lastName: '',
		employeeId: '',
		memberAdvocateId: 0,
		sessionStartTime: null,
		config: null
	},
	mutations: {
		setData,
		setMember(state, { firstName, lastName, employeeId, id }) {
			state.firstName = firstName;
			state.lastName = lastName;
			state.employeeId = employeeId;
			state.memberAdvocateId = id;
			state.sessionStartTime = new Date();
		},
		setConfig(state, newConfig: memberAdvocateAPI.IMemberAdvocateConfiguration) {
			state.config = newConfig;
		},
		resetMemberAdvocate(state) {
			state.firstName = '';
			state.lastName = '';
			state.employeeId = '';
			state.memberAdvocateId = 0;
			state.sessionStartTime = null;
		}
	},
	actions: {
		async getAdvocate({ getters, state, commit }): Promise<IMemberAdvocateState> {
			if (!getters.isSessionActive) {
				commit('resetMemberAdvocate');
			}
			return state;
		},
		async StartMemberAdvocateSession({ state, commit }, { firstName, lastName, employeeId, id }) {
			if (!state.config) {
				let configuration = await memberAdvocateAPI.getConfig();
				commit('setConfig', configuration);
			}
			
			commit('setMember', {
				firstName,
				lastName,
				employeeId,
				id
			});
		}
	},
	getters: {
		isSessionActive: state => {
			let now = new Date();
			if (state.sessionStartTime && state.config?.sessionDuration) {
				let diff = (new Date(now).getTime() - new Date(state.sessionStartTime).getTime()) / 1000;
				return diff < state.config?.sessionDuration * 60;
			}
			return false;
		},
		hasMemberAdvocate: state => (state.employeeId ? true : false),
		memberAdvocateId: state => state.memberAdvocateId,
		employeeFirstName: state => state.firstName,
		employeefullName: state => (state.firstName && state.lastName ? `${state.firstName} ${state.lastName}` : null)
	}
};

export default memberAdvocate;
