import axios from '@/utils/axios';

interface ICreateACHPaymentRequest {
    applicationToken: string;
    publicToken: string;
}

interface ICreateACHPaymentResponse{
    hasNSF: boolean;
    created: boolean;
}

interface IACHPayment {
    aCHPaymentsId: number;
    applicationId: number;
    initialAmount: number | null;
    accessToken: string;
    accountName: string;
    accountBalance: number | null;
    routingNumber: string;
    accountNumber: string;
    fileCreatedDate: Date | null;
    chargedAmount: number | null;
}

interface IApplicantTransferAccount {
    ProductName:string;
	AvailableBalance: number;
	SubAccountIndicator: string;
	CoreProductId: number;
	SubAccountSuffix: number;
	CoreAccountId: number;
	MaskedSubAccountNumber: string;
}
interface IGetApplicantTransferAccountsRequest {
	applicantToken: string;
}

interface IGetApplicantTransferAccountsResponse {
	transferAccounts: IApplicantTransferAccount[];
}
interface ICreateTransferPaymentRequest {
	transferAccount: IApplicantTransferAccount;
	applicationToken: string
}

interface ICreateTransferPaymentResponse {
	transferId: number;
}

interface IGetACHPaymentRequest {
    applicationToken: string;
}

interface IGetACHPaymentResponse {
    achPayment: IACHPayment
}

interface IGetPlaidSessionTokenResponse {
    token: string
}

interface IGetCardPaymentDataRequest {
	applicationToken: string;
	applicantToken: string;
}

interface IGetCardPaymentDataResponse {
	firstName: string;
	lastName: string;
	street: string;
	city: string;
	state: string;
	zipCode: string;
}

const getPlaidSessionTokenAsync = async () : Promise<string> => {
	try {
		const { data } = await axios.get<IGetPlaidSessionTokenResponse>(`api/v2/Funding/External/SessionToken`);

		return data.token;

	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}

		throw error;
	}
};
const getACHPaymentAsync = async (request: IGetACHPaymentRequest) : Promise<IGetACHPaymentResponse> => {
	try {
		const { data } = await axios.get<IGetACHPaymentResponse>(`api/v2/Funding/External/ACHPayment/${request.applicationToken}`);

		return data;

	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}

		throw error;
	}
};

const createACHPaymentAsync = async (request: ICreateACHPaymentRequest) : Promise<ICreateACHPaymentResponse> => {
	try {
		const { data } = await axios.post<ICreateACHPaymentResponse>(`api/v2/Funding/External/ACHPayment`, request);

		return data;

	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}

		throw error;
	}
};

const getCardPaymentApplicantData = async (request: IGetCardPaymentDataRequest): Promise<IGetCardPaymentDataResponse> => {
	try {
		const { data } = await axios.post<IGetCardPaymentDataResponse>(`api/v2/Funding/CardPayment/Configuration`, request);

		return data;

	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}

		throw error;
	}
};

const createCardPaymentAsync = async (request: any): Promise<any> => {
	try {
		const { data } = await axios.post<any>('api/v2/Funding/CardPayment', request);

		return data;

	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}

		throw error;
	}
}

const getApplicantTransferAccountsAsync = async (request: IGetApplicantTransferAccountsRequest): Promise<IGetApplicantTransferAccountsResponse> => {
	try {
		const { data } = await axios.get<IGetApplicantTransferAccountsResponse>(`api/v2/Transfer/Accounts/${request.applicantToken}`);
		return data;
	}
	catch (error) {

		if (typeof (error) === 'string') {
			throw new Error(error);
		}
		throw error;
	}
}

const createTransferPaymentAsync = async (request: ICreateTransferPaymentRequest): Promise<ICreateTransferPaymentResponse> => {
	try {
		const { data } = await axios.post<ICreateTransferPaymentResponse>('api/v2/Transfer', request);
		return data;
	}
	catch (error) {
	
		if (typeof (error) === 'string') {
			throw new Error(error);
		}
		throw error;
	}
}

export { 
    getPlaidSessionTokenAsync,
    getACHPaymentAsync, 
	getCardPaymentApplicantData,
	createCardPaymentAsync,
	createACHPaymentAsync,
	getApplicantTransferAccountsAsync,
	createTransferPaymentAsync,
    ICreateACHPaymentRequest, 
    ICreateACHPaymentResponse,
    IACHPayment, 
    IGetACHPaymentRequest,
	IGetACHPaymentResponse,
	IApplicantTransferAccount,
	ICreateTransferPaymentRequest,
	ICreateTransferPaymentResponse,
	IGetApplicantTransferAccountsRequest,
	IGetApplicantTransferAccountsResponse,
	IGetCardPaymentDataRequest,
	IGetCardPaymentDataResponse};