<template functional>
	<path
		d="M12.9368 14.5824L24.9999 2.52553L22.8857 0.417595L12.9368 10.3665L2.98782 0.411377L0.879883 2.52553L12.9368 14.5886V14.5824Z"
	/>
</template>

<script>
	export default {
		name: 'ChevronDown'
	};
</script>
