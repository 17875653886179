<template functional>

    <g>
        <path d="M16.4,12.9L16.4,12.9c0,0.3-0.3,0.7-0.7,0.7c0,0,0,0,0,0H1.4c-0.4,0-0.7-0.3-0.7-0.7V6.7
            c0-0.4,0.3-0.7,0.7-0.7h9.1V5.4H1.4C0.6,5.4,0,6,0,6.7v6.2c0,0.8,0.7,1.4,1.4,1.4h14.3c0.8,0,1.3-0.6,1.3-1.3v0V9.5h-0.7V12.9z"/>
        <rect x="4.8" y="11" width="3.2" height="0.7"/>
        <rect x="1.6" y="12.3" width="6.6" height="0.7"/>
        <path d="M2.7,6.5v0.7c-0.6,0-1,0.5-1,1v0.1c0,0.4,0.3,0.8,0.7,0.9l1.1,0.3c0.1,0,0.2,0.1,0.2,0.3V10
            c0,0.2-0.2,0.3-0.3,0.3H2.7c-0.2,0-0.3-0.2-0.3-0.3V9.7H1.7V10c0,0.6,0.5,1,1,1v0.7h0.7V11c0.6,0,1-0.5,1-1V9.9
            c0-0.4-0.3-0.8-0.7-0.9L2.6,8.6c-0.1,0-0.2-0.1-0.2-0.2V8.3c0-0.2,0.2-0.3,0.3-0.3h0.7c0.2,0,0.3,0.2,0.3,0.3v0.3h0.7V8.3
            c0-0.6-0.5-1-1-1V6.5H2.7z"/>
        <path d="M12.3,11.2L18,5.6l-1.9-1.9l-3.8,3.8L11,6.1L9.1,8L12.3,11.2z M12.3,8.5l3.8-3.8L17,5.6l-4.7,4.7L10.1,8
            L11,7.1L12.3,8.5z"/>
    </g>

</template>





<script>

    export default {

        name: 'AccountSelected'

    }

</script>