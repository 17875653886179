import router from '@/router';
import { cardConfiguation } from '@/api';
import { IProductCardConfiguration } from '@/api/_v2/cardConfiguation';
import { IConfigurationState } from '../modules/config';

export interface ICardSelectionConfigurationState {
	cardSelectionConfiguration: any[];
	filterConfiguration: any[];
	conversationalText: string;
}

const state: ICardSelectionConfigurationState = {
	cardSelectionConfiguration: [],
	conversationalText: '',
	filterConfiguration: []
};

function resetState(state) {
	state.cardSelectionConfiguration = [];
	state.conversationalText = '';
	state.filterConfiguration = [];
}

const mutations = {
	//TODO: Add Mutations (if any)
};
function filterProductCardOptions(productId: any, { state }: any): IProductCardConfiguration {
	const cardsOnlyForTheProduct =
		state.cardSelectionConfiguration.find((option: { productId: number }) => option.productId === productId) ?? [];

	let cardsAfterFiltered: IProductCardConfiguration = {
		productId: productId,
		cards: []
	};

	//Only check when cards for the product contain any of them
	if (state.filterConfiguration && cardsOnlyForTheProduct.cards) {
		state.filterConfiguration.filterValues.forEach(element => {
			var filteredCards = cardsOnlyForTheProduct.cards.filter(card => card.cardTypeSerial == element);
			cardsAfterFiltered.cards = cardsAfterFiltered.cards.concat(filteredCards);
		});
	}
	if (cardsAfterFiltered.cards.length < 1) {
		cardsAfterFiltered = cardsOnlyForTheProduct;
	}
	return cardsAfterFiltered;
}
const actions = {
	async getCardSelectionConfigs(
		{ commit, state, rootGetters }: any,
		productId: any
	): Promise<IProductCardConfiguration | boolean> {
		try {
			let response: any;

			if (state.cardSelectionConfiguration.length > 0) {
				response =
					state.cardSelectionConfiguration.find(
						(option: { productId: number }) => option.productId === productId
					) ?? false;
			} else {
				const request = { applicationToken: rootGetters['application/applicationToken'] };
				const { cardConfigurations: cards, filterConfiguration: filters, conversationalText: conversationalText } =
					await cardConfiguation.getProductCardSelectionConfiguration(request);

				commit('setData', { objName: 'cardSelectionConfiguration', data: cards });
				commit('setData', { objName: 'conversationalText', data: conversationalText });

				commit('setData', { objName: 'filterConfiguration', data: filters });
			}

			response = filterProductCardOptions(productId, { state });

			return response;
		} catch (error) {
		
			if (typeof error === 'string') {
				throw new Error(error);
			}

			throw error;
		}
	}
};

const getters = {
	cardPageConversationalText: state => state.conversationalText
};

export { state, resetState, mutations, actions, getters };

export default {
	state,
	resetState,
	mutations,
	actions,
	getters
};
