<template functional>

    <g transform="matrix(1 0 0 1 -725 -500 )">
		<path class="st0" d="M739,510.3c0.1-0.1,0.2-0.3,0.2-0.5v-1.5c0-0.2-0.1-0.4-0.2-0.5s-0.3-0.2-0.5-0.2h-3v-3c0-0.2-0.1-0.4-0.2-0.5
			s-0.3-0.2-0.5-0.2h-1.5c-0.2,0-0.4,0.1-0.5,0.2s-0.2,0.3-0.2,0.5v3h-3c-0.2,0-0.4,0.1-0.5,0.2s-0.2,0.3-0.2,0.5v1.5
			c0,0.2,0.1,0.4,0.2,0.5s0.3,0.2,0.5,0.2h3v3c0,0.2,0.1,0.4,0.2,0.5s0.3,0.2,0.5,0.2h1.5c0.2,0,0.4-0.1,0.5-0.2s0.2-0.3,0.2-0.5v-3
			h3C738.7,510.5,738.9,510.4,739,510.3z M741.8,504.5c0.8,1.4,1.2,2.9,1.2,4.5s-0.4,3.1-1.2,4.5s-1.9,2.5-3.3,3.3s-2.9,1.2-4.5,1.2
			s-3.1-0.4-4.5-1.2s-2.5-1.9-3.3-3.3s-1.2-2.9-1.2-4.5s0.4-3.1,1.2-4.5s1.9-2.5,3.3-3.3s2.9-1.2,4.5-1.2s3.1,0.4,4.5,1.2
			S741,503.1,741.8,504.5z"/>
	</g>

</template>




<script>

    export default {

        name: 'AddIcon'

    }

</script>