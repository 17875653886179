<template>
	<portal to="modal-content">
		<section class="session-expired">
			<OaoButton
				v-if="resumeApplicationConfigToggle"
				title="Resume Application"
				lowercase
				fullwidth
				small
				@click="launchResume"
			/>

			<OaoButton
				v-if="!resumeApplicationConfigToggle"
				tag="router-link"
				title="Restart Application"
				lowercase
				fullwidth
				small
				:to="{ name: 'Home' }"
			/>

			<OaoButton tag="a" :title="anchorTitle" type="hollow" lowercase fullwidth small :href="clientWebsite" />
		</section>
	</portal>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex';
	import { OaoButton } from '@/components';

	export default {
		name: 'SessionExpired',
		components: {
			OaoButton
		},
		computed: {
			anchorTitle() {
				return `Return to ${this.clientName} Homepage`;
			},
			...mapGetters('config', ['resumeApplicationConfigToggle']),
			...mapGetters('config', ['clientWebsite']),
			...mapGetters('branding', ['clientName']),
			...mapGetters('application', ['applicationToken'])
		},
		created() {
			this.triggerModal({
				isOpen: true,
				title: 'Your Session Has Expired',
				smallTitle: true,
				preventClose: true
			});
		},
		destroyed() {
			this.triggerModal();
		},
		methods: {
			...mapActions('modal', ['triggerModal']),
			async launchResume() {
				return this.$router.push(this.$constructUrlNewParams('Resume', { appId: this.applicationToken }));
			}
		}
	};
</script>

<style lang="scss" scoped>
	.session-expired {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		margin-top: 0.5rem;
		padding-top: 1rem;
		border-top: 2px solid var(--text-body-25);

		[type='button'] {
			border-radius: 0.2rem;
		}

		p {
			color: var(--primary);
			font-weight: 300;
			transition: color 0.5s;
		}
	}
</style>
