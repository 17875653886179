import axios from '@/utils/axios';

export interface IUpdateApplicationAffinityRequest {
	applicationToken: string;
	affinity: IAffinity;
}
export interface IUpdateApplicationAffinityResponse {
	updated: boolean;
}
export interface IAffinityCreateRequest {
	applicationToken: string;
	applicantToken: string;
	affinity: IAffinity;
}
export interface IAffinityGetRequest {
	applicationToken: string;
	applicantToken: string;
}
export interface IAffinityGetResponse {
	affinity: IAffinity;
}
export interface IAffinityCreateResponse {
	affinityValueId: number;
}
//How they are related (ex: Student)
export interface IAffinity {
	affinityId: number | null;
	affinityConfigurationId: number;
	value: string;
}

const getAffinityAsync = async (request: IAffinityGetRequest): Promise<IAffinityGetResponse> => {
	try {
		const { data } = await axios.post<IAffinityGetResponse>('/api/v2/Affinity/Applicant', request);
		return data;
	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};

const updateApplicationAffinityValueAsync = async (
	request: IUpdateApplicationAffinityRequest
): Promise<IUpdateApplicationAffinityResponse> => {
	try {
		const { data } = await axios.put<IUpdateApplicationAffinityResponse>('/api/v2/Affinity', request);

		return data;
	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};

const createAffinityAsync = async (request: IAffinityCreateRequest): Promise<IAffinityCreateResponse> => {
	try {
		const { data } = await axios.post<IAffinityCreateResponse>('/api/v2/Affinity', request);

		return data;
	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};

export { updateApplicationAffinityValueAsync, createAffinityAsync, getAffinityAsync };
