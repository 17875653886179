import axiosLib from 'axios';

import router from '@/router';
import store from '@/store/_v2';
import handleAudit from '@/utils/handleAudit';
import { constructUrl, logError } from '@/utils';

const axios = axiosLib.create({
	baseURL: process.env.BASE_URL
});

axios.interceptors.request.use(
	async config => {

		const token = store.getters["config/xsrfToken"];
		
		if (token) {
			config.headers['X-XSRF-TOKEN'] = token;
		}

		config.headers['Content-Type'] = 'application/json';
		
		return config;
	},
	error => {
		return Promise.reject(error);
	}
);

axios.interceptors.response.use(
	async response => {
		const { bypassAudit = false } = router.currentRoute.meta;

		if (!bypassAudit) {
			handleAudit({ success: true });
		}

		return response;
	},
	async error => {
		const { bypassAudit = false } = router.currentRoute.meta;

		if (!bypassAudit) {
			handleAudit({ success: false });
		}

		// find out how to access appToken and applicantToken
		// call logError here and pass params
		const applicantToken = store.getters["applicant/applicantToken"];
		const applicationToken = store.getters["application/applicationToken"];
		const requestId = error?.requestId || null;

		logError(error, requestId, applicantToken, applicationToken);

		if (error.response.status == 401) {
            router.push(constructUrl('Home'));
        }

		return Promise.reject(error);
	}
);

//axios.defaults.timeout = process.env.NODE_ENV === 'production' ? 50000 : 0;

export default axios;
export { axios };
