import { configurationsAPIv2 } from '@/api';
import { IDisclosure, IDisclosurePageConfigsResponse, IEStatementOption } from '@/api/_v2/configurations';
import { ApplicantTypes } from '@/constants';

export interface IDisclosureConfigurationState {
	disclosureConfiguration: IDisclosureConfiguration;
}

export interface IDisclosureConfiguration {
	primaryDisclosures: IDisclosure[];
	coapplicantDisclosures: IDisclosure[];
	isKeystoneEStatement: boolean;
	eStatementOptionConfigurations: IEStatementOption[];
}

const state: IDisclosureConfigurationState = {
	disclosureConfiguration: {
		primaryDisclosures: [],
		coapplicantDisclosures: [],
		isKeystoneEStatement: false,
		eStatementOptionConfigurations: []
	}
};

const mutations = {
	//TODO: Add Mutations (if any)
};

function resetState(state) {
	state.disclosureConfiguration.primaryDisclosures = [],
	state.disclosureConfiguration.coapplicantDisclosures = [],
	state.disclosureConfiguration.eStatementOptionConfigurations = [],
	state.disclosureConfiguration.isKeystoneEStatement = false
}

const actions = {
	async getDisclosureConfigurations({ commit, state }: any): Promise<IDisclosurePageConfigsResponse> {
		try {
			if (
				!state.disclosureConfiguration.primaryDisclosures.length ||
				!state.disclosureConfiguration.coapplicantDisclosures.length
			) {
				const configs = await configurationsAPIv2.getDisclosurePageConfigurations();
				const primary = configs.disclosureConfigurations.filter(
					x => x.applicantType == ApplicantTypes.PRIMARYAPPLICANT
				);
				const coapplicant = configs.disclosureConfigurations.filter(
					x => x.applicantType == ApplicantTypes.COAPPLICANT
				);
				commit('setData', {
					objName: 'disclosureConfiguration',
					data: { primaryDisclosures: primary, coapplicantDisclosures: coapplicant, isKeystoneEStatement: configs.keystoneEStatementOptionEnabled, eStatementOptionConfigurations: configs.eStatementOptionConfigurations }
				});
			}

			return state.disclosureConfiguration;
		} catch (error) {
			
			if (typeof error === 'string') {
				throw new Error(error);
			}

			throw error;
		}
	}
};

export { state, resetState, mutations, actions };

export default {
	state,
	resetState,
	actions,
	mutations
};
