<template functional>

    <main
        :class="[
            data.class,
            data.staticClass,
            'container',
            {
                'pad-vertical': props.padVertical
            }
        ]"
        :style="[
            data.style,
            data.staticStyle
        ]"
    >
        <slot/>
    </main>

</template>





<script>

    export default {

        name: 'Container',
        props: {

            padVertical: {
                type: Boolean,
                required: false
            }

        }

    };

</script>





<style lang="scss" scoped>

    .container.pad-vertical {
        padding: 1.75rem;
    }

    @media only screen and (min-width: 641px) {

        .container {
            max-width: 60%;
            margin: 0 auto;
        }

    }

</style>