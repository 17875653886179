<template functional>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
		<!--!Font Awesome Pro 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.-->
		<path
			d="M64 64C46.3 64 32 78.3 32 96v32H544V96c0-17.7-14.3-32-32-32H64zM32 160v64H544V160H32zm0 96V416c0 17.7 14.3 32 32 32H512c17.7 0 32-14.3 32-32V256H32zM0 96C0 60.7 28.7 32 64 32H512c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM96 368c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16zm128 0c0-8.8 7.2-16 16-16H368c8.8 0 16 7.2 16 16s-7.2 16-16 16H240c-8.8 0-16-7.2-16-16z"
		/>
	</svg>
</template>

<script>
	export default {
		name: 'CardIcon'
	};
</script>
