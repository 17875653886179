export default {
    ESIGNATUREAGREEMENT: 1,
    FEESCHEDULE: 2,
    STATEMENTSTERMSCONDITIONS: 3,
    RATESHEET: 4,
    PRIVACYPOLICY: 5,
    FAIRCREDITREPORTINGACT: 6,
    CARDOVERDRAFTCOVERAGE: 7,
    PROMOTIONALRATES: 8,
    JUMBOMONEYMARKET: 9
};