<template>

    <ValidationProvider
        :rules="validationRules"
        :name="name"
        :vid="validationId"
        v-slot="{ errors }"
        slim
    >
        <OaoSwitch
            v-model="innerValue"
            :label="label"
            :name="name"
            :optionValue="optionValue"
            :disabled="disabled"
            :noPadding="noPadding"
            :errors="errors[0]"
            :hasError="errors.length > 0"
        >
            <slot></slot>
        </OaoSwitch>

    </ValidationProvider>

</template>




<script>

    import { ValidationProvider } from 'vee-validate';
    import { OaoSwitch } from './form';

    export default {

        name: 'FormSwitch',
        components: {
            ValidationProvider,
            OaoSwitch
        },
        props: {

            value: {
                required: true
            },
            optionValue: {
                required: false,
                default: true
            },
            label: {
                type: String,
                required: false
            },
            name: {
                type: String,
                required: true
            },
            disabled: {
                type: Boolean,
                required: false,
            },
            noPadding: {
                type: Boolean,
                required: false
            },
            validationId: {
                type: String,
                required: false
            },
            validationRules: {
                type: String,
                required: false
            }

        },
        computed: {
            
            innerValue: {

                get() {

                    return this.value;

                },
                set(val) {

                    this.$emit('input', val);

                }

            }

        }

    }

</script>




<style lang="scss" scoped>

    p.has-error {
        border-top: 1px solid var(--danger-25);
        font-size: 0.85rem;
        margin-top: 0.5rem;
        padding-top: 0.5rem;
        color: var(--danger);
        text-align: center;
    }

</style>