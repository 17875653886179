<template>
		<label
			:for="id"
			:class="[
				'tile',
				{
					'is-required': required,
					'is-selected': !required & (optionValue == value)
				}
			]"
		>
			<input :id="id" :name="title" type="radio" v-model.trim="innerValue" :value="optionValue" data-test-input />
			<p class="required" v-if="required">REQUIRED</p>

			<section>
				<BaseIcon :name="title" :height="height" :width="width" :viewBox="viewbox" :color="fillColor">					
                    <component :is="tileIconSelected"  v-if="optionValue == value" />
                    <component v-if="optionValue != value" :is="tileIcon" />
				</BaseIcon>
				<div>
					<h1>{{ title }}</h1>
					<p>{{ description }}</p>
				</div>
			</section>

			<BaseIcon class="add-button" :name="optionValue == value ? 'tile Selected' : 'tile Unselected'">
				<SelectedIcon v-if="optionValue == value" />
			</BaseIcon>
		</label>
</template>

<script>
	import { BaseIcon, AddIcon, CheckingIcon, SelectedIcon } from '@/components/icons';

	export default {
		name: 'OaoSelectionTile',
		components: {
			BaseIcon,
			AddIcon,
			CheckingIcon,
			SelectedIcon
		},
		props: {
			value: {
				required: true
			},
			optionValue: {
				required: true
			},
			title: {
				type: String,
				required: true
			},
			description: {
				type: String,
				required: true
			},
			required: {
				type: Boolean,
				required: false
			},
			iconComponentName: {
				type: String,
				required: false
			},
			validationRules: {
				type: String,
				required: false
			},
			height: {
				type: String,
				required: false,
				default: 100
			},
			width: {
				type: String,
				required: false,
				default: 100
			},
			viewbox: {
				type: String,
				required: false,
				default: '0 0 50 50'
			}
		},
		computed: {
			fillColor() {
				return 'none';
			},
			id() {
				return `${this.title.replace(/\s/g, '')}-${this._uid}`;
			},
			innerValue: {
				get() {
					return this.value;
				},
				set(val) {                    
					this.$emit('input', val);
				}
			},
			tileIcon() {
				if (this.iconComponentName) {
					return () => import(`@/components/icons/${this.iconComponentName}`);
				}
			},
            tileIconSelected() {
				if (this.iconComponentName) {
					return () => import(`@/components/icons/${this.iconComponentName +"Selected"}`);
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	.tile {
		position: relative;
		display: block;
		padding: 0.75rem;
		border: 2px solid var(--primary);
		border-radius: 3px;
		transition: background-color 0.5s, color 0.5s;
		color: var(--primary);
		cursor: pointer;

		&:not(:last-of-type) {
			margin-bottom: 0.75rem;
		}

		svg {
			transition: fill 0.5s;
		}

		&.is-required {
			border: 2px solid var(--tertiary);
			color: var(--tertiary);
		}

		&.is-selected {
			background-color: var(--primary);
			color: var(--white);
		}

		&.is-disabled {
			cursor: not-allowed;
		}

		&.display-only {
			cursor: default;
		}

		.required {
			position: absolute;
			top: 0.25rem;
			right: 0.25rem;
			font-size: 0.625rem;
			font-weight: 800;
			color: var(--primary);
		}

		input {
			position: absolute;
			left: -9999px;
		}

		section {
			display: flex;
			align-items: center;

			div {
				flex: 1;
				margin-left: 1rem;

				h1 {
					font-size: 1.25rem;
					font-weight: 400;
				}

				p {
					font-size: 0.75rem;
					font-weight: 400;
					padding-right: 2.25rem;
				}
			}
		}

		.add-button {
			position: absolute;
			bottom: 0.25rem;
			right: 0.25rem;
			height: 1.5625rem;
			width: 1.5625rem;
			color: var(--primary);
		}

		&.is-required .add-button,
		&.is-selected .add-button {
			color: var(--success);
		}
	}
</style>
