import { Module } from 'vuex';
import { IRootState } from '..';
import setData from '../shared/setData';
import {
	cardConfigurationActions,
	cardConfigurationMutations,
	cardConfigurationState,
	cardConfigurationGetters,
	resetCardConfigurationState,
	employmentConfigurationActions,
	employmentConfigurationMutations,
	employmentConfigurationState,
	employmentConfigurationResetState,
	identityConfigurationActions,
	identityConfigurationMutations,
	identityConfigurationState,
	identityConfigurationResetState,
	disclosureConfigurationActions,
	disclosureConfigurationMutations,
	disclosureConfigurationState,
	disclosureConfigurationResetState,
	fundingConfigurationActions,
	fundingConfigurationMutations,
	fundingConfigurationState,
	fundingConfigurationResetState,
	fundingConfigurationGetters,
	signConfigurationActions,
	signConfigurationState,
	signConfigurationResetState,
	printConfigurationActions,
	printConfigurationResetState,
	printConfigurationState,
	printConfigurationGetters,
	affiliateConfigurationsActions,
	affiliateConfigurationsState,
	affiliateConfigurationsResetState,
	affiliateConfigurationsGetters
} from '../shared';
import { IHomePageConfigsResponse, ILeaveReason, IState } from '@/api/_v2/configurations';
import { ICaptcha } from '@/api/_v2/authenticate';
import { configurationsAPIv2 } from '../../../api';
import { CaptchaImplementation } from '@/constants';
import { buildCaptchaImplementationConfigurations } from '@/utils';
import _cloneDeep from 'lodash.clonedeep';
import { IDynamicHyperlinkConfiguration, IDynamicHeaderConfigurations } from '@/api/_v2/configurations';

export interface IConfigurationState {
	applicationConfigurationLoaded: boolean;
	siteAvailability: null;
	xsrfToken: string | null;
	showEmailField: boolean;
	displayLeavingModalToggle: boolean;
	resumeApplicationConfigToggle: boolean;
	leaveReasonConfigs: ILeaveReason[];
	existingMemberFlowToggle: boolean;
	//identity page

	//captcha
	captchaUseConfig: any[] | null;
	captchaConfigurations: any[] | null;
	captchaV3: ICaptcha | null;
	captchaV2: ICaptcha | null;
	captchaV2Hidden: ICaptcha | null;
	updateCaptcha: null;
	//client configs
	isLoaded: boolean;
	clientPhoneNumber: string;
	clientWebsite: string;
	clientLocationsWebsite: string;
	dynamicHyperlinks: IDynamicHyperlinkConfiguration[];
	dynamicHeaderConfigurations: IDynamicHeaderConfigurations[];
	reselectPageTitle: string;
	ineligibilityPageText: string;
	ineligibilityPageDeclinedReselectText: string;
	eDisclosuresConfirmationAttempts: number | null;
	eDisclosuresConfirmationRetryCadence: number | null;
	defaultsLoaded: boolean;
	isLoading: boolean;
	states: IState[];
	missingCoreInformationText: string;
	reviewPageTextTemplate: string;
}
const config: Module<IConfigurationState, IRootState> = {
	namespaced: true,
	state: {
		...cardConfigurationState,
		...employmentConfigurationState,
		...identityConfigurationState,
		...disclosureConfigurationState,
		...fundingConfigurationState,
		...signConfigurationState,
		...printConfigurationState,
		...affiliateConfigurationsState,
		applicationConfigurationLoaded: false,
		siteAvailability: null,
		xsrfToken: '',
		showEmailField: false,
		displayLeavingModalToggle: false,
		resumeApplicationConfigToggle: false,
		leaveReasonConfigs: [],
		existingMemberFlowToggle: false,
		captchaUseConfig: null,
		captchaConfigurations: null,
		captchaV3: null,
		captchaV2: null,
		captchaV2Hidden: { enabled: false, implementation: CaptchaImplementation.RECAPTCHAV2HIDDEN },
		updateCaptcha: null,
		isLoaded: false,
		clientPhoneNumber: '',
		clientWebsite: '',
		clientLocationsWebsite: '',
		dynamicHyperlinks: [],
		dynamicHeaderConfigurations: [],
		reselectPageTitle: '',
		ineligibilityPageText: '',
		ineligibilityPageDeclinedReselectText: '',
		eDisclosuresConfirmationAttempts: null,
		eDisclosuresConfirmationRetryCadence: null,
		defaultsLoaded: false,
		isLoading: false,
		states: [],
		missingCoreInformationText: '',
		reviewPageTextTemplate: ''
	},
	mutations: {
		setData,
		...cardConfigurationMutations,
		...employmentConfigurationMutations,
		...identityConfigurationMutations,
		...disclosureConfigurationMutations,
		...fundingConfigurationMutations,
		setLoading(state, isLoading) {
			state.isLoading = isLoading;
		},
		resetState(state) {
			state.siteAvailability = null;
			state.xsrfToken = '';
			state.defaultsLoaded = false;
			state.isLoading = false;
			resetCardConfigurationState(state);
			employmentConfigurationResetState(state);
			identityConfigurationResetState(state);
			disclosureConfigurationResetState(state);
			fundingConfigurationResetState(state);
			signConfigurationResetState(state);
			printConfigurationResetState(state);
			affiliateConfigurationsResetState(state);
		}
	},
	actions: {
		...cardConfigurationActions,
		...employmentConfigurationActions,
		...identityConfigurationActions,
		...disclosureConfigurationActions,
		...fundingConfigurationActions,
		...signConfigurationActions,
		...printConfigurationActions,
		...affiliateConfigurationsActions,
		async getStates({ commit, state }): Promise<IState[]> {
			if (state.states.length > 0) {
				return state.states;
			}
			const states = await configurationsAPIv2.getStates();

			commit('setData', { objName: 'states', data: states });

			return states;
		},
		async getHomePageConfigurations({ commit, state }): Promise<IHomePageConfigsResponse> {
			const {
				leaveReasonConfigs,
				resumeApplicationConfigToggle,
				displayLeavingModalToggle,
				xsrfToken,
				showEmailField,
				siteAvailability,
				existingMemberFlowToggle,
				clientPhoneNumber,
				clientWebsite,
				clientLocationsWebsite,
				dynamicHyperlinks,
				dynamicHeaderConfigurations,
				reselectPageTitle,
				ineligibilityPageText,
				ineligibilityPageDeclinedReselectText,
				eDisclosuresConfirmationAttempts,
				eDisclosuresConfirmationRetryCadence,
				captchaConfigurations,
				captchaUseConfigurations,
				missingCoreInformationText,
				reviewPageTextTemplate
			} = await configurationsAPIv2.getHomePageConfigurations();

			commit('setData', { objName: 'leaveReasonConfigs', data: leaveReasonConfigs });
			commit('setData', { objName: 'resumeApplicationConfigToggle', data: resumeApplicationConfigToggle });
			commit('setData', { objName: 'displayLeavingModalToggle', data: displayLeavingModalToggle });
			commit('setData', { objName: 'xsrfToken', data: xsrfToken });
			commit('setData', { objName: 'showEmailField', data: showEmailField });
			commit('setData', { objName: 'siteAvailability', data: siteAvailability });
			commit('setData', { objName: 'existingMemberFlowToggle', data: existingMemberFlowToggle });

			//client display
			commit('setData', { objName: 'clientPhoneNumber', data: clientPhoneNumber });
			commit('setData', { objName: 'clientWebsite', data: clientWebsite });
			commit('setData', { objName: 'clientLocationsWebsite', data: clientLocationsWebsite });
			commit('setData', { objName: 'dynamicHyperlinks', data: dynamicHyperlinks });
			commit('setData', { objName: 'dynamicHeaderConfigurations', data: dynamicHeaderConfigurations });
			commit('setData', { objName: 'reselectPageTitle', data: reselectPageTitle });
			commit('setData', { objName: 'ineligibilityPageText', data: ineligibilityPageText });
			commit('setData', {
				objName: 'ineligibilityPageDeclinedReselectText',
				data: ineligibilityPageDeclinedReselectText
			});
			commit('setData', { objName: 'eDisclosuresConfirmationAttempts', data: eDisclosuresConfirmationAttempts });
			commit('setData', {
				objName: 'eDisclosuresConfirmationRetryCadence',
				data: eDisclosuresConfirmationRetryCadence
			});
			commit('setData', { objName: 'missingCoreInformationText', data: missingCoreInformationText });
			commit('setData', { objName: 'reviewPageTextTemplate', data: reviewPageTextTemplate });

			//captcha
			commit('setData', { objName: 'captchaUseConfig', data: captchaUseConfigurations });
			commit('setData', { objName: 'captchaConfigurations', data: captchaConfigurations });

			commit('setData', { objName: 'isLoaded', data: true });
			commit('setData', { objName: 'applicationConfigurationLoaded', data: true });

			return {
				leaveReasonConfigs,
				resumeApplicationConfigToggle,
				displayLeavingModalToggle,
				xsrfToken,
				showEmailField,
				siteAvailability,
				existingMemberFlowToggle,
				clientPhoneNumber,
				clientWebsite,
				clientLocationsWebsite,
				dynamicHyperlinks,
				dynamicHeaderConfigurations,
				reselectPageTitle,
				ineligibilityPageText,
				ineligibilityPageDeclinedReselectText,
				eDisclosuresConfirmationAttempts,
				eDisclosuresConfirmationRetryCadence,
				captchaConfigurations,
				captchaUseConfigurations,
				missingCoreInformationText,
				reviewPageTextTemplate
			};
		},
		//TODO: Evaluate if we want ALL configuration to reload when we refesh or if only some should
		async setDefaults({ commit, dispatch, state }, { isError, themeCode }): Promise<void> {
			//TODO: Load this once initially, but have branding persist for a single client even on refresh
			await dispatch(
				'branding/setBranding',
				{
					hostName: window.location.hostname,
					productName: 'OAO',
					themeCode: themeCode
				},
				{ root: true }
			);

			if (state.defaultsLoaded) return;

			try {
				commit('setData', { objName: 'isLoading', data: true });
				let { captchaConfigurations: captcha, captchaUseConfigurations: captchaUseConfig } = await dispatch(
					'getHomePageConfigurations'
				);

				dispatch('setCaptchaConfiguration', {
					captchaConfigurations: captcha,
					captchaUseConfigurations: captchaUseConfig
				});

				if (!isError) {
					commit('setData', { objName: 'defaultsLoaded', data: true });
				}

				commit('setData', { objName: 'isLoading', data: false });
			} catch (error) {
				commit('setData', { objName: 'isLoading', data: false });

				if (typeof error === 'string') {
					throw new Error(error);
				}

				throw error;
			}
		},
		setCaptchaConfiguration({ commit }, { captchaConfigurations, captchaUseConfigurations }) {
			if (captchaConfigurations) {
				let captchaConfigs = buildCaptchaImplementationConfigurations(captchaConfigurations);

				if (captchaConfigs.v2.enabled) {
					commit('setData', { objName: 'captchaV2', data: captchaConfigs.v2 });
				}
				if (captchaConfigs.v2Hidden.enabled) {
					commit('setData', { objName: 'captchaV2Hidden', data: captchaConfigs.v2Hidden });
				}
				if (captchaConfigs.v3) {
					commit('setData', { objName: 'captchaV3', data: captchaConfigs.v3 });
				}
			}

			if (captchaUseConfigurations) {
				commit('setData', { objName: 'captchaUseConfig', data: captchaUseConfigurations });
			}
		},
		updateCaptcha({ commit, state }: any, { implementation, captcha }) {
			if (captcha) {
				switch (implementation) {
					case CaptchaImplementation.RECAPTCHAV2:
						{
							const merged = Object.assign(_cloneDeep(state.captchaV2), captcha);
							commit('setData', { objName: 'captchaV2', data: merged });
						}
						break;
					case CaptchaImplementation.RECAPTCHAV2HIDDEN:
						{
							const merged = Object.assign(_cloneDeep(state.captchaV2Hidden), captcha);
							commit('setData', { objName: 'captchaV2Hidden', data: merged });
						}
						break;
					case CaptchaImplementation.RECAPTCHAV3:
						{
							const merged = Object.assign(_cloneDeep(state.captchaV3), captcha);
							commit('setData', { objName: 'captchaV3', data: merged });
						}
						break;
				}
			}
		},
		updateCaptchaUse({ commit, state }: any, captchaUseConfig: any) {
			const origUseConfigs = _cloneDeep(state.captchaUseConfig);

			const index = origUseConfigs.findIndex(
				(config: { implementation: any; processingStage: any }) =>
					config.implementation === captchaUseConfig.implementation &&
					config.processingStage === captchaUseConfig.processingStage
			);

			if (index) {
				origUseConfigs.splice(index, 1);
				origUseConfigs.push(captchaUseConfig);
			}

			commit('setData', { objName: 'captchaUseConfig', data: origUseConfigs });
		},
		async getRegistrationPageConfigs({ commit }: any) {
			const response = await configurationsAPIv2.getRegistrationPageConfigurations();

			return response;
		}
	},
	getters: {
		...printConfigurationGetters,
		...fundingConfigurationGetters,
		...cardConfigurationGetters,
		...affiliateConfigurationsGetters,
		xsrfToken: state => state.xsrfToken,
		leaveReasonConfigs: state => state.leaveReasonConfigs,
		siteAvailability: state => state.siteAvailability,
		showEmailField: state => state.showEmailField,
		displayLeavingModalToggle: state => state.displayLeavingModalToggle,
		resumeApplicationConfigToggle: state => state.resumeApplicationConfigToggle,
		existingMemberFlowToggle: state => state.existingMemberFlowToggle,
		captchaV3: state => state.captchaV3,
		captchaV2: state => state.captchaV2,
		captchaV2Hidden: state => state.captchaV2Hidden,
		captchaConfig: state => [state.captchaV2, state.captchaV2Hidden, state.captchaV3],
		captchaUseConfig: state => state.captchaUseConfig,
		getUseConfig: state => (auditProcessingStage: number, captchaImplementationType: number) => {
			if (state.captchaUseConfig === null) return null;
			const currentStageConfigs = state.captchaUseConfig.filter(
				config => auditProcessingStage === config.processingStage
			);
			return (
				currentStageConfigs.filter(
					config => !captchaImplementationType || captchaImplementationType === config.implementation
				) || null
			);
		},
		captchaEnabled: (state, getters) => (auditProcessingStage: number, captchaImplementationType: number) => {
			const implementationConfigs = getters.getUseConfig(auditProcessingStage, captchaImplementationType);
			return implementationConfigs[0] ? implementationConfigs[0].enabled : false;
		},
		clientPhoneNumber: state => state.clientPhoneNumber,
		dynamicHyperlinks: state => state.dynamicHyperlinks,
		dynamicHeaderConfiguration: state => state.dynamicHeaderConfigurations,
		clientWebsite: state => state.clientWebsite,
		clientLocationsWebsite: state => state.clientLocationsWebsite,
		reselectPageTitle: state => state.reselectPageTitle,
		ineligibilityPageText: state => state.ineligibilityPageText,
		ineligibilityPageDeclinedReselectText: state => state.ineligibilityPageDeclinedReselectText,
		missingCoreInformationText: state => state.missingCoreInformationText,
		EDisclosuresConfirmationAttempts: state => state.eDisclosuresConfirmationAttempts,
		EDisclosuresConfirmationRetryCadence: state => state.eDisclosuresConfirmationRetryCadence,
		defaultsLoaded: state => state.defaultsLoaded,
		isLoading: state => state.isLoading,
		reviewPageTextTemplate: state => state.reviewPageTextTemplate
	}
};

export default config;
