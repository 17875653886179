import mainRoutes from '@/router/routes/mainRoutes';
import constructUrl from '@/utils/constructUrl';
import store from '@/store/_v2';

let happyPath = [];

const getHappyPath = () => {
	happyPath = [];

	for (const route of mainRoutes) {
		const { path, name, meta } = route;
		const slashIndex = path.indexOf('/');

		if (slashIndex < 1 || (meta.module === 'coApplicant' && store.state.user.applicationType === 'joint')) {
			// slash index filters out nested routes (cards/:index, funding/card, funding/transfer, resume/:appId, etc)
			const base = slashIndex === 0 ? '' : '/';
			let page = constructUrl(name);
			page.path = base + path;
			page.meta = meta;

			happyPath.push(page);
		}

		if (name === 'Cards') {
			// Special logic to add first index of cards in
			const [cards] = path.split('/');

			let page = constructUrl(name);
			(page.path = `/${cards}/0`), (page.meta = meta);

			happyPath.push(page);
		}
	}
};

const findLastIndex = routeName => {
	if (routeName === 'FundingCard' || routeName === 'FundingTransfer') {
		routeName = 'Funding';
	}

	for (const pathIndex in happyPath) {
		if (happyPath[pathIndex].name === routeName) {
			return pathIndex;
		}
	}
};

const getPreviousPaths = lastIndex => {
	let paths = [];
	let index = parseInt(lastIndex) + 1;

	paths = happyPath.slice(0, index);

	return paths;
};

export default lastVisitedRoute => {
	getHappyPath();

	if (!lastVisitedRoute) {
		return [];
	}

	const visitedIndex = findLastIndex(lastVisitedRoute);

	return getPreviousPaths(visitedIndex);
};
