<template functional>

	<section class="callout">
        <div class="icon">
            <slot name="icon"/>
        </div>
        <div class="default">
            <slot/>
        </div>
    </section>

</template>





<script>

    export default {

        name: 'IconCallout'

    };

</script>





<style lang="scss" scoped>

    .callout {
        margin-top: 1rem;
        padding: 2rem 1.5rem 1.5rem 1.5rem;
        position: relative;
        border: 1px solid var(--tertiary);
        border-radius: 11px;

        .icon {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 0 0.5rem;
            background-color: var(--form-background);
        }

        .default {
            text-align: center;
        }

    }

</style>