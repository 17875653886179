<template>

    <ValidationProvider
        :rules="validationRules"
        :name="label"
        v-slot="{ errors }"
        slim
    >

        <OaoSelect
            v-model="innerValue"
            :label="label"
            :options="options"
            :placeholder="placeholder"
            :placeholderValue="placeholderValue"
            :disabled="disabled"
            :error="errors[0]"
            :isOptional="isOptional"
            :disableAutocomplete="disableAutocomplete"
            :isSmall="isSmall"
            :enablePlaceholder="enablePlaceholder"
        />

    </ValidationProvider>

</template>




<script>

    import { ValidationProvider } from 'vee-validate';
    
    import { OaoSelect } from '@/components/form';

    export default {

        name: 'FormSelect',
        components: {

            ValidationProvider,
            OaoSelect

        },
        props: {

            value: {
                required: true
            },
            label: {
                type: String,
                required: true
            },
            placeholder: {
                type: String,
                required: false,
                default: 'Please Select an Option'
            },
            placeholderValue: {
                required: false,
                default: null
            },
            options: {
                type: Array,
                required: true
            },
            disabled: {
                type: Boolean,
                required: false
            },
            validationRules: {
                type: [ String, Object],
                required: false
            },
            isOptional: {
                type: Boolean,
                required: false
            },
            disableAutocomplete: {
                type: Boolean,
                required: false
            },
            isSmall: {
                type: Boolean,
                required: false,
                default: false
            },
            enablePlaceholder: {
                type: Boolean,
                required: false
            }

        },
        computed: {
            
            innerValue: {

                get() {

                    return this.value;

                },
                set(val) {

                    this.$emit('input', val);

                }

            }

        }

    }

</script>