<template functional>
	<h1 v-bind="data.attrs" :class="[data.class, data.staticClass]" :style="[data.style, data.staticStyle]">
		<slot />
	</h1>
</template>

<script>
	export default {
		name: 'Title'
	};
</script>

<style scoped>
	h1 {
		color: var(--primary-75);
		font-size: 1.5rem;
		font-weight: 600;
		line-height: 2rem;
		text-align: center;
		margin: 1rem 0 2.5rem 0;
	}

	@media only screen and (min-width: 429px) {
		h1 {
			font-size: 1.75rem;
			line-height: 2.25rem;
		}
	}

	@media only screen and (min-width: 811px) {
		h1 {
			font-size: 2.25rem;
			line-height: 2.75rem;
		}
	}

	@media only screen and (min-width: 850px) {
		h1 {
			font-size: 1.75rem;
			line-height: 2.25rem;
			font-weight: 400;
			color: var(--headerCopy);
			text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.75);
		}
	}

	@media only screen and (min-width: 1526px) {
		h1 {
			font-size: 2.25rem;
			line-height: 2.75rem;
		}
	}

	@media only screen and (min-width: 1921px) {
		h1 {
			font-size: 2.75rem;
			line-height: 3.25rem;
		}
	}

	@media only screen and (min-width: 2048px) {
		h1 {
			font-size: 3rem;
			line-height: 3.5rem;
		}
	}
</style>
