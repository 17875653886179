import axios from '@/utils/axios';
import vendorAxios from 'axios';
import logError from '@/utils/logError';
import { CaptchaImplementation } from '@/constants';

export enum CaptchaAction {
	None = 0,
	Deny,
	ForceCaptcha
}

export interface ICaptchaResult {
	implmentation: typeof CaptchaImplementation;
	token: string;
	isForced: boolean;
}

export interface IInitializeApplication {
	captchaAction: CaptchaAction;
	isEligibleToUseOAO: boolean;
	firstName: string;
	lastName: string;
	email: string;
	memberType: number;
	key: number;
	applicationToken: string;
	primaryApplicantToken: string;
	jointOwnerApplicantToken: string;
}

export interface IAuthenticateSSORequest {
	sso: string;
	loadUrl: string;
	clientIp: string;
}

export interface IAuthenticateSSOResponse extends IInitializeApplication {
	captchaAction: CaptchaAction;
	applicationId: number;
}

export enum AuthenticationResultTypes {
	None = 0,
	AuthenticationSuccess,
	InvalidUserName,
	InvalidPassword,
	AccountLocked,
	PasswordChangeRequired,
	Error
}

export interface IAuthenticateRequest {
	userName: string;
	password: string;
	validationToken: string;
	clientIp: string;
	loadUrl: string;
	captchaResults: ICaptchaResult;
	memberAdvocateId?: number;
}

export interface IAuthenticateResponse extends IInitializeApplication {
	authenticationResult: AuthenticationResultTypes;
	hasCaptchaFailed: boolean;
	applicationId: number;
}

export interface INewApplicantRequest {
	firstName: string;
	lastName: string;
	zipCode: string;
	emailAddress: string;
	clientIp: string;
	loadUrl: string;
	captchaResults: ICaptchaResult;
	memberAdvocateId?: number;
	isApplicantMembershipEligible: boolean;
}

export interface INewApplicantResponse extends IInitializeApplication {
	isApplicantMembershipEligible: boolean;
	hasCaptchaFailed: boolean;
	applicationId: number;
}

const authenticateSSO = async (request: IAuthenticateSSORequest): Promise<IAuthenticateSSOResponse> => {
	try {
		const { data } = await axios.post<IAuthenticateSSOResponse>(`/api/SSO`, request);

		return data;
	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};

const authenticate = async (request: IAuthenticateRequest) => {
	try {
		const { data } = await axios.post<IAuthenticateResponse>(`/api/Authenticate`, request);

		return data;
	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};

const newApplicant = async (request: INewApplicantRequest) => {
	try {
		const { data } = await axios.post<INewApplicantResponse>(`/api/Applicant`, request);

		return data;
	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};

const getApplicantIp = async () => {
	try {
		const { data } = await vendorAxios.get('https://api.ipify.org?format=json');

		return data.ip;
	} catch (error: any) {
		console.error(error);
		logError(error);
		
		//Intentionally swallowing error so we default to local IP Address
	}
};

export default {
	authenticate,
	authenticateSSO,
	getApplicantIp,
	newApplicant
};

export { authenticate, authenticateSSO, getApplicantIp, newApplicant };
