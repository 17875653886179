<template functional>
	<g>
		<path
			d="M26.4803 12.0179V3.05728C26.4859 2.78879 26.4377 2.52188 26.3387 2.27225C26.2397 2.02262 26.0918 1.7953 25.9037 1.60365C25.7156 1.41199 25.491 1.25987 25.2433 1.15622C24.9956 1.05256 24.7296 0.999473 24.461 1.00006H3.11719C2.56469 0.995738 2.03272 1.2092 1.63647 1.59423C1.24021 1.97927 1.01155 2.50489 1 3.05728V16.1403C1.01099 16.6945 1.2389 17.2223 1.63476 17.6103C2.03063 17.9983 2.56288 18.2156 3.11719 18.2155H5.13242"
			style="stroke: var(--form-background)"
			stroke-width="1.39947"
			stroke-miterlimit="10"
		/>
		<path d="M1 5.13245H26.4803" style="stroke: var(--form-background)" stroke-width="1.39947" stroke-miterlimit="10" />
		<path d="M1 9.2627H26.4803" style="stroke: var(--form-background)" stroke-width="1.39947" stroke-miterlimit="10" />
		<path
			d="M29.9748 30.6108C30.2439 30.6087 30.5098 30.5532 30.7572 30.4474C31.0046 30.3416 31.2285 30.1877 31.4158 29.9946C31.6032 29.8014 31.7502 29.573 31.8485 29.3225C31.9467 29.072 31.9941 28.8045 31.988 28.5355V15.4525C31.9936 15.1846 31.9457 14.9181 31.8471 14.6689C31.7484 14.4197 31.601 14.1926 31.4135 14.0011C31.2261 13.8095 31.0023 13.6572 30.7552 13.5532C30.5082 13.4492 30.2429 13.3955 29.9748 13.3953H8.62696C8.07446 13.391 7.54249 13.6045 7.14623 13.9895C6.74997 14.3745 6.52131 14.9001 6.50977 15.4525V28.5355C6.52076 29.0898 6.74866 29.6176 7.14453 30.0056C7.54039 30.3936 8.07264 30.6109 8.62696 30.6108H29.9748Z"
			style="stroke: var(--form-background)"
			stroke-width="1.39947"
			stroke-miterlimit="10"
		/>
		<path d="M9.26465 26.4783H12.7073" style="stroke: var(--form-background)" stroke-width="1.39947" stroke-miterlimit="10" />
		<path d="M14.7725 26.4783H18.2151" style="stroke: var(--form-background)" stroke-width="1.39947" stroke-miterlimit="10" />
		<path d="M20.2822 26.4783H23.7249" style="stroke: var(--form-background)" stroke-width="1.39947" stroke-miterlimit="10" />
		<path d="M25.79 26.4783H29.2347" style="stroke: var(--form-background)" stroke-width="1.39947" stroke-miterlimit="10" />
		<path
			d="M14.7723 17.5277H11.3296C11.1487 17.5274 10.9694 17.5629 10.8022 17.632C10.6349 17.7012 10.483 17.8026 10.355 17.9306C10.2271 18.0585 10.1256 18.2105 10.0565 18.3777C9.98734 18.545 9.95189 18.7242 9.95215 18.9052V20.2807C9.95189 20.4616 9.98734 20.6409 10.0565 20.8081C10.1256 20.9754 10.2271 21.1273 10.355 21.2553C10.483 21.3832 10.6349 21.4847 10.8022 21.5538C10.9694 21.6229 11.1487 21.6584 11.3296 21.6581H14.7723C14.9533 21.6584 15.1325 21.6229 15.2998 21.5538C15.467 21.4847 15.619 21.3832 15.7469 21.2553C15.8749 21.1273 15.9763 20.9754 16.0455 20.8081C16.1146 20.6409 16.15 20.4616 16.1498 20.2807V18.9052C16.15 18.7242 16.1146 18.545 16.0455 18.3777C15.9763 18.2105 15.8749 18.0585 15.7469 17.9306C15.619 17.8026 15.467 17.7012 15.2998 17.632C15.1325 17.5629 14.9533 17.5274 14.7723 17.5277V17.5277Z"
			style="stroke: var(--form-background)"
			stroke-width="1.39947"
			stroke-miterlimit="10"
		/>
	</g>
</template>

<script>
	export default {
		name: 'CardFundingSelected'
	};
</script>
