<template>

    <ValidationProvider
        class="checkbox-wrapper"
        :rules="validationRules"
        :name="name"
        v-slot="{ errors }"
    >

        <OaoCheckbox
            v-model="innerValue"
            :label="label"
            :name="name"
            :optionValue="optionValue"
            :disabled="disabled"
            :noPadding="noPadding"
            :hasError="errors.length > 0"
        />

        <transition name="fade">
            <p
                class="has-error"
                v-if="errors.length > 0"
                data-test-error
            >
                {{ errors[0] }}
            </p>
        </transition>

    </ValidationProvider>

</template>




<script>

    import { ValidationProvider } from 'vee-validate';
    
    import { OaoCheckbox } from '@/components/form';

    export default {

        name: 'FormCheckbox',
        components: {

            ValidationProvider,
            OaoCheckbox

        },
        props: {

            value: {
                required: true
            },
            optionValue: {
                required: false,
                default: true
            },
            label: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: true
            },
            disabled: {
                type: Boolean,
                required: false,
            },
            noPadding: {
                type: Boolean,
                required: false
            },
            validationRules: {
                type: String,
                required: false
            }

        },
        computed: {
            
            innerValue: {

                get() {

                    return this.value;

                },
                set(val) {

                    this.$emit('input', val);

                }

            }

        }

    }

</script>




<style lang="scss" scoped>

    p.has-error {
        border-top: 1px solid var(--danger-25);
        font-size: 0.85rem;
        margin-top: 0.5rem;
        padding-top: 0.5rem;
        color: var(--danger);
        text-align: center;
    }

</style>