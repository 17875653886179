import { render, staticRenderFns } from "./OaoContainer.vue?vue&type=template&id=347b8fea&scoped=true&functional=true"
import script from "./OaoContainer.vue?vue&type=script&lang=js"
export * from "./OaoContainer.vue?vue&type=script&lang=js"
import style0 from "./OaoContainer.vue?vue&type=style&index=0&id=347b8fea&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "347b8fea",
  null
  
)

export default component.exports