import _cloneDeep from 'lodash.clonedeep';
import { applicantAPIv2, registerAPIv2 } from '@/api';
import setData from '../shared/setData';
import { ActionTree } from 'vuex';
import {
	IAddress,
	IApplicantContactData,
	IApplicantContactDataGetResponse,
	IApplicantDataUpdateResponse
} from '@/api/_v2/applicant';
import { actions as addressActions, state as addressState } from './address';

import { IRegisterOnlineBankingResponse } from '@/api/_v2/register';
import { IRootState } from '..';
import { MemberType } from '@/constants';

export interface IApplicantState {
	applicantToken: string;
	firstName: string;
	lastName: string;
	email: string;
	memberType: number;
	isApplicantMembershipEligible: boolean;
	isEligibleToUseOAO: boolean;
	contactInfo: IApplicantContactData | null;
	addressId: number | null;
	mailingAddressId: number | null;
	coApplicantSelectionOptions: [] | null;
	selectedCoApplicant: object | null;
	username: string | null;
	addresses: IAddress[] | null;
}

const state: IApplicantState = {
	applicantToken: '',
	firstName: '',
	lastName: '',
	email: '',
	memberType: 0,
	isApplicantMembershipEligible: false,
	isEligibleToUseOAO: false,
	contactInfo: null,
	addressId: null,
	mailingAddressId: null,
	coApplicantSelectionOptions: null,
	selectedCoApplicant: null,
	username: null,
	...addressState
};

function resetApplicantState(state: IApplicantState) {
	state.applicantToken = '';
	state.firstName = '';
	state.lastName = '';
	state.email = '';
	state.memberType = 0;
	state.isEligibleToUseOAO = false;
	state.contactInfo = null;
	state.addressId = null;
	state.mailingAddressId = null;
	state.coApplicantSelectionOptions = null;
	state.selectedCoApplicant = null;
	state.username = null;
	state.addresses = [];
}

const mutations = {
	setData,
	resetApplicantState
};

const actions = <ActionTree<IApplicantState, any>>{
	...addressActions,
	setApplicantData({ commit, dispatch }: any, request: any): void {
		commit('setData', { objName: 'firstName', data: request.data.firstName });
		commit('setData', { objName: 'lastName', data: request.data.lastName });
		commit('setData', { objName: 'email', data: request.data.email });
		commit('setData', { objName: 'memberType', data: request.data.memberType });
		commit('setData', { objName: 'applicantToken', data: request.data.applicantToken || request.data.primaryApplicantToken});
		commit('setData', { objName: 'selectedCoApplicant', data: request.data.selectedCoApplicant });
		dispatch('application/setEncodedApplicationId', request.data.key, { root: true });

		if (request.data.isEligibleToUseOAO != null) {
			commit('setData', { objName: 'isEligibleToUseOAO', data: request.data.isEligibleToUseOAO });
		}

		if (request.data.isApplicantMembershipEligible != null) {
			commit('setData', {
				objName: 'isApplicantMembershipEligible',
				data: request.data.isApplicantMembershipEligible
			});
		}

		//Set state in User store until full v2 switch
		if (request.data.primaryApplicantToken) {
			dispatch('user/setV1UserNecessities', request, { root: true });
		}
	},
	async getApplicantContactData(
		{ commit, state, rootState }: any,
		request?: any
	): Promise<IApplicantContactDataGetResponse> {
		try {
			if (!state.coApplicantEmployment) {
			}

			const getDataRequest = {
				applicationToken: rootState.application.applicationToken,
				applicantToken: request.applicantToken || state.applicantToken
			};

			const response = await applicantAPIv2.getApplicantContactData(getDataRequest);

			if (response.applicantContactData != null) {
				commit('setData', { objName: 'contactInfo', data: response.applicantContactData });
				commit('setData', { objName: 'firstName', data: response.applicantContactData.firstName });
				commit('setData', { objName: 'lastName', data: response.applicantContactData.lastName });
				commit('setData', { objName: 'email', data: response.applicantContactData.emailAddress.emailAddress });
			}
			
			if (response.addressId != null){
				commit('setData', { objName: 'addressId', data: response.addressId });
			}
			
			if (response.mailingAddressId != null) {
				commit('setData', { objName: 'mailingAddressId', data: response.mailingAddressId });
			}

			return _cloneDeep(response);
		} catch (error) {
			throw error;
		}
	},
	async updateApplicantContactData({ commit }: any, request: any): Promise<IApplicantDataUpdateResponse> {
		const response = await applicantAPIv2.updateApplicantContactData(request);

		if (response.updated == true) {
			commit('setData', { objName: 'contactInfo', data: request.contactInfo });
			commit('setData', { objName: 'firstName', data: request.applicantContactData.firstName });
			commit('setData', { objName: 'lastName', data: request.applicantContactData.lastName });
			commit('setData', { objName: 'email', data: request.applicantContactData.emailAddress });
			commit('setData', { objName: 'addressId', data: request.applicantContactData.addressId });
			commit('setData', { objName: 'mailingAddressId', data: request.applicantContactData.mailingAddressId });
		}

		return response;
	},
	async registerOnlineBanking({ commit }: any, request: any): Promise<IRegisterOnlineBankingResponse> {
		const response = await registerAPIv2.registerOnlineBankingAsync(request);

		if (response.enrolled) {
			commit('setData', { objName: 'username', data: request.username });
		}

		return response;
	},
	async getApplicantRegistrationData({ commit }: any, request: any): Promise<any> {
		const response = await registerAPIv2.getApplicantRegistrationDataAsync(request);

		return response;
	}
};

export type Getters = {
	applicantToken(state: IApplicantState): string;
	applicantFirstName(state: IApplicantState): string;
	applicantLastName(state: IApplicantState): string;
	applicantFullName(state: IApplicantState): string;
	applicantEmail(state: IApplicantState): string;
	applicantMemberType(state: IApplicantState): number;
	memberTypeId(state: IApplicantState): number;
	applicantData(state: IApplicantState): object;
	applicantContactInfo(state: IApplicantState): IApplicantContactData | null;
	isMember(state: IApplicantState): boolean;
	coApplicantSelectionOptions(state: IApplicantState): [] | null;
	selectedCoApplicant(state: IApplicantState): object | null;
};

const getters: Getters = {
	applicantToken: state => state.applicantToken,
	applicantFirstName: state => state.firstName,
	applicantLastName: state => state.lastName,
	applicantFullName: state => state.firstName + ' ' + state.lastName,
	applicantEmail: state => state.email,
	applicantMemberType: state => state.memberType,
	memberTypeId: state => state.memberType,
	applicantData: state => {
		return {
			firstName: state.firstName,
			lastName: state.lastName,
			applicantToken: state.applicantToken,
			email: state.email
		};
	},
	applicantContactInfo: state => state.contactInfo,
	isMember: state => state.memberType === MemberType.EXISTING,
	coApplicantSelectionOptions: state => state.coApplicantSelectionOptions,
	selectedCoApplicant: state => state.selectedCoApplicant
};

export { state, mutations, actions, getters };

export default {
	state,
	mutations,
	actions,
	getters
};
