<template>

	<section class="joint-owners">

        <OaoButtonWrapper
            id="singleOwnerButton"
            title="Select single owner account"
            :class="{ 'selected': selected === 'single' }"
            @click="selectType('single')"
        >

            <BaseIcon
                id="singleOwnerSVG"
                name="Single Owner"
                width="6.3125rem"
                height="6.3125rem"
                viewBox="0 0 50 50"
            >
                <SingleOwnerSelected v-if="selected === 'single'"/>
                <SingleOwner v-else />
            </BaseIcon>

            <h2>Single</h2>
            <p>(For me)</p>

        </OaoButtonWrapper>

        <OaoButtonWrapper
            id="jointOwnerButton"
            title="Select joint owner account"
            :class="{ 'selected': selected === 'joint' }"
            @click="selectType('joint')"
        >

            <BaseIcon
                id="jointOwnerSVG"
                name="Joint Owner"
                width="6.3125rem"
                height="6.3125rem"
                viewBox="0 0 50 50"
            >
                <JointOwnerSelected v-if="selected === 'joint'"/>
                <JointOwner v-else />
            </BaseIcon>

            <h2>Joint</h2>
            <p>(For us)</p>

        </OaoButtonWrapper>

    </section>

</template>





<script>

    import { mapMutations } from 'vuex';

    import { BaseIcon, JointOwner, JointOwnerSelected, SingleOwner, SingleOwnerSelected } from '@/components/icons';
    import OaoButtonWrapper from '@/components/OaoButtonWrapper';

    export default {

        name: 'JointOwnerPopup',
        components: {

            BaseIcon,
            JointOwner,
            JointOwnerSelected,
            SingleOwner,
            SingleOwnerSelected,
            OaoButtonWrapper

        },
        data() {
            return {

                selected: ''

            }
        },
        methods: {

            ...mapMutations('user', [
                
                'setData'
                
            ]),
            selectType(type) {

                this.selected = type;

                this.setData({
                    objName: 'applicationType',
                    data: type
                });

                this.$nextTick(() => {

                    this.$emit('click', type);
                    this.$emit('close');

                });

            }

        }

    };

</script>





<style lang="scss" scoped>

    .joint-owners {
        display: grid;
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
        grid-template-columns: repeat(auto-fit, minmax(6.3125rem, 1fr));

        h2 {
            margin-top: 1rem;
            font-size: 1.75rem;
            color: var(--primary);
            font-weight: 400;
            transition: color 0.5s;
        }
        
        p {
            color: var(--primary);
            font-weight: 300;
            transition: color 0.5s;
        }

        button.selected p,
        button.selected h2 {
            color: var(--success);
        }

    }

</style>