import axios from '@/utils/axios';
import { Module } from 'vuex';
import { IRootState } from '..';
import setData from '../shared/setData';
import { employmentAPIv2 } from '@/api';
import {
	IUpdateApplicantEmploymentResponse,
	IUpdateApplicantEmploymentRequest,
	IEmploymentCreateRequest,
	IEmploymentCreateResponse,
	IEmploymentUpdateRequest,
	IEmploymentUpdateResponse,
	IEmployment,
	IEmploymentGetResponse
} from '@/api/_v2/employment';

export interface IEmploymentState {
	applicantEmployment: IEmployment | null;
	coApplicantEmployment: IEmployment | null;
}

const employment: Module<IEmploymentState, IRootState> = {
	namespaced: true,
	state: {
		applicantEmployment: null,
		coApplicantEmployment: null
	},
	mutations: {
		setData,
		resetState(state) {
			state.applicantEmployment = null;
			state.coApplicantEmployment = null;
		}
	},
	actions: {
		async getEmploymentAsync({ commit, state }, request: any): Promise<employmentAPIv2.IEmployment | null> {
			if (request.isCoApplicant) {
				if (!state.coApplicantEmployment) {
					const response = await employmentAPIv2.getEmploymentAsync(request);
					commit('setData', { objName: 'coApplicantEmployment', data: response.employment });
				}
				return state.coApplicantEmployment;
			} else {
				if (!state.applicantEmployment) {
					const response = await employmentAPIv2.getEmploymentAsync(request);
					commit('setData', { objName: 'applicantEmployment', data: response.employment });
				}
				return state.applicantEmployment;
			}
		},
		async createEmploymentAsync({ commit }, request: any): Promise<IEmploymentCreateResponse> {
			const response = await employmentAPIv2.createEmploymentAsync(request);

			if (response.employmentId > 0) {
				request.employment.employmentId = response.employmentId;
				if (request.isCoApplicant) {
					commit('setData', { objName: 'coApplicantEmployment', data: request.employment });
				} else {
					commit('setData', { objName: 'applicantEmployment', data: request.employment });
				}
			}

			return response;
		},
		async updateEmploymentAsync({ commit, state }, request: any): Promise<IEmploymentUpdateResponse> {
			const response = await employmentAPIv2.updateEmploymentAsync(request);

			if (response.updated) {
				if (request.isCoApplicant) {
					commit('setData', { objName: 'coApplicantEmployment', data: request.employment });
				} else {
					commit('setData', { objName: 'applicantEmployment', data: request.employment });
				}
			}

			return response;
		}
	}
};

export default employment;
