import axiosLib from 'axios';

import logError from './logError';
import store from '@/store/_v2';

const handleLogError = logError('@/utils/handleAudit.js');

const axios = axiosLib.create({
	baseURL: process.env.BASE_URL
});

axios.interceptors.request.use(
	async config => {

		const token = store.getters["config/xsrfToken"];

		if (token) {
			config.headers['X-XSRF-TOKEN'] = token;
		}

		config.headers['Content-Type'] = 'application/json';

		return config;
	},
	error => {
		return Promise.reject(error);
	}
);

const handleAudit = async ({ page = '', success = false }) => {
	const config = {
		headers: {
			'X-XSRF-TOKEN': store.getters["config/xsrfToken"]
		}
	};

	try {
		const applicationToken = store.getters['application/applicationToken'];

		if (page) {
			const {
				data: { auditSessionEventId }
			} = await axios.post(
				`/api/Audit/Event`,
				{
					page,
					success,
					applicationToken
				},
				config
			);

			store.commit('audit/setId', auditSessionEventId);

			return auditSessionEventId;
		}

		const eventId = store.getters['audit/eventId'];

		if (eventId && applicationToken){
			await axios.post(`/api/Audit/Event/Update`,
				{
					eventId,
					success,
					applicationToken
				},
				config
			);
		}

	} catch (error) {

		return handleLogError({
			action: page ? 'Starting audit event' : 'Updating audit event',
			error,
			request: {
				page,
				success,
				eventId: store.getters['audit/eventId'] || null
			}
		});
	}
};

export default handleAudit;
export { handleAudit };
