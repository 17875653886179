<template>

    <label :class="['radio-container', {
                    'is-selected': selected,
                }]">

        <input type="radio" v-model="innerValue" v-bind:value="option"/> 
    
        <BaseIcon v-if="selected" width="1rem" height="1rem" name="RadioSelected" viewBox="0 0 520 520" :class="['radio-toggle', {'is-selected': selected}]">
            <RadioSelected></RadioSelected>
        </BaseIcon>

        <BaseIcon v-else  width="1rem" height="1rem"  name="RadioUnselected" viewBox="0 0 520 520" class="radio-toggle">
            <RadioUnselected></RadioUnselected>
        </BaseIcon>

        <div class="radio-text">
            <h6>{{ title }}</h6>
            <p class="description">{{ description }}</p>
        </div>

    </label>

</template>




<script>

    import { ValidationProvider } from 'vee-validate';

    import { BaseIcon, RadioSelected, RadioUnselected } from '@/components/icons';

    export default {

        name: 'Radio',
        components: {
    ValidationProvider,
    BaseIcon,
    RadioSelected,
    RadioUnselected,
    RadioSelected
},
        props: {

            value: {
                required: true
            },
            option: {
                required: true,
            },
            optionProperty: {
                type: String,
                required: false,
                default: "value"
            },
            title: {
                type: String,
                required: true
            },
            description: {
                type: String,
                required: false
            },       
         

        },
        computed: {

            id() {

                return `${ this.title.replace(/\s/g, '') }-${ this._uid }`;

            },
            selected() {

                return this.value[this.optionProperty] === this.option[this.optionProperty]

            },
            innerValue: {

                get() {

                    return this.value;

                },
                set(val) {

                    this.$emit('input', val);

                }

            },

        }

    }

</script>




<style lang="scss" scoped>

   .radio-container {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;

        border: 2px solid var(--bodyCopy-75);
        padding: .5rem;
        border-radius: 3px;

        .radio-text {
            margin-left: .5rem;

            h6 {
                font-weight: 600;
                font-size: .85rem;
                color: var(--text-primary)
            }
        }

        input[type="radio"] {
            appearance: none;
            margin: 0;
        }

        .radio-toggle {
            min-width: 1rem;
            color: var(--bodyCopy-75);
            &.is-selected {
                color: var(--primary);
            }
        }

        &.is-selected {
            border: 2px solid var(--primary);
        }

        .description {
            font-size: .85rem;
        }

        p {
            margin-bottom: .25rem;
        }
   }




</style>