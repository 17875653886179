<template functional>

    <g class="clock">
		<path class="circle" d="M25,50C11.2,50,0,38.8,0,25S11.2,0,25,0s25,11.2,25,25S38.8,50,25,50z M25,1.7C12.1,1.7,1.7,12.1,1.7,25
			c0,12.9,10.5,23.3,23.3,23.3c12.9,0,23.3-10.5,23.3-23.3C48.3,12.1,37.9,1.7,25,1.7z"/>
		<g class="markers">
			<path d="M25,9.7c-0.5,0-0.8-0.4-0.8-0.8V5.4c0-0.5,0.4-0.8,0.8-0.8c0.5,0,0.8,0.4,0.8,0.8v3.4
				C25.8,9.4,25.5,9.7,25,9.7z"/>
			<path d="M17,11.9c-0.3,0-0.6-0.2-0.7-0.4l-1.7-3c-0.2-0.4-0.1-0.9,0.3-1.2c0.4-0.2,0.9-0.1,1.2,0.3l1.7,3
				c0.2,0.4,0.1,0.9-0.3,1.2C17.2,11.9,17.1,11.9,17,11.9z"/>
			<path d="M11.1,17.8c-0.1,0-0.3,0-0.4-0.1l-3-1.7c-0.4-0.2-0.5-0.8-0.3-1.2c0.2-0.4,0.8-0.5,1.2-0.3l3,1.7
				c0.4,0.2,0.5,0.8,0.3,1.2C11.6,17.6,11.3,17.8,11.1,17.8z"/>
			<path d="M8.9,25.8H5.4c-0.5,0-0.8-0.4-0.8-0.8c0-0.5,0.4-0.8,0.8-0.8h3.4c0.5,0,0.8,0.4,0.8,0.8
				C9.7,25.5,9.4,25.8,8.9,25.8z"/>
			<path d="M8.1,35.6c-0.3,0-0.6-0.2-0.7-0.4c-0.2-0.4-0.1-0.9,0.3-1.2l3-1.7c0.4-0.2,0.9-0.1,1.2,0.3
				c0.2,0.4,0.1,0.9-0.3,1.2l-3,1.7C8.4,35.6,8.2,35.6,8.1,35.6z"/>
			<path d="M15.2,42.8c-0.1,0-0.3,0-0.4-0.1c-0.4-0.2-0.5-0.8-0.3-1.2l1.7-3c0.2-0.4,0.8-0.5,1.2-0.3
				c0.4,0.2,0.5,0.8,0.3,1.2l-1.7,3C15.8,42.6,15.5,42.8,15.2,42.8z"/>
			<path d="M25,45.4c-0.5,0-0.8-0.4-0.8-0.8v-3.4c0-0.5,0.4-0.8,0.8-0.8c0.5,0,0.8,0.4,0.8,0.8v3.4
				C25.8,45,25.5,45.4,25,45.4z"/>
			<path d="M34.8,42.8c-0.3,0-0.6-0.2-0.7-0.4l-1.7-3c-0.2-0.4-0.1-0.9,0.3-1.2c0.4-0.2,0.9-0.1,1.2,0.3l1.7,3
				c0.2,0.4,0.1,0.9-0.3,1.2C35.1,42.7,34.9,42.8,34.8,42.8z"/>
			<path d="M41.9,35.6c-0.1,0-0.3,0-0.4-0.1l-3-1.7c-0.4-0.2-0.5-0.8-0.3-1.2c0.2-0.4,0.8-0.5,1.2-0.3l3,1.7
				c0.4,0.2,0.5,0.8,0.3,1.2C42.5,35.5,42.2,35.6,41.9,35.6z"/>
			<path d="M44.6,25.8h-3.4c-0.5,0-0.8-0.4-0.8-0.8c0-0.5,0.4-0.8,0.8-0.8h3.4c0.5,0,0.8,0.4,0.8,0.8
				C45.4,25.5,45,25.8,44.6,25.8z"/>
			<path d="M38.9,17.8c-0.3,0-0.6-0.2-0.7-0.4c-0.2-0.4-0.1-0.9,0.3-1.2l3-1.7c0.4-0.2,0.9-0.1,1.2,0.3
				c0.2,0.4,0.1,0.9-0.3,1.2l-3,1.7C39.2,17.8,39.1,17.8,38.9,17.8z"/>
			<path d="M33.1,11.9c-0.1,0-0.3,0-0.4-0.1c-0.4-0.2-0.5-0.8-0.3-1.2l1.7-3c0.2-0.4,0.8-0.5,1.2-0.3
				c0.4,0.2,0.5,0.8,0.3,1.2l-1.7,3C33.6,11.7,33.3,11.9,33.1,11.9z"/>
		</g>
		<line class="minute-hand" x1="25" y1="25" x2="25" y2="35" />
		<line class="hour-hand" x1="25" y1="25" x2="25" y2="31" />
	</g>

</template>




<script>

    export default {

        name: 'ClockIcon'

    }

</script>





<style lang="scss" scoped>

    .clock {

        @keyframes rotate {
            100% {
                transform: rotateZ(360deg);
            }
        }

        .circle,
        .markers {
            fill: var(--lightGrey);
        }

        .minute-hand {
            animation: rotate 1s infinite linear;
        }

        .hour-hand {
            animation: rotate 4s infinite linear;
        }

        line {
            stroke: var(--primary);
            stroke-width: 1.5;
            stroke-linecap: round;
            transform-origin: 50% 50%;
        }

    }

</style>