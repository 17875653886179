import axios from '@/utils/axios';
import vendorAxios from 'axios';
import logError from '@/utils/logError';
import { CaptchaAction, ICaptchaResult } from './authenticate';

export interface IInitializeApplication {
	captchaAction: CaptchaAction;
	isEligibleToUseOAO: boolean;
	missingCoreInformation: boolean;
	firstName: string;
	lastName: string;
	email: string;
	key: number;
	applicationToken: string;
	primaryApplicantToken: string;
	jointOwnerApplicantToken: string;
}

export interface INewApplicantRequest {
	firstName: string;
	lastName: string;
	zipCode: string;
	emailAddress: string;
	clientIp: string;
	loadUrl: string;
	captchaResults: ICaptchaResult | [];
	memberAdvocateId: number | null;
	applicantType: number;
}

export interface IAddress {
	id: number;
	addressCoreId: number;
	street: string;
	secondaryLine: string;
	city: string;
	state: string;
	postalCode: string;
	addressCategory: number;
	isPrimary: boolean
	bypassVerification: boolean
}

export interface IPhoneNumber {
	phoneCoreId: number;
	phoneNumber: string;
	phoneNumberCategory: number;
}

export interface IEmailAddress {
	emailCoreId: number;
	emailAddress: string;
}

export interface IApplicantContactData {
	firstName: string;
	middleName: string;
	lastName: string;
	suffix: string;
	emailAddress: IEmailAddress;
	phoneNumber: IPhoneNumber;
	primaryAddress: IAddress;
	mailingAddress: IAddress;
}

export interface IApplicantContactDataGetResponse {
	applicantContactData: IApplicantContactData;
	addressId: number | null;
	mailingAddressId: number | null;
}

export interface IGetApplicantFundingDataRequest {
	applicationToken: string;
	applicantToken: string;
}

export interface IApplicantFundingDataUpdateRequest {
	applicationToken: string;
	applicantToken: string;
	applicantIsEmployee: boolean;
	productFundings: any[];
	deviceSessionId: string;
}

export interface IGetApplicantPrintConfigsRequest {
	applicationToken: string;
	applicantToken: string;
	hasCardOrder: boolean;
}

export interface IGetApplicantFundingDataResponse {
	fundingLimitConfigurations: any[];
	isEmployee: boolean;
	promotionalCreditGLConfiguration: any[];
	selectedProducts: any[];
	transferAccounts: any[] | null;
	fundingOptions: any[];
}
enum ApplicationStatus {
	None = 0,
	InReview = 1,
	Approved = 2,
	Declined = 3,
	Cancelled = 4,
	Incomplete = 5
}
export interface IGetApplicantPrintConfigsResponse {
	username: string;
	accountNumber: string;
	emailAddress: string;
	applicationStatus: ApplicationStatus;
	displayConfigurations: IPrintDisplayConfig[] | null;
}

export interface IPrintDisplayConfig {
	id: number;
	value: string;
	iconName: string;
	isHtml: boolean;
}

export interface IApplicantFundingDataUpdateResponse {
	updated: boolean;
}

export interface IEmailVerificationResult {
	emailIsValid: boolean;
	emailSuggestion: string;
}

export interface IApplicantDataUpdateResponse {
	updated: boolean;
	emailVerificationResult: IEmailVerificationResult;
}

export interface INewApplicantResponse extends IInitializeApplication {
	isApplicantMembershipEligible: boolean;
	hasCaptchaFailed: boolean;
	applicationId: number;
}

export interface INewApplicantResponse extends IInitializeApplication {
	isApplicantMembershipEligible: boolean;
	hasCaptchaFailed: boolean;
	firstName: string;
	lastName: string;
}

const createApplicant = async (request: INewApplicantRequest): Promise<INewApplicantResponse> => {
	try {
		const applicantCreateRequest: INewApplicantRequest = {
			firstName: request.firstName,
			lastName: request.lastName,
			emailAddress: request.emailAddress,
			zipCode: request.zipCode,
			clientIp: request.clientIp,
			loadUrl: request.loadUrl,
			captchaResults: request.captchaResults,
			memberAdvocateId: request.memberAdvocateId,
			applicantType: request.applicantType
		};

		const { data } = await axios.post<INewApplicantResponse>(`api/v2/Applicant`, applicantCreateRequest);

		return data;

	} catch (error) {
		
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};

const createCoApplicant = async (request: any): Promise<any> => {
	try {
		const { data } = await axios.post<INewApplicantResponse>(`api/v2/Applicant/CoApplicant`, request);

		return data;

	} catch (error) {
		
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};

const getApplicantContactData = async (request: any): Promise<IApplicantContactDataGetResponse> => {
	try {
		const { data } = await axios.post<IApplicantContactDataGetResponse>(`api/v2/Contact/Applicant`, request);

		return data;

	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};

const updateApplicantContactData = async (request: any): Promise<IApplicantDataUpdateResponse> => {
	try {
		const { data } = await axios.put<IApplicantDataUpdateResponse>(`api/v2/Contact/Applicant`, request);

		return data;

	} catch (error) {
		
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};

const getApplicantIp = async (): Promise<string> => {
	try {
		const { data } = await vendorAxios.get('https://api.ipify.org?format=json');

		return data.ip;

	} catch (error:any) {
		console.error(error);
		logError(error);
		//Intentionally swallowing error so we default to local IP Address
		return "127.0.0.1";
	}
};

const getApplicantFundingData = async (request: IGetApplicantFundingDataRequest): Promise<IGetApplicantFundingDataResponse> => {
	try {
		const { data } = await axios.post<IGetApplicantFundingDataResponse>(`api/v2/Funding/Applicant`, request);
		return data;

	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
}

const updateApplicantFundingData = async (request: IApplicantFundingDataUpdateRequest): Promise<IApplicantFundingDataUpdateResponse> => {
	try {
		const { data } = await axios.put<IApplicantFundingDataUpdateResponse>(`api/v2/Funding/Applicant`, request);

		return data;

	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};

const getApplicantPrintConfigs = async (request: IGetApplicantPrintConfigsRequest): Promise<IGetApplicantPrintConfigsResponse> => {
	try {
		const { data } = await axios.post<IGetApplicantPrintConfigsResponse>(`api/v2/Print/Applicant`, request);

		return data;

	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
}

export default {
	createApplicant,
	getApplicantIp,
	createCoApplicant,
	getApplicantContactData,
	updateApplicantContactData,
	getApplicantFundingData,
	updateApplicantFundingData,
	getApplicantPrintConfigs
};

export { createApplicant, getApplicantIp, createCoApplicant, getApplicantContactData, updateApplicantContactData, getApplicantFundingData, updateApplicantFundingData, getApplicantPrintConfigs };
