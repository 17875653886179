<template>

    <section class="radio-group">

        <div 
            v-for="(option, index) in options"
            :key="option.label"
            class="radio-group-option"
        >

            <ValidationProvider
                :rules="validationRules"
                :name="name"
                v-slot="{ errors }"
                slim
            >
                <OaoRadio
                    v-model="innerValue"
                    :option="option"
                    :optionProperty="optionProperty"
                    :title="option[titleProperty]"
                    :description="option.description"
                />

            </ValidationProvider>

        </div>

    </section>

</template>




<script>

    import { ValidationProvider } from 'vee-validate';
    
    import { OaoRadio } from '@/components/form';

    export default {

        name: 'RadioGroup',
        components: {

            ValidationProvider,
            OaoRadio

        },
        props: {

            value: {
                required: true
            },
            name: {
                type: String,
                required: true
            },
            options: {
                type: Array,
                required: true
            },
            titleProperty: {
                type: String,
                required: false,
                default: "title"
            },
            optionProperty: {
                type: String,
                required: false,
                default: "value"
            },
            validationRules: {
                type: [ String, Object],
                required: false
            }

        },
        computed: {
            
            innerValue: {

                get() {

                    return this.value;

                },
                set(val) {

                    this.$emit('input', val);

                }

            }

        }

    }

</script>




<style lang="scss" scoped>

    .radio-group {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .radio-group-option {
            width: 100%;
        }
    }

</style>