import { Module } from 'vuex';
import { IRootState } from '..';
import setData from '../shared/setData';

import { configurationAPI, auditAPI } from '@/api';
import { IPersonVerificationConfiguration } from '@/api/configuration';

export interface IIdentityVerificationState {
	deviceIdEnabled: boolean;
	deviceIdSessionToken?: string;
	deviceIdSDKKey: string;
}

const identityVerification: Module<IIdentityVerificationState, IRootState> = {
	namespaced: true,
	state: {
		deviceIdEnabled: false,
		deviceIdSessionToken: '',
		deviceIdSDKKey: '',
	},
	mutations: {
		setData,
		setPersonVerificationConfiguration(state, data: IPersonVerificationConfiguration) {
			state.deviceIdEnabled = data.enabled;
			state.deviceIdSDKKey = data.sdkKey;
		},
		resetState(state) {
		}
	},
	actions: {
		async initDeviceId({ commit }) {
			try {
				const personVerificationConfiguration = await configurationAPI.getPersonVerificationConfiguration();
				commit('setPersonVerificationConfiguration', personVerificationConfiguration);
			} catch (error) {
			
			}
		},
		attachDeviceIdSDK({ state, dispatch }): void {
			try {
				if (state.deviceIdEnabled && !hasSigmaDeviceManager()) {
					//place deviceId script on document header
					let deviceIdScript = document.createElement('script');
					deviceIdScript.src = 'https://cdn.dvnfo.com/device-risk-sdk.js';
					deviceIdScript.setAttribute('data-public-key', state.deviceIdSDKKey);

					//get session id when loaded
					deviceIdScript.addEventListener('load', () => {
						dispatch('refreshDeviceIdSessionToken');
					});

					document.head.appendChild(deviceIdScript);
				}
			} catch (error) {
				
			}
		},

		async refreshDeviceIdSessionToken({ state, commit, rootGetters }): Promise<string> {
			let sessionToken = '';
			let updateClientAudit = !state.deviceIdSessionToken;
			try {
				if (state.deviceIdEnabled && hasSigmaDeviceManager()) {
					// @ts-ignore because SigmaDeviceManager is an object from an external script
					sessionToken = await SigmaDeviceManager.getDeviceSessionId();

					commit('setData', {
						objName: 'deviceIdSessionToken',
						data: sessionToken
					});

					if(updateClientAudit) {
						auditAPI.updateClientAuditAsync({deviceSessionId: sessionToken, applicationToken: rootGetters['application/applicationToken']});
					}
				}
			} catch (error) {
			
			}

			return sessionToken;
		}
	},
	getters: {
		deviceIdSessionToken: state => state.deviceIdSessionToken
	}
};

const hasSigmaDeviceManager = () => {
	// @ts-ignore because SigmaDeviceManager is an object from an external script
	return typeof SigmaDeviceManager !== 'undefined';
};

export default identityVerification;
