import axios from '@/utils/axios';
import { Module } from 'vuex';
import { IRootState } from '..';
import { applicantAPIv2, printAPIv2} from '@/api';
import { IGetApplicantPrintConfigsResponse, IPrintDisplayConfig } from '@/api/_v2/applicant'
import { IApplicationFileGetResponse, IApplicationEmailSendResponse } from '@/api/_v2/print';
import setData from '../shared/setData';

enum ApplicationStatus {
	None = 0,
	InReview = 1,
	Approved = 2,
	Declined = 3,
	Cancelled = 4,
	Incomplete = 5
}

export interface IPrintState {
	username: string;
	applicationStatus: ApplicationStatus;
	accountNumber: string;
	emailAddress: string;
	displayConfigurations: IPrintDisplayConfig[] | null;
	isLoading: boolean;
}

const print: Module<IPrintState, IRootState> = {
	namespaced: true,
	state: {
		username: '',
		applicationStatus: 0,
		accountNumber: '',
		emailAddress: '',
		displayConfigurations: null,
		isLoading: false
	},
	mutations: {
		setData,
		toggleLoading(state, newState) {
			state.isLoading = newState;
		},
		resetState(state) {
			state.username = '';
			state.applicationStatus = 0;
			state.accountNumber = '';
			state.emailAddress = '';
			state.displayConfigurations = null;
			state.isLoading = false;
		}
	},
	actions: {
		async getApplicantPrintConfigs({ commit, state }, request: any): Promise<any> {
			try {
				commit('toggleLoading', true);

				if (!request.applicantToken) {
					//Fixes issue where using FAO 'start new app button' on print page causes created() method on initiating page to be inexplicably called again.
					return;
				}

				const response = await applicantAPIv2.getApplicantPrintConfigs(request);

				commit('setData', { objName: 'username', data: response.username });
				commit('setData', { objName: 'accountNumber', data: response.accountNumber });
				commit('setData', { objName: 'emailAddress', data: response.emailAddress });
				commit('setData', { objName: 'displayConfigurations', data: response.displayConfigurations });

				return response;
			} catch (error) {
			
				if (typeof error === 'string') {
					throw new Error(error);
				}
				throw error;
			} finally {
				commit('toggleLoading', false);
			}
		},
		async sendApplicationEmailAsync({ commit }, request: any): Promise<IApplicationEmailSendResponse> {
			try {
				const response = await printAPIv2.sendApplicationEmailAsync(request);

				return response;

			} catch (error) {
			
				if (typeof error === 'string') {
					throw new Error(error);
				}
				throw error;
			} 
		},
		async getApplicationFileAsync({ commit }, request: any): Promise<IApplicationFileGetResponse> {
			try {
				const response = await printAPIv2.getApplicationFileAsync(request);

				return response;

			} catch (error) {
			
				if (typeof error === 'string') {
					throw new Error(error);
				}
				throw error;
			} 
		}
	},
	getters: {
		isLoading: state => state.isLoading,
		applicantAccountNumber: state => state.accountNumber,
		applicantEmail: state => state.emailAddress,
		applicantUsername: state => state.username,
		displayConfigurations: state => state.displayConfigurations,
		applicationStatus: state => state.applicationStatus
	}
};

export default print;
