var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button-container"},[(_vm.hasMemberAdvocate)?_c('router-link',{class:[
			'button-link',
			{
				'is-fullwidth': _vm.fullwidth,
				'is-small': _vm.small,
				'is-uppercase': !_vm.lowercase
			}
		],attrs:{"to":{ name: 'Home' }}},[_vm._v("Start a new Application")]):_vm._e(),(_vm.hasClientWebsiteLink)?_c('a',{staticClass:"button-link",attrs:{"href":_vm.clientWebsiteLink,"title":_vm.anchorTitle}},[_vm._v(" Return to "+_vm._s(_vm.clientName)+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }