<template>
	<div class="button-wrapper" @click="toggleDropdown">
		<div :class="['button', { 'is-uppercase': !lowercase, 'is-small': small }]">
			<span>{{ title }}</span>
			<BaseIcon name="dropdown icon" viewBox="0 0 27 17" :class="['icon', { 'rotate-icon': dropdownOpen }]">
				<ChevronDown />
			</BaseIcon>
		</div>
		<div v-if="dropdownOpen" :class="['dropdown', { 'is-uppercase': !lowercase, 'is-small': small }]">
			<span v-for="option in options" @click="$emit('input', option)" class="dropdownOption">
				{{ option }}
			</span>
		</div>
	</div>
</template>

<script>
	import { BaseIcon, ChevronDown } from './icons';
	export default {
		name: 'ButtonDropdown',
		components: {
			BaseIcon,
			ChevronDown
		},
		data() {
			return {
				dropdownOpen: false
			};
		},
		props: {
			title: {
				type: String,
				required: true
			},
			lowercase: {
				type: Boolean,
				required: false,
				default: false
			},
			options: {
				type: Array,
				required: true
			},
			small: {
				type: Boolean,
				require: false,
				default: false
			}
		},
		methods: {
			toggleDropdown() {
				this.dropdownOpen = !this.dropdownOpen;
			}
		}
	};
</script>

<style lang="scss" scoped>
	.button-wrapper {
		.button {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 0.5rem;

			padding: 15px 10px;

			cursor: pointer;
			font-size: 1.25rem;
			font-weight: 700;
			transition: all 0.3s linear;
			background-color: transparent;
			border: 2px solid var(--primary);
			color: var(--primary);

			&:hover {
				background-color: var(--primary);
				border: 2px solid var(--primary);
				color: var(--white);
			}

			&.is-small {
				font-size: 1.15rem;
				padding: 15px !important;
			}

			&.is-uppercase {
				text-transform: uppercase;
			}

			.icon {
				transition: transform 0.2s ease-in-out;
			}

			.rotate-icon {
				transform: rotate(0.5turn);
			}

			svg {
				width: 1.7rem;
				height: 1.7rem;
				min-width: 1.7rem;
			}
		}

		.dropdown {
			margin-top: 0.5rem;
			margin-right: auto;
			margin-left: auto;
			position: absolute;
			z-index: 1;
			min-width: fit-content;
			width: 7rem;
			left: 0;
			right: 0;

			display: flex;
			flex-direction: column;
			justify-content: center;
			gap: 0.25rem;

			padding: 0.5rem 0;
			border: 2px solid var(--lightGrey);

			background-color: var(--form-background);
			color: var(--primary);

			font-size: 1.2rem;
			font-weight: 700;

			&.is-small {
				font-size: 1.2rem;
			}

			&.is-uppercase {
				text-transform: uppercase;
			}

			.dropdownOption {
				cursor: pointer;
				padding: 0.5rem 2rem;
			}

			.dropdownOption:hover {
				background-color: var(--lightGrey-25);
			}
		}
	}
</style>
