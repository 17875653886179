import { render, staticRenderFns } from "./ExternalAccountFundingSelected.vue?vue&type=template&id=b5345246&functional=true"
import script from "./ExternalAccountFundingSelected.vue?vue&type=script&lang=js"
export * from "./ExternalAccountFundingSelected.vue?vue&type=script&lang=js"
import style0 from "./ExternalAccountFundingSelected.vue?vue&type=style&index=0&id=b5345246&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports