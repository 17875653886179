import router from '@/router';
import { configurationsAPIv2 } from '@/api';

export interface IEmploymentConfigurationState {
	employmentConfiguration: IEmploymentConfiguration;
}
export interface IEmploymentConfiguration {
	occupationConfigurations: any[];
	employmentStatusConfiguration: any[];
	allowManualOccupationEntry: boolean;
	showEmployerField: boolean;
}

const state: IEmploymentConfigurationState = {
	employmentConfiguration: {
		occupationConfigurations: [],
		employmentStatusConfiguration: [],
		allowManualOccupationEntry: false,
		showEmployerField: false
	}
};

function resetState(state) {
	state.employmentConfiguration.employmentStatusConfiguration = [];
	state.employmentConfiguration.occupationConfigurations = [];
	state.employmentConfiguration.allowManualOccupationEntry = false;
	state.employmentConfiguration.showEmployerField = false;
}

const mutations = {
	//TODO: Add Mutations (if any)
};

const actions = {
	async getEmploymentConfiguration({ commit, state }: any): Promise<IEmploymentConfiguration> {
		try {
			let response: any;
			if (state.employmentConfiguration.occupationConfigurations.length) {
				response = state.employmentConfiguration;
			} else {
				const { occupations: occupations,
					employmentStatuses: statuses,
					allowManualOccupationEntry: allowManualOccupationEntry,
					showEmployerField: showEmployerField
				} =
					await configurationsAPIv2.getEmploymentPageConfigurations();

				commit('setData', {
					objName: 'employmentConfiguration',
					data: {
						occupationConfigurations: occupations,
						employmentStatusConfiguration: statuses,
						allowManualOccupationEntry: allowManualOccupationEntry,
						showEmployerField: showEmployerField
					}
				});

				response = state.employmentConfiguration;
			}

			return response;
		} catch (error) {
		
			if (typeof error === 'string') {
				throw new Error(error);
			}

			throw error;
		}
	}
};

export { state, resetState, mutations, actions };

export default {
	state,
	resetState,
	mutations,
	actions
};
