import axios from '@/utils/axios';

export interface IMemberAdvocateConfiguration {
	sessionDuration: number;
}
export interface IMemberAdvocateConfigurationResponse {
	configuration: IMemberAdvocateConfiguration;
}
const getConfig = async (): Promise<IMemberAdvocateConfiguration> => {
	try {
		const { data: response } = await axios.get<IMemberAdvocateConfigurationResponse>(
			`/api/MemberAdvocateConfiguration`
		);
		return response.configuration;
	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}

		throw error;
	}
};

export { getConfig };
