<template functional>

    <g transform="matrix(1 0 0 1 -40 -1936 )">
        <path d="M57.5,1944.1c0.3,0.2,0.5,0.6,0.5,0.9c0,0.4-0.2,0.7-0.5,1l-8,6.8c-0.3,0.3-0.7,0.4-1.1,0.4
            c-0.4,0-0.8-0.1-1.1-0.4l-0.9-0.8c-0.3-0.3-0.5-0.6-0.5-1s0.2-0.7,0.5-1l3.6-3.1h-8.6c-0.4,0-0.8-0.1-1-0.4s-0.4-0.6-0.4-1v-1.3
            c0-0.4,0.1-0.7,0.4-1s0.6-0.4,1-0.4H50l-3.6-3.1c-0.3-0.3-0.5-0.6-0.5-0.9c0-0.4,0.2-0.7,0.5-0.9l0.9-0.8c0.3-0.3,0.7-0.4,1.1-0.4
            c0.4,0,0.8,0.1,1.1,0.4L57.5,1944.1z"/>
    </g>

</template>





<script>

    export default {

        name: 'ShortArrow'

    }

</script>