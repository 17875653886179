<template>
	<div class="input-container" @dragover.prevent @drop.prevent>
		<div class="initial-block draggable-section" @drop="filesChanges($event.dataTransfer)">
			<p :class=" hasErrors ? 'draggable-error' : 'draggable' ">Drag File Here or Click to Upload</p>
			<input
				type="file"
				:multiple="allowMultiple"
				:name="name"
				:id="id || name"
				:disabled="disabled"
				@change="filesChanges($event.target)"
				class="input-file"
			/>
		</div>
		<div class="form-error-promt">{{ FormErrors }}</div>
		<div class="form-error-promt">{{ errors }}</div>
	</div>
</template>

<script>
	import _cloneDeep from 'lodash.clonedeep';
	import { BaseIcon, CloseIcon } from '@/components/icons';
	export default {
		name: 'Upload',
		components: {
			BaseIcon,
			CloseIcon
		},
		props: {
			disabled: {
				type: Boolean,
				required: false,
				default: false
			},
			id: {
				type: String,
				required: false
			},
			name: {
				type: String,
				required: true
			},
			acceptedFiles: {
				type: String,
				required: true
			},
			acceptedFilesDisplayText: {
				type: String,
				required: true
			},
			maxUploadSizeMB: {
				type: Number,
				required: true
			},
			combinedMaxUploadSizeMB: {
				type: Number,
				required: true
			},
			allowMultiple: {
				type: Boolean,
				required: false
			},
			errors: {
				type: String,
				required: false
			}
		},
		data() {
			return {
				Files: {},
				FormErrors: '' //Could Not Submit Files: Invalid File Type
			};
		},
		computed: {
			hasErrors() {
                return this.errors !== '';
			}
		},
		methods: {
			filesChanges(target) {
				this.errors = '';
				let newFiles = new Map();

				Array.from(Array(target.files.length).keys()).map(idx => {
					if (!newFiles.has(target.files[idx].name)) {
						newFiles.set(target.files[idx].name, target.files[idx]);
					}
				});

				this.Files = _cloneDeep(newFiles);

				this.submitFiles();
			},
			submitFiles() {
				const data = new FormData();
				const fileCount = this.Files.size;
				const validationErrors = this.validateFiles(fileCount);

				this.FormErrors = validationErrors;
				if (validationErrors) {
					return;
				}

				//construct FormData object to emit
				this.Files.forEach(file => {
					data.append(this.name, file, file.name);
				});

				console.log('FileData', data);
				// Catch this emit on the parent and submit data to the backend, while using fileCount to display a success message on the UI (ie: 4 files uploaded)
				this.$emit('save', { data, fileCount });
			},
			validateFiles(fileCount) {
				let combinedFileSize = 0;
				let invalidTypeFound = '';
				let individualFileTooLarge = false;

				if (!fileCount) {
					return 'No files selected to upload';
				}

				if (!this.allowMultiple && fileCount > 1) {
					return 'Please select only one file';
				}

				this.Files.forEach(file => {
					combinedFileSize += file.size;

					if (!this.acceptedFiles.includes(file.type.split('/')[1])) {
						invalidTypeFound = file.name.split('.')[1] ? '.' + file.name.split('.')[1] : file.type;
					}

					if (file.size / 1048576 > this.maxUploadSizeMB) {
						individualFileTooLarge = true;
					}
				});

				if (individualFileTooLarge) {
					this.deleteFiles();
					return `Photo must not exceed ${this.maxUploadSizeMB} MB`;
				}

				if (combinedFileSize / 1048576 > this.combinedMaxUploadSizeMB) {
					this.deleteFiles();
					return `Total file upload size must not exceed ${this.combinedMaxUploadSizeMB} MB`;
				}

				if (invalidTypeFound) {
					this.deleteFiles();
					return `Photo must be in ${this.acceptedFilesDisplayText} format`;
				}

				return '';
			},
			deleteFiles() {
				this.Files = new Map();
			}
		}
	};
</script>

<style lang="scss" scoped>
    .input-container {
        .draggable-section {
            .input-file {
                opacity: 0;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                width: 100%;
                cursor: pointer;
            }

            &:hover .draggable {
                background-color: var(--secondary-25);
                font-weight: 700;
            }
        }

        .initial-block {
            position: relative;
            margin-bottom: .85rem;

            .draggable {
                border: 4px solid var(--primary);
                border-style: dashed;
                margin: 0;
                padding: 1rem;
                text-align: center;
                font-weight: 600;
                font-size: .85rem;
                color: var(--primary);
                transition: background-color 0.5s;
                cursor: pointer;
            }

            .draggable-error {
                border: 4px solid var(--danger);
                border-style: dashed;
                margin: 0;
                padding: 1rem;
                text-align: center;
                font-weight: 600;
                font-size: .85rem;
                color: var(--danger);
                transition: background-color 0.5s;
                cursor: pointer;
            }

            .center-section {
                padding: 0.5rem;
                text-align: center;
            }

            .button {
                box-sizing: border-box;
                background-color: var(--primary);
                padding: 0.5rem 2rem;
                cursor: pointer;
                text-align: center;
                vertical-align: middle;
                text-transform: uppercase;
                font-size: 1.25rem;
                font-weight: 600;
                color: var(--white);
                transition: all 0.3s linear;
                text-decoration: none;
                border: 2px solid var(--primary);
                max-width: 12rem;
                margin: 0 auto;
                cursor: pointer;

                &:hover {
                    background-color: transparent;
                    border: 2px solid var(--primary);
                    color: var(--primary);
                }
            }
        }

        p,
        .uploading {
            font-size: 20px;
            font-weight: 700;
            color: var(--primary);
        }

        .uploading {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 2rem 1rem;
            border: 1px solid var(--primary);
        }

        .hidden {
            visibility: hidden;
        }

        .file-item-wrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            border-style: solid;
            border-color: var(--primary);
            margin-top: 0.75rem;

            .file-item-delete:hover {
                opacity: 0.75;
            }

            p {
                font-weight: 100;
            }
        }

        .form-error-promt {
            color: var(--danger);
            margin-top: 0.5rem;
        }
    }
</style>
