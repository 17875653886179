import axios from '@/utils/axios';

export interface ICardSelectionGetResponse {
	cardSelections: ICardSelection[];
}
export interface ICardSelectionCreateResponse {
	cardOrderId: number;
}
export interface ICardSelectionUpdateResponse {
	updated: boolean;
}

export interface ICardSelectionGetRequest {
	applicationToken: string;
}
export interface ICardSelectionCreateRequest {
	applicantToken: string;
	cardOrder: ICardSelection;
	applicationToken: string;
}
export interface ICardSelectionUpdateRequest {
	applicantToken: string;
	cardOrder: ICardSelection;
	applicationToken: string;
}
export interface ICardSelection {
	cardOrderId: number;
	applicationProductId: number;
	applicantToken: string;
	cardConfigurationId: number;
}

const getApplicationCardSelections = async (request: ICardSelectionGetRequest): Promise<ICardSelectionGetResponse> => {
	try {
		const { data: response } = await axios.get<ICardSelectionGetResponse>(`/api/v2/CardSelection/${request.applicationToken}`);
		return response;
	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}

		throw error;
	}
};
const createCardSelection = async (request: ICardSelectionCreateRequest): Promise<ICardSelectionCreateResponse> => {
	try {
		const { data } = await axios.post<ICardSelectionCreateResponse>('/api/v2/CardSelection', request);

		return data;
	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};
const updateCardSelection = async (request: ICardSelectionUpdateRequest): Promise<ICardSelectionUpdateResponse> => {
	try {
		const { data } = await axios.put<ICardSelectionUpdateResponse>('/api/v2/CardSelection', request);

		return data;
	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};
export { getApplicationCardSelections, createCardSelection, updateCardSelection };
