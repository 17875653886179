import axios from '@/utils/axios';

export interface IJointOwner {
    relationship: PersonAccountRelationshipTypes | null,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    emailAddress: string
}

export enum PersonAccountRelationshipTypes {
    None = 0,
    PrimaryOwner,
    JointOwner
}

export interface IJointOwnerSetJointOptionRequest {
    IsRequestingJointOwner: boolean
}

export interface IJointOwnerCreateRequest {
   jointOwner: IJointOwner
}

export interface IJointOwnerCreateResponse {
    jointOwnerId: number,
    jointOwnerApplicantToken: string
}

export interface IJointOwnerGetResponse {
    jointOwner: IJointOwner
}

const setJointOption = async (jointOption: boolean): Promise<void> => {
    const request: IJointOwnerSetJointOptionRequest = {
        IsRequestingJointOwner: jointOption
    }

    try{

        await axios.post(`/api/Joint/Type`, request);

    }catch(error) {

     	if (typeof error === 'string') {
			throw new Error(error);
		}

		throw error;

    }
}

const create = async (newJointOwner: IJointOwner): Promise<IJointOwnerCreateResponse> => {
    const request: IJointOwnerCreateRequest = {
        jointOwner: newJointOwner
    }

    try{ 
        const {
            data: response
        } = await axios.post<IJointOwnerCreateResponse>(`/api/Joint`, request);

        return response
    }catch(error) {

		if (typeof error === 'string') {
			throw new Error(error);
		}

		throw error;
    }
} 

const get = async (): Promise<IJointOwner> => {
    try{

        const {
            data: response
        } = await axios.get<IJointOwnerGetResponse>(`/api/Joint`);

        return response.jointOwner;

    }catch(error) {

    	if (typeof error === 'string') {
			throw new Error(error);
		}

		throw error;
        
    }
}

export {setJointOption, create, get };

