<template>
	<label class="input-container" data-test-label>
		<input
			:id="id"
			:name="name"
			type="radio"
			v-model.trim="innerValue"
			:value="optionValue"
			:disabled="disabled"
			data-test-input
		/>

		<svg
			:class="[{ svg: !greyRadioIcon, 'svg-greyed': greyRadioIcon }]"
			fill="currentColor"
			preserveAspectRatio="xMidYMid meet"
			height="34px"
			width="34px"
			viewBox="0 0 34 34"
		>
			<circle class="radioOutline" cx="17" cy="17" r="15" fill="none" stroke="currentColor" stroke-width="3" />
			<circle class="radioDot" cx="17" cy="17" r="8" fill="currentColor" />
		</svg>

		<span v-if="!image">{{ label }}</span>
		<div class="image-container" v-else>
			<img :src="image" :alt="label" />
		</div>
	</label>
</template>

<script>
	export default {
		name: 'RadioField',
		props: {
			value: {
				required: true
			},
			optionValue: {
				required: true
			},
			label: {
				type: String,
				required: true
			},
			image: {
				type: String,
				required: false
			},
			name: {
				type: String,
				required: true
			},
			disabled: {
				type: Boolean,
				required: false
			},
			noPadding: {
				type: Boolean,
				required: false
			},
			greyRadioIcon: {
				type: Boolean,
				required: false
			}
		},
		computed: {
			id() {
				return `${this.label.replace(/\s/g, '')}-${this._uid}`;
			},
			innerValue: {
				get() {
					return this.value;
				},
				set(val) {
					this.$emit('input', val);
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	.input-container {
		display: flex;
		align-items: center;
		cursor: pointer;
		color: var(--text-body-75);

		&.no-padding {
			margin-bottom: 0;
		}

		input {
			position: absolute;
			left: -9999px;
			opacity: 0;
			cursor: pointer;
			height: 0;
			width: 0;

			&:checked + .svg .radioDot {
				opacity: 1;
			}

			&:active + .svg,
			&:focus + .svg {
				border: 1.5px dashed var(--text-body-50);
				border-radius: 100%;
			}

			&:checked + .svg-greyed .radioDot {
				opacity: 1;
			}

			&:checked + .svg-greyed .radioOutline {
				stroke: var(--primary);
			}
		}

		.svg {
			box-sizing: border-box;
			width: 2rem;
			height: 2rem;

			.radioOutline {
				stroke: var(--secondary);
			}

			.radioDot {
				fill: var(--secondary);
				opacity: 0;
				transition: opacity 0.5s;
			}
		}

		.svg-greyed {
			box-sizing: border-box;
			width: 2rem;
			height: 2rem;

			.radioOutline {
				stroke: var(--lightGrey);
			}

			.radioDot {
				fill: var(--primary);
				opacity: 0;
				transition: opacity 0.5s;
			}
		}

		span,
		.image-container {
			padding-left: 1.5rem;
			flex: 1;

			img {
				width: 100%;
				height: auto;
				max-width: 175px;
			}
		}

		&:hover {
			.radioDot {
				opacity: 0.2;
			}
		}
	}
</style>
