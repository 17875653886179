import { jointOwnerAPI } from '@/api';
import { IJointOwner, setJointOption } from '@/api/jointOwner';
import { Module } from 'vuex';
import { IRootState } from '..';
import setData from '../shared/setData';

export interface IJointOwnerState {
	moduleLoaded: boolean;
	jointOwner: IJointOwner;
	jointOwnerApplicantToken: string;
}

const jointOwner: Module<IJointOwnerState, IRootState> = {
	namespaced: true,
	state: {
		moduleLoaded: false,
		jointOwner: {
			relationship: null,
			firstName: '',
			lastName: '',
			phoneNumber: '',
			emailAddress: ''
		},
		jointOwnerApplicantToken: ''
	},
	mutations: {
		setData,
		resetState(
			state,
			{
				relationship = null,
				firstName = '',
				lastName = '',
				phoneNumber = '',
				emailAddress = '',
				jointOwnerApplicantToken = ''
			}
		) {
			state.moduleLoaded = false;
			state.jointOwner = {
				relationship,
				firstName,
				lastName,
				phoneNumber,
				emailAddress
			};
			state.jointOwnerApplicantToken = jointOwnerApplicantToken;
		}
	},
	actions: {
		async get({ commit, state }): Promise<IJointOwner> {
			if (!state.moduleLoaded) {
				try {
					const jointOwner = await jointOwnerAPI.get();

					commit('setData', { objName: 'jointOwner', data: jointOwner });
					commit('setData', { objName: 'moduleLoaded', data: true });
				} catch (error) {
					throw error;
				}
			}

			return state.jointOwner;
		},
		async create({ commit, state }, updatedJoint: IJointOwner): Promise<IJointOwner> {
			const oldJointOwner = state.jointOwner;

			if (
				oldJointOwner.firstName !== updatedJoint.firstName ||
				oldJointOwner.lastName !== updatedJoint.lastName ||
				oldJointOwner.emailAddress !== updatedJoint.emailAddress ||
				oldJointOwner.phoneNumber !== updatedJoint.phoneNumber
			) {
				try {
					let response = await jointOwnerAPI.create(updatedJoint);

					commit('setData', { objName: 'jointOwner', data: updatedJoint });
					commit('setData', { objName: 'jointOwnerApplicantToken', data: response.jointOwnerApplicantToken });
					commit('setData', { objName: 'moduleLoaded', data: true });
				} catch (error) {
					throw error;
				}
			}

			return state.jointOwner;
		}
	}
};

export default jointOwner;
