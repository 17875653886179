export default {
    NONE: 0,
    APPROVE: 1,
    IN_REVIEW: 2,
    DECLINE: 3,
    DUPLICATE: 4,
    0: 'None',
    1: 'approve',
    2: 'inReview',
    3: 'decline',
    4: 'duplicate'
};