import axios from '@/utils/axios';

export interface ICardSelectionConfigurationViewModelResponse {
	cardConfigurations: IProductCardConfiguration[];
	filterConfiguration: IFilterConfiguration;
	conversationalText: string;
}
export interface ICardSelectionConfigurationViewModelRequest {
	applicationToken: string;
}
export interface IProductCardConfiguration {
	productId: number;
	cards: ICardConfiguration[];
}
export interface IFilterConfiguration {
	filterValues: [];
	isInclusive: boolean;
}
export interface ICardConfiguration {
	cardId: number;
	cardName: string;
	cardDesignSerial: number;
	cardImage: string;
	isVertical: string;
}

const getProductCardSelectionConfiguration = async (
	request: ICardSelectionConfigurationViewModelRequest
): Promise<ICardSelectionConfigurationViewModelResponse> => {
	try {
		const { data: response } = await axios.post<ICardSelectionConfigurationViewModelResponse>(
			`/api/v2/Configuration/Card`,
			request
		);
		return response;
	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};
export { getProductCardSelectionConfiguration };
