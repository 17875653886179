import { affinityAPIv2 } from '@/api';
import { IUpdateApplicationAffinityRequest, IUpdateApplicationAffinityResponse } from '@/api/_v2/affinity';
import { Module } from 'vuex';
import { IRootState } from '..';
import setData from '../../setData';
import { IAffinityCreateRequest, IAffinityCreateResponse, IAffinity } from '@/api/_v2/affinity';

export interface IAffinityState {
	applicantAffinity: IAffinity | null;
	coApplicantAffinity: IAffinity | null;
}

const affinity: Module<IAffinityState, IRootState> = {
	namespaced: true,
	state: {
		applicantAffinity: null,
		coApplicantAffinity: null
	},
	mutations: {
		setData,
		resetState(state) {
			state.applicantAffinity = null;
			state.coApplicantAffinity = null;
		}
	},
	actions: {
		async getAffinityAsync({ commit, state }, request: any): Promise<IAffinity | null> {
			let response;
			if (request.isCoApplicant) {
				if (!state.coApplicantAffinity) {
					response = await affinityAPIv2.getAffinityAsync(request);
					commit('setData', { objName: 'coApplicantAffinity', data: response.affinity });
				}
				return state.coApplicantAffinity;
			} else {
				if (!state.applicantAffinity) {
					response = await affinityAPIv2.getAffinityAsync(request);
					commit('setData', { objName: 'applicantAffinity', data: response.affinity });
				}
				return state.applicantAffinity;
			}
		},
		async updateApplicationAffinityDataAsync(
			{ commit, state },
			request: any
		): Promise<IUpdateApplicationAffinityResponse> {
			const response = await affinityAPIv2.updateApplicationAffinityValueAsync(request);
			if (request.isCoApplicant) {
				commit('setData', { objName: 'coApplicantAffinity', data: request.affinity });
			} else {
				commit('setData', { objName: 'applicantAffinity', data: request.affinity });
			}

			return response;
		},
		async createAffinityAsync({ commit }: any, request: any): Promise<IAffinityCreateResponse> {
			const response = await affinityAPIv2.createAffinityAsync(request);

			if (response.affinityValueId > 0) {
				request.affinity.affinityId = response.affinityValueId;
				if (request.isCoApplicant) {
					commit('setData', { objName: 'coApplicantAffinity', data: request.affinity });
				} else {
					commit('setData', { objName: 'applicantAffinity', data: request.affinity });
				}
			}

			return response;
		}
	}
};

export default affinity;
