<template functional>

    <g>
        <path d="M6.6,9h0.2c0,0,0,0,0.1,0c1.8,0,3.3-1.4,3.3-3.2V4.1c0-1.8-1.4-3.3-3.2-3.4l-0.2,0C4.9,0.7,3.4,2.1,3.4,3.9
            l0,1.7C3.3,7.4,4.8,9,6.6,9z M6.8,1.5l0.2,0c1.4,0,2.5,1.2,2.4,2.6l0,1.7c0,1.4-1.2,2.4-2.6,2.4c0,0,0,0-0.1,0l-0.2,0
            C5.2,8.2,4.1,7,4.1,5.6l0-1.7C4.2,2.6,5.3,1.5,6.8,1.5z"/>
        <path d="M6.8,9.3C4.5,9.3,0,10.5,0,13.1v3h0.8v-3c0-1.9,4-3,6-3c0.9,0,1.6,0.1,2.2,0.3l0.2-0.7
            C8.4,9.5,7.7,9.4,6.8,9.3z"/>
        <path d="M13.5,8.3c-2.5,0-4.5,2-4.5,4.5s2,4.5,4.5,4.5s4.5-2,4.5-4.5S16,8.3,13.5,8.3z M13.5,16.5
            c-2.1,0-3.8-1.7-3.8-3.8S11.4,9,13.5,9s3.8,1.7,3.8,3.8S15.6,16.5,13.5,16.5z"/>
        <path d="M13.5,10.9c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.1-0.2,0.1-0.3s0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.1
            c-0.1,0-0.3,0-0.3,0.1C13,10.3,13,10.4,13,10.5s0,0.2,0.1,0.3C13.2,10.9,13.3,10.9,13.5,10.9z"/>
        <path d="M13.8,15v-3.4h-0.2c-0.1,0-0.3,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.2V12l0.5,0.1V15c-0.1,0-0.2,0-0.3,0.1
            c-0.1,0-0.2,0.1-0.2,0.1v0.2h1.6v-0.2c-0.1,0-0.1-0.1-0.2-0.1S13.9,15.1,13.8,15z"/>
    </g>

</template>





<script>

    export default {

        name: 'PersonInfo'

    }

</script>