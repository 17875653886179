var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tag,_vm._g(_vm._b({tag:"component",class:[
		'button',
		'is-' + _vm.type,
		{
			'is-fullwidth': _vm.fullwidth,
			'is-small': _vm.small,
			'is-uppercase': !_vm.lowercase,
			'is-hollow': _vm.hollow
		}
	],attrs:{"type":_vm.buttonType,"title":_vm.title,"disabled":_vm.disabled}},'component',_vm.$attrs,false),_vm.$listeners),[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.type === 'text')?_c('BaseIcon',{attrs:{"name":"arrow"}},[_c('ArrowIcon')],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }