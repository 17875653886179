import axios from '@/utils/axios';
import { CaptchaImplementation } from '@/constants';
import { IInitializeApplication } from './applicant';

export enum CaptchaAction {
	None = 0,
	Deny,
	ForceCaptcha
}

export interface ICaptchaResult {
	implmentation: typeof CaptchaImplementation;
	token: string;
	isForced: boolean;
}

export interface ICaptchaState {
	captchaUseConfig: any[] | null;
	captchaV3: ICaptcha | null;
	captchaV2: ICaptcha | null;
	captchaV2Hidden: ICaptcha | null;
	updateCaptcha: null;
}

export interface ICaptcha {
	enabled: boolean;
	implementation: number;
}

export interface IAuthenticateSSORequest {
	sso: string;
	loadUrl: string;
	clientIp: string;
	captchaResults: ICaptchaResult;
	applicantType: number;
}

export interface IAuthenticateSSOResponse extends IInitializeApplication {
	captchaAction: CaptchaAction;
	applicationId: number;
}

export enum AuthenticationResultTypes {
	None = 0,
	AuthenticationSuccess,
	InvalidUserName,
	InvalidPassword,
	AccountLocked,
	PasswordChangeRequired,
	Error
}

export interface IAuthenticateRequest {
	userName: string;
	password: string;
	validationToken: string;
	clientIp: string;
	loadUrl: string;
	captchaResults: ICaptchaResult;
	memberAdvocateId?: number;
	applicantType: number;
}

export interface IAuthenticateResponse extends IInitializeApplication {
	authenticationResult: AuthenticationResultTypes;
	hasCaptchaFailed: boolean;
	applicationId: number;
	applicationToken: string;
	applicantToken: string;
	firstName: string;
	lastName: string;
	email: string;
	isEligibleToUseOAO: boolean;
}

const authenticateSSO = async (request: IAuthenticateSSORequest): Promise<IAuthenticateSSOResponse> => {
	try {
		const authenticateSSOApplicant: IAuthenticateSSORequest = {
			sso: request.sso,
			loadUrl: request.loadUrl,
			clientIp: request.clientIp,
			captchaResults: request.captchaResults,
			applicantType: request.applicantType
		};

		const { data } = await axios.post<IAuthenticateSSOResponse>(`/api/v2/SSO`, authenticateSSOApplicant);

		return data;
	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};

const authenticate = async (request: IAuthenticateRequest) => {
	try {
		const authenticateApplicantRequest: IAuthenticateRequest = {
			userName: request.userName,
			password: request.password,
			validationToken: request.validationToken,
			clientIp: request.clientIp,
			loadUrl: request.loadUrl,
			captchaResults: request.captchaResults,
			memberAdvocateId: request.memberAdvocateId,
			applicantType: request.applicantType
		};

		const { data } = await axios.post<IAuthenticateResponse>(`/api/v2/Authenticate`, authenticateApplicantRequest);

		return data;
	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};

export default {
	authenticate,
	authenticateSSO,
};

export { authenticate, authenticateSSO };