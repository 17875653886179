import { configurationsAPIv2 } from '@/api';
import { IConfigurationState } from '../modules/config';

export interface ISignConfigurationState {
	signConfiguration: ISignConfiguration | null;
}
export interface ISignConfiguration {
	termsAndConditionsAgreement: string;
	signingAgreement: string;
	updateContactInformationMessage: string;
	hasOLBRegistration: boolean;
}

const state: ISignConfigurationState = {
	signConfiguration: null
};

function resetState(state) {
	state.signConfiguration = null;
}

const mutations = {
	//TODO: Add Mutations (if any)
};

const actions = {
	async getSignConfiguration({ commit, state }: any): Promise<ISignConfiguration> {
		try {
			if (!state.signConfiguration) {
				const {
					termsAndConditionsAgreement: terms,
					signingAgreement: agreement,
					updateContactInformationMessage: updateContactInformationMessage,
					hasOLBRegistration: hasOLBRegistration
				} = await configurationsAPIv2.getSignPageConfiguration();

				commit('setData', {
					objName: 'signConfiguration',
					data: {
						termsAndConditionsAgreement: terms,
						signingAgreement: agreement,
						updateContactInformationMessage: updateContactInformationMessage,
						hasOLBRegistration: hasOLBRegistration
					}
				});
			}

			return state.signConfiguration;
		} catch (error) {
	
			if (typeof error === 'string') {
				throw new Error(error);
			}

			throw error;
		}
	}
};

export { state, resetState, mutations, actions };

export default {
	state,
	resetState,
	mutations,
	actions
};
