<template function>

    <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0007 4.25C9.95902 4.25 4.25065 9.95837 4.25065 17C4.25065 24.0416 9.95902 29.75 17.0007 29.75C24.0423 29.75 29.7507 24.0416 29.7507 17C29.7507 9.95837 24.0423 4.25 17.0007 4.25ZM2.83398 17C2.83398 9.17597 9.17662 2.83334 17.0007 2.83334C24.8247 2.83334 31.1673 9.17597 31.1673 17C31.1673 24.824 24.8247 31.1667 17.0007 31.1667C9.17662 31.1667 2.83398 24.824 2.83398 17Z" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.08963 7.08743L26.9272 25.925L25.9255 26.9267L7.08789 8.08917L8.08963 7.08743Z" />
    </svg>

</template>

<script>

    export default {

        name: 'CancelIcon'

    }

</script>