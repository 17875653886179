import axios from '@/utils/axios';

export interface IRegisterOnlineBankingRequest {
	applicationToken: string;
	applicantToken: string;
	username: string;
	password: string;
}

export interface IRegisterOnlineBankingResponse {
	enrolled: boolean;
	error: number | null;
}

export interface IApplicantRegistrationConfigsRequest {
	applicationToken: string;
	applicantToken: string;
}

export interface IApplicantRegistrationConfigsResponse {
	accountNumber: string;
	ssn: string;
}

const registerOnlineBankingAsync = async (request: IRegisterOnlineBankingRequest): Promise<IRegisterOnlineBankingResponse> => {
	try {
		const { data } = await axios.post<IRegisterOnlineBankingResponse>('/api/v2/OnlineBankingRegistration', request);

		return data;
	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};

const getApplicantRegistrationDataAsync = async (request: IApplicantRegistrationConfigsRequest): Promise<IApplicantRegistrationConfigsResponse> => {
	try {
		const { data } = await axios.post<IApplicantRegistrationConfigsResponse>('/api/v2/OnlineBankingRegistration/Applicant/Configurations', request);

		return data;
	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
}

export { registerOnlineBankingAsync, getApplicantRegistrationDataAsync };