import { Wormhole } from 'portal-vue';

import { Module } from 'vuex';
import { IRootState } from '..';

export interface IModalState {
	isOpen: boolean;
	isLeavingModal: boolean;
	title: string | null;
	centeredTitle: boolean;
	smallTitle: boolean;
	isWide: boolean;
	preventClose: boolean;
	leavingModalShown: boolean;
	appIdCopy: number | null;
	isProductRemovalModal: boolean;
}

const modal: Module<IModalState, IRootState> = {
	namespaced: true,
	state: {
		isOpen: false,
		isLeavingModal: false,
		title: null,
		centeredTitle: false,
		smallTitle: false,
		isWide: false,
		preventClose: false,
		leavingModalShown: false,
		appIdCopy: null,
		isProductRemovalModal: false
	},
	mutations: {
		setData(
			state,
			{
				isOpen = false,
				isLeavingModal = false,
				title = null,
				centeredTitle = false,
				smallTitle = false,
				isWide = false,
				preventClose = false,
				isProductRemovalModal = false
			}
		) {
			state.isOpen = isOpen;
			state.title = title;
			state.isLeavingModal = isLeavingModal;
			state.centeredTitle = centeredTitle;
			state.smallTitle = smallTitle;
			state.isWide = isWide;
			state.preventClose = preventClose;
			state.isProductRemovalModal = isProductRemovalModal;
		},
		setLeavingModalShown(state) {
			state.leavingModalShown = true;
		},
		setLeavingModalUnshown(state) {
			state.leavingModalShown = false;
		},
		setAppIdCopy(state, appIdCopy) {
			state.appIdCopy = appIdCopy;
		},
		resetState(state) {

			state.isOpen = false;
			state.isLeavingModal = false;
			state.title = null;
			state.centeredTitle = false;
			state.smallTitle = false;
			state.isWide = false;
			state.preventClose = false;
			state.leavingModalShown = false;
			state.appIdCopy = null;
			state.isProductRemovalModal = false;

		},
	},
	actions: {
		triggerModal({ commit }, { isOpen, ...rest } = {}): void {
			// Destroy the Portal content for the modal before closing it,
			// so we don't have to manually do it somewhere else:
			if (!isOpen) {
				Wormhole.close({ to: 'modal-content' }, true);
				Wormhole.close({ to: 'modal-footer' }, true);
			}

			commit('setData', { ...rest, isOpen });
		},
		openLeavingModal({ commit, dispatch, state }, { appIdCopy, ...rest } = {}): void {
			commit('setAppIdCopy', appIdCopy);
			typeof rest.title === 'undefined' ? (rest.title = 'Sorry to see you leave!') : '';

			if (!state.leavingModalShown) {
				const openLeavingModalState = {
					isOpen: true,
					isLeavingModal: true
				};

				dispatch('triggerModal', { ...openLeavingModalState, ...rest });
				commit('setLeavingModalShown');
			}
		},
		closeLeavingModal({ commit, dispatch }, { ...rest } = {}): void {
			const closeLeavingModalState = {
				isOpen: false,
				isLeavingModal: false
			};

			dispatch('triggerModal', { ...closeLeavingModalState, ...rest });
		}
	},
	getters: {
		appIdCopy: state => state.appIdCopy,
		hasPortal() {
			return {
				content: Wormhole.hasContentFor('modal-content'),
				footer: Wormhole.hasContentFor('modal-footer')
			};
		},
		leavingModalShown: state => state.leavingModalShown
	}
};

export default modal;
