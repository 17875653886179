import store from '@/store/_v2';
import router from '@/router';

const constructUrl = (name, newParams = {}, newQuery = {}) => {
	const { params, query, path: fromRoute } = router.currentRoute;

	return {
		name,
		params: {
			...params,
			...newParams,
			fromRoute
		},
		query: {
			...newQuery,
			...query,
			memberType: store.state.applicant.memberType,
			applicationType: store.state.application.applicationType
		}
	};
};

export default constructUrl;
