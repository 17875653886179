<template>
	<ValidationProvider :rules="validationRules" :name="label" :vid="validationId" v-slot="{ errors }" slim>
		<OaoTextArea
			v-model="innerValue"
			:label="label"
			:placeholder="placeholder"
			:disabled="disabled"
			:error="errors[0]"
			:isOptional="isOptional"
			:disableAutocomplete="disableAutocomplete"
			:isSmall="isSmall"
			:hideLabel="hideLabel"
			@change="$emit('change', $event)"
		/>
	</ValidationProvider>
</template>




<script>
import { ValidationProvider } from 'vee-validate';

import { OaoTextArea } from '@/components/form';

export default {
	name: 'FormTextArea',
	components: {
		ValidationProvider,
		OaoTextArea
	},
	props: {
		value: {
			required: true
		},
		label: {
			type: String,
			required: true
		},
		hideLabel: {
			type: Boolean,
			required: false
		},
		placeholder: {
			type: String,
			required: false
		},
		disabled: {
			type: Boolean,
			required: false
		},
		validationRules: {
			type: [String, Object],
			required: false
		},
		isOptional: {
			type: Boolean,
			required: false
		},
		disableAutocomplete: {
			type: Boolean,
			required: false
		},
		validationId: {
			type: String,
			required: false
		},
		isSmall: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	computed: {
		innerValue: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit('input', val);
			}
		}
	}
};
</script>