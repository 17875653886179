import * as Sentry from '@sentry/vue';

const logError = (error = null, requestId = null, applicantToken = null, applicationToken = null) => {
    Sentry.setContext('ERROR CONTEXT', {
        error,
		requestId,
        applicantToken,
        applicationToken,
    });
    Sentry.captureException(error);
    
    return error;
};

// const logError =
// 	caller =>
// 	({ action, request = {}, error, logToSentry = false }) => {
// 		const errorMessage = error.message || error;
// 		if (logToSentry) {
// 			Sentry.setContext('ERROR CONTEXT', {
// 				action,
// 				caller,
// 				errorMessage,
// 				request
// 			});
// 			console.log('Capturing sentry log');
// 			Sentry.captureException(error);
// 		}

// 		return errorMessage;
// 	};

export default logError;
