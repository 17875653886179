import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import PortalVue from 'portal-vue';
import VTooltip from 'v-tooltip';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

import App from './App.vue';
import router from './router';
import store from './store/_v2';
import { axios, constructUrl, constructUrlNewParams } from '@/utils';

Vue.use(PortalVue);
Vue.use(VTooltip);
Vue.use(Toast, {
	transition: 'Vue-Toastification__bounce',
	maxToasts: 20,
	newestOnTop: true,
	position: 'top-center',
	timeout: 5000,
	closeOnClick: true,
	pauseOnFocusLoss: true,
	pauseOnHover: true,
	draggable: true,
	draggablePercent: 0.6,
	hideCloseButton: false,
	hideProgressBar: false,
	icon: true
});

Vue.prototype.$axios = axios;
Vue.prototype.$constructUrl = constructUrl;
Vue.prototype.$constructUrlNewParams = constructUrlNewParams;

Vue.config.productionTip = false;
Vue.config.performance = true;

Sentry.init({
	Vue,
	environment: window.location.hostname,
	dsn: 'https://2dddad815137b907e2f7d3564da4ad48@o1137879.ingest.sentry.io/6303144',
	beforeSend(event, hint) {
		// Don't track Google Analytics timeout error
		// REF: https://github.com/getsentry/sentry-javascript/issues/2514
		return hint?.originalException === 'Timeout' ? null : event;
	}
});

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app');
