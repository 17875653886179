<template>
	<span v-html="htmlContent" :class="className"> </span>
</template>

<script>
	import { mapGetters } from 'vuex';

	export default {
		name: 'OaoDynamicHyperlink',
		props: {
			className: {
				type: String,
				required: false
			},
			htmlContent: {
				type: String,
				required: true
			}
			//add prop for html
		}
	};
</script>

<style lang="scss" scoped></style>
