<template functional>
	<svg
		version="1.1"
		id="Layer_1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 216 100.8"
        style="enable-background:new 0 0 216 100.8;"
		xml:space="preserve"
	>
		<title>NCUA Logo</title>
		<g>
			<pattern
				x="491.88"
				y="-178.18"
				width="69"
				height="69"
				patternUnits="userSpaceOnUse"
				id="Polka_Dot_Pattern"
				viewBox="2.13 -70.9 69 69"
				style="overflow:visible;"
			>
				<g>
					<rect x="2.13" y="-70.9" class="st0" width="69" height="69" />
					<rect x="2.13" y="-70.9" class="st1" width="69" height="69" />
					<g>
						<path
							class="st2"
							d="M61.77-71.65c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                            c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                            c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                            c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                            c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                            c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                            c-0.02,0.01-0.02,0-0.03-0.03"
						/>
						<path
							class="st2"
							d="M54.11-71.65c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                            c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                            c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                            c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                            c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                            c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                            c-0.02,0.01-0.02,0-0.03-0.03"
						/>
						<path
							class="st2"
							d="M46.44-71.65c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                            c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                            c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                            c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                            c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                            c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                            c-0.02,0.01-0.02,0-0.03-0.03"
						/>
						<path
							class="st2"
							d="M38.77-71.65c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                            c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                            c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                            c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                            c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                            c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                            c-0.02,0.01-0.02,0-0.03-0.03"
						/>
						<path
							class="st2"
							d="M31.11-71.65c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.16,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                            c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.43,0.54
                            c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                            c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                            c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                            c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                            c-0.02,0.01-0.02,0-0.03-0.03"
						/>
						<path
							class="st2"
							d="M23.44-71.65c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                            c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.43,0.54
                            c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                            c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                            c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                            c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                            c-0.02,0.01-0.02,0-0.03-0.03"
						/>
						<path
							class="st2"
							d="M15.77-71.65c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                            c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                            c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                            c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                            c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                            c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                            c-0.02,0.01-0.02,0-0.03-0.03"
						/>
						<path
							class="st2"
							d="M8.11-71.65c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                            c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                            c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                            c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                            c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                            c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                            c-0.02,0.01-0.02,0-0.03-0.03"
						/>
						<path
							class="st2"
							d="M0.44-71.65c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                            C0-70.92,0.16-70.75,0.15-70.62c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                            c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                            c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                            c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                            c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                            c-0.02,0.01-0.02,0-0.03-0.03"
						/>
					</g>
					<g>
						<path
							class="st2"
							d="M69.44-71.65c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                            c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.43,0.54
                            c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                            c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                            c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                            c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                            c-0.02,0.01-0.02,0-0.03-0.03"
						/>
					</g>
					<path
						class="st2"
						d="M0.5-71.65c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                        c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                        c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                        c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                        c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                        c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                        c-0.02,0.01-0.02,0-0.03-0.03"
					/>
					<g>
						<g>
							<path
								class="st2"
								d="M69.44-64c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.43,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M61.78-64c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M54.12-64c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M46.46-64c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.16,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.05,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M38.8-64c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M31.14-64c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.16,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.05,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M23.48-64c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M15.82-64c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.16,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.05,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M8.16-64c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M0.5-64c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                C3.58-63,3.54-63.05,3.53-63.12c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
						</g>
						<g>
							<path
								class="st2"
								d="M69.44-56.35c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.43,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M61.78-56.35c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M54.12-56.35c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M46.46-56.35c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.16,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.05,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M38.8-56.35c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M31.14-56.35c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.16,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.05,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M23.48-56.35c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M15.82-56.35c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.16,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.05,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M8.16-56.35c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M0.5-56.35c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
						</g>
						<g>
							<path
								class="st2"
								d="M69.44-48.7c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.43,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M61.78-48.7c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M54.12-48.7c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M46.46-48.7c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.16,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.05,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M38.8-48.7c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M31.14-48.7c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.16,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.05,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M23.48-48.7c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M15.82-48.7c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.16,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.05,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M8.16-48.7c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M0.5-48.7c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
						</g>
						<g>
							<path
								class="st2"
								d="M69.44-41.04c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.43,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M61.78-41.04c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M54.12-41.04c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M46.46-41.04c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.16,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.05,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M38.8-41.04c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M31.14-41.04c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.16,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.05,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M23.48-41.04c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M15.82-41.04c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.16,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.05,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M8.16-41.04c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M0.5-41.04c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
						</g>
						<g>
							<path
								class="st2"
								d="M69.44-33.39c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.43,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M61.78-33.39c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M54.12-33.39c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M46.46-33.39c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.16,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.05,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M38.8-33.39c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M31.14-33.39c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.16,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.05,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M23.48-33.39c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M15.82-33.39c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.16,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.05,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M8.16-33.39c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M0.5-33.39c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
						</g>
						<g>
							<path
								class="st2"
								d="M69.44-25.74c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                C69-25,69.16-24.83,69.15-24.71c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.43,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M61.78-25.74c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M54.12-25.74c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M46.46-25.74c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.16,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.05,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M38.8-25.74c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M31.14-25.74c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.16,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.05,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M23.48-25.74c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M15.82-25.74c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.16,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.05,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M8.16-25.74c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                C7.72-25,7.88-24.83,7.87-24.71c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M0.5-25.74c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                C0.06-25,0.22-24.83,0.21-24.71c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
						</g>
						<g>
							<path
								class="st2"
								d="M69.44-18.08c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.43,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M61.78-18.08c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M54.12-18.08c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M46.46-18.08c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.16,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.05,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M38.8-18.08c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M31.14-18.08c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.16,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.05,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M23.48-18.08c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M15.82-18.08c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.16,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.05,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M8.16-18.08c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M0.5-18.08c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
						</g>
						<g>
							<path
								class="st2"
								d="M69.44-10.43c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                C69-9.69,69.16-9.52,69.15-9.4c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.43,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M61.78-10.43c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M54.12-10.43c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46C57.1-8.93,57.09-9,57.12-9.07c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M46.46-10.43c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.16,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.05,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M38.8-10.43c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46C41.78-8.93,41.77-9,41.8-9.07c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M31.14-10.43c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.16,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.05,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M23.48-10.43c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M15.82-10.43c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.16,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.05,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46C18.8-8.93,18.79-9,18.82-9.07c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M8.16-10.43c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                C7.72-9.69,7.88-9.52,7.87-9.4c0,0.05-0.04,0.11-0.04,0.16C7.82-9.12,7.84-8.97,7.89-8.87c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                                c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
							<path
								class="st2"
								d="M0.5-10.43c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                                C0.06-9.69,0.22-9.52,0.21-9.4c0,0.05-0.04,0.11-0.04,0.16C0.16-9.12,0.18-8.97,0.23-8.87c0.1,0.2,0.27,0.41,0.44,0.54
                                c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14C2-7.88,2.22-7.83,2.39-7.89c0.22-0.08,0.13-0.34,0.29-0.45
                                c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46C3.48-8.93,3.47-9,3.5-9.07c0.04-0.1,0.11-0.16,0.09-0.29
                                C3.58-9.43,3.54-9.48,3.53-9.55c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                                c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                                c-0.02,0.01-0.02,0-0.03-0.03"
							/>
						</g>
					</g>
					<g>
						<path
							class="st2"
							d="M69.44-2.78c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                            C69-2.04,69.16-1.87,69.15-1.75c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.43,0.54
                            c0.19,0.15,0.36,0.57,0.61,0.63C70.4,0,70.6-0.15,70.76-0.18c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                            c0.1-0.07,0.29-0.01,0.41-0.05C72.23-0.81,72.37-1,72.4-1.2c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                            c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                            c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                            c-0.02,0.01-0.02,0-0.03-0.03"
						/>
						<path
							class="st2"
							d="M61.78-2.78c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                            c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                            c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                            c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                            c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                            c-0.18,0.03-0.29,0.13-0.44,0.21C62.14-3.28,62.18-3.2,62-3.14c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                            c-0.02,0.01-0.02,0-0.03-0.03"
						/>
						<path
							class="st2"
							d="M54.12-2.78c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                            c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                            c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                            c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                            c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                            c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                            c-0.02,0.01-0.02,0-0.03-0.03"
						/>
						<path
							class="st2"
							d="M46.46-2.78c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.16,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                            c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.05,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                            C46.82-0.52,47-0.1,47.24-0.04c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                            c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                            c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                            c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                            c-0.02,0.01-0.02,0-0.03-0.03"
						/>
						<path
							class="st2"
							d="M38.8-2.78c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                            c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                            c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                            c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                            c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                            c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                            c-0.02,0.01-0.02,0-0.03-0.03"
						/>
						<path
							class="st2"
							d="M31.14-2.78c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.16,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                            c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.05,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                            c0.19,0.15,0.36,0.57,0.61,0.63C32.1,0,32.3-0.15,32.46-0.18c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                            c0.1-0.07,0.29-0.01,0.41-0.05C33.93-0.81,34.07-1,34.1-1.2c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                            c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                            c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                            c-0.02,0.01-0.02,0-0.03-0.03"
						/>
						<path
							class="st2"
							d="M23.48-2.78c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                            c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.04,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                            c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                            c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                            c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                            c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                            c-0.02,0.01-0.02,0-0.03-0.03"
						/>
						<path
							class="st2"
							d="M15.82-2.78c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.16,0.13c-0.05,0.06-0.1,0.28-0.08,0.36
                            c-0.17,0.06-0.01,0.23-0.02,0.35c0,0.05-0.04,0.11-0.05,0.16c-0.01,0.12,0.02,0.27,0.07,0.37c0.1,0.2,0.27,0.41,0.44,0.54
                            c0.19,0.15,0.36,0.57,0.61,0.63c0.18,0.04,0.38-0.1,0.54-0.14c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                            c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                            c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                            c-0.18,0.03-0.29,0.13-0.44,0.21c-0.17,0.09-0.14,0.17-0.32,0.22c-0.08,0.03-0.22,0.12-0.21,0.22c0,0.12,0,0.05,0.01,0.17
                            c-0.02,0.01-0.02,0-0.03-0.03"
						/>
						<path
							class="st2"
							d="M8.16-2.78c0.02,0.07,0.01,0.13-0.03,0.19c-0.05,0.1-0.11,0.06-0.17,0.13C7.91-2.4,7.87-2.18,7.89-2.1
                            C7.72-2.04,7.88-1.87,7.87-1.75c0,0.05-0.04,0.11-0.04,0.16C7.82-1.47,7.84-1.32,7.89-1.22c0.1,0.2,0.27,0.41,0.44,0.54
                            C8.52-0.52,8.69-0.1,8.94-0.04C9.11,0,9.32-0.15,9.48-0.18c0.18-0.04,0.4,0.01,0.57-0.05c0.22-0.08,0.13-0.34,0.29-0.45
                            c0.1-0.07,0.29-0.01,0.41-0.05c0.2-0.07,0.34-0.26,0.38-0.46c0.02-0.08,0.01-0.14,0.04-0.22c0.04-0.1,0.11-0.16,0.09-0.29
                            c-0.01-0.06-0.05-0.12-0.06-0.19c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55c-0.38-0.69-1.24-0.93-1.97-0.79
                            C8.95-3.54,8.85-3.45,8.69-3.37C8.52-3.28,8.55-3.2,8.37-3.14C8.29-3.12,8.16-3.02,8.16-2.92c0,0.12,0,0.05,0.01,0.17
                            c-0.02,0.01-0.02,0-0.03-0.03"
						/>
						<path
							class="st2"
							d="M0.5-2.78C0.51-2.71,0.5-2.65,0.47-2.59C0.42-2.49,0.36-2.53,0.3-2.46C0.25-2.4,0.21-2.18,0.23-2.1
                            C0.06-2.04,0.21-1.87,0.21-1.75c0,0.05-0.04,0.11-0.04,0.16C0.15-1.47,0.18-1.32,0.23-1.22c0.1,0.2,0.27,0.41,0.44,0.54
                            C0.86-0.52,1.03-0.1,1.28-0.04C1.45,0,1.66-0.15,1.82-0.18C2-0.23,2.22-0.17,2.39-0.24c0.22-0.08,0.13-0.34,0.29-0.45
                            c0.1-0.07,0.29-0.01,0.41-0.05C3.28-0.81,3.42-1,3.46-1.2C3.48-1.28,3.47-1.34,3.5-1.42c0.04-0.1,0.11-0.16,0.09-0.29
                            C3.58-1.77,3.54-1.83,3.53-1.9c-0.02-0.11,0-0.22,0-0.34c0-0.2,0.02-0.38-0.08-0.55C3.07-3.47,2.21-3.71,1.47-3.57
                            C1.29-3.54,1.19-3.45,1.03-3.37C0.86-3.28,0.89-3.2,0.71-3.14C0.63-3.12,0.5-3.02,0.5-2.92c0,0.12,0,0.05,0.01,0.17
                            c-0.02,0.01-0.02,0-0.03-0.03"
						/>
					</g>
				</g>
			</pattern>
			<g>
				<path
					class="st3"
					d="M44.07,22.92c-0.19,0-0.32,0.1-0.37,0.28c-0.06,0.19-0.09,0.47-0.09,0.85c0,0.38,0.03,0.66,0.09,0.85
                    c0.06,0.19,0.18,0.28,0.37,0.28c0.2,0,0.32-0.09,0.38-0.28c0.06-0.19,0.09-0.47,0.09-0.85c0-0.38-0.03-0.66-0.09-0.85
                    S44.27,22.92,44.07,22.92z"
				/>
				<path
					class="st3"
					d="M46.42,17.72c0.03,0.11,0.08,0.19,0.14,0.25c0.06,0.06,0.15,0.09,0.26,0.09c0.18,0,0.3-0.09,0.37-0.28
                    c0.07-0.19,0.1-0.5,0.1-0.93c0-0.43-0.03-0.74-0.1-0.93c-0.07-0.19-0.19-0.28-0.37-0.28c-0.11,0-0.19,0.03-0.26,0.09
                    c-0.06,0.06-0.11,0.14-0.14,0.25c-0.03,0.11-0.05,0.23-0.06,0.38c-0.01,0.15-0.01,0.31-0.01,0.49c0,0.17,0,0.34,0.01,0.49
                    C46.37,17.48,46.39,17.61,46.42,17.72z"
				/>
				<path
					class="st3"
					d="M48.64,24.9c0.06,0.19,0.18,0.28,0.38,0.28c0.19,0,0.32-0.09,0.37-0.28c0.06-0.19,0.09-0.47,0.09-0.85
                    c0-0.38-0.03-0.66-0.09-0.85s-0.18-0.28-0.37-0.28c-0.2,0-0.32,0.1-0.38,0.28s-0.09,0.47-0.09,0.85
                    C48.56,24.42,48.59,24.71,48.64,24.9z"
				/>
				<path
					class="st3"
					d="M17.04,24.22c-0.18,0.04-0.31,0.1-0.38,0.19c-0.08,0.09-0.12,0.21-0.12,0.36c0,0.13,0.03,0.25,0.08,0.34
                    c0.05,0.09,0.14,0.14,0.26,0.14c0.06,0,0.12-0.01,0.19-0.03c0.06-0.02,0.12-0.05,0.18-0.09c0.05-0.04,0.1-0.1,0.13-0.17
                    c0.03-0.07,0.05-0.15,0.05-0.24v-0.68c-0.06,0.05-0.12,0.08-0.19,0.11C17.16,24.19,17.1,24.21,17.04,24.22z"
				/>
				<path
					class="st3"
					d="M41.41,23.31c-0.01-0.1-0.04-0.19-0.07-0.26s-0.08-0.12-0.13-0.16c-0.06-0.04-0.13-0.05-0.22-0.05
                    c-0.09,0-0.16,0.02-0.22,0.06c-0.06,0.04-0.1,0.09-0.14,0.16s-0.06,0.14-0.07,0.22c-0.02,0.08-0.02,0.16-0.02,0.24v0.13h0.9
                    C41.44,23.53,41.43,23.41,41.41,23.31z"
				/>
				<path
					class="st3"
					d="M28.44,25.18c0.19,0,0.32-0.09,0.37-0.28c0.06-0.19,0.09-0.47,0.09-0.85c0-0.38-0.03-0.66-0.09-0.85
                    c-0.06-0.19-0.18-0.28-0.37-0.28c-0.2,0-0.32,0.1-0.38,0.28c-0.06,0.19-0.09,0.47-0.09,0.85c0,0.38,0.03,0.66,0.09,0.85
                    C28.11,25.09,28.24,25.18,28.44,25.18z"
				/>
				<path
					class="st3"
					d="M87.24,16.11c-0.01-0.1-0.04-0.19-0.07-0.26s-0.08-0.12-0.13-0.16c-0.06-0.04-0.13-0.05-0.22-0.05
                    c-0.09,0-0.16,0.02-0.22,0.06c-0.06,0.04-0.1,0.09-0.14,0.16c-0.03,0.07-0.06,0.14-0.07,0.22c-0.02,0.08-0.02,0.16-0.02,0.24v0.13
                    h0.9C87.27,16.33,87.26,16.21,87.24,16.11z"
				/>
				<path
					class="st3"
					d="M83.61,17.98c0.2,0,0.32-0.09,0.38-0.28c0.06-0.19,0.09-0.47,0.09-0.85c0-0.38-0.03-0.66-0.09-0.85
                    c-0.06-0.19-0.18-0.28-0.38-0.28c-0.19,0-0.32,0.1-0.37,0.28c-0.06,0.19-0.09,0.47-0.09,0.85c0,0.38,0.03,0.66,0.09,0.85
                    C83.29,17.89,83.42,17.98,83.61,17.98z"
				/>
				<path
					class="st3"
					d="M78.5,24.22c-0.18,0.04-0.31,0.1-0.38,0.19C78.04,24.5,78,24.62,78,24.78c0,0.13,0.03,0.25,0.08,0.34
                    c0.05,0.09,0.14,0.14,0.26,0.14c0.06,0,0.12-0.01,0.19-0.03c0.06-0.02,0.12-0.05,0.18-0.09c0.05-0.04,0.1-0.1,0.13-0.17
                    c0.03-0.07,0.05-0.15,0.05-0.24v-0.68c-0.06,0.05-0.12,0.08-0.19,0.11C78.62,24.19,78.56,24.21,78.5,24.22z"
				/>
				<path
					class="st3"
					d="M80.95,16.11c-0.01-0.1-0.04-0.19-0.07-0.26c-0.03-0.07-0.08-0.12-0.13-0.16c-0.06-0.04-0.13-0.05-0.22-0.05
                    c-0.09,0-0.16,0.02-0.22,0.06c-0.06,0.04-0.1,0.09-0.14,0.16c-0.03,0.07-0.06,0.14-0.07,0.22c-0.02,0.08-0.02,0.16-0.02,0.24v0.13
                    h0.9C80.97,16.33,80.97,16.21,80.95,16.11z"
				/>
				<path
					class="st3"
					d="M62.3,23.31c-0.01-0.1-0.04-0.19-0.07-0.26s-0.08-0.12-0.13-0.16c-0.06-0.04-0.13-0.05-0.22-0.05
                    c-0.09,0-0.16,0.02-0.22,0.06c-0.06,0.04-0.1,0.09-0.14,0.16s-0.06,0.14-0.07,0.22c-0.02,0.08-0.02,0.16-0.02,0.24v0.13h0.9
                    C62.32,23.53,62.31,23.41,62.3,23.31z"
				/>
				<path
					class="st3"
					d="M70.49,17.58c0.03,0.08,0.08,0.15,0.13,0.2c0.06,0.05,0.13,0.07,0.23,0.07c0.09,0,0.17-0.02,0.23-0.07
                    c0.06-0.05,0.11-0.12,0.15-0.2c0.04-0.09,0.06-0.19,0.08-0.31c0.01-0.12,0.02-0.25,0.02-0.4c0-0.42-0.03-0.71-0.1-0.89
                    c-0.07-0.17-0.19-0.26-0.36-0.26c-0.1,0-0.18,0.02-0.24,0.06c-0.06,0.04-0.11,0.11-0.14,0.21c-0.03,0.1-0.06,0.22-0.07,0.38
                    c-0.01,0.16-0.02,0.36-0.02,0.59c0,0.12,0.01,0.23,0.02,0.34C70.43,17.4,70.45,17.5,70.49,17.58z"
				/>
				<path
					class="st3"
					d="M59.74,18.06c0.06,0,0.12-0.01,0.19-0.03c0.06-0.02,0.12-0.05,0.18-0.09c0.05-0.04,0.1-0.1,0.13-0.17
                    c0.03-0.07,0.05-0.15,0.05-0.24v-0.68c-0.06,0.05-0.12,0.08-0.19,0.11c-0.07,0.03-0.13,0.05-0.19,0.06
                    c-0.18,0.04-0.31,0.1-0.38,0.19c-0.08,0.09-0.12,0.21-0.12,0.36c0,0.13,0.03,0.25,0.08,0.34C59.54,18.01,59.62,18.06,59.74,18.06z"
				/>
				<polygon class="st3" points="83.68,82.53 83.24,84.58 84.13,84.58 83.69,82.53 	" />
				<path
					class="st3"
					d="M75.68,83.37c-0.11,0-0.19,0.03-0.26,0.09c-0.06,0.06-0.11,0.14-0.14,0.25c-0.03,0.11-0.05,0.24-0.06,0.38
                    c-0.01,0.15-0.01,0.31-0.01,0.49c0,0.18,0,0.34,0.01,0.49c0.01,0.15,0.03,0.28,0.06,0.38s0.08,0.19,0.14,0.25
                    c0.06,0.06,0.15,0.09,0.26,0.09c0.18,0,0.3-0.09,0.37-0.28c0.07-0.19,0.1-0.5,0.1-0.93c0-0.43-0.03-0.74-0.1-0.93
                    C75.98,83.47,75.86,83.37,75.68,83.37z"
				/>
				<path
					class="st3"
					d="M109.62,22.92c-0.19,0-0.32,0.1-0.37,0.28c-0.06,0.19-0.09,0.47-0.09,0.85c0,0.38,0.03,0.66,0.09,0.85
                    c0.06,0.19,0.18,0.28,0.37,0.28c0.2,0,0.32-0.09,0.38-0.28c0.06-0.19,0.09-0.47,0.09-0.85c0-0.38-0.03-0.66-0.09-0.85
                    C109.94,23.01,109.81,22.92,109.62,22.92z"
				/>
				<path
					class="st3"
					d="M108.75,84.75c-0.18,0.04-0.31,0.1-0.38,0.19c-0.08,0.09-0.12,0.21-0.12,0.36c0,0.13,0.03,0.25,0.08,0.34
                    c0.05,0.09,0.14,0.14,0.26,0.14c0.06,0,0.12-0.01,0.19-0.03c0.06-0.02,0.12-0.05,0.18-0.09c0.05-0.04,0.1-0.1,0.13-0.17
                    c0.03-0.07,0.05-0.15,0.05-0.24v-0.68c-0.06,0.05-0.12,0.08-0.19,0.11C108.87,84.72,108.81,84.74,108.75,84.75z"
				/>
				<path
					class="st3"
					d="M58.9,83.45c-0.19,0-0.32,0.1-0.37,0.29c-0.06,0.19-0.09,0.47-0.09,0.85c0,0.38,0.03,0.66,0.09,0.85
                    c0.06,0.19,0.18,0.28,0.37,0.28c0.2,0,0.32-0.09,0.38-0.28s0.09-0.47,0.09-0.85c0-0.38-0.03-0.66-0.09-0.85
                    C59.23,83.54,59.1,83.45,58.9,83.45z"
				/>
				<path
					class="st3"
					d="M30.71,84.75c-0.18,0.04-0.31,0.1-0.38,0.19c-0.08,0.09-0.12,0.21-0.12,0.36c0,0.13,0.03,0.25,0.08,0.34
                    c0.05,0.09,0.14,0.14,0.26,0.14c0.06,0,0.12-0.01,0.19-0.03c0.06-0.02,0.12-0.05,0.18-0.09c0.05-0.04,0.1-0.1,0.13-0.17
                    c0.03-0.07,0.05-0.15,0.05-0.24v-0.68c-0.06,0.05-0.12,0.08-0.19,0.11C30.82,84.72,30.76,84.74,30.71,84.75z"
				/>
				<path
					class="st3"
					d="M56.18,83.59c-0.03-0.07-0.08-0.12-0.13-0.16c-0.06-0.04-0.13-0.05-0.22-0.05c-0.09,0-0.16,0.02-0.22,0.06
                    c-0.06,0.04-0.1,0.09-0.14,0.16c-0.03,0.07-0.06,0.14-0.07,0.22c-0.02,0.08-0.02,0.16-0.02,0.24v0.13h0.9
                    c0-0.13-0.01-0.25-0.03-0.35C56.23,83.74,56.21,83.66,56.18,83.59z"
				/>
				<path
					class="st3"
					d="M23.48,22.92c-0.19,0-0.32,0.1-0.37,0.28s-0.09,0.47-0.09,0.85c0,0.38,0.03,0.66,0.09,0.85
                    c0.06,0.19,0.18,0.28,0.37,0.28c0.2,0,0.32-0.09,0.38-0.28c0.06-0.19,0.09-0.47,0.09-0.85c0-0.38-0.03-0.66-0.09-0.85
                    C23.81,23.01,23.68,22.92,23.48,22.92z"
				/>
				<path
					class="st3"
					d="M31.58,24.22c-0.18,0.04-0.31,0.1-0.38,0.19c-0.08,0.09-0.12,0.21-0.12,0.36c0,0.13,0.03,0.25,0.08,0.34
                    c0.05,0.09,0.14,0.14,0.26,0.14c0.06,0,0.12-0.01,0.19-0.03c0.06-0.02,0.12-0.05,0.18-0.09c0.05-0.04,0.1-0.1,0.13-0.17
                    c0.03-0.07,0.05-0.15,0.05-0.24v-0.68c-0.06,0.05-0.12,0.08-0.19,0.11C31.7,24.19,31.63,24.21,31.58,24.22z"
				/>
				<path
					class="st3"
					d="M43.81,84.75c-0.18,0.04-0.31,0.1-0.38,0.19c-0.08,0.09-0.12,0.21-0.12,0.36c0,0.13,0.03,0.25,0.08,0.34
                    c0.05,0.09,0.14,0.14,0.26,0.14c0.06,0,0.12-0.01,0.19-0.03c0.06-0.02,0.12-0.05,0.18-0.09c0.05-0.04,0.1-0.1,0.13-0.17
                    c0.03-0.07,0.05-0.15,0.05-0.24v-0.68c-0.06,0.05-0.12,0.08-0.19,0.11C43.92,84.72,43.86,84.74,43.81,84.75z"
				/>
				<path
					class="st3"
					d="M37.42,83.37c-0.11,0-0.19,0.03-0.26,0.09c-0.06,0.06-0.11,0.14-0.14,0.25c-0.03,0.11-0.05,0.24-0.06,0.38
                    c-0.01,0.15-0.01,0.31-0.01,0.49c0,0.18,0,0.34,0.01,0.49c0.01,0.15,0.03,0.28,0.06,0.38c0.03,0.11,0.08,0.19,0.14,0.25
                    c0.06,0.06,0.15,0.09,0.26,0.09c0.18,0,0.3-0.09,0.37-0.28c0.07-0.19,0.1-0.5,0.1-0.93c0-0.43-0.03-0.74-0.1-0.93
                    C37.72,83.47,37.6,83.37,37.42,83.37z"
				/>
				<path
					class="st3"
					d="M87.08,83.45c-0.19,0-0.32,0.1-0.37,0.29c-0.06,0.19-0.09,0.47-0.09,0.85c0,0.38,0.03,0.66,0.09,0.85
                    c0.06,0.19,0.18,0.28,0.37,0.28c0.2,0,0.32-0.09,0.38-0.28s0.09-0.47,0.09-0.85c0-0.38-0.03-0.66-0.09-0.85
                    C87.41,83.54,87.28,83.45,87.08,83.45z"
				/>
				<path
					class="st3"
					d="M158.12,24.22c-0.18,0.04-0.31,0.1-0.38,0.19c-0.08,0.09-0.12,0.21-0.12,0.36c0,0.13,0.03,0.25,0.08,0.34
                    c0.05,0.09,0.14,0.14,0.26,0.14c0.06,0,0.12-0.01,0.19-0.03c0.06-0.02,0.12-0.05,0.18-0.09c0.05-0.04,0.1-0.1,0.13-0.17
                    c0.03-0.07,0.05-0.15,0.05-0.24v-0.68c-0.06,0.05-0.12,0.08-0.19,0.11C158.23,24.19,158.17,24.21,158.12,24.22z"
				/>
				<path
					class="st3"
					d="M152.23,17.47c0-0.16-0.03-0.29-0.09-0.38c-0.06-0.09-0.15-0.17-0.28-0.24v1.13c0.13-0.03,0.23-0.09,0.28-0.19
                    C152.2,17.7,152.23,17.59,152.23,17.47z"
				/>
				<path
					class="st3"
					d="M90.07,24.22c-0.18,0.04-0.31,0.1-0.38,0.19c-0.08,0.09-0.12,0.21-0.12,0.36c0,0.13,0.03,0.25,0.08,0.34
                    c0.05,0.09,0.14,0.14,0.26,0.14c0.06,0,0.12-0.01,0.19-0.03c0.06-0.02,0.12-0.05,0.18-0.09c0.05-0.04,0.1-0.1,0.13-0.17
                    c0.03-0.07,0.05-0.15,0.05-0.24v-0.68c-0.06,0.05-0.12,0.08-0.19,0.11C90.18,24.19,90.12,24.21,90.07,24.22z"
				/>
				<path
					class="st3"
					d="M151.45,14.72c-0.21,0.06-0.31,0.22-0.31,0.49c0,0.24,0.1,0.42,0.31,0.54V14.72z"
				/>
				<path
					class="st3"
					d="M147.94,25.18c0.2,0,0.32-0.09,0.38-0.28c0.06-0.19,0.09-0.47,0.09-0.85c0-0.38-0.03-0.66-0.09-0.85
                    c-0.06-0.19-0.18-0.28-0.38-0.28c-0.19,0-0.32,0.1-0.37,0.28c-0.06,0.19-0.09,0.47-0.09,0.85c0,0.38,0.03,0.66,0.09,0.85
                    C147.62,25.09,147.75,25.18,147.94,25.18z"
				/>
				<path
					class="st3"
					d="M173.99,24.92c0.03,0.11,0.08,0.19,0.14,0.25c0.06,0.06,0.15,0.09,0.26,0.09c0.18,0,0.3-0.09,0.37-0.28
                    c0.07-0.19,0.1-0.5,0.1-0.93c0-0.43-0.03-0.74-0.1-0.93c-0.07-0.19-0.19-0.28-0.37-0.28c-0.11,0-0.19,0.03-0.26,0.09
                    c-0.06,0.06-0.11,0.14-0.14,0.25s-0.05,0.23-0.06,0.38c-0.01,0.15-0.01,0.31-0.01,0.49c0,0.17,0,0.34,0.01,0.49
                    C173.93,24.68,173.95,24.81,173.99,24.92z"
				/>
				<path
					class="st3"
					d="M163.62,23.31c-0.01-0.1-0.04-0.19-0.07-0.26c-0.03-0.07-0.08-0.12-0.13-0.16c-0.06-0.04-0.13-0.05-0.22-0.05
                    c-0.09,0-0.16,0.02-0.22,0.06c-0.06,0.04-0.1,0.09-0.14,0.16c-0.03,0.07-0.06,0.14-0.07,0.22c-0.01,0.08-0.02,0.16-0.02,0.24v0.13
                    h0.9C163.64,23.53,163.63,23.41,163.62,23.31z"
				/>
				<path
					class="st3"
					d="M171.69,17.79c0.04,0.1,0.09,0.18,0.15,0.21c0.06,0.04,0.14,0.06,0.24,0.06c0.1,0,0.19-0.02,0.25-0.07
                    c0.06-0.05,0.11-0.13,0.15-0.25c0.04-0.12,0.06-0.27,0.08-0.47c0.01-0.2,0.02-0.44,0.02-0.73v-0.49c0-0.29-0.01-0.52-0.02-0.7
                    c-0.02-0.18-0.04-0.32-0.08-0.43c-0.04-0.1-0.09-0.18-0.15-0.21c-0.06-0.04-0.15-0.06-0.24-0.06c-0.1,0-0.19,0.02-0.25,0.07
                    c-0.06,0.05-0.11,0.13-0.15,0.25c-0.04,0.12-0.06,0.27-0.08,0.47c-0.02,0.2-0.02,0.44-0.02,0.73v0.49c0,0.29,0.01,0.52,0.02,0.7
                    C171.63,17.54,171.65,17.68,171.69,17.79z"
				/>
				<path
					class="st3"
					d="M145.29,23.31c-0.01-0.1-0.04-0.19-0.07-0.26c-0.03-0.07-0.08-0.12-0.13-0.16c-0.06-0.04-0.13-0.05-0.22-0.05
                    c-0.09,0-0.16,0.02-0.22,0.06c-0.06,0.04-0.1,0.09-0.14,0.16c-0.03,0.07-0.06,0.14-0.07,0.22c-0.01,0.08-0.02,0.16-0.02,0.24v0.13
                    h0.9C145.31,23.53,145.3,23.41,145.29,23.31z"
				/>
				<path
					class="st3"
					d="M168.55,17.79c0.04,0.1,0.09,0.18,0.15,0.21c0.06,0.04,0.15,0.06,0.24,0.06c0.1,0,0.19-0.02,0.25-0.07
                    c0.06-0.05,0.11-0.13,0.15-0.25c0.04-0.12,0.06-0.27,0.08-0.47c0.01-0.2,0.02-0.44,0.02-0.73v-0.49c0-0.29-0.01-0.52-0.02-0.7
                    c-0.02-0.18-0.04-0.32-0.08-0.43c-0.04-0.1-0.09-0.18-0.15-0.21c-0.06-0.04-0.15-0.06-0.24-0.06c-0.1,0-0.19,0.02-0.25,0.07
                    c-0.06,0.05-0.11,0.13-0.15,0.25c-0.04,0.12-0.06,0.27-0.08,0.47c-0.02,0.2-0.02,0.44-0.02,0.73v0.49c0,0.29,0.01,0.52,0.02,0.7
                    C168.49,17.54,168.52,17.68,168.55,17.79z"
				/>
				<path
					class="st3"
					d="M165.42,17.79c0.04,0.1,0.09,0.18,0.15,0.21c0.06,0.04,0.14,0.06,0.24,0.06c0.1,0,0.19-0.02,0.25-0.07
                    c0.06-0.05,0.11-0.13,0.15-0.25c0.04-0.12,0.06-0.27,0.08-0.47c0.01-0.2,0.02-0.44,0.02-0.73v-0.49c0-0.29-0.01-0.52-0.02-0.7
                    c-0.02-0.18-0.04-0.32-0.08-0.43c-0.04-0.1-0.09-0.18-0.15-0.21c-0.06-0.04-0.15-0.06-0.24-0.06c-0.1,0-0.19,0.02-0.25,0.07
                    c-0.06,0.05-0.11,0.13-0.15,0.25c-0.04,0.12-0.06,0.27-0.08,0.47c-0.02,0.2-0.02,0.44-0.02,0.73v0.49c0,0.29,0.01,0.52,0.02,0.7
                    C165.35,17.54,165.38,17.68,165.42,17.79z"
				/>
				<path
					class="st3"
					d="M160.68,17.79c0.04,0.1,0.09,0.18,0.15,0.21c0.06,0.04,0.14,0.06,0.24,0.06c0.1,0,0.19-0.02,0.25-0.07
                    c0.06-0.05,0.11-0.13,0.15-0.25c0.04-0.12,0.06-0.27,0.08-0.47c0.01-0.2,0.02-0.44,0.02-0.73v-0.49c0-0.29-0.01-0.52-0.02-0.7
                    c-0.02-0.18-0.04-0.32-0.08-0.43c-0.04-0.1-0.09-0.18-0.15-0.21c-0.06-0.04-0.15-0.06-0.24-0.06c-0.1,0-0.19,0.02-0.25,0.07
                    c-0.06,0.05-0.11,0.13-0.15,0.25c-0.04,0.12-0.06,0.27-0.08,0.47c-0.02,0.2-0.02,0.44-0.02,0.73v0.49c0,0.29,0.01,0.52,0.02,0.7
                    C160.62,17.54,160.64,17.68,160.68,17.79z"
				/>
				<path
					class="st3"
					d="M106.96,23.31c-0.01-0.1-0.04-0.19-0.07-0.26s-0.08-0.12-0.13-0.16s-0.13-0.05-0.22-0.05
                    c-0.09,0-0.16,0.02-0.22,0.06c-0.06,0.04-0.1,0.09-0.14,0.16s-0.06,0.14-0.07,0.22c-0.02,0.08-0.02,0.16-0.02,0.24v0.13h0.9
                    C106.98,23.53,106.98,23.41,106.96,23.31z"
				/>
				<path
					class="st3"
					d="M119.2,17.98c0.2,0,0.32-0.09,0.38-0.28c0.06-0.19,0.09-0.47,0.09-0.85c0-0.38-0.03-0.66-0.09-0.85
                    c-0.06-0.19-0.18-0.28-0.38-0.28c-0.19,0-0.32,0.1-0.37,0.28c-0.06,0.19-0.09,0.47-0.09,0.85c0,0.38,0.03,0.66,0.09,0.85
                    C118.88,17.89,119.01,17.98,119.2,17.98z"
				/>
				<path
					class="st3"
					d="M116.54,16.11c-0.01-0.1-0.04-0.19-0.07-0.26c-0.03-0.07-0.08-0.12-0.13-0.16c-0.06-0.04-0.13-0.05-0.22-0.05
                    c-0.09,0-0.16,0.02-0.22,0.06c-0.06,0.04-0.1,0.09-0.14,0.16c-0.03,0.07-0.06,0.14-0.07,0.22c-0.01,0.08-0.02,0.16-0.02,0.24v0.13
                    h0.9C116.56,16.33,116.56,16.21,116.54,16.11z"
				/>
				<path
					class="st3"
					d="M96.51,25.18c0.2,0,0.32-0.09,0.38-0.28c0.06-0.19,0.09-0.47,0.09-0.85c0-0.38-0.03-0.66-0.09-0.85
                    s-0.18-0.28-0.38-0.28c-0.19,0-0.32,0.1-0.37,0.28s-0.09,0.47-0.09,0.85c0,0.38,0.03,0.66,0.09,0.85
                    C96.19,25.09,96.31,25.18,96.51,25.18z"
				/>
				<path
					class="st3"
					d="M141.89,18.06c0.06,0,0.12-0.01,0.19-0.03c0.06-0.02,0.12-0.05,0.18-0.09c0.05-0.04,0.1-0.1,0.13-0.17
                    c0.03-0.07,0.05-0.15,0.05-0.24v-0.68c-0.06,0.05-0.12,0.08-0.19,0.11c-0.07,0.03-0.13,0.05-0.19,0.06
                    c-0.18,0.04-0.31,0.1-0.38,0.19c-0.08,0.09-0.12,0.21-0.12,0.36c0,0.13,0.03,0.25,0.08,0.34
                    C141.68,18.01,141.77,18.06,141.89,18.06z"
				/>
				<path
					class="st3"
					d="M91.9,18.06c0.06,0,0.12-0.01,0.19-0.03c0.06-0.02,0.12-0.05,0.18-0.09c0.05-0.04,0.1-0.1,0.13-0.17
                    c0.03-0.07,0.05-0.15,0.05-0.24v-0.68c-0.06,0.05-0.12,0.08-0.19,0.11c-0.07,0.03-0.13,0.05-0.19,0.06
                    c-0.18,0.04-0.31,0.1-0.38,0.19c-0.08,0.09-0.12,0.21-0.12,0.36c0,0.13,0.03,0.25,0.08,0.34C91.69,18.01,91.78,18.06,91.9,18.06z"
				/>
				<path
					class="st3"
					d="M125.6,17.72c0.03,0.11,0.08,0.19,0.14,0.25s0.15,0.09,0.26,0.09c0.18,0,0.3-0.09,0.37-0.28
                    c0.07-0.19,0.1-0.5,0.1-0.93c0-0.43-0.03-0.74-0.1-0.93c-0.07-0.19-0.19-0.28-0.37-0.28c-0.11,0-0.19,0.03-0.26,0.09
                    c-0.06,0.06-0.11,0.14-0.14,0.25s-0.05,0.23-0.06,0.38c-0.01,0.15-0.01,0.31-0.01,0.49c0,0.17,0,0.34,0.01,0.49
                    C125.55,17.48,125.57,17.61,125.6,17.72z"
				/>
				<path
					class="st3"
					d="M117.62,24.92c0.03,0.11,0.08,0.19,0.14,0.25c0.06,0.06,0.15,0.09,0.26,0.09c0.18,0,0.3-0.09,0.37-0.28
                    c0.07-0.19,0.1-0.5,0.1-0.93c0-0.43-0.03-0.74-0.1-0.93c-0.07-0.19-0.19-0.28-0.37-0.28c-0.11,0-0.19,0.03-0.26,0.09
                    c-0.06,0.06-0.11,0.14-0.14,0.25s-0.05,0.23-0.06,0.38c-0.01,0.15-0.01,0.31-0.01,0.49c0,0.17,0,0.34,0.01,0.49
                    C117.57,24.68,117.59,24.81,117.62,24.92z"
				/>
				<path
					class="st3"
					d="M139.43,16.11c-0.01-0.1-0.04-0.19-0.07-0.26c-0.03-0.07-0.08-0.12-0.13-0.16c-0.06-0.04-0.13-0.05-0.22-0.05
                    c-0.09,0-0.16,0.02-0.22,0.06c-0.06,0.04-0.1,0.09-0.14,0.16c-0.03,0.07-0.06,0.14-0.07,0.22c-0.01,0.08-0.02,0.16-0.02,0.24v0.13
                    h0.9C139.45,16.33,139.44,16.21,139.43,16.11z"
				/>
				<path
					class="st3"
					d="M130.56,18.06c0.06,0,0.12-0.01,0.19-0.03c0.06-0.02,0.12-0.05,0.18-0.09c0.05-0.04,0.1-0.1,0.13-0.17
                    c0.03-0.07,0.05-0.15,0.05-0.24v-0.68c-0.06,0.05-0.12,0.08-0.19,0.11c-0.07,0.03-0.13,0.05-0.19,0.06
                    c-0.18,0.04-0.31,0.1-0.38,0.19c-0.08,0.09-0.12,0.21-0.12,0.36c0,0.13,0.03,0.25,0.08,0.34
                    C130.35,18.01,130.44,18.06,130.56,18.06z"
				/>
				<path
					class="st3"
					d="M130.33,23.31c-0.01-0.1-0.04-0.19-0.07-0.26c-0.03-0.07-0.08-0.12-0.13-0.16c-0.06-0.04-0.13-0.05-0.22-0.05
                    c-0.09,0-0.16,0.02-0.22,0.06c-0.06,0.04-0.1,0.09-0.14,0.16c-0.03,0.07-0.06,0.14-0.07,0.22c-0.01,0.08-0.02,0.16-0.02,0.24v0.13
                    h0.9C130.35,23.53,130.34,23.41,130.33,23.31z"
				/>
				<path
					class="st3"
					d="M194.15,23.05c-0.03-0.07-0.08-0.12-0.13-0.16c-0.06-0.04-0.13-0.05-0.22-0.05c-0.09,0-0.16,0.02-0.22,0.06
                    c-0.06,0.04-0.1,0.09-0.14,0.16c-0.03,0.07-0.06,0.14-0.07,0.22c-0.01,0.08-0.02,0.16-0.02,0.24v0.13h0.9
                    c0-0.13-0.01-0.25-0.03-0.35C194.21,23.21,194.18,23.12,194.15,23.05z"
				/>
				<polygon class="st3" points="160.4,46.84 156.51,58.84 164.29,58.84 160.5,46.84 	" />
				<polygon class="st3" points="173.94,82.53 173.5,84.58 174.4,84.58 173.95,82.53 	" />
				<path
					class="st3"
					d="M164.42,83.59c-0.03-0.07-0.08-0.12-0.13-0.16c-0.06-0.04-0.13-0.05-0.22-0.05c-0.09,0-0.16,0.02-0.22,0.06
                    s-0.1,0.09-0.14,0.16c-0.03,0.07-0.06,0.14-0.07,0.22c-0.01,0.08-0.02,0.16-0.02,0.24v0.13h0.9c0-0.13-0.01-0.25-0.03-0.35
                    C164.47,83.74,164.45,83.66,164.42,83.59z"
				/>
				<path
					class="st3"
					d="M144.65,83.37c-0.11,0-0.19,0.03-0.26,0.09c-0.06,0.06-0.11,0.14-0.14,0.25c-0.03,0.11-0.05,0.24-0.06,0.38
                    c-0.01,0.15-0.01,0.31-0.01,0.49c0,0.18,0,0.34,0.01,0.49c0.01,0.15,0.03,0.28,0.06,0.38s0.08,0.19,0.14,0.25
                    c0.06,0.06,0.15,0.09,0.26,0.09c0.18,0,0.3-0.09,0.37-0.28c0.07-0.19,0.1-0.5,0.1-0.93c0-0.43-0.03-0.74-0.1-0.93
                    C144.95,83.47,144.83,83.37,144.65,83.37z"
				/>
				<path
					class="st3"
					d="M177.36,83.45c-0.1,0-0.18,0.02-0.24,0.06c-0.06,0.04-0.11,0.11-0.14,0.21c-0.03,0.1-0.06,0.22-0.07,0.38
                    c-0.01,0.16-0.02,0.36-0.02,0.59c0,0.12,0.01,0.23,0.02,0.34c0.01,0.11,0.03,0.2,0.06,0.28c0.03,0.08,0.08,0.15,0.13,0.2
                    c0.06,0.05,0.13,0.07,0.23,0.07s0.17-0.02,0.23-0.07c0.06-0.05,0.11-0.12,0.15-0.2c0.04-0.09,0.06-0.19,0.08-0.31
                    c0.02-0.12,0.02-0.25,0.02-0.4c0-0.42-0.03-0.71-0.1-0.89C177.65,83.54,177.53,83.45,177.36,83.45z"
				/>
				<path
					class="st3"
					d="M180.91,83.59c-0.03-0.07-0.08-0.12-0.13-0.16c-0.06-0.04-0.13-0.05-0.22-0.05c-0.09,0-0.16,0.02-0.22,0.06
                    c-0.06,0.04-0.1,0.09-0.14,0.16c-0.03,0.07-0.06,0.14-0.07,0.22c-0.01,0.08-0.02,0.16-0.02,0.24v0.13h0.9
                    c0-0.13-0.01-0.25-0.03-0.35C180.97,83.74,180.95,83.66,180.91,83.59z"
				/>
				<path class="st3" d="M0,0v100.8h216V0H0z M208.4,93.2H7.95V8H208.4V93.2z" />
				<path
					class="st3"
					d="M206,10.4H10.35v80.4H206V10.4z M174.39,22.24c0.26,0,0.48,0.03,0.66,0.11c0.17,0.07,0.31,0.19,0.42,0.34
                    c0.1,0.15,0.17,0.34,0.22,0.57c0.04,0.23,0.06,0.49,0.06,0.79c0,0.29-0.02,0.55-0.07,0.78c-0.05,0.23-0.12,0.42-0.23,0.57
                    c-0.11,0.15-0.25,0.26-0.42,0.34c-0.17,0.07-0.38,0.11-0.64,0.11c-0.26,0-0.48-0.03-0.66-0.1c-0.17-0.07-0.31-0.18-0.42-0.33
                    c-0.1-0.15-0.17-0.34-0.22-0.57c-0.04-0.23-0.06-0.49-0.06-0.79c0-0.29,0.02-0.55,0.07-0.78c0.05-0.23,0.12-0.42,0.23-0.57
                    c0.11-0.15,0.25-0.27,0.42-0.35C173.92,22.28,174.13,22.24,174.39,22.24z M170.7,15.55c0.02-0.29,0.08-0.55,0.17-0.77
                    c0.09-0.22,0.24-0.4,0.43-0.54c0.19-0.13,0.45-0.2,0.79-0.2c0.28,0,0.51,0.04,0.69,0.12c0.18,0.08,0.33,0.21,0.44,0.38
                    c0.11,0.18,0.19,0.4,0.23,0.68c0.04,0.28,0.07,0.62,0.07,1.02c0,0.32-0.01,0.62-0.03,0.91c-0.02,0.29-0.08,0.55-0.17,0.77
                    s-0.24,0.4-0.43,0.53c-0.19,0.13-0.45,0.19-0.79,0.19c-0.27,0-0.5-0.04-0.68-0.11c-0.18-0.07-0.33-0.2-0.44-0.37
                    c-0.11-0.17-0.19-0.4-0.23-0.68c-0.04-0.28-0.07-0.62-0.07-1.02C170.67,16.14,170.68,15.84,170.7,15.55z M171.82,25.35
                    c-0.11,0.18-0.24,0.31-0.4,0.38c-0.16,0.07-0.34,0.11-0.56,0.11c-0.28,0-0.51-0.05-0.69-0.15c-0.18-0.1-0.32-0.25-0.42-0.45
                    c-0.1-0.2-0.17-0.46-0.2-0.76c-0.03-0.3-0.05-0.65-0.05-1.05c0-0.38,0.02-0.72,0.07-1.01c0.05-0.29,0.13-0.53,0.25-0.73
                    c0.12-0.19,0.28-0.34,0.47-0.44c0.2-0.1,0.44-0.15,0.74-0.15c0.5,0,0.87,0.12,1.09,0.37c0.22,0.25,0.33,0.61,0.33,1.07h-0.88
                    c0-0.09-0.01-0.17-0.03-0.26c-0.02-0.09-0.05-0.17-0.09-0.25c-0.04-0.07-0.1-0.14-0.16-0.19c-0.07-0.05-0.15-0.07-0.24-0.07
                    c-0.22,0-0.38,0.12-0.48,0.37c-0.1,0.25-0.15,0.66-0.15,1.23c0,0.27,0.01,0.52,0.03,0.74c0.02,0.22,0.05,0.41,0.09,0.57
                    c0.04,0.16,0.11,0.28,0.19,0.36s0.19,0.12,0.32,0.12c0.06,0,0.12-0.01,0.18-0.04c0.07-0.03,0.13-0.07,0.19-0.13
                    c0.06-0.06,0.11-0.14,0.14-0.23c0.04-0.09,0.06-0.2,0.06-0.32v-0.47h-0.61v-0.68h1.49v2.46h-0.68v-0.42H171.82z M167.56,15.55
                    c0.02-0.29,0.08-0.55,0.17-0.77c0.09-0.22,0.24-0.4,0.43-0.54c0.19-0.13,0.45-0.2,0.79-0.2c0.28,0,0.51,0.04,0.69,0.12
                    c0.18,0.08,0.33,0.21,0.44,0.38c0.11,0.18,0.19,0.4,0.23,0.68c0.04,0.28,0.07,0.62,0.07,1.02c0,0.32-0.01,0.62-0.03,0.91
                    c-0.02,0.29-0.08,0.55-0.17,0.77s-0.24,0.4-0.43,0.53c-0.19,0.13-0.45,0.19-0.79,0.19c-0.27,0-0.5-0.04-0.68-0.11
                    c-0.18-0.07-0.33-0.2-0.44-0.37c-0.11-0.17-0.19-0.4-0.23-0.68c-0.04-0.28-0.07-0.62-0.07-1.02
                    C167.53,16.14,167.54,15.84,167.56,15.55z M165.82,23.44c0.05,0.07,0.15,0.13,0.3,0.17l0.53,0.18c0.28,0.09,0.47,0.22,0.59,0.37
                    c0.12,0.15,0.17,0.36,0.17,0.62c0,0.18-0.03,0.34-0.1,0.48c-0.06,0.13-0.15,0.25-0.27,0.34c-0.11,0.09-0.25,0.15-0.4,0.19
                    c-0.16,0.04-0.33,0.06-0.52,0.06c-0.25,0-0.45-0.02-0.62-0.07c-0.16-0.05-0.29-0.12-0.39-0.21c-0.1-0.09-0.16-0.21-0.2-0.34
                    c-0.04-0.13-0.06-0.28-0.06-0.45v-0.12h0.79v0.12c0,0.15,0.04,0.27,0.12,0.36c0.08,0.09,0.19,0.14,0.34,0.14
                    c0.14,0,0.25-0.03,0.33-0.1c0.08-0.07,0.12-0.17,0.12-0.31c0-0.11-0.03-0.2-0.1-0.25c-0.06-0.06-0.14-0.1-0.22-0.14l-0.62-0.22
                    c-0.24-0.09-0.43-0.21-0.55-0.36c-0.12-0.16-0.19-0.36-0.19-0.6c0-0.14,0.02-0.27,0.07-0.4c0.05-0.13,0.12-0.24,0.22-0.33
                    c0.1-0.09,0.23-0.17,0.39-0.22c0.16-0.06,0.35-0.08,0.58-0.08c0.4,0,0.7,0.09,0.89,0.26c0.19,0.17,0.29,0.41,0.29,0.72v0.14h-0.79
                    c0-0.18-0.03-0.31-0.09-0.39c-0.06-0.08-0.16-0.12-0.3-0.12c-0.11,0-0.21,0.03-0.29,0.09c-0.08,0.06-0.13,0.15-0.13,0.28
                    C165.74,23.3,165.77,23.37,165.82,23.44z M164.43,15.55c0.02-0.29,0.08-0.55,0.17-0.77c0.09-0.22,0.24-0.4,0.43-0.54
                    c0.19-0.13,0.45-0.2,0.79-0.2c0.28,0,0.51,0.04,0.69,0.12c0.18,0.08,0.33,0.21,0.44,0.38c0.11,0.18,0.19,0.4,0.23,0.68
                    c0.04,0.28,0.07,0.62,0.07,1.02c0,0.32-0.01,0.62-0.03,0.91c-0.02,0.29-0.08,0.55-0.17,0.77c-0.09,0.22-0.24,0.4-0.43,0.53
                    c-0.19,0.13-0.45,0.19-0.79,0.19c-0.27,0-0.5-0.04-0.68-0.11c-0.18-0.07-0.33-0.2-0.44-0.37c-0.11-0.17-0.19-0.4-0.23-0.68
                    c-0.04-0.28-0.07-0.62-0.07-1.02C164.39,16.14,164.4,15.84,164.43,15.55z M163.02,19.16c0.11-0.02,0.19-0.08,0.25-0.18
                    c0.06-0.1,0.08-0.21,0.08-0.33v-0.08h-0.33v-1h0.84v0.83c0,0.16-0.01,0.31-0.04,0.45s-0.07,0.27-0.13,0.37
                    c-0.06,0.1-0.15,0.19-0.26,0.26c-0.11,0.07-0.25,0.11-0.42,0.12V19.16z M163.23,22.24c0.26,0,0.47,0.04,0.63,0.12
                    c0.16,0.08,0.29,0.2,0.39,0.35c0.09,0.15,0.16,0.33,0.19,0.55c0.03,0.22,0.05,0.46,0.05,0.72v0.2h-1.75v0.28
                    c0,0.1,0.01,0.2,0.02,0.3c0.01,0.1,0.04,0.18,0.07,0.26c0.03,0.07,0.08,0.13,0.13,0.18c0.06,0.04,0.12,0.07,0.2,0.07
                    c0.15,0,0.26-0.05,0.33-0.16c0.07-0.11,0.12-0.27,0.14-0.49h0.79c-0.02,0.4-0.12,0.71-0.32,0.92c-0.2,0.21-0.51,0.31-0.93,0.31
                    c-0.32,0-0.57-0.05-0.75-0.16c-0.18-0.11-0.31-0.25-0.4-0.42c-0.09-0.17-0.14-0.37-0.16-0.59c-0.02-0.22-0.03-0.44-0.03-0.65
                    c0-0.23,0.02-0.45,0.05-0.67s0.1-0.4,0.19-0.57c0.1-0.17,0.23-0.3,0.41-0.4C162.69,22.29,162.93,22.24,163.23,22.24z M159.69,15.55
                    c0.02-0.29,0.08-0.55,0.17-0.77c0.09-0.22,0.24-0.4,0.43-0.54c0.19-0.13,0.45-0.2,0.79-0.2c0.28,0,0.51,0.04,0.69,0.12
                    c0.18,0.08,0.33,0.21,0.44,0.38c0.11,0.18,0.19,0.4,0.23,0.68c0.04,0.28,0.07,0.62,0.07,1.02c0,0.32-0.01,0.62-0.03,0.91
                    c-0.02,0.29-0.08,0.55-0.17,0.77c-0.09,0.22-0.24,0.4-0.43,0.53c-0.19,0.13-0.45,0.19-0.79,0.19c-0.27,0-0.5-0.04-0.68-0.11
                    c-0.18-0.07-0.33-0.2-0.44-0.37c-0.11-0.17-0.19-0.4-0.23-0.68c-0.04-0.28-0.07-0.62-0.07-1.02
                    C159.66,16.14,159.67,15.84,159.69,15.55z M161.17,25.08c0.04,0.06,0.12,0.09,0.25,0.09c0.03,0,0.07,0,0.1,0
                    c0.03,0,0.07-0.01,0.1-0.01v0.61c-0.1,0-0.19,0-0.28,0.01c-0.09,0.01-0.19,0.01-0.29,0.01c-0.17,0-0.31-0.01-0.42-0.04
                    c-0.11-0.02-0.19-0.07-0.25-0.14c-0.06-0.07-0.1-0.16-0.12-0.28c-0.02-0.12-0.03-0.26-0.03-0.44v-1.97h-0.45v-0.6h0.45v-0.99h0.88
                    v0.99h0.51v0.6h-0.51v1.86C161.11,24.93,161.13,25.02,161.17,25.08z M159.36,25.48c0.01,0.09,0.04,0.19,0.09,0.29h-0.84
                    c-0.03-0.06-0.05-0.11-0.07-0.18c-0.02-0.06-0.03-0.12-0.04-0.18h-0.01c-0.1,0.18-0.22,0.3-0.36,0.35
                    c-0.13,0.05-0.31,0.08-0.52,0.08c-0.15,0-0.28-0.03-0.39-0.08c-0.11-0.05-0.19-0.13-0.26-0.22c-0.06-0.09-0.11-0.2-0.14-0.32
                    c-0.03-0.12-0.04-0.23-0.04-0.35c0-0.16,0.02-0.3,0.05-0.42c0.03-0.12,0.09-0.22,0.16-0.3c0.07-0.08,0.16-0.15,0.27-0.21
                    c0.11-0.06,0.24-0.1,0.39-0.15l0.5-0.13c0.13-0.03,0.22-0.08,0.28-0.14c0.05-0.06,0.08-0.15,0.08-0.26c0-0.13-0.03-0.24-0.09-0.31
                    c-0.06-0.07-0.17-0.11-0.32-0.11c-0.14,0-0.24,0.04-0.31,0.12c-0.07,0.08-0.1,0.19-0.1,0.33v0.1h-0.79V23.3
                    c0-0.2,0.03-0.37,0.1-0.5c0.06-0.13,0.15-0.24,0.26-0.33c0.11-0.08,0.24-0.14,0.38-0.18c0.15-0.04,0.3-0.05,0.47-0.05
                    c0.26,0,0.48,0.03,0.64,0.08s0.29,0.12,0.38,0.22c0.09,0.09,0.15,0.21,0.18,0.34c0.03,0.13,0.05,0.27,0.05,0.43v1.81
                    C159.34,25.27,159.35,25.39,159.36,25.48z M156.52,17.28h0.88c0,0.08,0,0.16,0.01,0.24c0.01,0.09,0.03,0.17,0.06,0.24
                    c0.03,0.07,0.08,0.14,0.14,0.19c0.06,0.05,0.14,0.07,0.24,0.07c0.19,0,0.33-0.07,0.41-0.22c0.08-0.15,0.12-0.37,0.12-0.66
                    c0-0.19-0.01-0.34-0.03-0.47c-0.02-0.12-0.05-0.22-0.09-0.29c-0.04-0.07-0.09-0.12-0.16-0.15c-0.07-0.03-0.15-0.04-0.26-0.04
                    c-0.13,0-0.23,0.04-0.3,0.11c-0.07,0.07-0.11,0.18-0.13,0.3h-0.83l0.17-2.46h2.36v0.72h-1.67l-0.1,1.03h0.01
                    c0.09-0.12,0.2-0.21,0.33-0.26c0.14-0.05,0.3-0.08,0.5-0.08c0.22,0,0.4,0.04,0.54,0.12c0.14,0.08,0.26,0.19,0.34,0.33
                    c0.08,0.14,0.14,0.29,0.18,0.46c0.03,0.17,0.05,0.35,0.05,0.53c0,0.25-0.02,0.48-0.06,0.68c-0.04,0.2-0.12,0.38-0.23,0.53
                    c-0.11,0.15-0.26,0.26-0.44,0.33c-0.19,0.07-0.43,0.11-0.72,0.11c-0.29,0-0.52-0.04-0.69-0.11c-0.18-0.08-0.31-0.18-0.41-0.3
                    c-0.09-0.13-0.16-0.26-0.19-0.41s-0.05-0.28-0.05-0.41V17.28z M156.07,25.08c0.04,0.06,0.12,0.09,0.25,0.09c0.03,0,0.07,0,0.1,0
                    c0.03,0,0.07-0.01,0.1-0.01v0.61c-0.1,0-0.19,0-0.28,0.01c-0.09,0.01-0.19,0.01-0.29,0.01c-0.17,0-0.31-0.01-0.42-0.04
                    c-0.11-0.02-0.19-0.07-0.25-0.14c-0.06-0.07-0.1-0.16-0.12-0.28c-0.02-0.12-0.03-0.26-0.03-0.44v-1.97h-0.45v-0.6h0.45v-0.99h0.88
                    v0.99h0.51v0.6h-0.51v1.86C156.01,24.93,156.03,25.02,156.07,25.08z M154.78,14.64c-0.12,0-0.21,0.02-0.27,0.07
                    c-0.07,0.05-0.12,0.11-0.15,0.19c-0.04,0.08-0.06,0.17-0.07,0.28c-0.01,0.11-0.02,0.22-0.02,0.34h-0.88v-0.17
                    c0-0.42,0.11-0.75,0.34-0.97s0.58-0.34,1.07-0.34c0.46,0,0.8,0.11,1.02,0.32c0.22,0.21,0.33,0.52,0.33,0.92
                    c0,0.15-0.01,0.28-0.04,0.41c-0.03,0.13-0.07,0.25-0.12,0.36c-0.06,0.12-0.13,0.23-0.22,0.34c-0.09,0.11-0.2,0.22-0.33,0.34
                    l-0.51,0.47c-0.14,0.12-0.24,0.24-0.32,0.36c-0.08,0.12-0.13,0.22-0.15,0.3h1.71v0.72h-2.79c0-0.2,0.02-0.39,0.06-0.56
                    c0.04-0.17,0.1-0.34,0.19-0.5c0.08-0.16,0.19-0.31,0.31-0.46c0.13-0.15,0.27-0.3,0.44-0.46l0.37-0.35c0.1-0.09,0.18-0.17,0.25-0.25
                    c0.06-0.08,0.11-0.15,0.14-0.23c0.03-0.08,0.06-0.16,0.07-0.25c0.01-0.09,0.02-0.19,0.02-0.31
                    C155.23,14.83,155.08,14.64,154.78,14.64z M152.34,24.77c0.02,0.09,0.05,0.16,0.1,0.22c0.05,0.06,0.11,0.11,0.19,0.14
                    c0.08,0.03,0.18,0.05,0.3,0.05c0.14,0,0.27-0.05,0.38-0.14c0.11-0.09,0.17-0.23,0.17-0.43c0-0.1-0.01-0.19-0.04-0.27
                    c-0.03-0.07-0.07-0.14-0.14-0.2c-0.06-0.06-0.15-0.11-0.25-0.16c-0.1-0.05-0.23-0.1-0.39-0.15c-0.2-0.07-0.38-0.14-0.53-0.22
                    c-0.15-0.08-0.27-0.18-0.37-0.28c-0.1-0.11-0.17-0.23-0.22-0.38c-0.05-0.14-0.07-0.31-0.07-0.5c0-0.45,0.13-0.79,0.38-1.01
                    c0.25-0.22,0.6-0.33,1.04-0.33c0.2,0,0.39,0.02,0.57,0.07c0.17,0.04,0.32,0.12,0.45,0.22c0.13,0.1,0.22,0.23,0.29,0.38
                    c0.07,0.16,0.11,0.34,0.11,0.56v0.13h-0.88c0-0.22-0.04-0.38-0.12-0.5c-0.08-0.12-0.2-0.18-0.38-0.18c-0.1,0-0.19,0.02-0.26,0.04
                    c-0.07,0.03-0.12,0.07-0.16,0.12c-0.04,0.05-0.07,0.11-0.08,0.17c-0.01,0.06-0.02,0.13-0.02,0.2c0,0.14,0.03,0.26,0.09,0.36
                    c0.06,0.1,0.19,0.18,0.38,0.27l0.71,0.31c0.17,0.08,0.32,0.16,0.43,0.24c0.11,0.08,0.2,0.17,0.27,0.27c0.07,0.1,0.11,0.2,0.14,0.32
                    c0.03,0.12,0.04,0.24,0.04,0.38c0,0.48-0.14,0.83-0.42,1.05c-0.28,0.22-0.67,0.33-1.17,0.33c-0.52,0-0.89-0.11-1.12-0.34
                    c-0.22-0.23-0.34-0.55-0.34-0.97v-0.19h0.92v0.13C152.31,24.58,152.32,24.68,152.34,24.77z M150.19,17.21h0.88
                    c0,0.09,0,0.18,0.01,0.26c0.01,0.08,0.02,0.16,0.05,0.23c0.03,0.07,0.06,0.13,0.11,0.18c0.05,0.05,0.12,0.08,0.2,0.11v-1.29
                    l-0.32-0.12c-0.31-0.11-0.54-0.27-0.67-0.48c-0.14-0.21-0.2-0.46-0.2-0.75c0-0.4,0.1-0.7,0.31-0.92c0.21-0.22,0.5-0.34,0.89-0.39
                    V13.6h0.41v0.44c0.24,0.02,0.43,0.07,0.59,0.14c0.15,0.07,0.27,0.17,0.36,0.28c0.09,0.11,0.15,0.23,0.19,0.37
                    c0.03,0.14,0.05,0.28,0.05,0.42v0.09h-0.88c0-0.16-0.03-0.3-0.07-0.41c-0.04-0.11-0.12-0.18-0.23-0.21v1.21l0.51,0.2
                    c0.12,0.05,0.23,0.11,0.32,0.17c0.1,0.06,0.18,0.13,0.25,0.22c0.07,0.09,0.12,0.19,0.16,0.32c0.04,0.12,0.06,0.28,0.06,0.45
                    c0,0.46-0.11,0.79-0.33,1c-0.22,0.2-0.54,0.32-0.96,0.35v0.56h-0.41v-0.56c-0.26-0.02-0.48-0.07-0.64-0.14
                    c-0.16-0.07-0.29-0.16-0.38-0.28c-0.09-0.12-0.15-0.25-0.18-0.4c-0.03-0.15-0.05-0.31-0.05-0.47V17.21z M146.62,15.13h0.45v-0.99
                    h0.88v0.99h0.51v0.6h-0.51v1.86c0,0.14,0.02,0.23,0.06,0.29c0.04,0.06,0.12,0.09,0.25,0.09c0.03,0,0.07,0,0.1,0
                    c0.03,0,0.07-0.01,0.1-0.01v0.61c-0.1,0-0.19,0-0.28,0.01c-0.09,0.01-0.19,0.01-0.29,0.01c-0.17,0-0.31-0.01-0.42-0.04
                    c-0.11-0.02-0.19-0.07-0.25-0.14c-0.06-0.07-0.1-0.16-0.12-0.28s-0.03-0.26-0.03-0.44v-1.97h-0.45V15.13z M146.85,22.66
                    c0.17-0.28,0.44-0.43,0.8-0.43c0.17,0,0.31,0.03,0.43,0.1c0.12,0.07,0.22,0.17,0.32,0.31h0.01V21.2h0.88v4.57h-0.84V25.4h-0.01
                    c-0.1,0.16-0.21,0.28-0.33,0.34s-0.27,0.1-0.45,0.1c-0.36,0-0.63-0.14-0.8-0.42s-0.26-0.74-0.26-1.38
                    C146.6,23.41,146.68,22.95,146.85,22.66z M143.82,17.44h0.79v0.12c0,0.15,0.04,0.27,0.12,0.36c0.08,0.09,0.19,0.14,0.34,0.14
                    c0.14,0,0.25-0.03,0.33-0.1c0.08-0.07,0.12-0.17,0.12-0.31c0-0.11-0.03-0.2-0.1-0.25c-0.06-0.06-0.14-0.1-0.22-0.14l-0.62-0.22
                    c-0.24-0.09-0.43-0.21-0.55-0.36c-0.12-0.16-0.19-0.36-0.19-0.6c0-0.14,0.02-0.27,0.07-0.4c0.05-0.13,0.12-0.24,0.22-0.33
                    c0.1-0.09,0.23-0.17,0.39-0.22c0.16-0.06,0.35-0.08,0.58-0.08c0.4,0,0.7,0.09,0.89,0.26c0.19,0.17,0.29,0.41,0.29,0.72v0.14h-0.79
                    c0-0.18-0.03-0.31-0.09-0.39c-0.06-0.08-0.16-0.12-0.3-0.12c-0.11,0-0.21,0.03-0.29,0.09c-0.08,0.06-0.13,0.15-0.13,0.28
                    c0,0.09,0.03,0.16,0.08,0.23c0.05,0.07,0.15,0.13,0.3,0.17l0.53,0.18c0.28,0.09,0.47,0.22,0.59,0.37c0.12,0.15,0.17,0.36,0.17,0.62
                    c0,0.18-0.03,0.34-0.1,0.48s-0.15,0.25-0.27,0.34c-0.11,0.09-0.25,0.15-0.4,0.19c-0.16,0.04-0.33,0.06-0.52,0.06
                    c-0.25,0-0.45-0.02-0.62-0.07c-0.16-0.05-0.29-0.12-0.39-0.21c-0.1-0.09-0.16-0.21-0.2-0.34c-0.04-0.13-0.06-0.28-0.06-0.45V17.44z
                    M143.57,23.35c0.03-0.21,0.1-0.4,0.19-0.57c0.1-0.17,0.23-0.3,0.41-0.4c0.18-0.1,0.42-0.15,0.72-0.15c0.26,0,0.47,0.04,0.63,0.12
                    c0.16,0.08,0.29,0.2,0.39,0.35c0.09,0.15,0.16,0.33,0.19,0.55c0.03,0.22,0.05,0.46,0.05,0.72v0.2h-1.75v0.28
                    c0,0.1,0.01,0.2,0.02,0.3c0.01,0.1,0.04,0.18,0.07,0.26c0.03,0.07,0.08,0.13,0.13,0.18c0.06,0.04,0.12,0.07,0.2,0.07
                    c0.15,0,0.26-0.05,0.33-0.16c0.07-0.11,0.12-0.27,0.14-0.49h0.79c-0.02,0.4-0.12,0.71-0.32,0.92c-0.2,0.21-0.51,0.31-0.93,0.31
                    c-0.32,0-0.57-0.05-0.75-0.16c-0.18-0.11-0.31-0.25-0.4-0.42c-0.09-0.17-0.14-0.37-0.16-0.59c-0.02-0.22-0.03-0.44-0.03-0.65
                    C143.53,23.79,143.54,23.56,143.57,23.35z M140.76,17.25c0.03-0.12,0.09-0.22,0.16-0.3s0.16-0.15,0.27-0.21
                    c0.11-0.06,0.24-0.1,0.39-0.15l0.5-0.13c0.13-0.03,0.22-0.08,0.28-0.14c0.05-0.06,0.08-0.15,0.08-0.26c0-0.13-0.03-0.24-0.09-0.31
                    c-0.06-0.07-0.17-0.11-0.32-0.11c-0.14,0-0.24,0.04-0.31,0.12s-0.1,0.19-0.1,0.33v0.1h-0.79V16.1c0-0.2,0.03-0.37,0.1-0.5
                    s0.15-0.24,0.26-0.33c0.11-0.08,0.24-0.14,0.38-0.18c0.15-0.04,0.3-0.05,0.47-0.05c0.26,0,0.48,0.03,0.64,0.08
                    c0.16,0.05,0.29,0.12,0.38,0.22c0.09,0.09,0.15,0.21,0.18,0.34s0.05,0.27,0.05,0.43v1.81c0,0.16,0.01,0.29,0.02,0.38
                    c0.01,0.09,0.04,0.19,0.09,0.29h-0.84c-0.03-0.06-0.05-0.11-0.07-0.18c-0.02-0.06-0.03-0.12-0.04-0.18h-0.01
                    c-0.1,0.18-0.22,0.3-0.36,0.35c-0.13,0.05-0.31,0.08-0.52,0.08c-0.15,0-0.28-0.03-0.39-0.08c-0.11-0.05-0.19-0.13-0.26-0.22
                    c-0.06-0.09-0.11-0.2-0.14-0.32c-0.03-0.12-0.04-0.23-0.04-0.35C140.71,17.51,140.73,17.37,140.76,17.25z M119.75,37.33v20.64
                    c0,4.42,1.63,6.67,6,6.67c4.37,0,6-2.26,6-6.67V37.33h8.93v20.11c0,9.94-4.56,14.88-14.93,14.88s-14.93-4.94-14.93-14.88V37.33
                    H119.75z M111.65,25.77v-3.44h0.88v3.44H111.65z M114.81,22.33v0.6h-0.51v1.86c0,0.14,0.02,0.23,0.06,0.29
                    c0.04,0.06,0.12,0.09,0.25,0.09c0.03,0,0.07,0,0.1,0c0.03,0,0.07-0.01,0.1-0.01v0.61c-0.1,0-0.19,0-0.28,0.01
                    c-0.09,0.01-0.19,0.01-0.29,0.01c-0.17,0-0.31-0.01-0.42-0.04c-0.11-0.02-0.19-0.07-0.25-0.14c-0.06-0.07-0.1-0.16-0.12-0.28
                    c-0.02-0.12-0.03-0.26-0.03-0.44v-1.97h-0.45v-0.6h0.45v-0.99h0.88v0.99H114.81z M116.66,24.05c0-0.29,0.02-0.55,0.07-0.78
                    c0.05-0.23,0.12-0.42,0.23-0.57c0.11-0.15,0.25-0.27,0.42-0.35c0.17-0.08,0.39-0.12,0.64-0.12c0.26,0,0.48,0.03,0.66,0.11
                    c0.17,0.07,0.31,0.19,0.42,0.34c0.1,0.15,0.17,0.34,0.22,0.57c0.04,0.23,0.06,0.49,0.06,0.79c0,0.29-0.02,0.55-0.07,0.78
                    c-0.05,0.23-0.12,0.42-0.23,0.57c-0.11,0.15-0.25,0.26-0.42,0.34c-0.17,0.07-0.38,0.11-0.64,0.11c-0.26,0-0.48-0.03-0.66-0.1
                    c-0.17-0.07-0.31-0.18-0.42-0.33c-0.1-0.15-0.17-0.34-0.22-0.57C116.69,24.61,116.66,24.35,116.66,24.05z M137.69,22.73
                    c0.09-0.17,0.22-0.3,0.36-0.37c0.15-0.08,0.32-0.12,0.51-0.12c0.28,0,0.5,0.08,0.65,0.23c0.15,0.15,0.23,0.4,0.23,0.75v2.55h-0.88
                    V23.4c0-0.18-0.03-0.3-0.09-0.37c-0.06-0.07-0.16-0.11-0.29-0.11c-0.31,0-0.47,0.19-0.47,0.57v2.28h-0.88v-3.44h0.84v0.4H137.69z
                    M140.13,25.77v-3.44h0.88v3.44H140.13z M140.13,21.9v-0.76h0.88v0.76H140.13z M137.72,16.15c0.03-0.21,0.1-0.4,0.19-0.57
                    c0.1-0.17,0.23-0.3,0.41-0.4c0.18-0.1,0.42-0.15,0.72-0.15c0.26,0,0.47,0.04,0.63,0.12c0.16,0.08,0.29,0.2,0.39,0.35
                    c0.09,0.15,0.16,0.33,0.19,0.55c0.03,0.22,0.05,0.46,0.05,0.72v0.2h-1.75v0.28c0,0.1,0.01,0.2,0.02,0.3
                    c0.01,0.1,0.04,0.18,0.07,0.26c0.03,0.07,0.08,0.13,0.13,0.18c0.06,0.04,0.12,0.07,0.2,0.07c0.15,0,0.26-0.05,0.33-0.16
                    c0.07-0.11,0.12-0.27,0.14-0.49h0.79c-0.02,0.4-0.12,0.71-0.32,0.92c-0.2,0.21-0.51,0.31-0.93,0.31c-0.32,0-0.57-0.05-0.75-0.16
                    c-0.18-0.11-0.31-0.25-0.4-0.42c-0.09-0.17-0.14-0.37-0.16-0.59c-0.02-0.22-0.03-0.44-0.03-0.65
                    C137.67,16.59,137.68,16.36,137.72,16.15z M136.23,14h0.88v4.57h-0.88V14z M134.32,24.95c0.04,0.07,0.1,0.13,0.17,0.17
                    c0.07,0.04,0.16,0.06,0.27,0.06c0.11,0,0.2-0.02,0.27-0.06c0.07-0.04,0.12-0.1,0.16-0.17c0.04-0.07,0.07-0.15,0.08-0.25
                    c0.01-0.1,0.02-0.2,0.02-0.32V21.2h0.92v3.19c0,0.29-0.04,0.53-0.12,0.72c-0.08,0.19-0.19,0.34-0.33,0.45
                    c-0.14,0.11-0.29,0.19-0.47,0.22c-0.17,0.04-0.36,0.06-0.54,0.06c-0.19,0-0.37-0.02-0.54-0.05c-0.17-0.03-0.33-0.1-0.47-0.21
                    c-0.14-0.1-0.25-0.25-0.33-0.44c-0.08-0.19-0.12-0.44-0.12-0.75V21.2h0.92v3.19c0,0.12,0.01,0.22,0.02,0.32
                    C134.25,24.8,134.28,24.88,134.32,24.95z M132.38,15.13h0.45v-0.99h0.88v0.99h0.51v0.6h-0.51v1.86c0,0.14,0.02,0.23,0.06,0.29
                    c0.04,0.06,0.12,0.09,0.25,0.09c0.03,0,0.07,0,0.1,0c0.03,0,0.07-0.01,0.1-0.01v0.61c-0.1,0-0.19,0-0.28,0.01
                    c-0.09,0.01-0.19,0.01-0.29,0.01c-0.17,0-0.31-0.01-0.42-0.04c-0.11-0.02-0.19-0.07-0.25-0.14c-0.06-0.07-0.1-0.16-0.12-0.28
                    s-0.03-0.26-0.03-0.44v-1.97h-0.45V15.13z M129.43,17.25c0.03-0.12,0.09-0.22,0.16-0.3c0.07-0.08,0.16-0.15,0.27-0.21
                    c0.11-0.06,0.24-0.1,0.39-0.15l0.5-0.13c0.13-0.03,0.22-0.08,0.28-0.14c0.05-0.06,0.08-0.15,0.08-0.26c0-0.13-0.03-0.24-0.09-0.31
                    c-0.06-0.07-0.17-0.11-0.32-0.11c-0.14,0-0.24,0.04-0.31,0.12s-0.1,0.19-0.1,0.33v0.1h-0.79V16.1c0-0.2,0.03-0.37,0.1-0.5
                    c0.06-0.13,0.15-0.24,0.26-0.33c0.11-0.08,0.24-0.14,0.38-0.18c0.15-0.04,0.3-0.05,0.47-0.05c0.26,0,0.48,0.03,0.64,0.08
                    c0.16,0.05,0.29,0.12,0.38,0.22c0.09,0.09,0.15,0.21,0.18,0.34c0.03,0.13,0.05,0.27,0.05,0.43v1.81c0,0.16,0.01,0.29,0.02,0.38
                    c0.01,0.09,0.04,0.19,0.09,0.29h-0.84c-0.03-0.06-0.05-0.11-0.07-0.18c-0.02-0.06-0.03-0.12-0.04-0.18h-0.01
                    c-0.1,0.18-0.22,0.3-0.36,0.35c-0.13,0.05-0.31,0.08-0.52,0.08c-0.15,0-0.28-0.03-0.39-0.08c-0.11-0.05-0.19-0.13-0.26-0.22
                    c-0.06-0.09-0.11-0.2-0.14-0.32c-0.03-0.12-0.04-0.23-0.04-0.35C129.38,17.51,129.4,17.37,129.43,17.25z M128.62,23.35
                    c0.03-0.21,0.1-0.4,0.19-0.57c0.1-0.17,0.23-0.3,0.41-0.4c0.18-0.1,0.42-0.15,0.72-0.15c0.26,0,0.47,0.04,0.63,0.12
                    c0.16,0.08,0.29,0.2,0.39,0.35c0.09,0.15,0.16,0.33,0.19,0.55c0.03,0.22,0.05,0.46,0.05,0.72v0.2h-1.75v0.28
                    c0,0.1,0.01,0.2,0.02,0.3c0.01,0.1,0.04,0.18,0.07,0.26c0.03,0.07,0.08,0.13,0.13,0.18c0.06,0.04,0.12,0.07,0.2,0.07
                    c0.15,0,0.26-0.05,0.33-0.16c0.07-0.11,0.12-0.27,0.14-0.49h0.79c-0.02,0.4-0.12,0.71-0.32,0.92c-0.2,0.21-0.51,0.31-0.93,0.31
                    c-0.32,0-0.57-0.05-0.75-0.16c-0.18-0.11-0.31-0.25-0.4-0.42c-0.09-0.17-0.14-0.37-0.16-0.59s-0.03-0.44-0.03-0.65
                    C128.57,23.79,128.58,23.56,128.62,23.35z M128.04,23.22v2.55h-0.88V23.4c0-0.18-0.03-0.3-0.09-0.37
                    c-0.06-0.07-0.16-0.11-0.29-0.11c-0.31,0-0.47,0.19-0.47,0.57v2.28h-0.88V21.2h0.88v1.48h0.01c0.17-0.29,0.45-0.44,0.84-0.44
                    c0.28,0,0.5,0.08,0.65,0.23C127.97,22.62,128.04,22.87,128.04,23.22z M124.72,16.07c0.05-0.23,0.12-0.42,0.23-0.57
                    c0.11-0.15,0.25-0.27,0.42-0.35c0.17-0.08,0.39-0.12,0.64-0.12c0.26,0,0.48,0.03,0.66,0.11c0.17,0.07,0.31,0.19,0.42,0.34
                    c0.1,0.15,0.17,0.34,0.22,0.57c0.04,0.23,0.06,0.49,0.06,0.79c0,0.29-0.02,0.55-0.07,0.78s-0.12,0.42-0.23,0.57
                    c-0.11,0.15-0.25,0.26-0.42,0.34c-0.17,0.07-0.38,0.11-0.64,0.11c-0.26,0-0.48-0.03-0.66-0.1c-0.17-0.07-0.31-0.18-0.42-0.33
                    c-0.1-0.15-0.17-0.34-0.22-0.57c-0.04-0.23-0.06-0.49-0.06-0.79C124.65,16.55,124.67,16.29,124.72,16.07z M125.06,22.33v0.6h-0.51
                    v1.86c0,0.14,0.02,0.23,0.06,0.29c0.04,0.06,0.12,0.09,0.25,0.09c0.03,0,0.07,0,0.1,0c0.03,0,0.07-0.01,0.1-0.01v0.61
                    c-0.1,0-0.19,0-0.28,0.01c-0.09,0.01-0.19,0.01-0.29,0.01c-0.17,0-0.31-0.01-0.42-0.04c-0.11-0.02-0.19-0.07-0.25-0.14
                    c-0.06-0.07-0.1-0.16-0.12-0.28c-0.02-0.12-0.03-0.26-0.03-0.44v-1.97h-0.45v-0.6h0.45v-0.99h0.88v0.99H125.06z M122.55,15.13h0.45
                    v-0.99h0.88v0.99h0.51v0.6h-0.51v1.86c0,0.14,0.02,0.23,0.06,0.29c0.04,0.06,0.12,0.09,0.25,0.09c0.03,0,0.07,0,0.1,0
                    c0.03,0,0.07-0.01,0.1-0.01v0.61c-0.1,0-0.19,0-0.28,0.01c-0.09,0.01-0.19,0.01-0.29,0.01c-0.17,0-0.31-0.01-0.42-0.04
                    c-0.11-0.02-0.19-0.07-0.25-0.14c-0.06-0.07-0.1-0.16-0.12-0.28s-0.03-0.26-0.03-0.44v-1.97h-0.45V15.13z M121.03,21.11
                    c0.16,0,0.32,0.01,0.47,0.02v0.63c-0.02,0-0.05-0.01-0.09-0.01h-0.08c-0.12,0-0.21,0.02-0.26,0.06c-0.05,0.04-0.08,0.11-0.08,0.21
                    v0.3h0.51v0.6h-0.51v2.84h-0.88v-2.84h-0.45v-0.6h0.45v-0.39c0-0.15,0.02-0.28,0.06-0.39c0.04-0.11,0.1-0.19,0.18-0.26
                    c0.08-0.06,0.18-0.11,0.29-0.14C120.76,21.12,120.89,21.11,121.03,21.11z M118.11,15.46c0.17-0.28,0.44-0.43,0.8-0.43
                    c0.17,0,0.31,0.03,0.43,0.1c0.12,0.07,0.22,0.17,0.32,0.31h0.01V14h0.88v4.57h-0.84V18.2h-0.01c-0.1,0.16-0.21,0.28-0.33,0.34
                    c-0.12,0.06-0.27,0.1-0.45,0.1c-0.36,0-0.63-0.14-0.8-0.42c-0.17-0.28-0.26-0.74-0.26-1.38C117.86,16.21,117.94,15.75,118.11,15.46
                    z M114.83,16.15c0.03-0.21,0.1-0.4,0.19-0.57c0.1-0.17,0.23-0.3,0.41-0.4c0.18-0.1,0.42-0.15,0.72-0.15c0.26,0,0.47,0.04,0.63,0.12
                    c0.16,0.08,0.29,0.2,0.39,0.35c0.09,0.15,0.16,0.33,0.19,0.55c0.03,0.22,0.05,0.46,0.05,0.72v0.2h-1.75v0.28
                    c0,0.1,0.01,0.2,0.02,0.3c0.01,0.1,0.04,0.18,0.07,0.26c0.03,0.07,0.08,0.13,0.13,0.18c0.06,0.04,0.12,0.07,0.2,0.07
                    c0.15,0,0.26-0.05,0.33-0.16c0.07-0.11,0.12-0.27,0.14-0.49h0.79c-0.02,0.4-0.12,0.71-0.32,0.92c-0.2,0.21-0.51,0.31-0.93,0.31
                    c-0.32,0-0.57-0.05-0.75-0.16c-0.18-0.11-0.31-0.25-0.4-0.42c-0.09-0.17-0.14-0.37-0.16-0.59c-0.02-0.22-0.03-0.44-0.03-0.65
                    C114.78,16.59,114.8,16.36,114.83,16.15z M112.72,15.13h0.84v0.46h0.01c0.09-0.17,0.2-0.3,0.33-0.4c0.13-0.1,0.28-0.15,0.47-0.15
                    c0.03,0,0.05,0,0.08,0c0.03,0,0.05,0.01,0.08,0.01v0.84c-0.05,0-0.09-0.01-0.14-0.01c-0.04,0-0.09,0-0.14,0
                    c-0.08,0-0.15,0.01-0.23,0.03c-0.08,0.02-0.15,0.06-0.21,0.11c-0.06,0.05-0.12,0.11-0.15,0.19s-0.06,0.18-0.06,0.29v2.07h-0.88
                    V15.13z M112.54,21.9h-0.88v-0.76h0.88V21.9z M109.45,15.13h0.88v2.37c0,0.18,0.03,0.3,0.09,0.37c0.06,0.07,0.16,0.11,0.29,0.11
                    c0.31,0,0.47-0.19,0.47-0.57v-2.28h0.88v3.44h-0.84v-0.4h-0.01c-0.09,0.17-0.22,0.29-0.36,0.36c-0.15,0.07-0.32,0.11-0.51,0.11
                    c-0.28,0-0.5-0.07-0.65-0.22c-0.15-0.15-0.23-0.39-0.23-0.74V15.13z M106.4,17.44h0.79v0.12c0,0.15,0.04,0.27,0.12,0.36
                    c0.08,0.09,0.19,0.14,0.34,0.14c0.14,0,0.25-0.03,0.33-0.1c0.08-0.07,0.12-0.17,0.12-0.31c0-0.11-0.03-0.2-0.1-0.25
                    c-0.06-0.06-0.14-0.1-0.22-0.14l-0.62-0.22c-0.24-0.09-0.43-0.21-0.55-0.36c-0.12-0.16-0.19-0.36-0.19-0.6
                    c0-0.14,0.02-0.27,0.07-0.4c0.05-0.13,0.12-0.24,0.22-0.33c0.1-0.09,0.23-0.17,0.39-0.22c0.16-0.06,0.35-0.08,0.58-0.08
                    c0.4,0,0.7,0.09,0.89,0.26c0.19,0.17,0.29,0.41,0.29,0.72v0.14h-0.79c0-0.18-0.03-0.31-0.09-0.39c-0.06-0.08-0.16-0.12-0.3-0.12
                    c-0.11,0-0.21,0.03-0.29,0.09c-0.08,0.06-0.12,0.15-0.12,0.28c0,0.09,0.03,0.16,0.08,0.23c0.05,0.07,0.15,0.13,0.3,0.17l0.53,0.18
                    c0.28,0.09,0.47,0.22,0.59,0.37c0.12,0.15,0.17,0.36,0.17,0.62c0,0.18-0.03,0.34-0.1,0.48c-0.06,0.13-0.15,0.25-0.27,0.34
                    c-0.11,0.09-0.25,0.15-0.4,0.19c-0.16,0.04-0.33,0.06-0.52,0.06c-0.25,0-0.45-0.02-0.62-0.07c-0.16-0.05-0.29-0.12-0.39-0.21
                    c-0.1-0.09-0.16-0.21-0.2-0.34c-0.04-0.13-0.06-0.28-0.06-0.45V17.44z M106.11,24.76c0.01,0.1,0.04,0.18,0.07,0.26
                    c0.03,0.07,0.08,0.13,0.13,0.18c0.06,0.04,0.12,0.07,0.2,0.07c0.15,0,0.26-0.05,0.33-0.16c0.07-0.11,0.12-0.27,0.14-0.49h0.79
                    c-0.02,0.4-0.12,0.71-0.32,0.92s-0.51,0.31-0.93,0.31c-0.32,0-0.57-0.05-0.75-0.16c-0.18-0.11-0.31-0.25-0.4-0.42
                    c-0.09-0.17-0.14-0.37-0.16-0.59c-0.02-0.22-0.03-0.44-0.03-0.65c0-0.23,0.02-0.45,0.05-0.67s0.1-0.4,0.19-0.57
                    c0.1-0.17,0.23-0.3,0.41-0.4c0.18-0.1,0.42-0.15,0.72-0.15c0.26,0,0.47,0.04,0.63,0.12c0.16,0.08,0.29,0.2,0.39,0.35
                    c0.09,0.15,0.16,0.33,0.19,0.55c0.03,0.22,0.05,0.46,0.05,0.72v0.2h-1.75v0.28C106.09,24.56,106.09,24.66,106.11,24.76z
                    M90.47,64.65c4.18,0,6.58-2.4,6.91-6.34h8.93c-0.48,8.83-6.96,14.02-15.65,14.02c-10.37,0-17.09-7.92-17.09-17.86
                    c0-9.94,6.72-17.86,17.09-17.86c7.39,0,15.41,4.7,15.65,12.96h-8.93c-0.48-3.46-3.17-5.28-6.91-5.28c-5.76,0-7.97,5.09-7.97,10.18
                    C82.5,59.56,84.71,64.65,90.47,64.65z M81.33,22.33v3.44h-0.88v-3.44H81.33z M80.45,21.9v-0.76h0.88v0.76H80.45z M84.87,22.68
                    c0.17-0.29,0.45-0.44,0.84-0.44c0.28,0,0.5,0.08,0.65,0.23c0.15,0.15,0.23,0.4,0.23,0.75v2.55H85.7V23.4c0-0.18-0.03-0.3-0.09-0.37
                    c-0.06-0.07-0.16-0.11-0.29-0.11c-0.31,0-0.47,0.19-0.47,0.57v2.28h-0.88V21.2h0.88v1.48H84.87z M103.28,15.13h0.84v0.4h0.01
                    c0.09-0.17,0.22-0.3,0.36-0.37c0.15-0.08,0.32-0.12,0.51-0.12c0.28,0,0.5,0.08,0.65,0.23c0.15,0.15,0.23,0.4,0.23,0.75v2.55h-0.88
                    V16.2c0-0.18-0.03-0.3-0.09-0.37c-0.06-0.07-0.16-0.11-0.29-0.11c-0.31,0-0.47,0.19-0.47,0.57v2.28h-0.88V15.13z M103.14,22.33
                    h0.84v0.46h0.01c0.09-0.17,0.2-0.3,0.33-0.4c0.13-0.1,0.28-0.15,0.47-0.15c0.03,0,0.05,0,0.08,0c0.03,0,0.05,0.01,0.08,0.01v0.84
                    c-0.05,0-0.09-0.01-0.14-0.01c-0.04,0-0.09,0-0.14,0c-0.08,0-0.15,0.01-0.23,0.03c-0.08,0.02-0.15,0.06-0.21,0.11
                    c-0.06,0.05-0.12,0.11-0.15,0.19c-0.04,0.08-0.06,0.18-0.06,0.29v2.07h-0.88V22.33z M101.71,13.95h0.88v0.76h-0.88V13.95z
                    M101.71,15.13h0.88v3.44h-0.88V15.13z M100.08,23.17c0.07-0.23,0.16-0.42,0.29-0.56c0.12-0.14,0.27-0.24,0.44-0.29
                    c0.17-0.06,0.36-0.08,0.56-0.08c0.41,0,0.72,0.12,0.92,0.36c0.21,0.24,0.31,0.56,0.31,0.97h-0.84c0-0.09-0.01-0.17-0.02-0.25
                    c-0.01-0.08-0.03-0.15-0.06-0.22c-0.03-0.07-0.07-0.12-0.12-0.16c-0.05-0.04-0.12-0.06-0.2-0.06c-0.11,0-0.19,0.02-0.26,0.07
                    c-0.07,0.05-0.12,0.12-0.15,0.22c-0.03,0.1-0.06,0.22-0.07,0.36c-0.01,0.15-0.02,0.32-0.02,0.52c0,0.21,0.01,0.4,0.02,0.55
                    c0.01,0.15,0.04,0.28,0.07,0.37c0.03,0.1,0.08,0.17,0.14,0.21c0.06,0.04,0.14,0.07,0.24,0.07c0.15,0,0.26-0.06,0.33-0.19
                    c0.07-0.13,0.1-0.33,0.1-0.61h0.84c0,0.45-0.1,0.8-0.3,1.03c-0.2,0.24-0.54,0.36-1.01,0.36c-0.22,0-0.42-0.03-0.58-0.08
                    c-0.16-0.05-0.3-0.15-0.41-0.29c-0.11-0.14-0.19-0.32-0.25-0.56c-0.06-0.23-0.08-0.52-0.08-0.87
                    C99.98,23.69,100.01,23.4,100.08,23.17z M97.84,15.13l0.51,2.38h0.01l0.45-2.38h0.88l-0.83,3.21c-0.08,0.29-0.15,0.52-0.22,0.69
                    c-0.07,0.18-0.15,0.31-0.25,0.41c-0.1,0.1-0.21,0.16-0.35,0.19c-0.13,0.03-0.3,0.04-0.51,0.04h-0.36v-0.66
                    c0.08,0.01,0.15,0.02,0.23,0.02c0.16,0,0.28-0.04,0.35-0.13c0.07-0.09,0.11-0.2,0.12-0.34l-0.97-3.44H97.84z M95.61,14h0.88v4.57
                    h-0.88V14z M95.42,22.66c0.17-0.28,0.44-0.43,0.8-0.43c0.17,0,0.31,0.03,0.43,0.1c0.12,0.07,0.22,0.17,0.32,0.31h0.01V21.2h0.88
                    v4.57h-0.84V25.4H97c-0.1,0.16-0.21,0.28-0.33,0.34c-0.12,0.06-0.27,0.1-0.45,0.1c-0.36,0-0.63-0.14-0.8-0.42s-0.26-0.74-0.26-1.38
                    C95.16,23.41,95.25,22.95,95.42,22.66z M94.01,14h0.88v4.57h-0.88V14z M94.36,22.47c0.15,0.15,0.23,0.4,0.23,0.75v2.55h-0.88V23.4
                    c0-0.18-0.03-0.3-0.09-0.37c-0.06-0.07-0.16-0.11-0.29-0.11c-0.31,0-0.47,0.19-0.47,0.57v2.28h-0.88v-3.44h0.84v0.4h0.01
                    c0.09-0.17,0.22-0.3,0.36-0.37c0.15-0.08,0.32-0.12,0.51-0.12C94,22.24,94.21,22.31,94.36,22.47z M90.77,17.25
                    c0.03-0.12,0.09-0.22,0.16-0.3c0.07-0.08,0.16-0.15,0.27-0.21c0.11-0.06,0.24-0.1,0.39-0.15l0.5-0.13
                    c0.13-0.03,0.22-0.08,0.28-0.14c0.05-0.06,0.08-0.15,0.08-0.26c0-0.13-0.03-0.24-0.09-0.31c-0.06-0.07-0.17-0.11-0.32-0.11
                    c-0.14,0-0.24,0.04-0.31,0.12c-0.07,0.08-0.1,0.19-0.1,0.33v0.1h-0.79V16.1c0-0.2,0.03-0.37,0.1-0.5c0.06-0.13,0.15-0.24,0.26-0.33
                    c0.11-0.08,0.24-0.14,0.38-0.18c0.15-0.04,0.3-0.05,0.47-0.05c0.26,0,0.48,0.03,0.64,0.08c0.16,0.05,0.29,0.12,0.38,0.22
                    c0.09,0.09,0.15,0.21,0.18,0.34c0.03,0.13,0.05,0.27,0.05,0.43v1.81c0,0.16,0.01,0.29,0.02,0.38c0.01,0.09,0.04,0.19,0.09,0.29
                    h-0.84c-0.03-0.06-0.05-0.11-0.07-0.18c-0.02-0.06-0.03-0.12-0.04-0.18h-0.01c-0.1,0.18-0.22,0.3-0.36,0.35
                    c-0.13,0.05-0.31,0.08-0.52,0.08c-0.15,0-0.28-0.03-0.39-0.08c-0.11-0.05-0.19-0.13-0.26-0.22c-0.06-0.09-0.11-0.2-0.14-0.32
                    c-0.03-0.12-0.04-0.23-0.04-0.35C90.72,17.51,90.74,17.37,90.77,17.25z M91.06,22.53c0.09,0.09,0.15,0.21,0.18,0.34
                    s0.05,0.27,0.05,0.43v1.81c0,0.16,0.01,0.29,0.02,0.38c0.01,0.09,0.04,0.19,0.09,0.29h-0.84c-0.03-0.06-0.05-0.11-0.07-0.18
                    c-0.02-0.06-0.03-0.12-0.04-0.18h-0.01c-0.1,0.18-0.22,0.3-0.36,0.35c-0.13,0.05-0.31,0.08-0.52,0.08c-0.15,0-0.28-0.03-0.39-0.08
                    c-0.11-0.05-0.19-0.13-0.26-0.22c-0.06-0.09-0.11-0.2-0.14-0.32c-0.03-0.12-0.04-0.23-0.04-0.35c0-0.16,0.02-0.3,0.05-0.42
                    c0.03-0.12,0.09-0.22,0.16-0.3C89,24.07,89.09,24,89.2,23.95c0.11-0.06,0.24-0.1,0.39-0.15l0.5-0.13c0.13-0.03,0.22-0.08,0.28-0.14
                    c0.05-0.06,0.08-0.15,0.08-0.26c0-0.13-0.03-0.24-0.09-0.31c-0.06-0.07-0.17-0.11-0.32-0.11c-0.14,0-0.24,0.04-0.31,0.12
                    c-0.07,0.08-0.1,0.19-0.1,0.33v0.1h-0.79V23.3c0-0.2,0.03-0.37,0.1-0.5c0.06-0.13,0.15-0.24,0.26-0.33
                    c0.11-0.08,0.24-0.14,0.38-0.18c0.15-0.04,0.3-0.05,0.47-0.05c0.26,0,0.48,0.03,0.64,0.08C90.84,22.36,90.97,22.44,91.06,22.53z
                    M88.64,15.13h0.84v0.46h0.01c0.09-0.17,0.2-0.3,0.33-0.4c0.13-0.1,0.28-0.15,0.47-0.15c0.03,0,0.05,0,0.08,0
                    c0.03,0,0.05,0.01,0.08,0.01v0.84c-0.05,0-0.09-0.01-0.14-0.01c-0.04,0-0.09,0-0.14,0c-0.08,0-0.15,0.01-0.23,0.03
                    c-0.08,0.02-0.15,0.06-0.21,0.11c-0.06,0.05-0.12,0.11-0.15,0.19c-0.04,0.08-0.06,0.18-0.06,0.29v2.07h-0.88V15.13z M85.53,16.15
                    c0.03-0.21,0.1-0.4,0.19-0.57c0.1-0.17,0.23-0.3,0.41-0.4c0.18-0.1,0.42-0.15,0.72-0.15c0.26,0,0.47,0.04,0.63,0.12
                    c0.16,0.08,0.29,0.2,0.39,0.35c0.09,0.15,0.16,0.33,0.19,0.55c0.03,0.22,0.05,0.46,0.05,0.72v0.2h-1.75v0.28
                    c0,0.1,0.01,0.2,0.02,0.3c0.01,0.1,0.04,0.18,0.07,0.26c0.03,0.07,0.08,0.13,0.13,0.18c0.06,0.04,0.12,0.07,0.2,0.07
                    c0.15,0,0.26-0.05,0.33-0.16c0.07-0.11,0.12-0.27,0.14-0.49h0.79c-0.02,0.4-0.12,0.71-0.32,0.92c-0.2,0.21-0.51,0.31-0.93,0.31
                    c-0.32,0-0.57-0.05-0.75-0.16c-0.18-0.11-0.31-0.25-0.4-0.42s-0.14-0.37-0.16-0.59c-0.02-0.22-0.03-0.44-0.03-0.65
                    C85.48,16.59,85.5,16.36,85.53,16.15z M82.52,15.46c0.17-0.28,0.44-0.43,0.8-0.43c0.17,0,0.31,0.03,0.43,0.1
                    c0.12,0.07,0.22,0.17,0.32,0.31h0.01V14h0.88v4.57h-0.84V18.2H84.1c-0.1,0.16-0.21,0.28-0.33,0.34c-0.12,0.06-0.27,0.1-0.45,0.1
                    c-0.36,0-0.63-0.14-0.8-0.42c-0.17-0.28-0.26-0.74-0.26-1.38C82.26,16.21,82.35,15.75,82.52,15.46z M82.21,21.34h0.88v0.99h0.51
                    v0.6h-0.51v1.86c0,0.14,0.02,0.23,0.06,0.29c0.04,0.06,0.12,0.09,0.25,0.09c0.03,0,0.07,0,0.1,0c0.03,0,0.07-0.01,0.1-0.01v0.61
                    c-0.1,0-0.19,0-0.28,0.01c-0.09,0.01-0.19,0.01-0.29,0.01c-0.17,0-0.31-0.01-0.42-0.04s-0.19-0.07-0.25-0.14
                    c-0.06-0.07-0.1-0.16-0.12-0.28c-0.02-0.12-0.03-0.26-0.03-0.44v-1.97h-0.45v-0.6h0.45V21.34z M79.24,16.15
                    c0.03-0.21,0.1-0.4,0.19-0.57c0.1-0.17,0.23-0.3,0.41-0.4c0.18-0.1,0.42-0.15,0.72-0.15c0.26,0,0.47,0.04,0.63,0.12
                    c0.16,0.08,0.29,0.2,0.39,0.35c0.09,0.15,0.16,0.33,0.19,0.55c0.03,0.22,0.05,0.46,0.05,0.72v0.2h-1.75v0.28
                    c0,0.1,0.01,0.2,0.02,0.3c0.01,0.1,0.04,0.18,0.07,0.26c0.03,0.07,0.08,0.13,0.13,0.18c0.06,0.04,0.12,0.07,0.2,0.07
                    c0.15,0,0.26-0.05,0.33-0.16c0.07-0.11,0.12-0.27,0.14-0.49h0.79c-0.02,0.4-0.12,0.71-0.32,0.92c-0.2,0.21-0.51,0.31-0.93,0.31
                    c-0.32,0-0.57-0.05-0.75-0.16c-0.18-0.11-0.31-0.25-0.4-0.42c-0.09-0.17-0.14-0.37-0.16-0.59c-0.02-0.22-0.03-0.44-0.03-0.65
                    C79.19,16.59,79.21,16.36,79.24,16.15z M79.49,22.53c0.09,0.09,0.15,0.21,0.18,0.34s0.05,0.27,0.05,0.43v1.81
                    c0,0.16,0.01,0.29,0.02,0.38c0.01,0.09,0.04,0.19,0.09,0.29h-0.84c-0.03-0.06-0.05-0.11-0.07-0.18c-0.02-0.06-0.03-0.12-0.04-0.18
                    h-0.01c-0.1,0.18-0.22,0.3-0.36,0.35c-0.13,0.05-0.31,0.08-0.52,0.08c-0.15,0-0.28-0.03-0.39-0.08c-0.11-0.05-0.19-0.13-0.26-0.22
                    c-0.06-0.09-0.11-0.2-0.14-0.32c-0.03-0.12-0.04-0.23-0.04-0.35c0-0.16,0.02-0.3,0.05-0.42c0.03-0.12,0.09-0.22,0.16-0.3
                    c0.07-0.08,0.16-0.15,0.27-0.21c0.11-0.06,0.24-0.1,0.39-0.15l0.5-0.13c0.13-0.03,0.22-0.08,0.28-0.14
                    c0.05-0.06,0.08-0.15,0.08-0.26c0-0.13-0.03-0.24-0.09-0.31c-0.06-0.07-0.17-0.11-0.32-0.11c-0.14,0-0.24,0.04-0.31,0.12
                    c-0.07,0.08-0.1,0.19-0.1,0.33v0.1h-0.79V23.3c0-0.2,0.03-0.37,0.1-0.5c0.06-0.13,0.15-0.24,0.26-0.33
                    c0.11-0.08,0.24-0.14,0.38-0.18c0.15-0.04,0.3-0.05,0.47-0.05c0.26,0,0.48,0.03,0.64,0.08C79.28,22.36,79.4,22.44,79.49,22.53z
                    M77.11,15.13h0.45v-0.39c0-0.15,0.02-0.28,0.06-0.39c0.04-0.11,0.1-0.19,0.18-0.26c0.08-0.06,0.18-0.11,0.29-0.14
                    c0.12-0.03,0.24-0.04,0.38-0.04c0.16,0,0.32,0.01,0.47,0.02v0.63c-0.02,0-0.05-0.01-0.09-0.01h-0.08c-0.12,0-0.21,0.02-0.26,0.06
                    c-0.05,0.04-0.08,0.11-0.08,0.21v0.3h0.51v0.6h-0.51v2.84h-0.88v-2.84h-0.45V15.13z M75.51,21.94c0-0.15,0.02-0.28,0.06-0.39
                    c0.04-0.11,0.1-0.19,0.18-0.26c0.08-0.06,0.18-0.11,0.29-0.14c0.12-0.03,0.24-0.04,0.38-0.04c0.16,0,0.32,0.01,0.47,0.02v0.63
                    c-0.02,0-0.05-0.01-0.09-0.01h-0.08c-0.12,0-0.21,0.02-0.26,0.06c-0.05,0.04-0.08,0.11-0.08,0.21v0.3h0.51v0.6h-0.51v2.84h-0.88
                    v-2.84h-0.45v-0.6h0.45V21.94z M72.71,17.44h0.79v0.12c0,0.15,0.04,0.27,0.12,0.36c0.08,0.09,0.19,0.14,0.34,0.14
                    c0.14,0,0.25-0.03,0.33-0.1c0.08-0.07,0.12-0.17,0.12-0.31c0-0.11-0.03-0.2-0.1-0.25c-0.06-0.06-0.14-0.1-0.22-0.14l-0.62-0.22
                    c-0.24-0.09-0.43-0.21-0.55-0.36c-0.12-0.16-0.19-0.36-0.19-0.6c0-0.14,0.02-0.27,0.07-0.4c0.05-0.13,0.12-0.24,0.22-0.33
                    c0.1-0.09,0.23-0.17,0.39-0.22s0.35-0.08,0.58-0.08c0.4,0,0.7,0.09,0.89,0.26s0.29,0.41,0.29,0.72v0.14h-0.79
                    c0-0.18-0.03-0.31-0.09-0.39c-0.06-0.08-0.16-0.12-0.3-0.12c-0.11,0-0.21,0.03-0.29,0.09c-0.08,0.06-0.12,0.15-0.12,0.28
                    c0,0.09,0.03,0.16,0.08,0.23c0.05,0.07,0.15,0.13,0.3,0.17l0.53,0.18c0.28,0.09,0.47,0.22,0.59,0.37c0.12,0.15,0.17,0.36,0.17,0.62
                    c0,0.18-0.03,0.34-0.1,0.48c-0.06,0.13-0.15,0.25-0.27,0.34c-0.11,0.09-0.25,0.15-0.4,0.19c-0.16,0.04-0.33,0.06-0.52,0.06
                    c-0.25,0-0.45-0.02-0.62-0.07c-0.16-0.05-0.29-0.12-0.39-0.21c-0.1-0.09-0.16-0.21-0.2-0.34c-0.04-0.13-0.06-0.28-0.06-0.45V17.44z
                    M73.03,21.2v4.57h-0.88V21.2H73.03z M69.56,16.18c0.02-0.21,0.07-0.4,0.15-0.57c0.07-0.17,0.18-0.31,0.33-0.41
                    c0.14-0.11,0.33-0.16,0.57-0.16c0.12,0,0.25,0.03,0.38,0.1c0.13,0.07,0.24,0.19,0.33,0.37h0.01v-0.38h0.84v3.27
                    c0,0.44-0.11,0.78-0.33,1c-0.22,0.22-0.59,0.33-1.1,0.33c-0.38,0-0.66-0.08-0.87-0.24c-0.2-0.16-0.3-0.39-0.3-0.68h0.84
                    c0,0.11,0.04,0.2,0.13,0.26c0.04,0.03,0.09,0.05,0.14,0.07c0.05,0.02,0.1,0.03,0.16,0.03c0.18,0,0.31-0.06,0.38-0.17
                    c0.07-0.12,0.11-0.26,0.11-0.43v-0.45h-0.01c-0.09,0.13-0.2,0.23-0.33,0.31c-0.13,0.08-0.27,0.12-0.41,0.12
                    c-0.35,0-0.61-0.13-0.79-0.4c-0.18-0.27-0.27-0.71-0.27-1.34C69.52,16.59,69.53,16.39,69.56,16.18z M71.43,21.2v4.57h-0.88V21.2
                    H71.43z M66.33,15.13h0.84v0.4h0.01c0.09-0.17,0.22-0.3,0.36-0.37c0.15-0.08,0.32-0.12,0.51-0.12c0.28,0,0.5,0.08,0.65,0.23
                    c0.15,0.15,0.23,0.4,0.23,0.75v2.55h-0.88V16.2c0-0.18-0.03-0.3-0.09-0.37c-0.06-0.07-0.16-0.11-0.29-0.11
                    c-0.31,0-0.47,0.19-0.47,0.57v2.28h-0.88V15.13z M66.02,21.15c0.12-0.03,0.24-0.04,0.38-0.04c0.16,0,0.32,0.01,0.47,0.02v0.63
                    c-0.02,0-0.05-0.01-0.09-0.01h-0.08c-0.12,0-0.21,0.02-0.26,0.06c-0.05,0.04-0.08,0.11-0.08,0.21v0.3h0.51v0.6h-0.51v2.84h-0.88
                    v-2.84h-0.45v-0.6h0.45v-0.39c0-0.15,0.02-0.28,0.06-0.39c0.04-0.11,0.1-0.19,0.18-0.26C65.81,21.23,65.91,21.18,66.02,21.15z
                    M64.76,13.95h0.88v0.76h-0.88V13.95z M64.76,15.13h0.88v3.44h-0.88V15.13z M62.48,15.13l0.49,2.46h0.01l0.47-2.46h0.88l-0.86,3.44
                    h-1.02l-0.89-3.44H62.48z M61.44,24.76c0.01,0.1,0.04,0.18,0.07,0.26c0.03,0.07,0.08,0.13,0.13,0.18c0.06,0.04,0.12,0.07,0.2,0.07
                    c0.15,0,0.26-0.05,0.33-0.16c0.07-0.11,0.12-0.27,0.14-0.49h0.79c-0.02,0.4-0.12,0.71-0.32,0.92s-0.51,0.31-0.93,0.31
                    c-0.32,0-0.57-0.05-0.75-0.16c-0.18-0.11-0.31-0.25-0.4-0.42c-0.09-0.17-0.14-0.37-0.16-0.59c-0.02-0.22-0.03-0.44-0.03-0.65
                    c0-0.23,0.02-0.45,0.05-0.67s0.1-0.4,0.19-0.57c0.1-0.17,0.23-0.3,0.41-0.4c0.18-0.1,0.42-0.15,0.72-0.15
                    c0.26,0,0.47,0.04,0.63,0.12c0.16,0.08,0.29,0.2,0.39,0.35c0.09,0.15,0.16,0.33,0.19,0.55c0.03,0.22,0.05,0.46,0.05,0.72v0.2h-1.75
                    v0.28C61.42,24.56,61.43,24.66,61.44,24.76z M58.62,17.25c0.03-0.12,0.09-0.22,0.16-0.3c0.07-0.08,0.16-0.15,0.27-0.21
                    c0.11-0.06,0.24-0.1,0.39-0.15l0.5-0.13c0.13-0.03,0.22-0.08,0.28-0.14c0.05-0.06,0.08-0.15,0.08-0.26c0-0.13-0.03-0.24-0.09-0.31
                    c-0.06-0.07-0.17-0.11-0.32-0.11c-0.14,0-0.24,0.04-0.31,0.12c-0.07,0.08-0.1,0.19-0.1,0.33v0.1h-0.79V16.1
                    c0-0.2,0.03-0.37,0.1-0.5c0.06-0.13,0.15-0.24,0.26-0.33c0.11-0.08,0.24-0.14,0.38-0.18c0.15-0.04,0.3-0.05,0.47-0.05
                    c0.26,0,0.48,0.03,0.64,0.08c0.16,0.05,0.29,0.12,0.38,0.22c0.09,0.09,0.15,0.21,0.18,0.34s0.05,0.27,0.05,0.43v1.81
                    c0,0.16,0.01,0.29,0.02,0.38c0.01,0.09,0.04,0.19,0.09,0.29H60.4c-0.03-0.06-0.05-0.11-0.07-0.18c-0.02-0.06-0.03-0.12-0.04-0.18
                    h-0.01c-0.1,0.18-0.22,0.3-0.36,0.35c-0.13,0.05-0.31,0.08-0.52,0.08c-0.15,0-0.28-0.03-0.39-0.08c-0.11-0.05-0.19-0.13-0.26-0.22
                    c-0.06-0.09-0.11-0.2-0.14-0.32c-0.03-0.12-0.04-0.23-0.04-0.35C58.57,17.51,58.58,17.37,58.62,17.25z M58.3,22.68
                    c0.17-0.29,0.45-0.44,0.84-0.44c0.28,0,0.5,0.08,0.65,0.23c0.15,0.15,0.23,0.4,0.23,0.75v2.55h-0.88V23.4
                    c0-0.18-0.03-0.3-0.09-0.37c-0.06-0.07-0.16-0.11-0.29-0.11c-0.31,0-0.47,0.19-0.47,0.57v2.28H57.4V21.2h0.88v1.48H58.3z
                    M55.63,17.44h0.79v0.12c0,0.15,0.04,0.27,0.12,0.36c0.08,0.09,0.19,0.14,0.34,0.14c0.14,0,0.25-0.03,0.33-0.1
                    c0.08-0.07,0.12-0.17,0.12-0.31c0-0.11-0.03-0.2-0.1-0.25c-0.06-0.06-0.14-0.1-0.22-0.14l-0.62-0.22
                    c-0.24-0.09-0.43-0.21-0.55-0.36c-0.12-0.16-0.19-0.36-0.19-0.6c0-0.14,0.02-0.27,0.07-0.4c0.05-0.13,0.12-0.24,0.22-0.33
                    c0.1-0.09,0.23-0.17,0.39-0.22s0.35-0.08,0.58-0.08c0.4,0,0.7,0.09,0.89,0.26c0.19,0.17,0.29,0.41,0.29,0.72v0.14h-0.79
                    c0-0.18-0.03-0.31-0.09-0.39c-0.06-0.08-0.16-0.12-0.3-0.12c-0.11,0-0.21,0.03-0.29,0.09c-0.08,0.06-0.12,0.15-0.12,0.28
                    c0,0.09,0.03,0.16,0.08,0.23c0.05,0.07,0.15,0.13,0.3,0.17l0.53,0.18c0.28,0.09,0.47,0.22,0.59,0.37c0.12,0.15,0.17,0.36,0.17,0.62
                    c0,0.18-0.03,0.34-0.1,0.48c-0.06,0.13-0.15,0.25-0.27,0.34c-0.11,0.09-0.25,0.15-0.4,0.19c-0.16,0.04-0.33,0.06-0.52,0.06
                    c-0.25,0-0.45-0.02-0.62-0.07c-0.16-0.05-0.29-0.12-0.39-0.21c-0.1-0.09-0.16-0.21-0.2-0.34c-0.04-0.13-0.06-0.28-0.06-0.45V17.44z
                    M55.19,22.33h0.45v-0.99h0.88v0.99h0.51v0.6h-0.51v1.86c0,0.14,0.02,0.23,0.06,0.29c0.04,0.06,0.12,0.09,0.25,0.09
                    c0.03,0,0.07,0,0.1,0c0.03,0,0.07-0.01,0.1-0.01v0.61c-0.1,0-0.19,0-0.28,0.01c-0.09,0.01-0.19,0.01-0.29,0.01
                    c-0.17,0-0.31-0.01-0.42-0.04c-0.11-0.02-0.19-0.07-0.25-0.14c-0.06-0.07-0.1-0.16-0.12-0.28c-0.02-0.12-0.03-0.26-0.03-0.44v-1.97
                    h-0.45V22.33z M51.98,15.13h0.84v0.46h0.01c0.09-0.17,0.2-0.3,0.33-0.4c0.13-0.1,0.28-0.15,0.47-0.15c0.03,0,0.05,0,0.08,0
                    c0.03,0,0.05,0.01,0.08,0.01v0.84c-0.05,0-0.09-0.01-0.14-0.01c-0.04,0-0.09,0-0.14,0c-0.08,0-0.15,0.01-0.23,0.03
                    c-0.08,0.02-0.15,0.06-0.21,0.11c-0.06,0.05-0.12,0.11-0.15,0.19c-0.04,0.08-0.06,0.18-0.06,0.29v2.07h-0.88V15.13z M52.11,24.71
                    h0.01l0.45-2.38h0.88l-0.83,3.21c-0.08,0.29-0.15,0.52-0.22,0.69c-0.07,0.18-0.15,0.31-0.25,0.41c-0.1,0.1-0.21,0.16-0.35,0.19
                    c-0.13,0.03-0.3,0.04-0.51,0.04h-0.36v-0.66c0.08,0.01,0.15,0.02,0.23,0.02c0.16,0,0.28-0.04,0.35-0.13
                    c0.07-0.09,0.11-0.2,0.12-0.34l-0.97-3.44h0.92L52.11,24.71z M48.72,15.13h0.88v2.37c0,0.18,0.03,0.3,0.09,0.37
                    c0.06,0.07,0.16,0.11,0.29,0.11c0.31,0,0.47-0.19,0.47-0.57v-2.28h0.88v3.44h-0.84v-0.4h-0.01c-0.09,0.17-0.22,0.29-0.36,0.36
                    c-0.15,0.07-0.32,0.11-0.51,0.11c-0.28,0-0.5-0.07-0.65-0.22c-0.15-0.15-0.23-0.39-0.23-0.74V15.13z M48.57,22.65
                    c0.09-0.14,0.2-0.24,0.32-0.31c0.12-0.07,0.26-0.1,0.43-0.1c0.36,0,0.63,0.14,0.8,0.43c0.17,0.28,0.26,0.75,0.26,1.39
                    c0,0.64-0.09,1.1-0.26,1.38c-0.17,0.28-0.44,0.42-0.8,0.42c-0.18,0-0.33-0.03-0.45-0.1c-0.12-0.06-0.23-0.18-0.33-0.34h-0.01v0.36
                    h-0.84V21.2h0.88v1.45H48.57z M46.19,15.16c0.17-0.08,0.38-0.12,0.64-0.12c0.26,0,0.48,0.03,0.66,0.11
                    c0.18,0.07,0.31,0.19,0.42,0.34c0.1,0.15,0.17,0.34,0.22,0.57c0.04,0.23,0.06,0.49,0.06,0.79c0,0.29-0.02,0.55-0.07,0.78
                    c-0.05,0.23-0.12,0.42-0.23,0.57c-0.11,0.15-0.25,0.26-0.42,0.34c-0.17,0.07-0.39,0.11-0.64,0.11c-0.26,0-0.48-0.03-0.66-0.1
                    c-0.17-0.07-0.31-0.18-0.42-0.33c-0.1-0.15-0.18-0.34-0.22-0.57c-0.04-0.23-0.06-0.49-0.06-0.79c0-0.29,0.02-0.55,0.07-0.78
                    c0.05-0.23,0.12-0.42,0.23-0.57C45.87,15.35,46.01,15.23,46.19,15.16z M43.43,14l0.6,1.77h0.01L44.69,14h1l-1.18,2.77v1.8h-0.92
                    v-1.8L42.39,14H43.43z M44.56,25.4c-0.1,0.16-0.21,0.28-0.33,0.34c-0.12,0.06-0.27,0.1-0.45,0.1c-0.36,0-0.63-0.14-0.8-0.42
                    c-0.17-0.28-0.26-0.74-0.26-1.38c0-0.64,0.09-1.1,0.26-1.39c0.17-0.28,0.44-0.43,0.8-0.43c0.17,0,0.31,0.03,0.43,0.1
                    c0.12,0.07,0.22,0.17,0.32,0.31h0.01V21.2h0.88v4.57h-0.84V25.4H44.56z M39.7,23.35c0.03-0.21,0.1-0.4,0.19-0.57
                    c0.1-0.17,0.23-0.3,0.41-0.4c0.18-0.1,0.42-0.15,0.72-0.15c0.26,0,0.47,0.04,0.63,0.12c0.16,0.08,0.29,0.2,0.39,0.35
                    c0.09,0.15,0.16,0.33,0.19,0.55c0.03,0.22,0.05,0.46,0.05,0.72v0.2h-1.75v0.28c0,0.1,0.01,0.2,0.02,0.3
                    c0.01,0.1,0.04,0.18,0.07,0.26c0.03,0.07,0.08,0.13,0.13,0.18c0.06,0.04,0.12,0.07,0.2,0.07c0.15,0,0.26-0.05,0.33-0.16
                    c0.07-0.11,0.12-0.27,0.14-0.49h0.79c-0.02,0.4-0.12,0.71-0.32,0.92s-0.51,0.31-0.93,0.31c-0.32,0-0.57-0.05-0.75-0.16
                    c-0.18-0.11-0.31-0.25-0.4-0.42c-0.09-0.17-0.14-0.37-0.16-0.59c-0.02-0.22-0.03-0.44-0.03-0.65
                    C39.65,23.79,39.67,23.56,39.7,23.35z M36.55,21.2h0.88v2.41h0.01l0.89-1.29h0.97l-0.99,1.36l1.14,2.09h-0.97l-0.74-1.41
                    l-0.31,0.41v1h-0.88V21.2z M27.09,21.2h0.88v1.45h0.01c0.09-0.14,0.2-0.24,0.32-0.31c0.12-0.07,0.26-0.1,0.43-0.1
                    c0.36,0,0.63,0.14,0.8,0.43c0.17,0.28,0.26,0.75,0.26,1.39c0,0.64-0.09,1.1-0.26,1.38c-0.17,0.28-0.44,0.42-0.8,0.42
                    c-0.18,0-0.33-0.03-0.45-0.1c-0.12-0.06-0.23-0.18-0.33-0.34h-0.01v0.36h-0.84V21.2z M17.53,25.77c-0.03-0.06-0.05-0.11-0.07-0.18
                    c-0.02-0.06-0.03-0.12-0.04-0.18h-0.01c-0.1,0.18-0.22,0.3-0.36,0.35c-0.13,0.05-0.31,0.08-0.52,0.08c-0.15,0-0.28-0.03-0.39-0.08
                    c-0.11-0.05-0.19-0.13-0.26-0.22c-0.06-0.09-0.11-0.2-0.14-0.32c-0.03-0.12-0.04-0.23-0.04-0.35c0-0.16,0.02-0.3,0.05-0.42
                    c0.03-0.12,0.09-0.22,0.16-0.3c0.07-0.08,0.16-0.15,0.27-0.21c0.11-0.06,0.24-0.1,0.39-0.15l0.5-0.13
                    c0.13-0.03,0.22-0.08,0.28-0.14c0.05-0.06,0.08-0.15,0.08-0.26c0-0.13-0.03-0.24-0.09-0.31c-0.06-0.07-0.17-0.11-0.32-0.11
                    c-0.14,0-0.24,0.04-0.31,0.12c-0.07,0.08-0.1,0.19-0.1,0.33v0.1h-0.79V23.3c0-0.2,0.03-0.37,0.1-0.5c0.06-0.13,0.15-0.24,0.26-0.33
                    c0.11-0.08,0.24-0.14,0.38-0.18c0.15-0.04,0.3-0.05,0.47-0.05c0.26,0,0.48,0.03,0.64,0.08c0.16,0.05,0.29,0.12,0.38,0.22
                    c0.09,0.09,0.15,0.21,0.18,0.34c0.03,0.13,0.05,0.27,0.05,0.43v1.81c0,0.16,0.01,0.29,0.02,0.38c0.01,0.09,0.04,0.19,0.09,0.29
                    H17.53z M21.57,25.77h-0.88V23.4c0-0.18-0.03-0.3-0.09-0.37c-0.06-0.07-0.16-0.11-0.29-0.11c-0.31,0-0.47,0.19-0.47,0.57v2.28
                    h-0.88v-3.44h0.84v0.4h0.01c0.09-0.17,0.22-0.3,0.36-0.37c0.15-0.08,0.32-0.12,0.51-0.12c0.28,0,0.5,0.08,0.65,0.23
                    c0.15,0.15,0.23,0.4,0.23,0.75V25.77z M24.83,25.77h-0.84V25.4h-0.01c-0.1,0.16-0.21,0.28-0.33,0.34c-0.12,0.06-0.27,0.1-0.45,0.1
                    c-0.36,0-0.63-0.14-0.8-0.42c-0.17-0.28-0.26-0.74-0.26-1.38c0-0.64,0.09-1.1,0.26-1.39c0.17-0.28,0.44-0.43,0.8-0.43
                    c0.17,0,0.31,0.03,0.43,0.1c0.12,0.07,0.22,0.17,0.32,0.31h0.01V21.2h0.88V25.77z M28.8,86.3h-1.05l-1.13-3.2h-0.01v3.2h-0.84
                    v-4.57h1.06l1.11,3.13h0.01v-3.13h0.84V86.3z M31.19,86.3c-0.03-0.06-0.05-0.11-0.07-0.18c-0.02-0.06-0.03-0.12-0.04-0.18h-0.01
                    c-0.1,0.18-0.22,0.3-0.36,0.35c-0.13,0.05-0.31,0.08-0.52,0.08c-0.15,0-0.28-0.03-0.39-0.08c-0.11-0.05-0.19-0.13-0.26-0.22
                    c-0.06-0.09-0.11-0.2-0.14-0.32c-0.03-0.12-0.04-0.23-0.04-0.35c0-0.16,0.02-0.3,0.05-0.42c0.03-0.12,0.09-0.22,0.16-0.3
                    c0.07-0.08,0.16-0.15,0.27-0.21c0.11-0.06,0.24-0.1,0.39-0.15l0.5-0.13c0.13-0.03,0.22-0.08,0.28-0.14
                    c0.05-0.06,0.08-0.15,0.08-0.26c0-0.13-0.03-0.24-0.09-0.31c-0.06-0.07-0.17-0.11-0.32-0.11c-0.14,0-0.24,0.04-0.31,0.12
                    c-0.07,0.08-0.1,0.19-0.1,0.33v0.1h-0.79v-0.08c0-0.2,0.03-0.37,0.1-0.5c0.06-0.13,0.15-0.24,0.26-0.33
                    c0.11-0.08,0.24-0.14,0.38-0.18c0.15-0.04,0.3-0.05,0.47-0.05c0.26,0,0.48,0.03,0.64,0.08c0.16,0.05,0.29,0.12,0.38,0.22
                    c0.09,0.09,0.15,0.21,0.18,0.34c0.03,0.13,0.05,0.27,0.05,0.43v1.81c0,0.16,0.01,0.29,0.02,0.38c0.01,0.09,0.04,0.19,0.09,0.29
                    H31.19z M32,25.59c-0.02-0.06-0.03-0.12-0.04-0.18h-0.01c-0.1,0.18-0.22,0.3-0.36,0.35c-0.13,0.05-0.31,0.08-0.52,0.08
                    c-0.15,0-0.28-0.03-0.39-0.08c-0.11-0.05-0.19-0.13-0.26-0.22c-0.06-0.09-0.11-0.2-0.14-0.32c-0.03-0.12-0.04-0.23-0.04-0.35
                    c0-0.16,0.02-0.3,0.05-0.42c0.03-0.12,0.09-0.22,0.16-0.3c0.07-0.08,0.16-0.15,0.27-0.21c0.11-0.06,0.24-0.1,0.39-0.15l0.5-0.13
                    c0.13-0.03,0.22-0.08,0.28-0.14c0.05-0.06,0.08-0.15,0.08-0.26c0-0.13-0.03-0.24-0.09-0.31c-0.06-0.07-0.17-0.11-0.32-0.11
                    c-0.14,0-0.24,0.04-0.31,0.12c-0.07,0.08-0.1,0.19-0.1,0.33v0.1h-0.79V23.3c0-0.2,0.03-0.37,0.1-0.5c0.06-0.13,0.15-0.24,0.26-0.33
                    c0.11-0.08,0.24-0.14,0.38-0.18c0.15-0.04,0.3-0.05,0.47-0.05c0.26,0,0.48,0.03,0.64,0.08c0.16,0.05,0.29,0.12,0.38,0.22
                    c0.09,0.09,0.15,0.21,0.18,0.34c0.03,0.13,0.05,0.27,0.05,0.43v1.81c0,0.16,0.01,0.29,0.02,0.38c0.01,0.09,0.04,0.19,0.09,0.29
                    h-0.84C32.03,25.71,32.01,25.66,32,25.59z M34.21,83.46h-0.51v1.86c0,0.14,0.02,0.23,0.06,0.29c0.04,0.06,0.12,0.09,0.25,0.09
                    c0.03,0,0.07,0,0.1,0c0.03,0,0.07-0.01,0.1-0.01v0.61c-0.1,0-0.19,0-0.28,0.01c-0.09,0.01-0.19,0.01-0.29,0.01
                    c-0.17,0-0.31-0.01-0.42-0.04s-0.19-0.07-0.25-0.14c-0.06-0.07-0.1-0.16-0.12-0.28s-0.03-0.26-0.03-0.44v-1.97h-0.45v-0.6h0.45
                    v-0.99h0.88v0.99h0.51V83.46z M35.49,86.3h-0.88v-3.44h0.88V86.3z M35.49,82.44h-0.88v-0.76h0.88V82.44z M34.27,24.6
                    c0.01,0.15,0.04,0.28,0.07,0.37c0.03,0.1,0.08,0.17,0.14,0.21c0.06,0.04,0.14,0.07,0.24,0.07c0.15,0,0.26-0.06,0.33-0.19
                    c0.07-0.13,0.1-0.33,0.1-0.61h0.84c0,0.45-0.1,0.8-0.3,1.03c-0.2,0.24-0.54,0.36-1.01,0.36c-0.22,0-0.42-0.03-0.58-0.08
                    c-0.16-0.05-0.3-0.15-0.41-0.29c-0.11-0.14-0.19-0.32-0.25-0.56c-0.06-0.23-0.08-0.52-0.08-0.87c0-0.35,0.03-0.65,0.1-0.88
                    s0.16-0.42,0.29-0.56c0.12-0.14,0.27-0.24,0.44-0.29c0.17-0.06,0.36-0.08,0.56-0.08c0.41,0,0.72,0.12,0.92,0.36
                    c0.21,0.24,0.31,0.56,0.31,0.97h-0.84c0-0.09-0.01-0.17-0.02-0.25c-0.01-0.08-0.03-0.15-0.06-0.22c-0.03-0.07-0.07-0.12-0.12-0.16
                    c-0.05-0.04-0.12-0.06-0.2-0.06c-0.11,0-0.19,0.02-0.26,0.07c-0.07,0.05-0.12,0.12-0.15,0.22c-0.03,0.1-0.06,0.22-0.07,0.36
                    c-0.01,0.15-0.02,0.32-0.02,0.52C34.25,24.27,34.25,24.45,34.27,24.6z M38.71,85.36c-0.05,0.23-0.12,0.41-0.23,0.57
                    s-0.25,0.26-0.42,0.34c-0.17,0.07-0.39,0.11-0.64,0.11c-0.26,0-0.48-0.03-0.66-0.1s-0.31-0.18-0.42-0.33
                    c-0.1-0.15-0.17-0.34-0.22-0.57c-0.04-0.23-0.06-0.49-0.06-0.79c0-0.29,0.02-0.55,0.07-0.78c0.05-0.23,0.12-0.41,0.23-0.57
                    c0.11-0.15,0.25-0.27,0.42-0.35c0.17-0.08,0.38-0.12,0.64-0.12c0.26,0,0.48,0.03,0.66,0.11c0.17,0.08,0.31,0.19,0.42,0.34
                    c0.1,0.15,0.17,0.34,0.22,0.57c0.04,0.23,0.06,0.49,0.06,0.79C38.78,84.88,38.75,85.14,38.71,85.36z M41.92,86.3h-0.88v-2.37
                    c0-0.18-0.03-0.3-0.09-0.37c-0.06-0.07-0.16-0.11-0.29-0.11c-0.31,0-0.47,0.19-0.47,0.57v2.28h-0.88v-3.44h0.84v0.4h0.01
                    c0.09-0.17,0.22-0.3,0.36-0.37c0.15-0.08,0.32-0.12,0.51-0.12c0.28,0,0.5,0.08,0.65,0.23c0.15,0.15,0.23,0.4,0.23,0.75V86.3z
                    M44.29,86.3c-0.03-0.06-0.05-0.11-0.07-0.18c-0.02-0.06-0.03-0.12-0.04-0.18h-0.01c-0.1,0.18-0.22,0.3-0.36,0.35
                    c-0.13,0.05-0.31,0.08-0.52,0.08c-0.15,0-0.28-0.03-0.39-0.08c-0.11-0.05-0.19-0.13-0.26-0.22c-0.06-0.09-0.11-0.2-0.14-0.32
                    c-0.03-0.12-0.04-0.23-0.04-0.35c0-0.16,0.02-0.3,0.05-0.42c0.03-0.12,0.09-0.22,0.16-0.3c0.07-0.08,0.16-0.15,0.27-0.21
                    c0.11-0.06,0.24-0.1,0.39-0.15l0.5-0.13c0.13-0.03,0.22-0.08,0.28-0.14c0.05-0.06,0.08-0.15,0.08-0.26c0-0.13-0.03-0.24-0.09-0.31
                    c-0.06-0.07-0.17-0.11-0.32-0.11c-0.14,0-0.24,0.04-0.31,0.12c-0.07,0.08-0.1,0.19-0.1,0.33v0.1h-0.79v-0.08
                    c0-0.2,0.03-0.37,0.1-0.5c0.06-0.13,0.15-0.24,0.26-0.33s0.24-0.14,0.38-0.18c0.15-0.04,0.3-0.05,0.47-0.05
                    c0.26,0,0.48,0.03,0.64,0.08c0.16,0.05,0.29,0.12,0.38,0.22c0.09,0.09,0.15,0.21,0.18,0.34c0.03,0.13,0.05,0.27,0.05,0.43v1.81
                    c0,0.16,0.01,0.29,0.02,0.38c0.01,0.09,0.04,0.19,0.09,0.29H44.29z M46.63,86.3h-0.88v-4.57h0.88V86.3z M47.36,50.25V71.6h-8.4
                    V37.33h9.17l11.9,21.12h0.1V37.33h8.4V71.6h-9.17l-11.9-21.36H47.36z M51.8,85.26c-0.04,0.22-0.11,0.42-0.21,0.58
                    c-0.1,0.17-0.25,0.3-0.44,0.39c-0.19,0.09-0.44,0.14-0.74,0.14c-0.35,0-0.62-0.06-0.83-0.17c-0.2-0.11-0.36-0.27-0.47-0.48
                    c-0.11-0.21-0.18-0.46-0.21-0.75c-0.03-0.29-0.05-0.61-0.05-0.96c0-0.35,0.02-0.66,0.05-0.96c0.03-0.29,0.1-0.54,0.21-0.75
                    c0.11-0.21,0.27-0.37,0.47-0.49c0.2-0.12,0.48-0.18,0.83-0.18c0.33,0,0.59,0.05,0.78,0.16c0.19,0.11,0.33,0.24,0.43,0.41
                    c0.09,0.16,0.15,0.34,0.18,0.53c0.02,0.19,0.04,0.37,0.04,0.53H50.9c0-0.33-0.04-0.57-0.12-0.73c-0.08-0.15-0.22-0.23-0.43-0.23
                    c-0.12,0-0.21,0.03-0.28,0.09c-0.07,0.06-0.13,0.16-0.18,0.3c-0.04,0.14-0.07,0.32-0.09,0.54c-0.02,0.22-0.03,0.5-0.03,0.82
                    c0,0.35,0.01,0.63,0.04,0.84c0.02,0.21,0.06,0.38,0.11,0.5c0.05,0.12,0.11,0.2,0.19,0.24c0.07,0.04,0.16,0.06,0.26,0.06
                    c0.08,0,0.16-0.01,0.23-0.04c0.07-0.03,0.13-0.08,0.18-0.17c0.05-0.08,0.09-0.2,0.12-0.35c0.03-0.15,0.04-0.35,0.04-0.6h0.92
                    C51.86,84.8,51.84,85.04,51.8,85.26z M54.23,83.63c-0.05,0-0.09-0.01-0.14-0.01c-0.04,0-0.09,0-0.14,0c-0.08,0-0.15,0.01-0.23,0.03
                    c-0.08,0.02-0.15,0.06-0.21,0.11c-0.06,0.05-0.12,0.11-0.15,0.19c-0.04,0.08-0.06,0.18-0.06,0.29v2.07h-0.88v-3.44h0.84v0.46h0.01
                    c0.09-0.17,0.2-0.3,0.33-0.4c0.13-0.1,0.28-0.15,0.47-0.15c0.03,0,0.05,0,0.08,0c0.03,0,0.05,0.01,0.08,0.01V83.63z M57.12,84.72
                    h-1.75v0.28c0,0.1,0.01,0.2,0.02,0.3c0.01,0.1,0.04,0.18,0.07,0.26c0.03,0.07,0.08,0.13,0.13,0.18c0.06,0.04,0.12,0.07,0.2,0.07
                    c0.15,0,0.26-0.05,0.33-0.16c0.07-0.11,0.12-0.27,0.14-0.49h0.79c-0.02,0.4-0.12,0.71-0.32,0.92c-0.2,0.21-0.51,0.31-0.93,0.31
                    c-0.32,0-0.57-0.05-0.75-0.16c-0.18-0.11-0.31-0.25-0.4-0.42c-0.09-0.18-0.14-0.37-0.16-0.59c-0.02-0.22-0.03-0.44-0.03-0.65
                    c0-0.23,0.02-0.45,0.05-0.67s0.1-0.4,0.19-0.57c0.1-0.17,0.23-0.3,0.41-0.4c0.18-0.1,0.42-0.15,0.72-0.15
                    c0.26,0,0.47,0.04,0.63,0.13c0.16,0.08,0.29,0.2,0.39,0.35c0.09,0.15,0.16,0.34,0.19,0.55s0.05,0.46,0.05,0.72V84.72z M60.25,86.3
                    h-0.84v-0.36H59.4c-0.1,0.16-0.21,0.28-0.33,0.34c-0.12,0.06-0.27,0.1-0.45,0.1c-0.36,0-0.63-0.14-0.8-0.42s-0.26-0.74-0.26-1.38
                    c0-0.64,0.09-1.1,0.26-1.39c0.17-0.28,0.44-0.43,0.8-0.43c0.17,0,0.31,0.03,0.43,0.1c0.12,0.07,0.22,0.17,0.32,0.31h0.01v-1.45
                    h0.88V86.3z M61.82,86.3h-0.88v-3.44h0.88V86.3z M61.82,82.44h-0.88v-0.76h0.88V82.44z M64.09,83.46h-0.51v1.86
                    c0,0.14,0.02,0.23,0.06,0.29c0.04,0.06,0.12,0.09,0.25,0.09c0.03,0,0.07,0,0.1,0c0.03,0,0.07-0.01,0.1-0.01v0.61
                    c-0.1,0-0.19,0-0.28,0.01c-0.09,0.01-0.19,0.01-0.29,0.01c-0.17,0-0.31-0.01-0.42-0.04c-0.11-0.02-0.19-0.07-0.25-0.14
                    c-0.06-0.07-0.1-0.16-0.12-0.28s-0.03-0.26-0.03-0.44v-1.97h-0.45v-0.6h0.45v-0.99h0.88v0.99h0.51V83.46z M68.95,84.92
                    c0,0.29-0.04,0.53-0.12,0.72c-0.08,0.19-0.19,0.34-0.33,0.45c-0.14,0.11-0.29,0.19-0.47,0.22c-0.17,0.04-0.36,0.06-0.54,0.06
                    c-0.19,0-0.37-0.02-0.54-0.05c-0.17-0.03-0.33-0.1-0.47-0.21c-0.14-0.1-0.25-0.25-0.33-0.44s-0.12-0.44-0.12-0.75v-3.19h0.92v3.19
                    c0,0.12,0.01,0.22,0.02,0.32c0.01,0.1,0.04,0.18,0.08,0.25c0.04,0.07,0.1,0.13,0.17,0.17c0.07,0.04,0.16,0.06,0.27,0.06
                    c0.11,0,0.2-0.02,0.27-0.06c0.07-0.04,0.12-0.1,0.16-0.17c0.04-0.07,0.07-0.15,0.08-0.25c0.02-0.1,0.02-0.2,0.02-0.32v-3.19h0.92
                    V84.92z M69,25.37c-0.09,0.17-0.22,0.29-0.36,0.36c-0.15,0.07-0.32,0.11-0.51,0.11c-0.28,0-0.5-0.07-0.65-0.22
                    c-0.15-0.15-0.23-0.39-0.23-0.74v-2.55h0.88v2.37c0,0.18,0.03,0.3,0.09,0.37c0.06,0.07,0.16,0.11,0.29,0.11
                    c0.31,0,0.47-0.19,0.47-0.57v-2.28h0.88v3.44h-0.84v-0.4H69z M72.18,86.3H71.3v-2.37c0-0.18-0.03-0.3-0.09-0.37
                    c-0.06-0.07-0.16-0.11-0.29-0.11c-0.31,0-0.47,0.19-0.47,0.57v2.28h-0.88v-3.44h0.84v0.4h0.01c0.09-0.17,0.22-0.3,0.36-0.37
                    c0.15-0.08,0.32-0.12,0.51-0.12c0.28,0,0.5,0.08,0.65,0.23c0.15,0.15,0.23,0.4,0.23,0.75V86.3z M73.75,86.3h-0.88v-3.44h0.88V86.3z
                    M73.75,82.44h-0.88v-0.76h0.88V82.44z M76.96,85.36c-0.05,0.23-0.12,0.41-0.23,0.57s-0.25,0.26-0.42,0.34
                    c-0.17,0.07-0.39,0.11-0.64,0.11c-0.26,0-0.48-0.03-0.66-0.1c-0.17-0.07-0.31-0.18-0.42-0.33c-0.1-0.15-0.18-0.34-0.22-0.57
                    c-0.04-0.23-0.06-0.49-0.06-0.79c0-0.29,0.02-0.55,0.07-0.78c0.05-0.23,0.12-0.41,0.23-0.57c0.11-0.15,0.25-0.27,0.42-0.35
                    c0.17-0.08,0.38-0.12,0.64-0.12c0.26,0,0.48,0.03,0.66,0.11c0.18,0.08,0.31,0.19,0.42,0.34c0.1,0.15,0.17,0.34,0.22,0.57
                    c0.04,0.23,0.06,0.49,0.06,0.79C77.03,84.88,77.01,85.14,76.96,85.36z M80.18,86.3H79.3v-2.37c0-0.18-0.03-0.3-0.09-0.37
                    c-0.06-0.07-0.16-0.11-0.29-0.11c-0.31,0-0.47,0.19-0.47,0.57v2.28h-0.88v-3.44h0.84v0.4h0.01c0.09-0.17,0.22-0.3,0.36-0.37
                    c0.15-0.08,0.32-0.12,0.51-0.12c0.28,0,0.5,0.08,0.65,0.23c0.15,0.15,0.23,0.4,0.23,0.75V86.3z M84.5,86.3l-0.21-0.97h-1.22
                    l-0.21,0.97H81.9l1.21-4.57h1.14l1.21,4.57H84.5z M88.43,86.3h-0.84v-0.36h-0.01c-0.1,0.16-0.21,0.28-0.33,0.34
                    c-0.12,0.06-0.27,0.1-0.45,0.1c-0.36,0-0.63-0.14-0.8-0.42s-0.26-0.74-0.26-1.38c0-0.64,0.09-1.1,0.26-1.39
                    c0.17-0.28,0.44-0.43,0.8-0.43c0.17,0,0.31,0.03,0.43,0.1c0.12,0.07,0.22,0.17,0.32,0.31h0.01v-1.45h0.88V86.3z M93.35,86.3h-0.88
                    V83.9c0-0.15-0.03-0.26-0.08-0.33c-0.05-0.08-0.14-0.12-0.27-0.12c-0.14,0-0.25,0.05-0.34,0.14c-0.08,0.09-0.12,0.24-0.12,0.45
                    v2.27h-0.88V83.9c0-0.15-0.03-0.26-0.08-0.33c-0.05-0.08-0.14-0.12-0.27-0.12c-0.14,0-0.25,0.05-0.34,0.14
                    c-0.08,0.09-0.12,0.24-0.12,0.45v2.27h-0.88v-3.44h0.84v0.35h0.01c0.11-0.16,0.24-0.27,0.37-0.34c0.14-0.07,0.3-0.1,0.5-0.1
                    c0.2,0,0.38,0.05,0.52,0.14c0.14,0.09,0.23,0.23,0.28,0.41h0.01c0.07-0.18,0.18-0.32,0.33-0.41c0.15-0.09,0.33-0.14,0.54-0.14
                    c0.29,0,0.51,0.09,0.65,0.27c0.14,0.18,0.21,0.43,0.21,0.76V86.3z M94.92,86.3h-0.88v-3.44h0.88V86.3z M94.92,82.44h-0.88v-0.76
                    h0.88V82.44z M98.21,86.3h-0.88v-2.37c0-0.18-0.03-0.3-0.09-0.37c-0.06-0.07-0.16-0.11-0.29-0.11c-0.31,0-0.47,0.19-0.47,0.57v2.28
                    H95.6v-3.44h0.84v0.4h0.01c0.09-0.17,0.22-0.3,0.36-0.37c0.15-0.08,0.32-0.12,0.51-0.12c0.28,0,0.5,0.08,0.65,0.23
                    c0.15,0.15,0.23,0.4,0.23,0.75V86.3z M99.78,86.3H98.9v-3.44h0.88V86.3z M99.78,82.44H98.9v-0.76h0.88V82.44z M102.77,85.79
                    c-0.06,0.13-0.15,0.25-0.27,0.34c-0.11,0.09-0.25,0.15-0.4,0.19c-0.16,0.04-0.33,0.06-0.52,0.06c-0.25,0-0.45-0.02-0.62-0.07
                    c-0.16-0.05-0.29-0.12-0.39-0.21c-0.1-0.09-0.16-0.21-0.2-0.34c-0.04-0.13-0.06-0.28-0.06-0.45v-0.12h0.79v0.12
                    c0,0.15,0.04,0.27,0.12,0.36c0.08,0.09,0.19,0.14,0.34,0.14c0.14,0,0.25-0.03,0.33-0.1c0.08-0.07,0.12-0.17,0.12-0.31
                    c0-0.11-0.03-0.19-0.1-0.25c-0.06-0.06-0.14-0.1-0.22-0.14l-0.62-0.22c-0.24-0.09-0.43-0.21-0.55-0.36
                    c-0.12-0.16-0.19-0.36-0.19-0.6c0-0.14,0.02-0.27,0.07-0.4s0.12-0.24,0.22-0.33c0.1-0.09,0.23-0.17,0.39-0.22
                    c0.16-0.06,0.35-0.08,0.58-0.08c0.4,0,0.7,0.09,0.89,0.26c0.19,0.17,0.29,0.41,0.29,0.72v0.14h-0.79c0-0.18-0.03-0.31-0.09-0.39
                    c-0.06-0.08-0.16-0.13-0.3-0.13c-0.11,0-0.21,0.03-0.29,0.09s-0.12,0.15-0.12,0.28c0,0.09,0.03,0.16,0.08,0.23
                    c0.05,0.07,0.15,0.13,0.3,0.17l0.53,0.18c0.28,0.09,0.47,0.22,0.59,0.37c0.12,0.15,0.17,0.36,0.17,0.62
                    C102.86,85.49,102.83,85.65,102.77,85.79z M104.96,83.46h-0.51v1.86c0,0.14,0.02,0.23,0.06,0.29c0.04,0.06,0.12,0.09,0.25,0.09
                    c0.03,0,0.07,0,0.1,0c0.03,0,0.07-0.01,0.1-0.01v0.61c-0.1,0-0.19,0-0.28,0.01c-0.09,0.01-0.19,0.01-0.29,0.01
                    c-0.17,0-0.31-0.01-0.42-0.04s-0.19-0.07-0.25-0.14c-0.06-0.07-0.1-0.16-0.12-0.28c-0.02-0.12-0.03-0.26-0.03-0.44v-1.97h-0.45
                    v-0.6h0.45v-0.99h0.88v0.99h0.51V83.46z M107.14,83.63c-0.05,0-0.09-0.01-0.14-0.01c-0.04,0-0.09,0-0.14,0
                    c-0.08,0-0.15,0.01-0.23,0.03c-0.08,0.02-0.15,0.06-0.21,0.11c-0.06,0.05-0.12,0.11-0.15,0.19c-0.04,0.08-0.06,0.18-0.06,0.29v2.07
                    h-0.88v-3.44h0.84v0.46h0.01c0.09-0.17,0.2-0.3,0.33-0.4c0.13-0.1,0.28-0.15,0.47-0.15c0.03,0,0.05,0,0.08,0
                    c0.03,0,0.05,0.01,0.08,0.01V83.63z M109.24,86.3c-0.03-0.06-0.05-0.11-0.07-0.18c-0.02-0.06-0.03-0.12-0.04-0.18h-0.01
                    c-0.1,0.18-0.22,0.3-0.36,0.35c-0.13,0.05-0.31,0.08-0.52,0.08c-0.15,0-0.28-0.03-0.39-0.08c-0.11-0.05-0.19-0.13-0.26-0.22
                    c-0.06-0.09-0.11-0.2-0.14-0.32c-0.03-0.12-0.04-0.23-0.04-0.35c0-0.16,0.02-0.3,0.05-0.42c0.03-0.12,0.09-0.22,0.16-0.3
                    c0.07-0.08,0.16-0.15,0.27-0.21c0.11-0.06,0.24-0.1,0.39-0.15l0.5-0.13c0.13-0.03,0.22-0.08,0.27-0.14
                    c0.05-0.06,0.08-0.15,0.08-0.26c0-0.13-0.03-0.24-0.09-0.31c-0.06-0.07-0.17-0.11-0.32-0.11c-0.14,0-0.24,0.04-0.31,0.12
                    c-0.07,0.08-0.1,0.19-0.1,0.33v0.1h-0.79v-0.08c0-0.2,0.03-0.37,0.1-0.5c0.06-0.13,0.15-0.24,0.26-0.33s0.24-0.14,0.38-0.18
                    c0.15-0.04,0.3-0.05,0.47-0.05c0.26,0,0.48,0.03,0.64,0.08c0.16,0.05,0.29,0.12,0.38,0.22c0.09,0.09,0.15,0.21,0.18,0.34
                    c0.03,0.13,0.05,0.27,0.05,0.43v1.81c0,0.16,0.01,0.29,0.02,0.38c0.01,0.09,0.04,0.19,0.09,0.29H109.24z M110.11,25.4
                    c-0.1,0.16-0.21,0.28-0.33,0.34c-0.12,0.06-0.27,0.1-0.45,0.1c-0.36,0-0.63-0.14-0.8-0.42s-0.26-0.74-0.26-1.38
                    c0-0.64,0.09-1.1,0.26-1.39c0.17-0.28,0.44-0.43,0.8-0.43c0.17,0,0.31,0.03,0.43,0.1c0.12,0.07,0.22,0.17,0.32,0.31h0.01V21.2h0.88
                    v4.57h-0.84V25.4H110.11z M112.25,83.46h-0.51v1.86c0,0.14,0.02,0.23,0.06,0.29c0.04,0.06,0.12,0.09,0.25,0.09c0.03,0,0.07,0,0.1,0
                    c0.03,0,0.07-0.01,0.1-0.01v0.61c-0.1,0-0.19,0-0.28,0.01c-0.09,0.01-0.19,0.01-0.29,0.01c-0.17,0-0.31-0.01-0.42-0.04
                    c-0.11-0.02-0.19-0.07-0.25-0.14c-0.06-0.07-0.1-0.16-0.12-0.28c-0.02-0.12-0.03-0.26-0.03-0.44v-1.97h-0.45v-0.6h0.45v-0.99h0.88
                    v0.99h0.51V83.46z M113.54,86.3h-0.88v-3.44h0.88V86.3z M113.54,82.44h-0.88v-0.76h0.88V82.44z M116.75,85.36
                    c-0.05,0.23-0.12,0.41-0.23,0.57c-0.11,0.15-0.25,0.26-0.42,0.34c-0.17,0.07-0.38,0.11-0.64,0.11c-0.26,0-0.48-0.03-0.66-0.1
                    c-0.17-0.07-0.31-0.18-0.42-0.33s-0.17-0.34-0.22-0.57c-0.04-0.23-0.06-0.49-0.06-0.79c0-0.29,0.02-0.55,0.07-0.78
                    c0.05-0.23,0.12-0.41,0.23-0.57c0.11-0.15,0.25-0.27,0.42-0.35c0.17-0.08,0.39-0.12,0.64-0.12c0.26,0,0.48,0.03,0.66,0.11
                    c0.17,0.08,0.31,0.19,0.42,0.34s0.17,0.34,0.22,0.57c0.04,0.23,0.06,0.49,0.06,0.79C116.82,84.88,116.8,85.14,116.75,85.36z
                    M119.97,86.3h-0.88v-2.37c0-0.18-0.03-0.3-0.09-0.37c-0.06-0.07-0.16-0.11-0.29-0.11c-0.31,0-0.47,0.19-0.47,0.57v2.28h-0.88
                    v-3.44h0.84v0.4h0.01c0.09-0.17,0.22-0.3,0.36-0.37c0.15-0.08,0.32-0.12,0.51-0.12c0.28,0,0.5,0.08,0.65,0.23
                    c0.15,0.15,0.23,0.4,0.23,0.75V86.3z M121.52,86.14c0,0.16-0.01,0.31-0.04,0.45s-0.07,0.27-0.13,0.37
                    c-0.06,0.1-0.15,0.19-0.26,0.26c-0.11,0.07-0.25,0.11-0.42,0.13v-0.45c0.11-0.02,0.19-0.08,0.25-0.18c0.06-0.1,0.08-0.21,0.08-0.33
                    V86.3h-0.33v-1h0.84V86.14z M125.3,86.3c-0.03-0.06-0.05-0.11-0.07-0.18c-0.02-0.06-0.03-0.12-0.04-0.18h-0.01
                    c-0.1,0.18-0.22,0.3-0.36,0.35c-0.13,0.05-0.31,0.08-0.52,0.08c-0.15,0-0.28-0.03-0.39-0.08c-0.11-0.05-0.19-0.13-0.26-0.22
                    c-0.06-0.09-0.11-0.2-0.14-0.32c-0.03-0.12-0.04-0.23-0.04-0.35c0-0.16,0.02-0.3,0.05-0.42c0.03-0.12,0.09-0.22,0.16-0.3
                    c0.07-0.08,0.16-0.15,0.27-0.21c0.11-0.06,0.24-0.1,0.39-0.15l0.5-0.13c0.13-0.03,0.22-0.08,0.28-0.14
                    c0.05-0.06,0.08-0.15,0.08-0.26c0-0.13-0.03-0.24-0.09-0.31c-0.06-0.07-0.17-0.11-0.32-0.11c-0.14,0-0.24,0.04-0.31,0.12
                    c-0.07,0.08-0.1,0.19-0.1,0.33v0.1h-0.79v-0.08c0-0.2,0.03-0.37,0.1-0.5c0.06-0.13,0.15-0.24,0.26-0.33
                    c0.11-0.08,0.24-0.14,0.38-0.18c0.15-0.04,0.3-0.05,0.47-0.05c0.26,0,0.48,0.03,0.64,0.08c0.16,0.05,0.29,0.12,0.38,0.22
                    c0.09,0.09,0.15,0.21,0.18,0.34c0.03,0.13,0.05,0.27,0.05,0.43v1.81c0,0.16,0.01,0.29,0.02,0.38c0.01,0.09,0.04,0.19,0.09,0.29
                    H125.3z M131.22,84.92c0,0.29-0.04,0.53-0.12,0.72c-0.08,0.19-0.19,0.34-0.33,0.45c-0.14,0.11-0.29,0.19-0.47,0.22
                    c-0.17,0.04-0.36,0.06-0.54,0.06c-0.19,0-0.37-0.02-0.54-0.05c-0.17-0.03-0.33-0.1-0.47-0.21c-0.14-0.1-0.25-0.25-0.33-0.44
                    c-0.08-0.19-0.12-0.44-0.12-0.75v-3.19h0.92v3.19c0,0.12,0.01,0.22,0.02,0.32c0.02,0.1,0.04,0.18,0.08,0.25
                    c0.04,0.07,0.1,0.13,0.17,0.17c0.07,0.04,0.16,0.06,0.27,0.06c0.11,0,0.2-0.02,0.27-0.06c0.07-0.04,0.12-0.1,0.16-0.17
                    c0.04-0.07,0.07-0.15,0.08-0.25c0.01-0.1,0.02-0.2,0.02-0.32v-3.19h0.92V84.92z M132.73,86.3h-0.84v-1h0.84V86.3z M135.9,86.04
                    c-0.28,0.22-0.67,0.33-1.17,0.33c-0.52,0-0.89-0.11-1.12-0.34c-0.22-0.23-0.34-0.55-0.34-0.97v-0.19h0.92v0.13
                    c0,0.11,0.01,0.2,0.03,0.29c0.02,0.09,0.05,0.16,0.1,0.22s0.11,0.11,0.19,0.14c0.08,0.03,0.18,0.05,0.3,0.05
                    c0.14,0,0.27-0.05,0.38-0.14c0.11-0.09,0.17-0.23,0.17-0.43c0-0.1-0.01-0.19-0.04-0.27c-0.03-0.07-0.07-0.14-0.14-0.2
                    c-0.06-0.06-0.15-0.11-0.25-0.16c-0.1-0.05-0.23-0.1-0.39-0.15c-0.2-0.07-0.38-0.14-0.53-0.22c-0.15-0.08-0.27-0.18-0.37-0.28
                    c-0.1-0.11-0.17-0.23-0.22-0.38c-0.05-0.14-0.07-0.31-0.07-0.5c0-0.45,0.13-0.79,0.38-1.01c0.25-0.22,0.6-0.33,1.04-0.33
                    c0.2,0,0.39,0.02,0.57,0.07c0.17,0.04,0.32,0.12,0.45,0.22c0.13,0.1,0.22,0.23,0.29,0.38c0.07,0.16,0.11,0.34,0.11,0.56V83h-0.88
                    c0-0.22-0.04-0.39-0.12-0.5c-0.08-0.12-0.2-0.18-0.38-0.18c-0.1,0-0.19,0.02-0.26,0.04c-0.07,0.03-0.12,0.07-0.16,0.12
                    c-0.04,0.05-0.07,0.11-0.08,0.17c-0.01,0.06-0.02,0.13-0.02,0.2c0,0.14,0.03,0.26,0.09,0.36c0.06,0.1,0.19,0.18,0.38,0.27
                    l0.71,0.31c0.17,0.08,0.32,0.16,0.43,0.24s0.2,0.17,0.27,0.27c0.07,0.1,0.11,0.2,0.14,0.32c0.03,0.12,0.04,0.24,0.04,0.38
                    C136.32,85.47,136.18,85.82,135.9,86.04z M137.72,86.3h-0.84v-1h0.84V86.3z M142.77,86.3h-0.68v-0.42h-0.01
                    c-0.11,0.18-0.24,0.31-0.4,0.38c-0.16,0.07-0.34,0.11-0.56,0.11c-0.28,0-0.51-0.05-0.69-0.15c-0.18-0.1-0.32-0.25-0.42-0.45
                    c-0.1-0.2-0.17-0.46-0.2-0.76c-0.03-0.3-0.05-0.65-0.05-1.05c0-0.38,0.02-0.72,0.07-1.01c0.05-0.29,0.13-0.53,0.25-0.73
                    c0.12-0.19,0.28-0.34,0.47-0.44c0.2-0.1,0.44-0.15,0.74-0.15c0.5,0,0.87,0.13,1.09,0.37c0.22,0.25,0.33,0.61,0.33,1.07h-0.88
                    c0-0.09-0.01-0.17-0.03-0.26c-0.02-0.09-0.05-0.17-0.09-0.25c-0.04-0.07-0.1-0.14-0.16-0.19c-0.07-0.05-0.15-0.07-0.24-0.07
                    c-0.22,0-0.38,0.12-0.48,0.37c-0.1,0.25-0.15,0.66-0.15,1.23c0,0.27,0.01,0.52,0.03,0.74c0.02,0.22,0.05,0.41,0.09,0.57
                    c0.04,0.16,0.11,0.28,0.19,0.36c0.08,0.08,0.19,0.12,0.32,0.12c0.06,0,0.12-0.01,0.18-0.04c0.07-0.03,0.13-0.07,0.19-0.13
                    c0.06-0.06,0.11-0.14,0.14-0.23c0.04-0.09,0.06-0.2,0.06-0.32v-0.47h-0.61v-0.68h1.49V86.3z M142.71,25.79
                    c-0.17,0-0.31-0.01-0.42-0.04c-0.11-0.02-0.19-0.07-0.25-0.14c-0.06-0.07-0.1-0.16-0.12-0.28c-0.02-0.12-0.03-0.26-0.03-0.44v-1.97
                    h-0.45v-0.6h0.45v-0.99h0.88v0.99h0.51v0.6h-0.51v1.86c0,0.14,0.02,0.23,0.06,0.29c0.04,0.06,0.12,0.09,0.25,0.09
                    c0.03,0,0.07,0,0.1,0c0.03,0,0.07-0.01,0.1-0.01v0.61c-0.1,0-0.19,0-0.28,0.01C142.91,25.79,142.81,25.79,142.71,25.79z
                    M145.94,85.36c-0.05,0.23-0.12,0.41-0.23,0.57c-0.11,0.15-0.25,0.26-0.42,0.34c-0.17,0.07-0.38,0.11-0.64,0.11
                    c-0.26,0-0.48-0.03-0.66-0.1c-0.17-0.07-0.31-0.18-0.42-0.33c-0.1-0.15-0.18-0.34-0.22-0.57c-0.04-0.23-0.06-0.49-0.06-0.79
                    c0-0.29,0.02-0.55,0.07-0.78c0.05-0.23,0.12-0.41,0.23-0.57s0.25-0.27,0.42-0.35c0.17-0.08,0.39-0.12,0.64-0.12
                    c0.26,0,0.48,0.03,0.66,0.11c0.17,0.08,0.31,0.19,0.42,0.34c0.1,0.15,0.17,0.34,0.22,0.57c0.04,0.23,0.06,0.49,0.06,0.79
                    C146.01,84.88,145.98,85.14,145.94,85.36z M148.19,86.3h-1.02l-0.89-3.44h0.92l0.49,2.46h0.01l0.47-2.46h0.88L148.19,86.3z
                    M151.95,84.72h-1.75v0.28c0,0.1,0.01,0.2,0.02,0.3c0.01,0.1,0.04,0.18,0.07,0.26c0.03,0.07,0.08,0.13,0.13,0.18
                    c0.06,0.04,0.12,0.07,0.2,0.07c0.15,0,0.26-0.05,0.33-0.16c0.07-0.11,0.12-0.27,0.14-0.49h0.79c-0.02,0.4-0.12,0.71-0.32,0.92
                    c-0.2,0.21-0.51,0.31-0.93,0.31c-0.32,0-0.57-0.05-0.75-0.16c-0.18-0.11-0.31-0.25-0.4-0.42c-0.09-0.18-0.14-0.37-0.16-0.59
                    c-0.02-0.22-0.03-0.44-0.03-0.65c0-0.23,0.02-0.45,0.05-0.67s0.1-0.4,0.19-0.57c0.1-0.17,0.23-0.3,0.41-0.4
                    c0.18-0.1,0.42-0.15,0.72-0.15c0.26,0,0.47,0.04,0.63,0.13c0.16,0.08,0.29,0.2,0.39,0.35c0.09,0.15,0.16,0.34,0.19,0.55
                    c0.03,0.22,0.05,0.46,0.05,0.72V84.72z M154.29,83.63c-0.05,0-0.09-0.01-0.14-0.01c-0.04,0-0.09,0-0.14,0
                    c-0.08,0-0.15,0.01-0.23,0.03c-0.08,0.02-0.15,0.06-0.21,0.11c-0.06,0.05-0.12,0.11-0.15,0.19s-0.06,0.18-0.06,0.29v2.07h-0.88
                    v-3.44h0.84v0.46h0.01c0.09-0.17,0.2-0.3,0.33-0.4c0.13-0.1,0.28-0.15,0.47-0.15c0.03,0,0.05,0,0.08,0c0.03,0,0.05,0.01,0.08,0.01
                    V83.63z M157.28,86.3h-0.88v-2.37c0-0.18-0.03-0.3-0.09-0.37c-0.06-0.07-0.16-0.11-0.29-0.11c-0.31,0-0.47,0.19-0.47,0.57v2.28
                    h-0.88v-3.44h0.84v0.4h0.01c0.09-0.17,0.22-0.3,0.36-0.37c0.15-0.08,0.32-0.12,0.51-0.12c0.28,0,0.5,0.08,0.65,0.23
                    c0.15,0.15,0.23,0.4,0.23,0.75V86.3z M162.2,86.3h-0.88V83.9c0-0.15-0.03-0.26-0.08-0.33c-0.05-0.08-0.14-0.12-0.27-0.12
                    c-0.14,0-0.25,0.05-0.34,0.14c-0.08,0.09-0.13,0.24-0.13,0.45v2.27h-0.88V83.9c0-0.15-0.03-0.26-0.08-0.33
                    c-0.05-0.08-0.14-0.12-0.27-0.12c-0.14,0-0.25,0.05-0.34,0.14c-0.08,0.09-0.12,0.24-0.12,0.45v2.27h-0.88v-3.44h0.84v0.35h0.01
                    c0.11-0.16,0.24-0.27,0.37-0.34c0.14-0.07,0.3-0.1,0.5-0.1c0.21,0,0.38,0.05,0.52,0.14c0.14,0.09,0.23,0.23,0.28,0.41h0.01
                    c0.07-0.18,0.18-0.32,0.33-0.41c0.15-0.09,0.33-0.14,0.54-0.14c0.29,0,0.51,0.09,0.65,0.27c0.14,0.18,0.21,0.43,0.21,0.76V86.3z
                    M165.35,84.72h-1.75v0.28c0,0.1,0.01,0.2,0.02,0.3c0.01,0.1,0.04,0.18,0.07,0.26c0.03,0.07,0.08,0.13,0.13,0.18
                    c0.06,0.04,0.12,0.07,0.2,0.07c0.15,0,0.26-0.05,0.33-0.16c0.07-0.11,0.12-0.27,0.14-0.49h0.79c-0.02,0.4-0.12,0.71-0.32,0.92
                    c-0.2,0.21-0.51,0.31-0.93,0.31c-0.32,0-0.57-0.05-0.75-0.16c-0.18-0.11-0.31-0.25-0.4-0.42c-0.09-0.18-0.14-0.37-0.16-0.59
                    c-0.02-0.22-0.03-0.44-0.03-0.65c0-0.23,0.02-0.45,0.05-0.67s0.1-0.4,0.19-0.57c0.1-0.17,0.23-0.3,0.41-0.4
                    c0.18-0.1,0.42-0.15,0.72-0.15c0.26,0,0.47,0.04,0.63,0.13c0.16,0.08,0.29,0.2,0.39,0.35c0.09,0.15,0.16,0.34,0.19,0.55
                    c0.03,0.22,0.05,0.46,0.05,0.72V84.72z M168.49,86.3h-0.88v-2.37c0-0.18-0.03-0.3-0.09-0.37c-0.06-0.07-0.16-0.11-0.29-0.11
                    c-0.31,0-0.47,0.19-0.47,0.57v2.28h-0.88v-3.44h0.84v0.4h0.01c0.09-0.17,0.22-0.3,0.36-0.37c0.15-0.08,0.32-0.12,0.51-0.12
                    c0.28,0,0.5,0.08,0.65,0.23c0.15,0.15,0.23,0.4,0.23,0.75V86.3z M166.4,65.46h-11.95l-2.16,6.14h-9.17l12.82-34.27h9.07
                    l12.82,34.27h-9.31L166.4,65.46z M170.73,83.46h-0.51v1.86c0,0.14,0.02,0.23,0.06,0.29c0.04,0.06,0.12,0.09,0.25,0.09
                    c0.03,0,0.07,0,0.1,0c0.03,0,0.07-0.01,0.1-0.01v0.61c-0.1,0-0.19,0-0.28,0.01c-0.09,0.01-0.19,0.01-0.29,0.01
                    c-0.17,0-0.31-0.01-0.42-0.04c-0.11-0.02-0.19-0.07-0.25-0.14c-0.06-0.07-0.1-0.16-0.12-0.28s-0.03-0.26-0.03-0.44v-1.97h-0.45
                    v-0.6h0.45v-0.99h0.88v0.99h0.51V83.46z M174.77,86.3l-0.21-0.97h-1.22l-0.21,0.97h-0.96l1.21-4.57h1.14l1.21,4.57H174.77z
                    M178.66,86.13c0,0.44-0.11,0.78-0.33,1c-0.22,0.22-0.59,0.33-1.1,0.33c-0.38,0-0.66-0.08-0.87-0.24c-0.2-0.16-0.3-0.39-0.3-0.68
                    h0.84c0,0.11,0.04,0.2,0.13,0.26c0.04,0.03,0.09,0.05,0.14,0.07c0.05,0.02,0.1,0.03,0.16,0.03c0.18,0,0.3-0.06,0.38-0.17
                    c0.07-0.12,0.11-0.26,0.11-0.43v-0.45h-0.01c-0.09,0.13-0.2,0.23-0.33,0.31s-0.27,0.12-0.41,0.12c-0.35,0-0.61-0.13-0.79-0.4
                    c-0.18-0.27-0.27-0.71-0.27-1.34c0-0.2,0.01-0.41,0.04-0.61c0.02-0.21,0.07-0.4,0.15-0.57c0.07-0.17,0.18-0.31,0.33-0.41
                    c0.14-0.11,0.33-0.16,0.57-0.16c0.12,0,0.25,0.03,0.38,0.1c0.13,0.07,0.24,0.19,0.33,0.37h0.01v-0.38h0.84V86.13z M177.93,25.77
                    h-1.02l-0.89-3.44h0.92l0.49,2.46h0.01l0.47-2.46h0.88L177.93,25.77z M179.06,24.02c0-0.23,0.02-0.45,0.05-0.67s0.1-0.4,0.19-0.57
                    c0.1-0.17,0.23-0.3,0.41-0.4c0.18-0.1,0.42-0.15,0.72-0.15c0.26,0,0.47,0.04,0.63,0.12c0.16,0.08,0.29,0.2,0.39,0.35
                    c0.09,0.15,0.16,0.33,0.19,0.55c0.03,0.22,0.05,0.46,0.05,0.72v0.2h-1.75v0.28c0,0.1,0.01,0.2,0.02,0.3
                    c0.01,0.1,0.04,0.18,0.07,0.26c0.03,0.07,0.08,0.13,0.13,0.18c0.06,0.04,0.12,0.07,0.2,0.07c0.15,0,0.26-0.05,0.33-0.16
                    c0.07-0.11,0.12-0.27,0.14-0.49h0.79c-0.02,0.4-0.12,0.71-0.32,0.92c-0.2,0.21-0.51,0.31-0.93,0.31c-0.32,0-0.57-0.05-0.75-0.16
                    c-0.18-0.11-0.31-0.25-0.4-0.42c-0.09-0.17-0.14-0.37-0.16-0.59C179.07,24.45,179.06,24.23,179.06,24.02z M181.85,84.72h-1.75v0.28
                    c0,0.1,0.01,0.2,0.02,0.3c0.01,0.1,0.04,0.18,0.07,0.26c0.03,0.07,0.08,0.13,0.13,0.18c0.06,0.04,0.12,0.07,0.2,0.07
                    c0.15,0,0.26-0.05,0.33-0.16c0.07-0.11,0.12-0.27,0.14-0.49h0.79c-0.02,0.4-0.12,0.71-0.32,0.92c-0.2,0.21-0.51,0.31-0.93,0.31
                    c-0.32,0-0.57-0.05-0.75-0.16c-0.18-0.11-0.31-0.25-0.4-0.42c-0.09-0.18-0.14-0.37-0.16-0.59c-0.02-0.22-0.03-0.44-0.03-0.65
                    c0-0.23,0.02-0.45,0.05-0.67s0.1-0.4,0.19-0.57c0.1-0.17,0.23-0.3,0.41-0.4c0.18-0.1,0.42-0.15,0.72-0.15
                    c0.26,0,0.47,0.04,0.63,0.13c0.16,0.08,0.29,0.2,0.39,0.35c0.09,0.15,0.16,0.34,0.19,0.55c0.03,0.22,0.05,0.46,0.05,0.72V84.72z
                    M182.21,22.33h0.84v0.46h0.01c0.09-0.17,0.2-0.3,0.33-0.4c0.13-0.1,0.28-0.15,0.47-0.15c0.03,0,0.05,0,0.08,0
                    c0.03,0,0.05,0.01,0.08,0.01v0.84c-0.05,0-0.09-0.01-0.14-0.01s-0.09,0-0.14,0c-0.08,0-0.15,0.01-0.23,0.03
                    c-0.08,0.02-0.15,0.06-0.21,0.11c-0.06,0.05-0.12,0.11-0.15,0.19s-0.06,0.18-0.06,0.29v2.07h-0.88V22.33z M184.99,86.3h-0.88v-2.37
                    c0-0.18-0.03-0.3-0.09-0.37c-0.06-0.07-0.16-0.11-0.29-0.11c-0.31,0-0.47,0.19-0.47,0.57v2.28h-0.88v-3.44h0.84v0.4h0.01
                    c0.09-0.17,0.22-0.3,0.36-0.37c0.15-0.08,0.32-0.12,0.51-0.12c0.28,0,0.5,0.08,0.65,0.23c0.15,0.15,0.23,0.4,0.23,0.75V86.3z
                    M185.29,23.48v2.28h-0.88v-3.44h0.84v0.4h0.01c0.09-0.17,0.22-0.3,0.36-0.37c0.15-0.08,0.32-0.12,0.51-0.12
                    c0.28,0,0.5,0.08,0.65,0.23c0.15,0.15,0.23,0.4,0.23,0.75v2.55h-0.88V23.4c0-0.18-0.03-0.3-0.09-0.37
                    c-0.06-0.07-0.16-0.11-0.29-0.11C185.45,22.92,185.29,23.11,185.29,23.48z M186.41,85.14c0.01,0.15,0.04,0.28,0.07,0.37
                    c0.03,0.1,0.08,0.17,0.14,0.21c0.06,0.04,0.14,0.07,0.24,0.07c0.15,0,0.26-0.06,0.33-0.19c0.07-0.13,0.1-0.33,0.1-0.61h0.84
                    c0,0.45-0.1,0.8-0.3,1.03c-0.2,0.24-0.54,0.36-1.01,0.36c-0.22,0-0.41-0.03-0.58-0.08c-0.16-0.05-0.3-0.15-0.41-0.29
                    c-0.11-0.14-0.19-0.32-0.25-0.56c-0.06-0.23-0.08-0.52-0.08-0.87c0-0.35,0.03-0.65,0.1-0.88c0.07-0.23,0.16-0.42,0.29-0.56
                    c0.12-0.14,0.27-0.24,0.44-0.29s0.36-0.08,0.56-0.08c0.41,0,0.72,0.12,0.92,0.36c0.21,0.24,0.31,0.56,0.31,0.97h-0.84
                    c0-0.09-0.01-0.17-0.02-0.25c-0.01-0.08-0.03-0.15-0.06-0.22c-0.03-0.07-0.07-0.12-0.12-0.16c-0.05-0.04-0.12-0.06-0.21-0.06
                    c-0.11,0-0.19,0.03-0.26,0.07c-0.07,0.05-0.12,0.12-0.15,0.22c-0.03,0.1-0.06,0.22-0.07,0.36c-0.01,0.15-0.02,0.32-0.02,0.52
                    C186.4,84.8,186.4,84.98,186.41,85.14z M190.22,86.07c-0.08,0.29-0.15,0.52-0.22,0.69c-0.07,0.18-0.15,0.31-0.25,0.41
                    c-0.1,0.1-0.21,0.16-0.35,0.19c-0.13,0.03-0.3,0.04-0.51,0.04h-0.36v-0.66c0.08,0.01,0.15,0.02,0.23,0.02
                    c0.16,0,0.28-0.04,0.35-0.13c0.07-0.09,0.11-0.2,0.13-0.34l-0.97-3.44h0.92l0.51,2.38h0.01l0.45-2.38h0.88L190.22,86.07z
                    M191.93,25.77h-0.88v-2.41c0-0.14-0.03-0.26-0.08-0.33c-0.05-0.08-0.14-0.12-0.27-0.12c-0.14,0-0.25,0.05-0.34,0.14
                    c-0.08,0.09-0.13,0.24-0.13,0.45v2.27h-0.88v-2.41c0-0.14-0.03-0.26-0.08-0.33c-0.05-0.08-0.14-0.12-0.27-0.12
                    c-0.14,0-0.25,0.05-0.34,0.14c-0.08,0.09-0.12,0.24-0.12,0.45v2.27h-0.88v-3.44h0.84v0.35h0.01c0.11-0.16,0.24-0.27,0.37-0.34
                    c0.14-0.07,0.3-0.1,0.5-0.1c0.21,0,0.38,0.05,0.52,0.14c0.14,0.09,0.23,0.23,0.28,0.41h0.01c0.07-0.18,0.18-0.32,0.33-0.41
                    c0.15-0.09,0.33-0.14,0.54-0.14c0.29,0,0.51,0.09,0.65,0.27c0.14,0.18,0.21,0.43,0.21,0.76V25.77z M195.09,24.18h-1.75v0.28
                    c0,0.1,0.01,0.2,0.02,0.3c0.01,0.1,0.04,0.18,0.07,0.26c0.03,0.07,0.08,0.13,0.13,0.18c0.06,0.04,0.12,0.07,0.2,0.07
                    c0.15,0,0.26-0.05,0.33-0.16c0.07-0.11,0.12-0.27,0.14-0.49h0.79c-0.02,0.4-0.12,0.71-0.32,0.92c-0.2,0.21-0.51,0.31-0.93,0.31
                    c-0.32,0-0.57-0.05-0.75-0.16c-0.18-0.11-0.31-0.25-0.4-0.42c-0.09-0.17-0.14-0.37-0.16-0.59c-0.02-0.22-0.03-0.44-0.03-0.65
                    c0-0.23,0.02-0.45,0.05-0.67s0.1-0.4,0.19-0.57c0.1-0.17,0.23-0.3,0.41-0.4c0.18-0.1,0.42-0.15,0.72-0.15
                    c0.26,0,0.47,0.04,0.63,0.12c0.16,0.08,0.29,0.2,0.39,0.35c0.09,0.15,0.16,0.33,0.19,0.55c0.03,0.22,0.05,0.46,0.05,0.72V24.18z
                    M198.23,25.77h-0.88V23.4c0-0.18-0.03-0.3-0.09-0.37c-0.06-0.07-0.16-0.11-0.29-0.11c-0.31,0-0.47,0.19-0.47,0.57v2.28h-0.88
                    v-3.44h0.84v0.4h0.01c0.09-0.17,0.22-0.3,0.36-0.37c0.15-0.08,0.32-0.12,0.51-0.12c0.28,0,0.5,0.08,0.65,0.23
                    c0.15,0.15,0.23,0.4,0.23,0.75V25.77z M200.47,22.93h-0.51v1.86c0,0.14,0.02,0.23,0.06,0.29c0.04,0.06,0.12,0.09,0.25,0.09
                    c0.03,0,0.07,0,0.1,0c0.03,0,0.07-0.01,0.1-0.01v0.61c-0.1,0-0.19,0-0.28,0.01c-0.09,0.01-0.19,0.01-0.29,0.01
                    c-0.17,0-0.31-0.01-0.42-0.04c-0.11-0.02-0.19-0.07-0.25-0.14c-0.06-0.07-0.1-0.16-0.12-0.28c-0.02-0.12-0.03-0.26-0.03-0.44v-1.97
                    h-0.45v-0.6h0.45v-0.99h0.88v0.99h0.51V22.93z"
				/>
				<path
					class="st3"
					d="M180.82,23.31c-0.01-0.1-0.04-0.19-0.07-0.26c-0.03-0.07-0.08-0.12-0.13-0.16c-0.06-0.04-0.13-0.05-0.22-0.05
                    c-0.09,0-0.16,0.02-0.22,0.06c-0.06,0.04-0.1,0.09-0.14,0.16c-0.03,0.07-0.06,0.14-0.07,0.22c-0.01,0.08-0.02,0.16-0.02,0.24v0.13
                    h0.9C180.84,23.53,180.83,23.41,180.82,23.31z"
				/>
				<path
					class="st3"
					d="M124.82,84.75c-0.18,0.04-0.31,0.1-0.38,0.19c-0.08,0.09-0.12,0.21-0.12,0.36c0,0.13,0.03,0.25,0.08,0.34
                    c0.05,0.09,0.14,0.14,0.26,0.14c0.06,0,0.12-0.01,0.19-0.03c0.06-0.02,0.12-0.05,0.18-0.09c0.05-0.04,0.1-0.1,0.13-0.17
                    c0.03-0.07,0.05-0.15,0.05-0.24v-0.68c-0.06,0.05-0.12,0.08-0.19,0.11C124.93,84.72,124.87,84.74,124.82,84.75z"
				/>
				<path
					class="st3"
					d="M151.01,83.59c-0.03-0.07-0.08-0.12-0.13-0.16c-0.06-0.04-0.13-0.05-0.22-0.05c-0.09,0-0.16,0.02-0.22,0.06
                    c-0.06,0.04-0.1,0.09-0.14,0.16c-0.03,0.07-0.06,0.14-0.07,0.22c-0.01,0.08-0.02,0.16-0.02,0.24v0.13h0.9
                    c0-0.13-0.01-0.25-0.03-0.35C151.07,83.74,151.04,83.66,151.01,83.59z"
				/>
				<path
					class="st3"
					d="M115.47,83.37c-0.11,0-0.19,0.03-0.26,0.09c-0.06,0.06-0.11,0.14-0.14,0.25c-0.03,0.11-0.05,0.24-0.06,0.38
                    c-0.01,0.15-0.01,0.31-0.01,0.49c0,0.18,0,0.34,0.01,0.49c0.01,0.15,0.03,0.28,0.06,0.38s0.08,0.19,0.14,0.25
                    c0.06,0.06,0.15,0.09,0.26,0.09c0.18,0,0.3-0.09,0.37-0.28c0.07-0.19,0.1-0.5,0.1-0.93c0-0.43-0.03-0.74-0.1-0.93
                    C115.77,83.47,115.64,83.37,115.47,83.37z"
				/>
			</g>
		</g>
	</svg>
</template>





<script>

    export default {

        name: 'NCUALogo'

    };

</script>