<template functional>

    <g>
        <path d="M12.2,16.4c0,0.2-0.2,0.4-0.4,0.4H1.1c-0.2,0-0.4-0.2-0.4-0.4V3.6l2.4-2.4h8.8c0.2,0,0.4,0.2,0.4,0.4v3.7H13V1.6
            c0-0.6-0.5-1.1-1.2-1.1h-9L0,3.3v13.1c0,0.6,0.5,1.1,1.1,1.1h10.8c0.6,0,1.1-0.5,1.1-1.1V12h-0.8V16.4z"/>
        <rect x="3.9" y="4.7" width="5.2" height="0.7"/>
        <rect x="3.9" y="6.2" width="2.2" height="0.7"/>
        <rect x="3.9" y="3.2" width="5.2" height="0.7"/>
        <path d="M7.5,13.3l-0.3,2l1.9-0.7c0.5-0.1,1-0.5,1.4-0.9L18,4.8l-0.1-0.2c-0.1-0.5-0.3-0.9-0.7-1.2c-0.4-0.3-0.8-0.5-1.3-0.5h-0.2
            l-7.5,8.9C7.8,12.3,7.6,12.8,7.5,13.3z M10.8,12.1l-1.1-1L15,4.9l1.1,1L10.8,12.1z M16,3.7c0.3,0,0.5,0.1,0.7,0.3
            c0.2,0.1,0.4,0.4,0.5,0.6l-0.6,0.8l-1.1-1L16,3.7z M9.2,11.7l1.1,1l-0.4,0.5c-0.3,0.3-0.6,0.6-1,0.7l-0.8,0.3l0.1-0.8
            c0-0.4,0.2-0.8,0.5-1.1L9.2,11.7z"/>
    </g>

</template>





<script>

    export default {

        name: 'Terms'

    }

</script>