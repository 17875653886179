import axios from '@/utils/axios';

export interface IGetAffinityConfigurationsResponse {
	affinityConfigurations: IAffinityConfiguration[];
	affiliationTriggers: IAffiliateTriggerConfiguration[];
}
export interface IAffiliateTriggerConfiguration {
	AffiliateId: number;
	triggerTypeId: number;
	Value: string;
	AffinityFieldLabel: string;
}
export interface IAffinityConfiguration {
	Id: number;
	Label: string;
	Value: string;
	RequiresIdentifier: boolean;
	IdentifierLabel: string;
	IdentifierValidationRules: string;
	AffiliateId: number;
}

export const getAffiliationTriggerConfigurations = async (): Promise<IGetAffinityConfigurationsResponse> => {
	try {
		const { data: response } = await axios.get<IGetAffinityConfigurationsResponse>(
			`/api/v2/Configuration/AffiliateTriggerConfigurations`
		);
		return response;
	} catch (error) {
		
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};
