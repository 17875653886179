<template>

    <section>

        <div 
            v-for="(option, index) in options"
            :key="option.label"
            :class="[ 'radio-group', { 'no-margin': index + 1 === options.length, 'is-condensed': false } ]"
        >

            <ValidationProvider
                :rules="validationRules"
                :name="name"
                v-slot="{ errors }"
                slim
            >
                <OaoRadio
                    v-model="innerValue"
                    :optionValue="option.value"
                    :label="option.label"
                    :name="name"
                    :disabled="disabled || option.disabled"
                />

                <transition name="fade">
                    <p
                        class="has-error"
                        v-if="errors.length > 0"
                        data-test-error
                    >
                        {{ errors[0] }}
                    </p>
                </transition>
            </ValidationProvider>

            <slot v-if="$slots.default && innerValue === option.value"/>

        </div>

    </section>

</template>




<script>

    import { ValidationProvider } from 'vee-validate';
    
    import { OaoRadioDep as OaoRadio } from '@/components/form';

    export default {

        name: 'RadioGroup',
        components: {

            ValidationProvider,
            OaoRadio

        },
        props: {

            value: {
                required: true
            },
            name: {
                type: String,
                required: true
            },
            options: {
                type: Array,
                required: true
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            },
            validationRules: {
                type: [ String, Object],
                required: false
            },
            isCondensed: {
                type: Boolean,
                required: false,
                default: false
            }

        },
        computed: {
            
            innerValue: {

                get() {

                    return this.value;

                },
                set(val) {

                    this.$emit('input', val);

                }

            }

        }

    }

</script>




<style lang="scss" scoped>

    .radio-group {
        margin-bottom: 3rem;

        &.is-condensed {
            margin-bottom: 1.5rem;
        }

        &.no-margin {
            margin-bottom: 0;
        }

    }

    .has-error {
        color: var(--danger);

        &section {
            outline: 1px solid var(--danger);
        }

    }

</style>