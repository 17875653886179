<template functional>

    <path d="M1.6,18C1.6,18,1.5,18,1.6,18C1.5,18,1.5,18,1.6,18c-0.1,0-0.4,0-0.6-0.2c-0.2-0.2-0.3-0.4-0.3-0.7L0.6,2.5
	C0.3,2.3,0,1.8,0,1.4C0,0.6,0.6,0,1.4,0c0.7,0,1.4,0.6,1.4,1.4c0,0.4-0.2,0.8-0.5,1L2.4,17c0,0.3-0.1,0.6-0.3,0.8
	C1.9,18,1.7,18,1.6,18z M1.5,17.4C1.6,17.4,1.6,17.4,1.5,17.4c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.4L1.7,2.7
	c-0.2,0-0.3,0.1-0.5,0l0.1,14.4c0,0.1,0,0.2,0.1,0.3C1.4,17.4,1.5,17.4,1.5,17.4L1.5,17.4C1.5,17.4,1.5,17.4,1.5,17.4z M1.4,0.6
	c-0.4,0-0.8,0.4-0.8,0.8s0.4,0.8,0.8,0.8s0.8-0.4,0.8-0.8S1.8,0.6,1.4,0.6z M3.4,11.4c-0.3,0-0.6-0.2-0.6-0.5L2.7,3.1
	c0-0.1,0-0.2,0.1-0.2c0.7-0.5,4.1-2,5.9-0.9c1.2,0.8,3.5-0.2,4.9-0.9c0.2-0.1,0.4-0.2,0.5-0.2c0.1,0,0.2,0,0.3,0
	c0.1,0,0.1,0.1,0.1,0.2l0.2,8.2c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.3,0.1-0.5,0.2c-1.7,0.8-4,1.8-5.5,0.9c-1.3-0.8-3.8,0.1-4.9,0.7
	C3.6,11.4,3.4,11.4,3.4,11.4z M3.3,10.9C3.3,10.9,3.3,10.9,3.3,10.9C3.3,10.9,3.3,10.9,3.3,10.9z M3.4,10.8L3.4,10.8
	C3.4,10.8,3.4,10.8,3.4,10.8z M3.4,10.8C3.4,10.8,3.4,10.8,3.4,10.8C3.4,10.8,3.4,10.8,3.4,10.8C3.4,10.8,3.4,10.8,3.4,10.8z
	 M3.2,3.2l0.2,7.6c1.1-0.6,3.9-1.5,5.4-0.6c1.2,0.8,3.5-0.2,5-0.9c0.1,0,0.2-0.1,0.3-0.1l-0.2-7.6c0,0-0.1,0-0.1,0.1
	c-1.7,0.8-4,1.8-5.4,0.8C7,1.6,4.1,2.7,3.2,3.2z"/>

</template>





<script>

    export default {

        name: 'FlagIcon'

    }

</script>