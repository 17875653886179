import axios from '@/utils/axios';

export interface IAddressVerificationResult {
	verifyAddressStatus: number;
	addressSuggestions: [];
	isInvalidPOBox: boolean;
}

export interface IAddressCreateResponse {
	addressId: number;
	addressVerificationResult: IAddressVerificationResult
}

export interface IAddressUpdateResponse {
	updated: boolean;
	addressVerificationResult: IAddressVerificationResult
}

export interface IAddress {
	id: number;
	addressCoreId: number;
	street: string;
	secondaryLine: string;
	city: string;
	state: string;
	postalCode: string;
	addressCategory: number;
	isPrimary: boolean
	bypassVerification: boolean

}

export interface IAddressesGetResponse {
	addresses: IAddress[] | null;
}

const getApplicantAddresses = async (applicantToken: string): Promise<IAddressesGetResponse> => {
	try {
		const {	data } = await axios.get<IAddressesGetResponse>(`api/v2/Applicant/${applicantToken}/Addresses`);

		return data;
	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}

};

const createApplicantAddress = async (request: any): Promise<IAddressCreateResponse> => {
	try {
		const { data } = await axios.post<IAddressCreateResponse>(`api/v2/Address`, request);

		return data;
	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};

const updateApplicantAddress = async (request: any): Promise<IAddressUpdateResponse> => {
	try {
		const { data } = await axios.put<IAddressUpdateResponse>(`api/v2/Address`, request);

		return data;
	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
}

export default {
	getApplicantAddresses,
	createApplicantAddress,
	updateApplicantAddress
};

export {
	getApplicantAddresses,
	createApplicantAddress,
	updateApplicantAddress };