<template functional>

    <path d="M21.7,49c-0.2,0-0.3,0-0.5-0.1c-0.4,0.2-0.9,0.2-1.2-0.2c-0.3-0.3-0.4-0.8-0.2-1.2L10,37.7c0,0,0,0,0,0
	l-1.3-1.3L5.1,40l7.3,7.3c0.4,0.4,0.4,1,0,1.4s-1,0.4-1.4,0l-8-8c-0.4-0.4-0.4-1,0-1.4l5-5c0.4-0.4,1-0.4,1.4,0l1.1,1.1
	c0.7-1.2,1.4-2.3,1.5-2.5c0.1-0.3,0.1-1.4,0.2-2.2c0-1.8,0.1-4.1,0.8-6c0.7-1.7,1.1-2.9,1.7-5.1c0.3-1.1,0.7-2.4,1.3-4.1
	c0.2-0.5,0.5-0.9,0.9-1.2c0.3-0.2,0.5-0.3,0.8-0.4V5c0-2.2,1.8-4,4-4h17c2.2,0,4,1.8,4,4v3.2c1.2-0.5,2.6-0.2,3.6,0.8l0.1,0.1
	c1.3,1.3,1.3,3.3,0,4.6l-1,0.9c0.2,0.1,0.4,0.3,0.6,0.4l0.1,0.1c0.6,0.5,1,1.2,1.1,2.1c0.1,0.8-0.1,1.6-0.6,2.3c0,0-0.1,0.1-0.1,0.1
	l-1,1.2c0.2,0.1,0.3,0.2,0.5,0.3c0,0,0.1,0.1,0.1,0.1l0.1,0.1c1.3,1.1,1.5,3.1,0.4,4.4l-1.2,1.5c0.2,0.1,0.4,0.2,0.5,0.4
	c0,0,0.1,0.1,0.1,0.1l0.1,0.1c1.3,1.1,1.4,3,0.4,4.3l-2,2.3c-0.3,0.4-0.8,0.6-1.4,0.7c-0.1,0-0.3,0-0.4,0v1c0,2.2-1.8,4-4,4h-2.5
	c-1.1,1.6-4.6,6.4-12.1,6c-0.5,0.4-1,1-1.5,1.5c0.2,0.4,0.2,0.9-0.2,1.2C22.2,48.9,21.9,49,21.7,49z M11.9,36.9l9.2,9.2
	c0.6-0.6,1.3-1.3,1.8-1.8c0.2-0.2,0.5-0.3,0.8-0.3c5.4,0.4,8.4-2.2,9.9-4H23c-0.1,0.5-0.3,0.9-0.4,1.3c-0.2,0.5-0.8,0.8-1.3,0.6
	c-0.5-0.2-0.8-0.8-0.6-1.3c0.1-0.3,0.2-0.6,0.3-0.9c-0.2-0.2-0.4-0.5-0.4-0.8c0-0.5,0.3-0.9,0.7-1c0.6-3.7-0.3-7.6-2.5-10.7
	c-0.2-0.3-0.2-0.6-0.1-0.9c0.8-2.2,1.2-4.6,1.4-6.9c0.1-1.2-0.3-2.5-1.1-3.5c0,0-0.1-0.1-0.1-0.1c-0.3,0.1-0.6,0.1-0.9-0.1
	c0,0,0,0-0.1,0c-0.1,0.1-0.2,0.2-0.2,0.3c-0.5,1.6-0.9,2.9-1.2,4c-0.7,2.2-1.1,3.5-1.8,5.3c-0.6,1.6-0.6,3.6-0.7,5.3
	c0,1.4,0,2.3-0.3,3C13.8,33.8,13.7,34,11.9,36.9z M35.7,38h3c1.1,0,2-0.9,2-2v-1.9c0,0,0,0,0,0c0,0,0,0,0,0L40.5,34
	c-0.7-0.6-1.2-1.5-1.3-2.3c0-0.6,0.1-1.1,0.5-1.5l1.7-1.9c-0.2-0.1-0.3-0.2-0.5-0.3c-0.1,0-0.1-0.1-0.2-0.1l-0.2-0.1
	c-0.8-0.7-1.3-1.5-1.3-2.4c0-0.6,0.1-1.1,0.5-1.5l1.5-1.8c-0.2-0.1-0.3-0.2-0.5-0.3c0,0-0.1-0.1-0.1-0.1l-0.1-0.1
	c-1.3-1.1-1.7-2.8-0.8-3.8l1.4-1.6c-0.3-0.2-0.7-0.4-1-0.7c0,0-0.1-0.1-0.1-0.1l-0.2-0.2c-1.3-1.3-1.5-3.1-0.5-4l1.4-1.3V5
	c0-1.1-0.9-2-2-2h-17c-1.1,0-2,0.9-2,2v9c0.4,0.2,0.8,0.5,1,0.8c1.1,1.4,1.6,3.1,1.5,4.8c-0.2,2.4-0.6,4.7-1.3,6.9
	C23.1,30,24,34,23.4,38H35.7C35.7,38,35.7,38,35.7,38z M42,32.6c0.5,0.4,0.9,0.5,1.1,0.4l2-2.3c0.4-0.4,0.3-1.1-0.1-1.5l-0.2-0.2
	c-0.5-0.4-1.1-0.4-1.5,0.1l-2,2.3c0,0.1,0.1,0.5,0.6,0.9L42,32.6z M41.8,26.2c0.1,0,0.1,0.1,0.2,0.1c0.5,0.4,1,0.5,1.1,0.4l2-2.3
	c0.4-0.5,0.3-1.1-0.1-1.5l-0.2-0.1c-0.2-0.2-0.5-0.3-0.8-0.3c-0.2,0.1-0.4,0.1-0.6,0.2c-0.1,0.1-0.2,0.1-0.2,0.2l-2,2.3
	C41.2,25.3,41.3,25.7,41.8,26.2L41.8,26.2z M42.1,20.3c0.3,0.3,0.6,0.4,0.8,0.4c0.1,0,0.2-0.1,0.3-0.1l1.9-2.2
	c0.2-0.3,0.3-0.5,0.2-0.8c0-0.3-0.2-0.5-0.4-0.7l-0.2-0.2c-0.5-0.4-1.2-0.4-1.6,0.1l-2,2.3c0,0.1,0.1,0.5,0.6,1L42.1,20.3
	C42,20.2,42.1,20.2,42.1,20.3z M41.3,13.9C41.4,13.9,41.4,13.9,41.3,13.9c0.6,0.6,1.2,0.8,1.3,0.7l2.4-2.2c0.4-0.5,0.4-1.2,0-1.7
	l-0.2-0.1c-0.5-0.6-1.3-0.6-1.8-0.1l-2.4,2.2C40.6,12.7,40.7,13.2,41.3,13.9L41.3,13.9z M9.7,42c-0.3,0-0.5-0.1-0.7-0.3l-1-1
	c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l1,1c0.4,0.4,0.4,1,0,1.4C10.2,41.9,9.9,42,9.7,42z M31.7,37h-3c-0.6,0-1-0.4-1-1s0.4-1,1-1h3
	c0.6,0,1,0.4,1,1S32.2,37,31.7,37z M35.7,32h-10c-0.6,0-1-0.4-1-1v-9c0-0.6,0.4-1,1-1h7c0.6,0,1,0.4,1,1s-0.4,1-1,1h-6v7h8v-4
	c0-0.6,0.4-1,1-1s1,0.4,1,1v5C36.7,31.6,36.2,32,35.7,32z M30.7,29C30.6,29,30.6,29,30.7,29c-0.4,0-0.7-0.2-0.8-0.4l-2-3
	c-0.3-0.5-0.2-1.1,0.3-1.4c0.5-0.3,1.1-0.2,1.4,0.3l1.3,2l4.1-4.1c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4l-5,5C31.2,28.9,30.9,29,30.7,29z
	 M35.7,18h-10c-0.6,0-1-0.4-1-1V8c0-0.6,0.4-1,1-1h7c0.6,0,1,0.4,1,1s-0.4,1-1,1h-6v7h8v-4c0-0.6,0.4-1,1-1s1,0.4,1,1v5
	C36.7,17.6,36.2,18,35.7,18z M30.7,15C30.6,15,30.6,15,30.7,15c-0.4,0-0.7-0.2-0.8-0.4l-2-3c-0.3-0.5-0.2-1.1,0.3-1.4
	c0.5-0.3,1.1-0.2,1.4,0.3l1.3,2L35,8.3c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4l-5,5C31.2,14.9,30.9,15,30.7,15z"/>

</template>





<script>

    export default {

        name: 'MobileCheckIcon'

    }

</script>