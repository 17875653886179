
export default (captchaImplementation, configs) => {

    let config = { enabled: false, implementation: captchaImplementation };
    let index = configs.findIndex(config => config.implementation === captchaImplementation);

    if (index >= 0) {

        config = configs[index];

    }

    return config;
};