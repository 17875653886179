import axios from '@/utils/axios';
import { ICaptchaResult } from './authenticate';

export enum CaptchaAction {
	None = 0,
	Deny,
	ForceCaptcha
}

export interface IApplicationEmailSendRequest {
	applicationToken: string;
	emailAddress: string;
}
export interface IApplicationEmailSendResponse {
	completed: boolean;
}
export interface IApplicationFileGetRequest {
	applicationToken: string;
	captchaResults: ICaptchaResult | [];
}
export interface IApplicationFileGetResponse {
	fileData: string;
	captchaAction: CaptchaAction;
}

const sendApplicationEmailAsync = async (request: IApplicationEmailSendRequest): Promise<IApplicationEmailSendResponse> => {
	try {
		const { data } = await axios.post<IApplicationEmailSendResponse>('/api/v2/Print/Email', request);

		return data;
	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};
const getApplicationFileAsync = async (request: IApplicationFileGetRequest): Promise<IApplicationFileGetResponse> => {
	try {
		const { data } = await axios.post<IApplicationFileGetResponse>('/api/v2/Print/File', request);

		return data;
	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};

export { getApplicationFileAsync, sendApplicationEmailAsync };
