import { Module } from 'vuex';
import { IRootState } from '..';
import setData from '../shared/setData';

export interface IPageOptionsState {
	currentHeader: string | null;
}

const pageOptions: Module<IPageOptionsState, IRootState> = {
	namespaced: true,
	state: {
		currentHeader: null
	},
	mutations: {
		setData
	}
};

export default pageOptions;
