import _cloneDeep from 'lodash.clonedeep';
import { addressAPIv2 } from '@/api';
import { IAddressCreateResponse, IAddressUpdateResponse, IAddress } from '@/api/_v2/address';



export interface IAddressState {
	addresses: IAddress[] | null;
}

const state: IAddressState = {
	addresses: []
};

const actions = {
	async getAddresses({ state, commit }: any, resync: boolean): Promise<IAddress[] | null> {
		try {
			let addresses: IAddress[] | null = state.addresses;

			if (resync || state.addresses == null || state.addresses.length === 0) {
				let response = await addressAPIv2.getApplicantAddresses(state.applicantToken);
				addresses = response.addresses;
				commit('setData', { objName: 'addresses', data: response.addresses });
			}

			return _cloneDeep(addresses);
		} catch (error) {
		
			if (typeof error === 'string') {
				throw new Error(error);
			}

			throw error;
		}
	},
	async getAddress(
		{ state, commit }: any,
		addressCategory: number,
		resync: boolean = false
	): Promise<IAddress | null> {
		try {
			let address: IAddress | null = null;
			let addresses: IAddress[] | null = state.addresses;

			if (resync || state.addresses == null || state.addresses.length === 0) {
				let response = await addressAPIv2.getApplicantAddresses(state.applicantToken);
				addresses = response.addresses;

				commit('setData', { objName: 'addresses', data: response.addresses });
			}

			if (addresses && addresses.length > 0) {
				let match: IAddress | undefined = addresses.find(x => x.addressCategory === addressCategory);

				if (match) {
					address = match;
				}
			}

			return _cloneDeep(address);
		} catch (error) {
		
			if (typeof error === 'string') {
				throw new Error(error);
			}

			throw error;
		}
	},
	async createAddress({ dispatch, state, rootState }: any, request: any): Promise<IAddressCreateResponse> {
		try {
			request.applicantToken = state.applicantToken;
			request.applicationToken = rootState.application.applicationToken;

			const response = await addressAPIv2.createApplicantAddress(request);

			return response;
		} catch (error) {
			
			if (typeof error === 'string') {
				throw new Error(error);
			}

			throw error;
		}
	},
	async updateAddress({ dispatch, state, rootState }: any, request: any): Promise<IAddressUpdateResponse> {
		try {
			request.applicantToken = state.applicantToken;
			request.applicationToken = rootState.application.applicationToken;

			const response = await addressAPIv2.updateApplicantAddress(request);
			return response;
		} catch (error) {
			
			if (typeof error === 'string') {
				throw new Error(error);
			}

			throw error;
		}
	}
};

export { state, actions };

export default {
	state,
	actions
};
