import { Module } from 'vuex';
import { IRootState } from '..';
import setData from '../shared/setData';
import { applicantAPIv2, applicationAPIv2, authenticateAPIv2 } from '@/api';
import { IAuthenticateSSORequest, ICaptchaResult } from '@/api/_v2/authenticate';
import filterInsufficientAccounts from '@/utils/filterInsufficientAccounts';
import localStorage from '@/store/localStorage';
import { AuthenticationTypes } from '@/constants';

export interface IApplicationState {
	applicationToken: string;
	sessionToken: string;
	authenticated: boolean;
	applicationType: string;
	encodedApplicationId: string;
	fundingLimitConfigurations: [];
	selectedApplicationProducts: [];
	promotionalCreditGLConfiguration: any;
	primaryApplicantIsEmployee: boolean;
	allApplicantTransferAccounts: [];
	authenticationType: number;
}


const application: Module<IApplicationState, IRootState> = {
	namespaced: true,
	state: {
		applicationToken: '',
		sessionToken: '',
		authenticated: false,
		applicationType: '',
		encodedApplicationId: '',
		fundingLimitConfigurations: [],
		selectedApplicationProducts: [],
		promotionalCreditGLConfiguration: null,
		primaryApplicantIsEmployee: false,
		allApplicantTransferAccounts: [],
		authenticationType: 0

	},
	mutations: {
		setData,
		resetState(state) {
			state.applicationToken = '';
			state.sessionToken = '';
			state.authenticated = false;
			state.applicationType = '';
			state.encodedApplicationId = '';
			state.fundingLimitConfigurations = [];
			state.selectedApplicationProducts = [];
			state.promotionalCreditGLConfiguration = null;
			state.primaryApplicantIsEmployee = false;
			state.allApplicantTransferAccounts = [];

		},
		setApplication(state, { applicationToken, authenticated, applicationType, sessionToken = '', encodedApplicationId = '' }) {
			state.applicationToken = applicationToken;
			state.sessionToken = sessionToken;
			state.authenticated = authenticated;
			state.applicationType = applicationType;
			state.encodedApplicationId = encodedApplicationId;
		}
	},
	actions: {
		async initializeApplication(
			{ dispatch, commit },
			{ selectedModel, userInput, captchaValidationToken, memberAdvocateId, captchaResults, applicantType }
		) {

			try {
				const clientIp = (await applicantAPIv2.getApplicantIp()) || '127.0.0.1';
				let result: any;

				if (selectedModel === 'sso') {
					const request: IAuthenticateSSORequest = {
						sso: userInput,
						clientIp: clientIp,
						loadUrl: window.location.href, 
						captchaResults: captchaResults,
						applicantType: applicantType
					};
					const ssoAuthResponse = await authenticateAPIv2.authenticateSSO(request);
					result = ssoAuthResponse;
					if (ssoAuthResponse.applicationToken) {
						dispatch(
							'applicant/setApplicantData',
							{ data: ssoAuthResponse },
							{ root: true }
						);
						await dispatch('setApplicationType', 'single');
						commit('setData', { objName: 'authenticated', data: true });
						commit('setData', { objName: 'authenticationType', data: AuthenticationTypes.SSO });
					}
				}
				const startApplicationRequest = {
					...userInput,
					validationToken: captchaValidationToken,
					clientIp: clientIp,
					memberAdvocateId: memberAdvocateId,
					captchaResults: captchaResults,
					loadUrl: window.location.href,
					applicantType: applicantType
				};

				if (selectedModel === 'authenticate') {
					const authResponse = await authenticateAPIv2.authenticate(startApplicationRequest);
					result = authResponse;
					if (authResponse.applicationToken != null) {
						dispatch(
							'applicant/setApplicantData',
							{ data: authResponse },
							{ root: true }
						);
						commit('setData', { objName: 'authenticated', data: true });
						commit('setData', { objName: 'authenticationType', data: AuthenticationTypes.AUTHENTICATE });
					}
				}

				if (selectedModel === 'applicant') {
					const applicantData = await dispatch('applicant/createApplicant', startApplicationRequest, {
						root: true
					});
					dispatch('applicant/setApplicantData', { data: applicantData }, { root: true });
					//enable deviceID sdk for fraud monitoring
					await dispatch('identityVerification/initDeviceId', {}, { root: true });
					
					result = applicantData;
				}
		
				commit('setData', { objName: 'applicationToken', data: result.applicationToken });
				localStorage.setOrOverwrite('encodedApplicationId', result.key);
				commit('setData', { objName: 'authenticationType', data: AuthenticationTypes.APPLICANT });

				return result;
			} catch (error) {
				throw error;
			}
		},
		setApplicationType({ commit, state }, applicationType: 'none' | 'joint' | 'single'): void {
			try {

				commit('setData', { objName: 'applicationType', data: applicationType });

			} catch (error) {
				throw error;
			}
		},
		setEncodedApplicationId({ commit, state }, encodedId: string) {
			commit('setData', { objName: 'encodedApplicationId', data: encodedId });
		},
		async getApplicantFundingData({commit, state }, request: any) {
			try {
				const response = await applicantAPIv2.getApplicantFundingData(request);

				if (response.fundingLimitConfigurations.length) {
					commit('setData', { objName: 'fundingLimitConfigurations', data: response.fundingLimitConfigurations });
					commit('setData', { objName: 'selectedApplicationProducts', data: response.selectedProducts });
					commit('setData', { objName: 'promotionalCreditGLConfiguration', data: response.promotionalCreditGLConfiguration });
					commit('setData', { objName: 'primaryApplicantIsEmployee', data: response.isEmployee });

					if (response.transferAccounts) {
						commit('setData', { objName: 'allApplicantTransferAccounts', data: response.transferAccounts });
					}
				}
				
				return response;

			} catch (error) {
				throw error;
			}
		},
		async abandonApplication({state})
		{
			try {
				if (state.applicationToken) {
					const response = applicationAPIv2.abandonApplication(state.applicationToken);
					return response;
				} else  {
					const response = applicationAPIv2.clearApplicationSession();
					return response;
				}
			} 
			catch(error)
			{
				//do nothing 
			}
		}
	},
	getters: {
		applicationToken: state => state.applicationToken,
		isAuthenticated: state => state.authenticated,
		applicationType: state => state.applicationType,
		encodedApplicationId: state => state.encodedApplicationId,
		selectedApplicationProducts: state => state.selectedApplicationProducts,
		fundingLimitConfigs: state => state.fundingLimitConfigurations,
		promotionalCreditConfig: state => state.promotionalCreditGLConfiguration,
		primaryIsEmployee: state => state.primaryApplicantIsEmployee,
		allApplicantTransferAccounts: state => state.allApplicantTransferAccounts
	}
};

export default application;
