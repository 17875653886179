const filterInsufficientAccounts = (total, accounts) =>
	accounts
		.filter(account => account.availableBalance > 0 && account.availableBalance >= total)
		.map(({ maskedSubAccountNumber, availableBalance, ...account }) => {
			return {
				label: maskedSubAccountNumber,
				value: {
					...account,
					availableBalance: availableBalance.toFixed(2)
				}
			};
		});

export default filterInsufficientAccounts;
