import axios from '@/utils/axios';
import filterInsufficientAccounts from '@/utils/filterInsufficientAccounts';
import { FundingSelectionTypes } from '@/defaultData';
import { Module } from 'vuex';
import { IRootState } from '..';
import setData from '../shared/setData';
import { applicantAPIv2 } from '@/api';
import { IApplicantFundingDataUpdateResponse } from '@/api/_v2/applicant';
import { FundingTypes } from '@/constants';
import { fundingAPIv2 } from '@/api';
import { ICreateACHPaymentResponse, IGetCardPaymentDataResponse } from '@/api/_v2/funding';

interface IAccount {
    productName: string;
    availableBalance: number;
    subAccountIndicator: string;
    coreProductId: string;
    subAccountSuffix: string;
    coreAccountId: number;
    maskedSubAccountNumber: string;
}

interface IFundingProduct {
    productId: number;
    categoryId: number;
    productName: string;
    displayOrder: number;
    isAccountRequired: boolean;
    benefits: string;
    helpText: string;
    discountMessage: string;
    iconName: string;
    value: boolean;
    fundingMessage: string;
    fundingAmount: number | null;
    applicationProductId: number;
    cardOrderId: number | null;
}

interface ICommonFundingOptionsRequest {
    isMember: boolean;
    allFundingOptions: IProductFundingOptions[]
}

interface IFundingOption {
    optionId: number;
    title: string;
    description: string;
    order: number;
    iconComponentName: string;
    iconHeight: string;
    iconWidth: string;
    iconViewbox: string;
    enabled: boolean;
    isDefault: boolean;
    memberOnly: boolean;
    fundingSelectionType: number;
}

interface IProductFundingOptions {
    productId: number;
    options: IFundingOption[];
}

export interface IFundingState {
    accounts: IAccount[];
    total: number;
    achPayment: fundingAPIv2.IACHPayment | {};
    products: IFundingProduct[];
    fundingOptions: IFundingOption[];
}

const funding: Module<IFundingState, IRootState> = {
    namespaced: true,
    state: {
        accounts: [],
        total: 0,
        achPayment: {},
        products: [],
        fundingOptions: []
    },
    mutations: {
        setData,
        resetState(state) {
            state.accounts = [];
            state.total = 0;
            state.achPayment = {};
            state.products = [];
            state.fundingOptions = [];
        },
        toggleInternalTransfer(state, enable) {
            let internalFundingOption = state.fundingOptions.find(
                x => x.fundingSelectionType == FundingSelectionTypes.INTERNAL_TRANSFER
            );
            if (internalFundingOption) {
                internalFundingOption.enabled = enable;
            }
        }
    },
    actions: {
        async updateApplicantFundingData({ commit, state }, request: any): Promise<IApplicantFundingDataUpdateResponse> {
            try {
                const response = await applicantAPIv2.updateApplicantFundingData(request);

                return response;

            } catch (error) {
                throw error;
            }
        },
        async getPlaidSessionTokenAsync() : Promise<string> {
            try {
                const token = await fundingAPIv2.getPlaidSessionTokenAsync();
            
                return token;
            } catch (error) {
             
                if (typeof error === 'string') {
                    throw new Error(error);
                }
                throw error;
            }
            
        },
        async getACHPaymentAsync({ commit, state, rootGetters }): Promise<{ achPayment: fundingAPIv2.IACHPayment | {}; total?: number | null }> {
            if (state.achPayment) {
                return { achPayment: state.achPayment, total: state.total };
            }

            try {
                const request = { applicationToken: rootGetters['application/applicationToken'] };
                const response = await fundingAPIv2.getACHPaymentAsync(request);

                commit('setData', {
                    objName: 'achPayment',
                    data: response.achPayment
                });

                commit('setData', {
                    objName: 'total',
                    data: response.achPayment.initialAmount
                });

                return { achPayment: response.achPayment, total: response.achPayment.initialAmount };
            } catch (error) {
              
                if (typeof error === 'string') {
                    throw new Error(error);
                }
                throw error;
            }
        },
        async createACHPaymentAsync({ commit, state, rootGetters }, publicToken: string): Promise<fundingAPIv2.ICreateACHPaymentResponse> {

            try {
                const request = { applicationToken: rootGetters['application/applicationToken'], publicToken: publicToken};
                const response = await fundingAPIv2.createACHPaymentAsync(request);

                return response;
            } catch (error) {
             
                if (typeof error === 'string') {
                    throw new Error(error);
                }
                throw error;
            }
        },
        async createInternalTransferPaymentAsync({ commit, state, rootGetters }, request: any): Promise<fundingAPIv2.ICreateTransferPaymentResponse> {
            try {
                const response = await fundingAPIv2.createTransferPaymentAsync(request);
                return response;
            }
            catch (error) {
                throw error;
            }
        },
        async getApplicantTransferAccountsAsync({ commit, state, rootGetters }, request: any): Promise<fundingAPIv2.IGetApplicantTransferAccountsResponse> {
            try {
                const response = await fundingAPIv2.getApplicantTransferAccountsAsync(request);

                commit('setData', {
                    objName: 'transferAccounts',
                    data: response.transferAccounts
                });

                return response;
            }
            catch (error) {
                throw error;
            }
        },
        getCommonFundingOptions({ commit }, request: ICommonFundingOptionsRequest): IFundingOption[] {
            let availableFundingTypes = request.allFundingOptions[0].options;

            // Remove funding options that are not shared across all selected products
            for (let i = 0; i < request.allFundingOptions.length - 1; i++) {
                availableFundingTypes = request.allFundingOptions[i + 1].options.filter(optionIn2 =>
                    availableFundingTypes.find(optionIn1 => optionIn2.optionId === optionIn1.optionId)
                );

                if (!availableFundingTypes.length) {
                    break;
                }
            }

            if (!request.isMember) {
                availableFundingTypes = availableFundingTypes.filter(option => option.fundingSelectionType != FundingTypes.INTERNAL);
            }

            commit('setData', {
                objName: 'fundingOptions',
                data: availableFundingTypes
            });

            return availableFundingTypes;
        },
        setSufficientAccounts({ commit, state }, allAccounts: IAccount[]): IAccount[] {

            const accounts = filterInsufficientAccounts(state.total, allAccounts);

            commit('setData', {
                objName: 'accounts',
                data: accounts || []
            });

            return accounts || [];
        },
        async getCardPaymentApplicantData({ commit }, request: any): Promise<IGetCardPaymentDataResponse> {
            try {
                const response = await fundingAPIv2.getCardPaymentApplicantData(request);

                return response;

            } catch (error) {
               
                if (typeof error === 'string') {
                    throw new Error(error);
                }
                throw error;
            }
        },
        async createCardPaymentAsync({ commit }, request: any): Promise<any> {
            try {
                const response = await fundingAPIv2.createCardPaymentAsync(request);

                return response;

            } catch (error) {
             
                if (typeof error === 'string') {
                    throw new Error(error);
                }
                throw error;
            }
        }
    },
    getters: {
        fundingOptions: state => state.fundingOptions,
        hasCardOption: state => (state.fundingOptions.find(option => option.fundingSelectionType === 2) ? true : false),
        sufficientAccounts: state => state.accounts
    }
};

export default funding;
