import axios from '@/utils/axios';

export interface IGetDisclosureAcceptanceRequest {
	applicantId: number;
}

export interface IDisclosureAcceptanceGetResponse {
	disclosureAcceptance: IDisclosureAcceptance[];
}

export interface IDisclosureAcceptanceCreateResponse {
	created: boolean;
	autoDecline: boolean;
	creditBuilderQualified: boolean;
	applicantVerificationDecline: boolean;
}
export interface IDisclosureAcceptanceCreateRequest {
	applicationToken: string;
	deviceSessionId: string;
	hasMemberAdvocate: boolean;
	applicantToken: string;
	disclosureAcceptances: any[];
	eStatementOptions: any[];
}

export interface IDisclosureAcceptance {
	disclosureAcceptanceId: number | null;
	applicationId: number | null;
	disclosureType: number;
}
export interface IElectronicDisclosuresAcceptanceGetResponse {
	created: boolean;
}

const getDisclosureAcceptanceAsync = async (
	request: IGetDisclosureAcceptanceRequest
): Promise<IDisclosureAcceptanceGetResponse> => {
	try {
		const { data } = await axios.get<IDisclosureAcceptanceGetResponse>(`/api/v2/Disclosure/${request.applicantId}`);
		return data;
	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}

		throw error;
	}
};

const createDisclosureAcceptanceAsync = async (
	request: IDisclosureAcceptanceCreateRequest
): Promise<IDisclosureAcceptanceCreateResponse> => {
	try {
		const { data } = await axios.post<IDisclosureAcceptanceCreateResponse>(`/api/v2/Disclosure`, request);
		return data;
	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}

		throw error;
	}
};

const getElectronicDisclosuresAcceptanceAsync = async (
	applicantToken: string
): Promise<IElectronicDisclosuresAcceptanceGetResponse> => {
	try {
		const { data } = await axios.get<IElectronicDisclosuresAcceptanceGetResponse>(
			`/api/v2/Disclosure/${applicantToken}`
		);
		return data;
	} catch (error) {
		
		if (typeof error === 'string') {
			throw new Error(error);
		}

		throw error;
	}
};

export { getDisclosureAcceptanceAsync, getElectronicDisclosuresAcceptanceAsync, createDisclosureAcceptanceAsync };
