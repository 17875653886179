<template functional>
	<svg width="34" height="26" viewBox="0 0 34 26" fill="transparent" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M12.5003 12.9999C15.3768 12.9999 17.7087 10.6681 17.7087 7.79159C17.7087 4.9151 15.3768 2.58325 12.5003 2.58325C9.62384 2.58325 7.29199 4.9151 7.29199 7.79159C7.29199 10.6681 9.62384 12.9999 12.5003 12.9999Z"
			stroke="currentColor"
			stroke-width="1.61"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M21.4476 23.4167C21.4476 19.3854 17.4372 16.125 12.4997 16.125C7.56217 16.125 3.55176 19.3854 3.55176 23.4167"
			stroke="currentColor"
			stroke-width="1.61"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path d="M23 11.5H33" stroke="currentColor" stroke-width="1.61" stroke-linecap="round" />
		<path d="M28 16.5V6.5" stroke="currentColor" stroke-width="1.61" stroke-linecap="round" />
	</svg>
</template>

<script>
	export default {
		name: 'PersonPlusIcon'
	};
</script>
