import _cloneDeep from 'lodash.clonedeep';

import axios from '@/utils/axios';
import { Module } from 'vuex';
import { IRootState } from '..';
import { IAccount } from './user';
import { reselectAPIv2 } from '@/api';

import {
	IGetIneligibleAccountsResponse,
	IProductSelection,
	IProductSelectionConfiguration,
	ISubmitReselectionRequest,
	ISubmitReselectionResponse
} from '@/api/_v2/reselect'

import setData from '../shared/setData';

enum FundingType {
	None = 0,
	Transfer = 1,
	Card = 2,
	ExternalTransfer = 3
}

enum MemberType {
	None = 0,
	Member = 1,
	NonMember = 2
}

enum SelectType {
	None = 0,
	AutomaticSelection = 1,
	ForcedSelection = 2
}

interface ICard {
	cardId: number;
	cardName: string;
	cardImage: string;
}

export interface IProduct {
	productId: number;
	categoryId: number;
	displayName: string;
	productName: string;
	displayOrder: number;
	isAccountRequired: boolean;
	benefits: string;
	helpText: string;
	discountMessage: string;
	iconName: string;
	cards: ICard[];
	cardId: number | null;
	value: boolean;
	removalText: string;
	applicationProductId: number;
	accountTypeApplicationLimit: number;
	hasError: boolean;
}

interface IConfig {
	key: 'FundingLimitModel';
	productId: number;
	fundingType: FundingType;
	memberType: MemberType;
	minimumFundingAmount: number;
	maximumFundingAmount: number;
}

interface IConfiguration {
	settings: IConfig[];
	enableReselectField: boolean;
	promotionalCreditGLConfiguration: IPromotionalCreditGLConfiguration;
	isEmployee: boolean;
}

interface IFunding {}

interface ISubmitProductsResponse {
	selectedProducts: any[];
	hasProductSelectErrors: boolean;
	errorDisplayMessages: [];
}

interface IGetProductsResponse {
	hasMultipleAccounts: boolean | null;
	requiredProducts: IProduct[];
	availableProducts: IProduct[];
	selectType: number | null;
}

interface IPromotionalCreditGLConfiguration {
	id: number | null;
	gLAccountNumber: string;
	amount: number | null;
	productConfigurationId: number | null;
	isEnabled: boolean;
	affiliatesTypeId: number | null;
}

export interface IProductsState {
	hasMultipleAccounts: boolean | null;
	requiredProducts: IProduct[];
	availableProducts: IProduct[];
	selectedProducts: IProduct[];
	memberTypeId: number | null;
	config: IConfig[];
	savedFunding: IFunding[];
	ineligibleProducts: IProduct[];
	creditBuilderProducts: IProduct[];
	selectType: number | null;
}

export interface IRemoveProductResponse {
	productDeleted: boolean;
	cardOrderDeleted: boolean;
}

const products: Module<IProductsState, IRootState> = {
	namespaced: true,
	state: {
		hasMultipleAccounts: null,
		requiredProducts: [],
		availableProducts: [],
		selectedProducts: [],
		memberTypeId: null,
		config: [],
		savedFunding: [],
		ineligibleProducts: [],
		creditBuilderProducts: [],
		selectType: null
	},
	mutations: {
		setData,
		resetState(state) {
			state.hasMultipleAccounts = null;
			state.selectedProducts = [];
			state.memberTypeId = null;
			state.ineligibleProducts = [];
			state.creditBuilderProducts = [];
			state.savedFunding = [];
			state.selectType = null;
		},
		updateSelectedProducts(state, data) {
			state.selectedProducts = data.constructor === Array ? [...data] : [data];
		},
		updateCardConfig(state, { isRequired, productId, cardId }) {
			const searchObj = isRequired ? 'requiredProducts' : 'selectedProducts';
			const matchingObj = state[searchObj].find(product => product.productId === productId);

			if (isRequired && !cardId) return;

			if (matchingObj) {
				matchingObj['cardId'] = cardId;
			}
		},
		addOrUpdateFundingAmount(state, { productId, fundingAmount }) {
			state.savedFunding[productId] = fundingAmount;
		}
	},
	actions: {
		async removeProduct({ commit, state }, request: any): Promise<IRemoveProductResponse> {
			try {
				const { data } = await axios.post<IRemoveProductResponse>('/api/RemoveProduct', request);
				
				if (data.productDeleted) {
					const updatedSelectedProducts = state.selectedProducts.filter(
						product => product.productId != request.productConfigId
					);
					commit('updateSelectedProducts', updatedSelectedProducts);
				}

				return data;
			} catch (error) {
				if (typeof error === 'string') {
					throw new Error(error);
				}
				throw error;
			}
		},
		async getProducts({ dispatch, getters, state }, request): Promise<IGetProductsResponse> {
			//if (request.memberType) {
			//	return {
			//		hasMultipleAccounts: state.hasMultipleAccounts,
			//		requiredProducts: _cloneDeep(state.requiredProducts),
			//		availableProducts: _cloneDeep(state.availableProducts),
			//		selectType: _cloneDeep(state.selectType)
			//	};
			//}

			try {
				return await dispatch('getProductsFromRoute', request);
			} catch (error) {
				if (typeof error === 'string') {
					throw new Error(error);
				}
				throw error;
			}
		},
		async getProductsFromRoute({ commit }, request) {
			try {
				const {
					data: { hasMultipleAccounts = false, products = [], accounts = [], selectType = null }
				} = await axios.post<{
					hasMultipleAccounts: boolean;
					products: IProduct[];
					accounts: IAccount[];
					selectType: null;
				}>(`/api/SelectProducts/Configuration`, request);

				let requiredProducts: IProduct[] = [];
				let availableProducts: IProduct[] = [];

				products.forEach((product: IProduct) => {
					product.isAccountRequired ? requiredProducts.push(product) : availableProducts.push(product);
				});

				commit('setData', { objName: 'hasMultipleAccounts', data: hasMultipleAccounts });
				commit('setData', { objName: 'requiredProducts', data: _cloneDeep(requiredProducts) });
				commit('setData', { objName: 'availableProducts', data: _cloneDeep(availableProducts) });
				commit('setData', { objName: 'selectType', data: _cloneDeep(selectType) });
				commit('user/setData', { objName: 'accounts', data: accounts ? accounts : [] }, { root: true });

				return {
					hasMultipleAccounts,
					requiredProducts,
					availableProducts,
					selectType
				};
			} catch (error) {
			
				if (typeof error === 'string') {
					throw new Error(error);
				}
				throw error;
			}
		},
		async submitProducts({ commit, state, rootGetters }, products: IProduct[]): Promise<ISubmitProductsResponse> {
			try {
				let chosenProducts = products ? products : [...state.selectedProducts];
				const selectProductsRequest = {
					applicationToken: rootGetters['application/applicationToken'],
					selectedProducts: chosenProducts
				};
				const { data } = await axios.post<ISubmitProductsResponse>(
					`/api/SelectProducts`,
					selectProductsRequest
				);

				let newChosenProducts: any = [];

				data.selectedProducts.forEach(selected => {
					const found = [...state.requiredProducts, ...state.availableProducts]
						.find(anyProduct => selected.productId === anyProduct.productId);

					newChosenProducts.push({
						...found,
						applicationProductId: selected.applicationProductId
					});
				});

				commit('setData', { objName: 'selectedProducts', data: newChosenProducts });
				commit('setData', { objName: 'hasProductSelectErrors', data: data.hasProductSelectErrors });
				commit('setData', { objName: 'errorDisplayMessages', data: data.errorDisplayMessages });

				return data;
			} catch (error) {
				
				if (typeof error === 'string') {
					throw new Error(error);
				}
				throw error;
			}
		},
		async resumeProducts(
			{ commit },
			{ availableProducts, selectedProducts }: { availableProducts: IProduct[]; selectedProducts: IProduct[] }
		): Promise<IProduct[]> {
			try {
				const mappedProducts: IProduct[] = selectedProducts.map(({ productId, cardId }) => {
					const found = availableProducts.find(product => productId === product.productId);

					if (!found) {
						throw `Couldn't map previously selected product to existing offerings`;
					}

					if (found.isAccountRequired) {
						commit('updateCardConfig', { ...found, isRequired: true });
					}

					return { ...found, cardId };
				});

				let requiredProducts: IProduct[] = [];
				let notRequired: IProduct[] = [];

				mappedProducts.forEach(product => {
					product.isAccountRequired ? requiredProducts.push(product) : notRequired.push(product);
				});

				commit('setData', { objName: 'requiredProducts', data: requiredProducts });			
				commit('updateSelectedProducts', mappedProducts);

				return mappedProducts;
			} catch (error) {
				if (typeof error === 'string') {
					throw new Error(error);
				}
				throw error;
			}
		},
		async getReselectProducts({ commit, rootState }: any): Promise<IGetIneligibleAccountsResponse> {
			try {
				const response = await reselectAPIv2.getIneligibleAccountsAsync(rootState.application.applicationToken);

				commit('setData', { objName: 'ineligibleProducts', data: response.ineligibleProducts });
				commit('setData', { objName: 'creditBuilderProducts', data: response.eligibleProducts });

				return response;

			} catch (error) {
				
				if (typeof error === 'string') {
					throw new Error(error);
				}
				throw error;
			}
		},
		async submitReselectProducts({ state, commit, rootGetters }, products): Promise<ISubmitReselectionResponse> {
			const seen = new Set();
			const requiredAndSubmitted = [...products, ...state.requiredProducts];

			const productSelection = requiredAndSubmitted.filter(el => {
				const duplicate = seen.has(el.productId);
				seen.add(el.productId);
				return !duplicate;
			});

			const request: ISubmitReselectionRequest = {
				applicationToken: rootGetters['application/applicationToken'],
				productSelection: productSelection
			};

			try {
				commit('updateSelectedProducts', productSelection);
				var response = await reselectAPIv2.submitAccountReselectionAsync(request);
				await this.dispatch("cardSelection/addReselectCards", response.reselectProductCardOrders, { root: true });

				return response;
			} catch (error) {
				
				if (typeof error === 'string') {
					throw new Error(error);
				}
				throw error;
			}
		},
		async getConfig({ commit, rootGetters }): Promise<IConfiguration> {
			try {
				const {
					data: {
						settings = [],
						enableReselectField = false,
						promotionalCreditGLConfiguration = {
							id: -1 /*<<--will signify load error, vs just being nonappliciable 0*/,
							gLAccountNumber: '',
							amount: 0.0,
							productConfigurationId: 0,
							isEnabled: false,
							affiliatesTypeId: null
						},
						isEmployee = false
					}
				} = await axios.get<{
					settings: IConfig[];
					enableReselectField: boolean;
					promotionalCreditGLConfiguration: IPromotionalCreditGLConfiguration;
					isEmployee: boolean;
				}>(`/api/Funding/Configuration`);

				commit('setData', { objName: 'config', data: settings ? settings : [] });
				commit('setData', {
					objName: 'enableReselectField',
					data: enableReselectField ? enableReselectField : false
				});
				commit('setData', {
					objName: 'promotionalCreditGLConfiguration',
					data: promotionalCreditGLConfiguration
				});
				commit('setData', { objName: 'memberTypeId', data: rootGetters['user/memberTypeId'] });
				commit('setData', { objName: 'isEmployee', data: isEmployee ? isEmployee : false });

				const newSettings: IConfiguration = {
					settings: settings,
					enableReselectField: enableReselectField,
					promotionalCreditGLConfiguration: promotionalCreditGLConfiguration,
					isEmployee: isEmployee
				};

				return newSettings;
			} catch (error) {
			
				if (typeof error === 'string') {
					throw new Error(error);
				}
				throw error;
			}
		}
	},
	getters: {
		hasData: state => (memberTypeId: number) => {
			if (
				state.memberTypeId === memberTypeId &&
				state.hasMultipleAccounts !== null &&
				state.availableProducts.length > 0
			) {
				return true;
			}
			return false;
		},
		getSelectedProducts: state => state.selectedProducts,
		hasCards: state => {
			const chosenProducts = [...state.requiredProducts, ...state.selectedProducts].filter(
				product => product.cardId != null && product.cardId > 0
			);
			return chosenProducts.length ? true : false;
		},
		chosenCardProducts: state => {
			const chosenProducts = [...state.requiredProducts, ...state.selectedProducts];

			return chosenProducts
				.filter(product => (product.cards ? product.cards.length > 0 : false))
				.sort((a, b) => a.categoryId - b.categoryId); //TODO: Update to use product sort ID
		}
	}
};

export default products;
