import axios from '@/utils/axios';

import { IProduct } from '@/store/_v2/modules/products';

export enum MemberType {
	None = 0,
	Member = 1,
	NonMember = 2
}
export interface IRehydrateFromAppIdRequest {
	encodedValue: string;
}

export interface IRehydrateFromAppIdResponse {
	applicationId: number;
	availableProducts: IProduct[];
	currentPage: string;
	firstName: string;
	lastName: string;
	memberType: MemberType;
	hasJoint: boolean;
	selectedProducts: IProduct[];
}

const GetApplicationRehydrationData = async (request: string): Promise<IRehydrateFromAppIdResponse> => {
	try {
		const { data } = await axios.get<IRehydrateFromAppIdResponse>(
			`/api/Resume/RehydrateFromAppId?EncodedValue=${request}`
		);

		return data;
	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};

export default {
	GetApplicationRehydrationData
};

export { GetApplicationRehydrationData };
