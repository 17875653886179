import axios from '@/utils/axios';

enum HyperlinkType {
	None = 0,
	OLBLoginLinkDesktop = 1,
	OLBLoginLinkMobile = 2
}
interface IDynamicHeroHeaderConfiguration {
	id: number;
	route: string;
	enabled: boolean;
	desktopHeader: string;
	mobileHeader: string;
	baoHeader: string;
}
interface IDynamicHyperlinkConfiguration {
	hyperlinkType: HyperlinkType;
	hyperlinkHTML: string;
}

interface IIdentificationConfiguration {
	id: number;
	label: string;
}

interface ICountryConfiguration {
	countryId: number;
	name: string;
	code: string;
	currencyCode: string;
	ofac: boolean;
	iban: boolean;
	transferEligible: boolean;
	countryCode: string;
}

interface IClientConfigurations {
	eDisclosuresConfirmationAttempts: number;
	eDisclosuresConfirmationRetryCadence: number;
	dynamicHyperlinks: IDynamicHyperlinkConfiguration[];
	dynamicHeaderConfigurations: IDynamicHeroHeaderConfiguration[];
	clientPhoneNumber: string;
	clientWebsite: string;
	clientLocationsWebsite: string;
	reselectPageTitle: string;
	ineligibilityPageText: string;
	ineligibilityPageDeclinedReselectText: string;
	gtmContainerId: string;
	identificationConfigurations: IIdentificationConfiguration[];
	countryConfigurations: ICountryConfiguration[];
	enableExistingMemberFlow: boolean;
}

export interface IPersonVerificationConfiguration {
	sdkKey: string;
	enabled: boolean;
}

const getConfigurations = async (): Promise<IClientConfigurations> => {
	try {
		const { data: configurations } = await axios.get<IClientConfigurations>(`${process.env.BASE_URL}api/Configuration`);
		
		return configurations;
	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}

		throw error;
	}
};

const getPersonVerificationConfiguration = async (): Promise<IPersonVerificationConfiguration> =>  {
	try {

		const { data: deviceConfiguration } = await axios.get<IPersonVerificationConfiguration>(`${process.env.BASE_URL}api/PersonVerificationConfiguration`);

		return deviceConfiguration;
	} catch (error) {
		
		if (typeof error === 'string') {
			throw new Error(error);
		}

		throw error;
	}
}

export {
	HyperlinkType,
	IDynamicHeroHeaderConfiguration,
	IDynamicHyperlinkConfiguration,
	IIdentificationConfiguration,
	IClientConfigurations,
	ICountryConfiguration,
	getConfigurations,
	getPersonVerificationConfiguration
};
