<template functional>

	<button
        v-on="listeners"
        v-bind="data.attrs"
        :class="[ data.class, data.staticClass ]"
        :type="props.type || 'button'"
        :title="props.title"
    >
        <slot/>
    </button>

</template>





<script>

    export default {

        name: 'ButtonWrapper'

    };

</script>





<style lang="scss" scoped>

    button {
        background: none;
        border: none;
        padding: 0;
        margin: 0;
        cursor: pointer;

        &:hover {
            opacity: 0.6;
        }

    }

</style>