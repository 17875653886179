import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store/_v2';
import handleGetError from '@/utils/handleGetError';
import routes from './routes';


Vue.use(Router);

const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior() {
		return typeof window.scroll === 'function'
			? window.scroll({ top: 0, left: 0, behavior: 'smooth' })
			: { x: 0, y: 0 };
	},
	routes
});


router.afterEach(async (to, from) => {
	if (to.meta.resetState && !from.meta.ignoreIncomingStateReset) {
		await store.dispatch('resetState');
	}

	// Set defaults and write the incoming header
	await store.dispatch('config/setDefaults', {
		isError: to.meta.isError,
		themeCode: to.query.theme ? to.query.theme : null
	});

	const incomingHeader =
			store.state.branding.images && to.name
				? store.state.branding.images[to.name.toLowerCase()]
				: null;

	store.commit('pageOptions/setData', {
		objName: 'currentHeader',
		data: incomingHeader ? incomingHeader : store.state.branding.images.default
	});

	await store.dispatch('identityVerification/attachDeviceIdSDK');
	await store.dispatch('identityVerification/refreshDeviceIdSessionToken');
})

router.beforeEach(async (incoming, last, next) => {
	try {
		const routeEnforcerAction = await store.dispatch('routeEnforcer/enforceRoute', incoming);

		//Backtracking from leaveModal page? open modal and force redirect
		if (routeEnforcerAction.isBackTrack) {
			const applicationId = store.getters['user/applicationType'];
			const displayLeavingModal = store.getters['config/displayLeavingModalToggle'];

			if (displayLeavingModal) {
				await store.dispatch('modal/openLeavingModal', { ...incoming, appIdCopy: applicationId });
			}
		}

		if (!routeEnforcerAction.isBackTrack && incoming.meta.leavingModal) {
			store.commit('modal/setLeavingModalUnshown');
		}

		//turn off session timer if route bypasses enforcer
		if (incoming.meta.bypassEnforcer) {
			store.commit('sessionTimer/resetTimers');
		} else if (!store.state.sessionTimer.sessionExpired) {
			await store.dispatch('sessionTimer/reset');
		}

		if (routeEnforcerAction.redirect) {
			return next(routeEnforcerAction.redirectRoute);
		}

		// If the incoming route requires a user object and we don't have member/applicationType set, set it
		if (incoming.meta.requiresUser && (!incoming.query.memberType || !incoming.query.applicationType)) {
			const applicationType = store.getters['user/applicationType'];
			const memberType = store.getters['user/memberTypeId'];

			return next({
				...incoming,
				query: {
					...incoming.query,
					memberType,
					applicationType
				}
			});
		}

		if (!incoming.meta.bypassAudit) {
			const { handleAudit } = await import('@/utils/handleAudit');

			handleAudit({
				page: incoming.name,
				success: true
			});
		}

		// Move to next route
		return next();
	} catch (error) {
		console.log(error)
		return handleGetError(error);
	}
});

export default router;
