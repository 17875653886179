var render = function render(_c,_vm){return _c('aside',{class:[
        'notification',
        _vm.data.class,
        _vm.data.staticClass,
        {
            'danger': _vm.props.isDanger
        }
    ],style:([
        _vm.data.style, 
        _vm.data.staticStyle
    ])},[_c('h1',[_vm._v(_vm._s(_vm.props.title))]),(_vm.props.body)?_c('div',{staticClass:"body",domProps:{"innerHTML":_vm._s(_vm.props.body)}}):_c('div',[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }