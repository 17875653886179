import errorRoutes from './errorRoutes';
import mainRoutes from './mainRoutes';

export default [
    
    {
        path: '/',
        component: () => import(/* webpackChunkName: "DefaultLayout" */ '@/layouts/Default'),
        props: route => ({ overflow: route.meta.overflow ? true : false }),
        children: [
            
            ...mainRoutes,
            ...errorRoutes,

        ]
    },
    {
        path: '/*',
        redirect: { name: 'Home' }
    }

];