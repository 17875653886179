<template>

	<div>

		<div id="captcha-v2-wrapper">
			<div id="captcha-v2"></div>
		</div>


		<transition name="slide-fade">
			<span v-if="hasCaptchaError" class="help is-danger">
				{{ captchaError }}
			</span>
		</transition>

	</div>

</template>

<script>

    import { mapActions, mapGetters } from 'vuex';
	import { ValidationProvider } from 'vee-validate';
    import { CaptchaImplementation } from '@/constants';

	export default {

        name: 'ReCaptchaV2',
        components: {

            ValidationProvider

        },
		props: {

			dataReset: {
				type: Boolean,
				required: false,
				default: false
			}

		},
		data() {

			return {
				validationToken: '',
				captchaId: null,
				captchaError: 'There was a problem validating your Captcha response. Try again.',
                hasCaptchaError: false
			}

		},
        computed: {

            ...mapGetters('config', ['captchaV2'])

        },
		mounted() {

            // Create script tag for reCaptcha and append it as the first child in the #captcha div
			let	captchaScript = document.createElement('script');
				captchaScript.setAttribute( 'type', 'text/javascript' );
				captchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js' );
				captchaScript.setAttribute( 'async', '' );
				captchaScript.setAttribute( 'defer', '' );
            document.getElementById('captcha-v2-wrapper').appendChild(captchaScript);

			this.initCaptcha();

		},
		destroyed() {

			grecaptcha.reset(this.captchaId);

		},
		methods: {

            ...mapActions({ updateCaptcha: 'config/updateCaptcha'}),
			initCaptcha() {

				setTimeout(() => {

                    if (typeof grecaptcha === 'undefined' || !grecaptcha.render) {

                        this.initCaptcha();

                    }
					else {

                        this.captchaId = grecaptcha.render('captcha-v2', {
                            sitekey: this.captchaV2.siteKey,
							callback: this.verify,
							'expired-callback': this.verify,
							'error-callback': this.handleError
						});

						this.reset();

					}

				}, 200);

			},
			verify(token) {

                this.validationToken = token;
				this.updateCaptcha({ implementation: CaptchaImplementation.RECAPTCHAV2, captcha: { token: this.validationToken, reset: false } });

				if (token) {

					this.hasCaptchaError = false;

				}

			},
			handleError() {

				this.hasCaptchaError = true;

			},
			reset() {

                grecaptcha.reset();
                this.validationToken = '';
                this.dataReset = false;
                this.updateCaptcha({
                    implementation: CaptchaImplementation.RECAPTCHAV2, captcha: {
                        ready: true,
                        triggerAction: false,
                        token: '',
                        reset: false
                    }
                });

            }

		},
		watch: {

			dataReset() {

				this.reset();

			},
            validationToken(value) {

                this.$emit('input', value);

            }
			
		}

	}

</script>





<style lang="scss" scoped>



</style>