import _cloneDeep from 'lodash.clonedeep';

import { Module } from 'vuex';
import { Route } from 'vue-router';
import { IRootState } from '..';

export interface IRouteEnforcerState {
	routeHistory: Route[];
}

const routeEnforcer: Module<IRouteEnforcerState, IRootState> = {
	namespaced: true,
	state: {
		routeHistory: []
	},
	mutations: {
		resetState(state) {
			state.routeHistory = [];
		},
		addHistoryItem(state, { matched, ...route }) {
			state.routeHistory.push(_cloneDeep(route));
		},
		removeLastRoute(state) {
			state.routeHistory.pop();
		},
		replaceHistory(state, routes) {
			state.routeHistory = _cloneDeep(routes);
		}
	},
	actions: {
		async forceNavigation({ commit }, incoming) {
			commit('resetState');

			commit('addHistoryItem', incoming);

			return false;
		},
		async enforceRoute({ commit, rootState, dispatch, getters }, incoming) {
			var appearsInHistory = false;

			if (getters.history.length > 0) {
				for (let i = 0; i < getters.history.length; i++) {
					if (
						getters.history[i].fullPath == incoming.fullPath &&
						getters.current.fullPath != incoming.fullPath
					) {
						appearsInHistory = true;
					}
				}
			}

			// User backtracked and left a page with the leavingModal
			if (typeof getters.current != 'undefined') {
				if (getters.current.meta.leavingModal && appearsInHistory && !rootState.modal?.leavingModalShown ) {
					const  redirectRoute = getters.redirectRoute
					commit('addHistoryItem', incoming);
					return { redirectRoute, isBackTrack: true, redirect: true };
				}
			}

			if (incoming.meta.abandonApplication) {
				await dispatch('application/abandonApplication', {}, { root: true });
			}

			if (incoming.meta.bypassEnforcer) {
				if (incoming.meta.forceNavigation) {
					return { redirect: await dispatch('forceNavigation', incoming) };
				}

				commit('addHistoryItem', incoming);

				return { redirect: false };
			}

			const hasCurrent = !!getters.current;
			const hasPrevious = !!getters.previous;
			const fromRoute = incoming.params.fromRoute;

			if (hasCurrent && incoming.path === getters.current.path) {
				return { redirect: incoming.meta.forceNavigation ? dispatch('forceNavigation', incoming) : false };
			}

			if (hasPrevious && incoming.path === getters.previous.path) {
				if (incoming.meta.forceNavigation) {
					return dispatch('forceNavigation', incoming);
				}

				commit('removeLastRoute');

				return { redirect: false };
			}

			const fromMatchesHistory =
				(hasCurrent && fromRoute === getters.current.path) ||
				(hasPrevious && fromRoute === getters.previous.path);

			if (fromMatchesHistory) {
				if (incoming.meta.forceNavigation) {
					return dispatch('forceNavigation', incoming);
				}

				commit('addHistoryItem', incoming);

				return { redirect: false };
			}

			return { redirectRoute: getters.redirectRoute, redirect: true };
		}
	},
	getters: {
		current: state => state.routeHistory[state.routeHistory.length - 1],
		previous: state => state.routeHistory[state.routeHistory.length - 2],
		redirectRoute: (_, getters) => getters.current || { name: 'Home' },
		history: state => state.routeHistory
	}
};

export default routeEnforcer;
