<template functional>
	<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M12.5003 12.5C15.3768 12.5 17.7087 10.1682 17.7087 7.29171C17.7087 4.41522 15.3768 2.08337 12.5003 2.08337C9.62384 2.08337 7.29199 4.41522 7.29199 7.29171C7.29199 10.1682 9.62384 12.5 12.5003 12.5Z"
			stroke="currentColor"
			stroke-width="1.2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M21.4476 22.9167C21.4476 18.8854 17.4372 15.625 12.4997 15.625C7.56217 15.625 3.55176 18.8854 3.55176 22.9167"
			stroke="currentColor"
			stroke-width="1.2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>

<script>
	export default {
		name: 'UserIcon'
	};
</script>
