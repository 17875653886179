<template functional>
	<g>
        <path class="eaf-2" d="M17.26,3.07c10.79-5.33,20.3.17,23.05,10.2l.47,1.71M43.49,9.71l-2.72,5.34-5.34-2.72M32.69,31.98c-10.79,5.33-20.3-.17-23.05-10.2l-.47-1.71M6.43,24.33l2.72-5.34,5.34,2.72"/>
        <path class="eaf-1" d="M16.08,4.61L8.14,1,.21,4.61M16.08,6.05H.21M2.73,12.9v-5.41H.93v6.85M13.69,13v-5.41h1.8v6.85M2.01,13.98h7.31M6.71,13.98h7.53M.56,15.74h8.79M7.14,15.74h8.79M4.17,12.9v-4.69c0-.18.09-.36.22-.49.13-.13.31-.2.5-.2s.36.07.5.2c.13.13.21.3.22.49v4.69M7.42,12.9v-4.69c0-.18.09-.36.22-.49.13-.13.31-.2.5-.2s.36.07.5.2c.13.13.21.3.22.49v4.69M10.56,12.96v-4.69c0-.18.09-.36.22-.49.13-.13.31-.2.5-.2s.36.07.5.2c.13.13.21.3.22.49v4.69M9.22,3.89c0,.21-.06.42-.18.6-.12.18-.29.32-.49.4-.2.08-.42.1-.63.06-.21-.04-.4-.14-.56-.3-.15-.15-.25-.34-.3-.56-.04-.21-.02-.43.06-.63.08-.2.22-.37.4-.49.18-.12.39-.18.6-.18.29,0,.56.11.76.32.2.2.32.48.32.76Z"/>
        <path class="eaf-1" d="M50.75,21.7l-7.94-3.61-7.93,3.61M50.75,23.14h-15.87M37.4,30v-5.41h-1.8v6.85M48.36,30.09v-5.41h1.8v6.85M36.68,31.08h7.31M41.38,31.08h7.53M35.23,32.83h8.79M41.81,32.83h8.79M38.84,30v-4.69c0-.18.09-.36.22-.49.13-.13.31-.2.5-.2s.36.07.5.2c.13.13.21.3.22.49v4.69M42.09,30v-4.69c0-.18.09-.36.22-.49.13-.13.31-.2.5-.2s.36.07.5.2c.13.13.21.3.22.49v4.69M45.23,30.06v-4.69c0-.18.09-.36.22-.49.13-.13.31-.2.5-.2s.36.07.5.2c.13.13.21.3.22.49v4.69M43.89,20.98c0,.21-.06.42-.18.6-.12.18-.29.32-.49.4-.2.08-.42.1-.63.06-.21-.04-.4-.14-.56-.3-.15-.15-.25-.34-.3-.56-.04-.21-.02-.43.06-.63.08-.2.22-.37.4-.49.18-.12.39-.18.6-.18.29,0,.56.11.76.32.2.2.32.48.32.76Z"/>
	</g>
</template>

<script>
	export default {
		name: 'ExternalAccountFunding'
	};
</script>


<style lang="scss">
.eaf-1, .eaf-2 {
    fill: none;
    stroke: var(--primary);
    stroke-miterlimit: 10;
}

.eaf-2 {
    stroke-width: 2px;
}
</style>
