<template functional>

    <path d="M18,13.9c0,0.1,0,0.1,0,0.3c0,0.2,0,0.5-0.1,0.9c-0.1,0.4-0.2,0.7-0.3,0.9c-0.2,0.4-0.7,0.9-1.6,1.4
	c-0.8,0.4-1.6,0.7-2.4,0.7c-0.2,0-0.5,0-0.7,0c-0.2,0-0.5-0.1-0.7-0.2c-0.3-0.1-0.5-0.1-0.6-0.2c-0.1,0-0.4-0.1-0.7-0.3
	c-0.3-0.1-0.5-0.2-0.6-0.2c-0.8-0.3-1.6-0.7-2.2-1.1c-1.1-0.7-2.2-1.6-3.4-2.8S2.6,11,1.9,9.9C1.5,9.3,1.2,8.5,0.9,7.7
	c0-0.1-0.1-0.3-0.2-0.6C0.5,6.7,0.4,6.5,0.4,6.3c0-0.1-0.1-0.3-0.2-0.6C0.1,5.5,0.1,5.2,0,5s0-0.4,0-0.7C0,3.5,0.2,2.8,0.7,2
	C1.1,1.1,1.6,0.6,2,0.4c0.2-0.1,0.5-0.2,0.9-0.3S3.6,0,3.8,0C3.9,0,4,0,4.1,0c0.2,0.1,0.4,0.4,0.7,1C4.8,1.2,5,1.4,5.1,1.7
	C5.3,2,5.4,2.3,5.6,2.5C5.7,2.8,5.8,3,6,3.2c0,0,0.1,0.1,0.2,0.3S6.4,3.8,6.5,4c0.1,0.1,0.1,0.2,0.1,0.4c0,0.2-0.1,0.4-0.4,0.6
	C5.9,5.2,5.7,5.5,5.4,5.7S4.8,6.1,4.6,6.4C4.4,6.6,4.2,6.8,4.2,6.9c0,0.1,0,0.2,0.1,0.3c0,0.1,0.1,0.2,0.1,0.3
	c0,0.1,0.1,0.2,0.2,0.3C4.7,7.9,4.7,8,4.7,8c0.6,1.2,1.4,2.2,2.2,3c0.8,0.8,1.8,1.6,3,2.2c0,0,0.1,0.1,0.2,0.1
	c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0,0.1,0.1,0.3,0.1c0.1,0,0.2,0.1,0.3,0.1c0.2,0,0.3-0.1,0.6-0.4c0.2-0.2,0.5-0.5,0.7-0.8
	c0.2-0.3,0.4-0.5,0.7-0.8c0.3-0.2,0.5-0.4,0.6-0.4c0.1,0,0.2,0,0.4,0.1c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.3,0.2,0.3,0.2
	c0.2,0.1,0.4,0.3,0.7,0.4c0.2,0.1,0.5,0.3,0.8,0.4c0.3,0.2,0.5,0.3,0.7,0.4C17.6,13.6,17.9,13.8,18,13.9z"/>

</template>





<script>

    export default {

        name: 'PhoneIcon'

    }

</script>