import { configurationsAPIv2 } from '@/api';
import { IIdentificationConfiguration, ICountryConfiguration } from '@/api/_v2/configurations';

export interface IIdentityConfigurationState {
	identificationConfigurations: IIdentificationConfiguration[];
	countryConfigurations: ICountryConfiguration[];
}

const state: IIdentityConfigurationState = {
	identificationConfigurations: [],
	countryConfigurations: []
};

function resetState(state) {
	state.identificationConfigurations = [];
	state.countryConfigurations = [];
}

const mutations = {
	//TODO: Add Mutations (if any)
};

const actions = {
	async getIdentityConfiguration({ commit, state }: any): Promise<IIdentityConfigurationState> {
		try {
			let response: any;

			if (state.identityConfigurations && state.countryConfigurations) {
				response = state.employmentConfiguration;
			} else {
				const {
					identificationConfigurations: identityConfigurations,
					countryConfigurations: countryConfigurations
				} = await configurationsAPIv2.getIdentityPageConfigurations();

				commit('setData', { objName: 'identificationConfigurations', data: identityConfigurations });
				commit('setData', {
					objName: 'countryConfigurations',
					data: countryConfigurations.filter(config => config.countryCode)
				});

				response = {
					countryConfigurations: state.countryConfigurations,
					identityConfigurations: state.identificationConfigurations
				};
			}

			return response;
		} catch (error) {
		
			if (typeof error === 'string') {
				throw new Error(error);
			}

			throw error;
		}
	}
};

export { state, resetState, mutations, actions };

export default {
	state,
	resetState,
	mutations,
	actions
};
