<template>

    <label :class="[ 'input-container', { 'has-error': hasError } ]" data-test-label>

        <input
            :id="id"
            class="test"
            :name="name"
            type="checkbox"
            v-model.trim="innerValue"
            :value="optionValue"
            :disabled="disabled"
            data-test-input
        />

        <span class="switch"></span>

        <span v-if="label" class="switch-label" v-html="label"></span>
        <slot></slot>

      
        <transition name="fade">
            <p
                class="has-error"
                v-if="error"
                data-test-error
            >
                {{ error }}
            </p>
        </transition>

    </label>

</template>




<script>


    export default {

        name: 'SwitchField',
        props: {

            value: {
                required: true
            },
            optionValue: {
                required: false,
                default: true
            },
            label: {
                type: String,
                required: false
            },
            name: {
                type: String,
                required: true
            },
            disabled: {
                type: Boolean,
                required: false,
            },
            noPadding: {
                type: Boolean,
                required: false
            },
            hasError: {
                type: Boolean,
                required: false
            },
            error: {
                type: String,
                required: false
            },
            isBold: {
                type: Boolean,
                required: false,
                default: false
            }

        },
        computed: {

            id() {

                return this.label ? `${ this.label.replace(/\s/g, '') }-${ this._uid }`: `SwitchField-${ this._uid }`;
                
            },
            innerValue: {

                get() {

                    return this.value;

                },
                set(val) {

                    this.$emit('input', val);

                }

            }

        }

    }

</script>


<style lang="scss" scoped>

    .input-container {
        display: flex;
        align-items: center;
        cursor: pointer;
        color: var(--text-body-75);

        &:not(:last-child) {
			margin-bottom: 1rem;
		}
		
		&.no-padding {
			margin-top: 0;
        }

        input {
            position: absolute;
            cursor: pointer;
            height: 4rem;
            width: 2rem;
            visibility: hidden;
        }

        .switch {
            width:3.5rem;
            height: 2rem;
            background: var(--lightGrey);
            display: block;
            border-radius: 100px;
	        position: relative;
        }

        .switch::after {
            content: '';
            position: absolute;
            top: .26rem;
            left: .25rem;
            width: 1.5rem;
            height: 1.5rem;
            background: var(--white);
            border-radius: 90px;
            transition: 0.3s;
        }

        input:checked + .switch {
            background: var(--primary)
        }

        input:checked + .switch:after {
            left: calc(100% - 5px);
            transform: translateX(-100%);
        }

        .switch-label {
            padding-left: 1.5rem;
            flex: 1;

            color: var(--text-primary);
            font-size: 0.8rem;
            margin-bottom: 0.25rem;
            font-weight: 400;
            text-transform: uppercase;
        }


	}

</style>