import axios from '@/utils/axios';

export interface ICoApplicantSelectModel {
	firstName: string;
	lastName: string;
	middleName: string;
	suffix: string;
	personSerial: number;
}
export interface ICoApplicantSelectionsGetRequest {
	applicantToken: string;
}

export interface ICoApplicantSelectionsGetResponse {
	coApplicantSelectionOptions: ICoApplicantSelectModel[]
}

export interface ICoApplicantSelectionCreateRequest {
	applicationToken: string;
	coApplicantToken: string;
	personSerial: number;
}

export interface ICoApplicantSelectionCreateResponse {
	updated: boolean;
}

const getCoApplicantSelectionsAsync = async (request: ICoApplicantSelectionsGetRequest): Promise<ICoApplicantSelectionsGetResponse> => {
	try {
		const { data } = await axios.get<ICoApplicantSelectionsGetResponse>(`api/v2/CoApplicantSelect/${request.applicantToken}`);

		return data;
	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};

const createCoApplicantSelection = async (request: ICoApplicantSelectionCreateRequest): Promise<ICoApplicantSelectionCreateResponse> => {
	try {
		const { data } = await axios.post<ICoApplicantSelectionCreateResponse>(`api/v2/CoApplicantSelect`, request);

		return data;
	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
}

export default {
	getCoApplicantSelectionsAsync,
	createCoApplicantSelection
};

export {
	getCoApplicantSelectionsAsync,
	createCoApplicantSelection
};