import { Module } from 'vuex';

import { IRootState } from '..';

export interface IAuditState {
	id: number | null;
}

const audit: Module<IAuditState, IRootState> = {
	namespaced: true,
	state: {
		id: null
	},
	mutations: {
		setId(state, id) {
			state.id = id;
		},
		resetState(state) {
			state.id = null;
		}
	},
	getters: {
		eventId: state => state.id
	}
};

export default audit;
