<template functional>

    <g>
        <path class="fill-success-color" d="M36.7,6.1C33.3,4,29.3,2.8,25,2.8C12.8,2.8,2.8,12.8,2.8,25s10,22.2,22.2,22.2s22.2-10,22.2-22.2
            c0-4.8-1.5-9.3-4.2-12.9V12c1-0.2,1.9-0.6,2.6-1.2l0,0c2.8,4,4.4,8.9,4.4,14.2c0,13.9-11.1,25-25,25S0,38.9,0,25S11.1,0,25,0
            c4.7,0,9,1.3,12.7,3.4l0,0C37.2,4.2,36.8,5.1,36.7,6.1L36.7,6.1z M42.1,1.9c2.6,0,4.7,2.1,4.7,4.7s-2.1,4.7-4.7,4.7
            s-4.7-2.1-4.7-4.7S39.5,1.9,42.1,1.9z M42.2,9.6L45.7,6c0.3-0.3,0.3-0.7,0-1s-0.7-0.3-1,0l-3.1,3.1l-1.8-1.8c-0.3-0.3-0.7-0.3-1,0
            s-0.3,0.7,0,1l2.3,2.3c0.1,0.1,0.3,0.2,0.5,0.2C41.8,9.8,42,9.7,42.2,9.6z"/>
        <path class="fill-primary-color" d="M25,10.3c4.6,0,8.3,3.7,8.3,8.3s-3.7,8.3-8.3,8.3s-8.3-3.7-8.3-8.3S20.4,10.3,25,10.3z M10.1,41.4
            c-0.2-2.8-0.3-7.8,1-11.1c0.3,0,1.4-4.7,6.4-4.5c0,0,3.9,3.3,7.8,3.1c0,0,3.1,0.3,6.9-3.3c0,0,5.3,0,6.7,6.4c0,0,1,3.5,0.7,9.7l0,0
            c-3.9,3.4-9,5.5-14.6,5.5C19.3,47.2,14.1,45,10.1,41.4L10.1,41.4z"/>
    </g>

</template>





<script>

    export default {

        name: 'SingleOwnerSelected'

    }

</script>