const routePrefix = '@/views/Error';
const meta = {
	hideNav: false,
	overflow: true,
	resetState: true,
	forceNavigation: true,
	bypassEnforcer: true,
	showFooter: true
};
const metaNoReset = {
	hideNav: true,
	overflow: true,
	resetState: true,
	forceNavigation: true,
	bypassEnforcer: true,
	showFooter: true
};

export default [
	{
		path: 'error/:statusType',
		name: 'Error',
		component: () => import(/* webpackChunkName: "Error" */ '@/views/_v2/Error'),
		meta: {
			backButton: {
				route: 'Home',
				title: 'Return to the homepage'
			},
			overflow: true,
			isError: true,
			//resetState: true,
			bypassEnforcer: true,
			showFooter: true
		}
	},
	{
		path: 'ineligible',
		name: 'Ineligible',
		component: () => import(/* webpackChunkName: "Ineligible" */ '@/views/_v2/Error/Ineligible'),
		meta
	},
	{
		path: 'declined',
		name: 'Declined',
		component: () => import(/* webpackChunkName: "Declined" */ '@/views/_v2/Error/Declined'),
		meta
	},
	{
		path: 'unverifiable',
		name: 'Unverifiable',
		component: () => import(/* webpackChunkName: "Unverifiable" */ '@/views/_v2/Error/Unverifiable'),
		meta
	},
	{
		path: 'duplicate/:type',
		name: 'Duplicate',
		component: () => import(/* webpackChunkName: "Duplicate" */ '@/views/_v2/Error/Duplicate'),
		meta
	},
	{
		path: 'existingMember',
		name: 'ExistingMember',
		component: () => import(/* webpackChunkName: "ExistingMember" */ '@/views/_v2/Error/ExistingMember'),
		meta
	},
	{
		path: 'memberIneligible',
		name: 'MemberIneligible',
		component: () => import(/* webpackChunkName: "MemberIneligible" */ '@/views/_v2/Error/MemberIneligible'),
		meta
	}
];
