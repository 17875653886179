import { render, staticRenderFns } from "./PhoneIcon.vue?vue&type=template&id=00942ab2&functional=true"
import script from "./PhoneIcon.vue?vue&type=script&lang=js"
export * from "./PhoneIcon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports