<template functional>

    <g>
        <path class="fill-primary-color" d="M25,9.4c4.9,0,8.9,4,8.9,8.9s-4,8.9-8.9,8.9s-8.9-4-8.9-8.9S20.1,9.4,25,9.4z M9.6,42.4
            c-0.2-2.7-0.5-8.6,1-12.2c0,0.3,1.4-4.7,6.7-4.4c0,0,4.2,3.6,8.3,3.1c0,0,3.3,0.3,7.2-3.6c0,0,5.6,0,6.9,6.7c0,0,1,3.9,0.6,10.6
            l0,0c-4.1,3.6-9.5,5.8-15.3,5.8C19.1,48.3,13.7,46.1,9.6,42.4L9.6,42.4z"/>
        <path class="fill-secondary-color" d="M25,50C11.1,50,0,38.9,0,25S11.1,0,25,0s25,11.1,25,25S38.9,50,25,50z M25,1.7C12.2,1.7,1.7,12.2,1.7,25
            S12.2,48.3,25,48.3S48.3,37.8,48.3,25S37.8,1.7,25,1.7z"/>
    </g>

</template>





<script>

    export default {

        name: 'SingleOwner'

    }

</script>