import axios from '@/utils/axios';

export interface IGetIneligibleAccountsResponse {
	ineligibleProducts: IProductSelection[];
	eligibleProducts: IProductSelectionConfiguration[];
}

export interface IProductSelection {
	productId: number;
	cardId: number;
	autoSelected: boolean;
	applicationProductId: number
}

export interface IProductSelectionConfiguration {
	id: number;
	productId: number;
	memberTypeId: number;
	order: number;
	hasCards: boolean;
	isRequired: boolean;
	benefitsText: string;
	helpText: string;
	displayName: string;
	iconName: boolean;
	isDisplayedForSelection: boolean;
}

export interface ISubmitReselectionRequest {
	applicationToken: string
	productSelection: IProductSelection[]
}

export interface ISubmitReselectionResponse {
	applicationProductId: number;
	reselectProductCardOrders: object[];
}

const getIneligibleAccountsAsync = async (applicationToken: string): Promise<IGetIneligibleAccountsResponse> => {
	try {
		const { data } = await axios.get<IGetIneligibleAccountsResponse>(`api/v2/Reselect/${applicationToken}`);

		return data;
	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};

const submitAccountReselectionAsync = async (request: ISubmitReselectionRequest): Promise<ISubmitReselectionResponse> => {
	try {
		const { data } = await axios.post<ISubmitReselectionResponse>('/api/v2/Reselect', request);

		return data;
	} catch (error) {
	
		if (typeof error === 'string') {
			throw new Error(error);
		}
		throw error;
	}
};

export default { getIneligibleAccountsAsync, submitAccountReselectionAsync };

export { getIneligibleAccountsAsync, submitAccountReselectionAsync };
