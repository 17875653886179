<template>
	<section v-if="!hasError">
		<OaoButton
			class="plaid_button"
			buttonType="button"
			id="externalButton"
			title="Select an Account"
			:disabled="submitting"
			@click="handleClick"
		/>

		<OaoButton
			v-if="hasCardOption"
			class="plaid_button"
			buttonType="button"
			id="cardFundingButton"
			title="Fund by Debit or Credit Card"
			:disabled="submitting"
			@click="toCardFunding"
		/>
	</section>
</template>

<script>
	import OaoButton from '@/components/OaoButton';

	import showSpinner from '@/mixins/showSpinner';
	import { logError } from '@/utils';
	import { mapActions, mapGetters } from 'vuex';


	export default {
		name: 'PlaidModalHandler',
		mixins: [showSpinner],
		components: {
			OaoButton
		},
		props: {
			selected: {
				type: Boolean,
				required: false
			},
			hasError: {
				type: Boolean,
				required: false,
				default: false
			},
			hasCardOption: {
				type: Boolean,
				required: true,
				default: true
			},
			submitting: { type: Boolean, required: false, default: true }
		},
		data() {
			return {
				instance: null
			};
		},
		async mounted() {
			try {
				this.showSpinner({ loading: true });
				//Store app id locally
				await this.writePlaidScript();
				return (
					(this.instance = Plaid.create({
						token: await this.getSessionToken(),
						onSuccess: (public_token, metadata) => this.$emit('success', { public_token, metadata }),
						onLoad: () => this.handleLoad(),
						onExit: (error, metadata) => this.handleExit(error, metadata),
						onEvent: (eventName, metadata) => this.$emit('event', { eventName, metadata })
					})),
					this.instance.open(),
					this.addDelay()
				);
			} catch (error) {
				this.submitting = false;
				return this.$toast.error(`Plaid | ${error.message || error}`);
			}
		},
		computed: {
			...mapGetters('applicant', ['applicantToken']),
			...mapGetters('application', ['applicationToken']),
		},
		methods: {
			...mapActions('funding', ['getPlaidSessionTokenAsync']),
			async writePlaidScript() {
				return new Promise((resolve, reject) => {
					const script = document.createElement('script');
					script.async = true;
					script.src = 'https://cdn.plaid.com/link/v2/stable/link-initialize.js';

					script.addEventListener('load', resolve);
					script.addEventListener('error', e => {
						return reject(e);
					});
					script.addEventListener('abort', e => {
						return reject(e);
					});

					document.head.appendChild(script);
				});
			},
			async getSessionToken() {
				try {
					const token = await this.getPlaidSessionTokenAsync();
					localStorage.setItem('linkTokenData', token);
					return token;
				} catch (error) {
					logError(error, error?.requestId, this.applicantToken, this.applicationToken);
					this.submitting = false;
					this.hasError = true;
					throw new Error(error);
				}
			},
			async handleClick() {
				try {
					this.$emit('click');
					this.showSpinner({ loading: true });
					return (
						(this.instance = Plaid.create({
							token: await this.getSessionToken(),
							onSuccess: (public_token, metadata) => this.$emit('success', { public_token, metadata }),
							onLoad: () => this.handleLoad(),
							onExit: (error, metadata) => this.handleExit(error, metadata),
							onEvent: (eventName, metadata) => this.$emit('event', { eventName, metadata })
						})),
						this.instance.open(),
						this.addDelay()
					);
				} catch (error) {
					this.submitting = false;
					this.showSpinner({ loading: false });
					this.$toast.error('ERROR: There was a problem connecting to Plaid. Please refresh and try again.');
				}
			},
			toCardFunding() {
				this.$router.push(this.$constructUrl('FundingCard'));
			},
			addDelay() {
				setTimeout(() => (this.submitting = false), 20000);
			},
			handleExit(error, metadata) {
				this.submitting = false;
				console.log('destroying instance in handler');
				this.instance.destroy();
				this.showSpinner({ loading: false });
				return this.$emit('exit', { error, metadata });
			},
			handleLoad() {
				this.submitting;
				this.showSpinner({ loading: true });
				return this.$emit('load');
			}
		}
	};
</script>

<style lang="scss" scoped>
	::v-deep .icon-button {
		padding: 0.5rem;
		border: 2px solid var(--disabled);
		border-radius: 10px;
		display: none;
	}

	h2 {
		margin-top: 1rem;
		font-size: 1.125rem;
		color: var(--primary);
		font-weight: 400;
		transition: color 0.5s;
	}

	.plaid_button {
		margin: auto;
		display: flex;
	}

	#cardFundingButton {
		margin-top: 25px;
	}
</style>
