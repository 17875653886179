import { identificationAPIv2 } from '@/api';
import setData from '../shared/setData';
import { Module } from 'vuex';
import { IRootState } from '..';
import { IIdentification, IApplicantIdentificationUpdateResponse } from '@/api/_v2/identification';
import { IdentificationTypes } from '@/constants';

export interface IIdentificationState {
	applicantIdentification: IIdentification | null;
	coApplicantIdentification: IIdentification | null;
	applicantIdentificationId: number | null;
	coApplicantIdentificationId: number | null;
}

const identification: Module<IIdentificationState, IRootState> = {
	namespaced: true,
	state: {
		applicantIdentification: null,
		coApplicantIdentification: null,
		applicantIdentificationId: null,
		coApplicantIdentificationId: null,
	},
	mutations: {
		setData,
		resetState(state) {
			state.applicantIdentification = null,
			state.coApplicantIdentification = null,
			state.applicantIdentificationId = null,
			state.coApplicantIdentificationId = null
		}
	},
	actions: {
		async getIdentificationByApplicantAsync({ commit, rootState}, request: any): Promise<any> {

			const response = await identificationAPIv2.getIdentificationByApplicantAsync(request);

			if (rootState.applicant?.applicantToken == request.applicantToken) {
				commit('setData', { objName: 'applicantIdentification', data: response.identification });
			}
			else  {
				commit('setData', { objName: 'coApplicantIdentification', data: response.identification });
			}

			return response;
		},
		async createIdentificationAsync({ commit }, request: any): Promise<any> {

			const response = await identificationAPIv2.createIdentificationAsync(request);
			
			if (request.isCoApplicant) {
				commit('setData', { objName: 'coApplicantIdentification', data: request.identification });
				commit('setData', { objName: 'coApplicantIdentificationId', data: response.identificationId });
			} else {
				commit('setData', { objName: 'applicantIdentification', data: request.identification });
				commit('setData', { objName: 'applicantIdentificationId', data: response.identificationId });
			}

			return response;
		},
		async updateIdentificationAsync({ commit }, request: any): Promise<any> {

			const response = await identificationAPIv2.updateIdentificationAsync(request);

			if (request.isCoApplicant) {
				commit('setData', { objName: 'coApplicantIdentification', data: request.identification });
				commit('setData', { objName: 'coApplicantIdentificationId', data: request.identificationId });
			} else {
				commit('setData', { objName: 'applicantIdentification', data: request.identification });
				commit('setData', { objName: 'applicantIdentificationId', data: request.identificationId });
			}

			return response;
		},
		async updateApplicantIdentificationDataAsync({ commit }, request: any): Promise<IApplicantIdentificationUpdateResponse> {

			const response = await identificationAPIv2.updateApplicantIdentificationDataAsync(request);


			return response;
		},
		
	},
	getters: {
		applicantIdentificationType: state => state.applicantIdentification?.identificationType,
		coApplicantIdentificationType: state => state.coApplicantIdentification?.identificationType
	}
};

export default identification;
