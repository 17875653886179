<template functional>

    <g>
        <path class="fill-primary-color" d="M34.4,8.3c4.9,0,8.9,4,8.9,8.9s-4,8.9-8.9,8.9s-8.9-4-8.9-8.9S29.5,8.3,34.4,8.3z M46.9,36.4
            c0-0.3,0.3-0.3,0.3-0.6c1.1-1.7,1.4-3.6,1.7-5.6c-1.7-6.1-6.9-6.1-6.9-6.1c-4.2,4.2-7.2,3.6-7.2,3.6c-4.2,0.3-8.3-3.1-8.3-3.1
            c-5.3-0.3-6.4,4.7-6.4,4.7c-2.2,4.7-0.8,13.6-0.8,13.6c5.3,3.6,12.2,4.4,15,4.7c2.8-1.9,5.8-3.6,8.3-5.8c0.3-0.3,0.3-0.6,0.6-0.6
            C44.2,39.4,45.6,38.1,46.9,36.4L46.9,36.4z"/>
        <path class="fill-secondary-color" d="M18.3,19.7c4,0,7.2,3.2,7.2,7.2s-3.2,7.2-7.2,7.2s-7.2-3.2-7.2-7.2S14.3,19.7,18.3,19.7z M30.3,38.3
            c-1.1-5.6-5.8-5.6-5.8-5.6c-3.3,3.3-6.1,3.1-6.1,3.1c-3.3,0.3-6.9-2.8-6.9-2.8c-4.4-0.3-5.3,3.9-5.3,3.9c-0.3,0.8-0.6,1.7-0.8,2.8
            c0,0.3,0.3,0.3,0.3,0.6s0.3,0.6,0.3,0.8c0.6,0.3,0.8,0.3,1.1,0.8c0.8,0.3,1.4,0.8,1.7,1.4c0.6,0.3,1.1,0.6,1.4,1.1
            c0.3,0,0.3,0.3,0.6,0.3c2.2,1.1,4.4,1.9,6.4,2.8c2.2,0.8,4.4,1.1,6.7,1.1c1.4,0,2.8,0,3.9,0.6c0.3,0,0.3,0,0.6,0
            c1.1-0.8,1.9-1.4,1.9-1.4C31.4,41.7,30.3,38.3,30.3,38.3z"/>
        <path class="fill-secondary-color" d="M25,50C11.1,50,0,38.9,0,25S11.1,0,25,0s25,11.1,25,25S38.9,50,25,50z M25,1.7C12.2,1.7,1.7,12.2,1.7,25
            S12.2,48.3,25,48.3S48.3,37.8,48.3,25S37.8,1.7,25,1.7z"/>
    </g>

</template>


<script>

    export default {

        name: 'JointOwner'

    }

</script>